<template>
  <div class="app-status">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.88 6.06"  class="offline" v-if="$store.state.ui.isOffline">
      <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.25px" class="cls-1" x1="0.7" y1="0.13" x2="6.51" y2="5.93"/>
      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="0.25px" class="cls-1" d="M5.43,4.85H1.23a1.11,1.11,0,0,1-.15-2.2l.17,0L1.2,2.46A1.55,1.55,0,0,1,1.58,1m.8-.44A1.52,1.52,0,0,1,3.73,1l.11.1.1-.1A1.53,1.53,0,0,1,6.48,2.45l0,.19h.23a1.11,1.11,0,0,1,0,2.21"/>
    </svg>
    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.884 6.057" class="online" v-if="!$store.state.ui.isOffline"><path d="M2.382,.574c.47-.093,.976,.038,1.349,.394l.107,.102,.107-.102c.38-.363,.92-.505,1.429-.376,.816,.207,1.31,1.037,1.102,1.853l-.038,.192h.199c.011,0,.022,0,.033,0,.611,.009,1.099,.512,1.09,1.123-.009,.611-.512,1.099-1.123,1.09H1.235c-.552,.002-1.022-.405-1.099-.952-.086-.605,.336-1.165,.94-1.25l.171-.024-.044-.167c-.135-.513,.006-1.059,.373-1.441,0,0,.324-.346,.806-.441Z" style="fill:none; stroke:currentColor; stroke-linecap:round; stroke-miterlimit:10; stroke-width:.25px;"/></svg> -->
  </div>
</template>

<script>

export default {
  name: 'Header',
  props: {
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style scoped>
.offline {
  width: 16px;
  height: 12px;
  color: #f00;
}
.online {
  width: 16px;
  height: 12px;
}
</style>