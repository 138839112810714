<template>
  <AppLayout>

    <template #navigation-title>
      <div v-if="project && customer" class="customer-details-title noselect">Kundendaten</div>
    </template>

    <template #page>
      <div class="content">
        <div class="left-side" v-if="project && customer">
          <InitialsBadge class="initials large" :user="customer" />
          <h3>{{ customer.firstName }} {{ customer.lastName }}</h3>
          <a class="email" :href="'mailto:' + customer.email">{{ customer.email }}</a>

          <div class="project-navigation">
            <table class="customer-details-link ios-style cursor-pointer">
              <tr data-testid="project" @click="$router.push('/project/edit')">
                <td class="noselect">Kundendaten</td>
                <td>
                  <ArrowRight />
                </td>
              </tr>
            </table>

            <table class="ios-style cursor-pointer">
              <tr data-testid="configurations" @click="$router.push('/configurations')">
                <td class="noselect">Konfigurationen</td>
                <td>
                  {{ stats.configurationCount}}
                  <ArrowRight />
                </td>
              </tr>
              <tr data-testid="scribbles" @click="$router.push('/scribbles')">
                <td class="noselect cursor-pointer">Skizzen</td>
                <td>
                  {{ stats.scribbleCount}}
                  <ArrowRight />
                </td>
              </tr>
              <tr data-testid="notes" @click="$router.push('/notes')">
                <td class="noselect cursor-pointer">Notizen</td>
                <td>
                  {{ stats.noteCount}}
                  <ArrowRight />
                </td>
              </tr>
              <tr data-testid="photos" @click="$router.push('/photos')">
                <td class="noselect cursor-pointer">Fotos</td>
                <td>
                  {{ stats.photoCount}}
                  <ArrowRight />
                </td>
              </tr>
            </table>

            <button
              data-testid="showPDF"
              :disabled="!isPdfEnabled"
              :class="'button min-width' + (isEmbedded ? ' ghost' : ' primary')"
              @click.stop="onShowPDF">PDF herunterladen</button>

            <button
              v-if="isEmbedded"
              :disabled="!saveNeeded"
              class="button primary min-width save-project"
              @click.stop="onSaveProject">Projekt speichern</button>

          </div>
        </div>
        <div class="left-side not-found" v-else>
          <span v-if="isEmbedded">Das Projekt wurde noch nicht synchronisiert. Drücken Sie auf <b>Synchronisieren</b>, um es zu laden.<br></span>
          <span v-else>Das Projekt wurde nicht nicht gefunden.</span>
          <button
            v-if="isEmbedded"
            class="button primary min-width sync-reload"
            @click.stop="onReload">Synchronisieren</button>
        </div>

        <div class="right-side">
          <EditProjectForm class="edit-project"
            @project:delete="$refs.deleteProjectSheet.present()"
            v-if="project" v-model="project" />
        </div>
      </div>
    </template>
    <template #modal>
      <DeleteProjectSheet ref="deleteProjectSheet" />
    </template>
  </AppLayout>

</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"

import { InitialsBadge, ArrowRight } from "@profineberater/configurator-clientlib"
import EditProjectForm from "@/components/projects/EditProjectForm.vue"
import DeleteProjectSheet from "./DeleteProjectSheet.vue"

export default {
  name: "ConfigurationsOverviewPage",
  components: {
    AppLayout,
    InitialsBadge,
    EditProjectForm,
    ArrowRight,
    DeleteProjectSheet
  },
  data: function() {
    return {
      saveNeeded: false
    }
  },
  computed: {
    isEmbedded() {
      return this.$store.getters["ui/isEmbedded"]
    },
    project() {
      return this.$store.getters["project/currentProject"]
    },
    customer() {
      return this.project?.customer
    },
    projectSubTitle() {
      let c = this.project?.customer
      return `${c?.streetAndNumber}, ${c?.zip} ${c?.city}`
    },
    stats() {
      return {
        configurationCount: this.project?.configurations?.length || 0,
        scribbleCount: this.project?.scribbles?.length || 0,
        noteCount: this.project?.notes?.length || 0,
        photoCount: this.project?.photos?.length || 0,
      }
    },
    isPdfEnabled() {
      return this.stats.configurationCount > 0 || this.stats.scribbleCount > 0 || this.stats.noteCount > 0 || this.stats.photoCount > 0
    }
  },
  methods: {
    onAddConfiguration() {
      this.$store.commit("ui/pendingAction", "add")
      this.$router.push({ path: "/configurations"})
    },
    onAddScribble() {
      this.$store.commit("ui/pendingAction", "add")
      this.$router.push({ path: "/scribbles"})
    },
    onAddNote() {
      this.$store.commit("ui/pendingAction", "add")
      this.$router.push({ path: "/notes"})
    },
    onAddPhoto() {
      this.$store.commit("ui/pendingAction", "add")
      this.$router.push({ path: "/photos"})
    },
    onShowPDF() {
      this.$router.push("/pdf")
    },
    onSaveProject() {
      this.$store.dispatch("project/update", this.project).then( () => {
        this.saveNeeded = false
      })
    },
    onReload() {
      window.location.reload()
    }
  },
  watch: {
    project: {
      handler(newProject) {
        let lastSavedProject = this.$store.getters["project/lastSavedProject"]
        if (lastSavedProject != null && (JSON.stringify(newProject) !== JSON.stringify(lastSavedProject))) {
          this.saveNeeded = true
        }
      },
      deep: true,
      immediate: true
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      let action = vm.$store.getters["ui/pendingAction"]
      vm.$store.commit("ui/pendingAction", null)
      if (action == "add") {
        setTimeout( () => {
          vm.onAdd()
        }, 500)
      }
    })
    return true
  },
  beforeRouteLeave (to, from, next) {
    if (to.path == "/login") {
      next()
    } else {
      if (!this.project) {
        next()
      } else {
        this.$store.dispatch("project/update", this.project).then( () => {
          next()
        })
      }
    }
  },
};
</script>

<style scoped>
.content {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 35% 1fr;
  gap: 0px 0px;
  height: calc(100vh - var(--navigation-bar-height) - 20px);
  overflow-y: auto;
  overflow-x: hidden;
}

.right-side {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 40px;
  /* overflow: hidden; */
}

:deep(.initials-badge) {
  margin: 0 auto;
  background: #ebeff4;
  margin-bottom: 40px;
}
/* :deep(.title) {
  padding-right: 25%;
  text-align: right;
} */
.customer-details-title {
  width: 100%;
  padding-left: 45%;
}
a.email {
  font-size: 14px;
  font-weight: 400;
}
.project-navigation {
  width: 80%;
  margin: 40px auto;
  transform: translateX(-20px);  /* FIXME */
}

.project-navigation button {
  width: 100%;
  padding: 0;
}

.project-navigation table {
  margin-bottom: 40px;
}
.edit-project {
  padding-left: 40px;
}
.customer-details-link {
  display: none;
}

button.save-project {
  margin-top: 20px;
}

button.sync-reload {
  margin-top: 20px;
}
.not-found {
  text-align: left;
}

@media only screen and (max-width : 1024px) {
  .customer-details-link {
    display: table;
  }
  .project-navigation {
    width: 100%;
    padding: 0 20px;
    transform: none; /* FIXME */
  }
  .content {
    display: block;
    height: calc(100vh - var(--navigation-bar-height) - var(--tab-bar-height));
  }
  .edit-project {
    display: none;
  }
  .customer-details-title {
    width: 100%;
    padding-left: 0;
  }
}

</style>
