<template>
    <div class="custom-selector window-rails-type-selector">
      <SelectorTile>
        <template #figure>
          <img :src="imageURL">
        </template>
        <template #form>
          <label>Variante</label>
          <DropDown :options="options.values" v-model="configuration.windowRailsType" :placeholder="options.placeholder" :allowNoSelection="false" />
        </template>
      </SelectorTile>
    </div>
</template>

<script>
import SelectorTile from "@/components/configuration/SelectorTile.vue";
import { DropDown } from "@profineberater/configurator-clientlib"

export default {
  name: "WindowRailsTypeSelector",
  components: {
    SelectorTile,
    DropDown,
  },
  data: function () {
    return {
      selectedOptions: null,
    };
  },
  props: {},
  methods: {
  },
  computed: {
    configuration() {
      return this.$store.getters["configuration/configuration"]
    },
    imageURL() {
      return this.$store.getters["configurationOptions/metadataForKey"](this.configuration.windowRailsType || "keine sprossen")?.url
    },
    options() {
      let opts = this.$store.getters["configurationOptions/dropdownOptionsForKey"]("windowRailsType", this.configuration)
      return {
        placeholder: "Bitte wählen",
        values: opts
      }
    }
  },
};
</script>

<style scoped>
.roller-shutter-selector {
  display: flex;
  flex-direction: column;
}
</style>

