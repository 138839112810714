export const ModalKeys = Object.freeze({
  NONE: 'none',
  DRAWING_PAD: 'drawingPad',
  NOTES: 'notes',
  CART: 'cart',
  ITEMS: 'items'
})

var deferrBlockingSyncTimer = null
const deferBlockingSyncInterval = 1000

const initialState = {
  app: 'framework',
  error: null,
  backNavigation: null,
  pendingTransition: null,
  pendingAction: null,
  appUpdateAvailable: false,
  isShowingModal: false,
  currentModalKey: ModalKeys.NONE,
  isLoading: false,
  userMessages: [],
  isOffline: false,
  contextMenuState: {
    active: false,
    top: 0,
    left: 0,
    data: null
  },
  isSmartphone: false,
  isNavigationBarTransparent: false,
  globalError: {
    visible: false,
    message: "Hinweis",
    description: "Unerwarteter Fehler",
  },
  isBlockingSyncInProgress: false,
  isUserDBSyncInProgress: true,
  reload: false, // used to force reload of page,
  isEmbedded: false, // is opened by configurator-admin
  profileValidationModel: {
    firstName: {},
    lastName: {},
    companyName: {},
    streetAndNumber: {},
    zip: {},
    city: {},
    country: {},
    phone: {},
    mobile: {},
    email: {},
    website: {},
  },
  isShowArchive: false,
}

export const ui = {
  namespaced: true,
  state: () => { return initialState },
  mutations: {
    currentModalKey(state, currentModalKey) {
      state.currentModalKey = currentModalKey
      state.isShowingModal = currentModalKey == ModalKeys.NONE ? false : true
    },
    isOffline(state, isOffline) {
      state.isOffline = isOffline
    },
    contextMenuState(state, menuState) {
      state.contextMenuState = menuState
    },
    contextMenuActive(state, active) {
      let menuState = state.contextMenuState
      menuState.active = active
      state.contextMenuState = menuState
    },
    backNavigation(state, backNavigation) {
      state.backNavigation = backNavigation
    },
    pendingAction(state, pendingAction) {
      state.pendingAction = pendingAction
    },
    pendingTransition(state, pendingTransition) {
      state.pendingTransition = pendingTransition
    },
    appUpdateAvailable(state, appUpdateAvailable) {
      state.appUpdateAvailable = appUpdateAvailable
    },
    isSmartphone(state, isSmartphone) {
      state.isSmartphone = isSmartphone
    },
    isNavigationBarTransparent(state, isNavigationBarTransparent) {
      state.isNavigationBarTransparent = isNavigationBarTransparent
    },
    customAppLayoutClass(state, customAppLayoutClass) {
      state.customAppLayoutClass = customAppLayoutClass
    },
    globalError(state, globalError) {
      state.globalError = globalError
    },
    showGlobalError(state, show) {
      state.globalError = {
        visible: show,
        message: "",
        description: ""
      }
    },
    isBlockingSyncInProgress(state, value) {
      state.isBlockingSyncInProgress = value
      deferrBlockingSyncTimer && clearTimeout(deferrBlockingSyncTimer)
    },
    setBlockingSyncInProgressDeferred(state) {
      deferrBlockingSyncTimer = window.setTimeout(() => {
        state.isBlockingSyncInProgress = true
      }, deferBlockingSyncInterval)
    },
    reload(state, reload) {
      state.reload = reload
    },
    isEmbedded(state, isEmbedded) {
      state.isEmbedded = isEmbedded
    },
    isUserDBSyncInProgress(state, value) {
      state.isUserDBSyncInProgress = value
    },
    profileValidationModel(state, profileValidationModel) {
      state.profileValidationModel = profileValidationModel
    },
    isShowArchive(state, isShowArchive) {
      state.isShowArchive = isShowArchive
    },
    error(state, error) {
      if (error && error.response && error.response.data && error.response.data.error) {
        state.error = error.response.data.error
      } else {
        state.error = error
      }
    },
  },
  getters: {
    backNavigation( state ) {
      return state.backNavigation
    },
    pendingAction( state ) {
      return state.pendingAction
    },
    pendingTransition( state ) {
      return state.pendingTransition
    },
    appUpdateAvailable( state ) {
      return state.appUpdateAvailable
    },
    isBlockingSyncInProgress( state ) {
      return state.isBlockingSyncInProgress
    },
    testDataEnabled() {
      return process.env.VUE_APP_TESTDATA_ENABLED === "true"
    },
    contextMenuState(state) {
      return state.contextMenuState
    },
    reload(state) {
      return state.reload
    },
    isEmbedded(state) {
      return state.isEmbedded
    },
    isUserDBSyncInProgress(state) {
      return state.isUserDBSyncInProgress
    },
    profileValidationModel(state) {
      return state.profileValidationModel
    },
    isShowArchive(state) {
      return state.isShowArchive
    },
    app(state) {
      return state.app
    }
  },
  actions: {
    resetProfileValidationModel({ commit }) {
      commit('profileValidationModel', JSON.parse(JSON.stringify(initialState.profileValidationModel)))
    }
  }
};
