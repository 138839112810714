<template>
  <div :class="'app-layout ' + $store.state.ui.customAppLayoutClass">
    <!-- <div class="status-info">{{ $store.getters["ui/isEmbedded"] ? "embedded" : "normal" }}</div> -->
    <NavigationBar>
      <template #navigation-accessories>
        <AppStatus />
      </template>
      <template #navigation-title>
          <slot name="navigation-title"></slot>
      </template>
      <template #right-navigation-item>
          <slot name="right-navigation-item"></slot>
      </template>
    </NavigationBar>
    <div class="page">
      <PageTitle>
        <template #page-title>
          <slot name="page-title"></slot>
        </template>
      </PageTitle>
      <slot name="page"></slot>
    </div>
    <div class="modal">
      <slot name="modal"></slot>
    </div>
    <div class="blur" v-show="false">
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/components/navigationBar/NavigationBar.vue"
import AppStatus from "@/components/AppStatus.vue"
import PageTitle from "@/components/page/PageTitle.vue"

export default {
  name: "AppLayout",
  components: {
    NavigationBar,
    PageTitle,
    AppStatus
  },
}
</script>

<style scoped>
.app-layout {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.app-layout.project-details {
  background: linear-gradient(90deg, rgba(251,251,252,1) 0%, rgba(251,251,252,1) calc(35% + var(--navigation-bar-height) - 20px), rgba(233,235,242,1), rgba(247,248,250,1) calc(35% + var(--navigation-bar-height) + 1px  - 20px), rgba(247,248,250,1) 100%);
}
.app-layout.project-details .page {
  padding: 20px 0 0 30px;
}

.page {
  position: relative;
  /* margin-left: 50px; */
  /* background-color: var(--page-bg-color); */
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px;
  width: 100vw;
  height: calc(100vh - var(--navigation-bar-height));
  transition: padding 0.75s ease-in-out;
}
.app-layout.full-width .page {
    padding: 0;
}
.app.has-tabbar .page {
  height: calc(100vh - var(--navigation-bar-height));
  width: calc(100vw - 100px);
  margin-left: 100px;
}
.blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0.95;
  z-index: 1010;
}

.status-info {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 20px;
  color: var(--primary-color);
  z-index: 999999999;
}
@media only screen and (max-width : 1023px) {
  .page, .app.has-tabbar .page {
    margin-left: 0;
    width: 100%;
    padding: 0 20px;
  }
  .app-layout.full-width .page {
    padding: 0;
  }

  .app.has-tabbar .page {
    height: calc(100vh - var(--navigation-bar-height) - var(--tab-bar-height));
  }

  .app-layout.project-details {
    background: none;
    background-color: var(--page-color);
  }

  .app-layout.project-details .page {
    padding: 0;
  }
}

@media only screen and (min-width : 1024px) {
  .page {
    padding: 20px 20px 40px 30px;
  }
}

</style>