<template>
    <AppLayout>
      <template v-if="itemCount" #page-title>
        <h1>Fotos</h1>
      </template>
      <template #navigation-title>
        {{ $store.getters["project/navigationTitle"] }}
      </template>
      <template #page>
        <div class="photos-page">
          <input ref="uploadPhotoInput" type="file" id="picture" class="filepond" accept="image/*" @change="handleUpload">
          <section class="photos-collection-sec" v-if="itemCount">
            <PageSectionHeader :title="numberOfPhotosTitle" />
              <div class="gallery">
              <figure :class="'gallery-item' + (photo.positionNumber || photo.title ? ' has-title' : '')" v-for="(photo, index) in photos" v-bind:key="index">
                <img class="gallery-item__image"
                      :id="getPhotoAttachmentId(photo).replace(/\.[^/.]+$/, '')"
                      :src="attachments[getPhotoAttachmentId(photo)]"
                      @click="onPhotoClick(photo, index)"
                      @load="showEditButton(index)">
                  <PhotoEditor
                    ref="photoeditor"
                    class="photo-editor"
                    :photo="photo"
                    :project="project"
                    :photoRef="getPhotoAttachmentId(photo).replace(/\.[^/.]+$/, '')"
                    :discardMarkerChanges="discardMarkerChanges"
                    :saveMarkerChanges="saveMarkerChanges"
                    @onCancel="onCancelMarkerChanges()"
                  />
                  <CloseIcon class="delete-action" @click="onRequestDeletePhoto(photo)" />
                <div class="photo-title">
                  <span>{{ photo.title }}<span v-if="photo.positionNumber" class="photo-position-number"> ({{ photo.positionNumber }})</span></span>
                  <br v-if="photo.title || photo.positionNumber"><span class="date" v-html="createdAt(photo)"></span>
                </div>
              </figure>
              </div>
          </section>
          <NoContentPlaceholder v-else title="Fotos" text="Machen Sie Ihr erstes Foto.">
            <img src="@/assets/svg/illustration-foto.svg">
          </NoContentPlaceholder>

          <AddButton @click="onAdd" />
        </div>
      </template>
      <template #modal>
        <BottomSheet ref="contextMenuBottomSheet"
          @sheet:presented="onBottomSheetDidPresent"
          @sheet:dismissed="onBottomSheetDidDismiss">
          <template #bottom-sheet-title>
            <slot name="bottom-sheet-title">Foto bearbeiten</slot>
          </template>
          <template #bottom-sheet-content>
            <slot name="bottom-sheet-content">
              <TitleAndPositionInput v-model="properties" />
            </slot>
          </template>
          <template #bottom-sheet-actions>
            <button class="button primary" @click.stop="onAcceptEditProperties">Übernehmen</button>
            <button
              class="plain"
              @click.stop="onCancelEditProperties">
              Abbrechen
            </button>
          </template>
        </BottomSheet>
        <BottomSheet ref="deletePhotoBottomSheet">
          <template #bottom-sheet-title>
            <slot name="bottom-sheet-title">Foto löschen</slot>
          </template>
          <template #bottom-sheet-content>
            <slot name="bottom-sheet-content">
              <img class="thumb" :src="photoToBeDeletedUrl">
            </slot>
          </template>
          <template #bottom-sheet-actions>
            <button class="button primary login tall" @click="onDeletePhoto()">
              <span>Löschen</span>
            </button>
            <button class="button secondary login tall" @click="onCancelDeletePhoto">
              <span>Abbrechen</span>
            </button>
          </template>
        </BottomSheet>
        <BottomSheet ref="discardMarkerChangesBottomSheet">
          <template #bottom-sheet-title>
            <slot name="bottom-sheet-title">Änderungen speichern</slot>
          </template>
          <template #bottom-sheet-content>
            <slot name="bottom-sheet-content">
              Es gibt ungesicherte Änderungen. Möchten Sie die Änderungen speichern?
            </slot>
          </template>
          <template #bottom-sheet-actions>
            <button class="button primary login tall" @click="onSaveMarkerChanges()">
              <span>Speichern</span>
            </button>
            <button class="button secondary login tall" @click="onDiscardMarkerChanges()">
              <span>Verwerfen</span>
            </button>
          </template>
        </BottomSheet>
      </template>
    </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout.vue";
import BottomSheet from "@/components/modal/BottomSheet.vue";
import AddButton from "@/components/buttons/AddButton.vue"
import NoContentPlaceholder from "@/components/NoContentPlaceholder.vue"
import PhotoEditor from "@/components/photoEditor/PhotoEditor.vue"

import { nanoid } from "nanoid";
import * as FilePond from "filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

import PhotoSwipeLightbox from "photoswipe/dist/photoswipe-lightbox.esm"
import 'photoswipe/dist/photoswipe.css';

import de_DE from "filepond/locale/de-de.js";

import PageSectionHeader from "@/components/page/PageSectionHeader.vue"
import { AttachmentsMixin, CloseIcon } from "@profineberater/configurator-clientlib"
import TitleAndPositionInput from '@/components/modal/TitleAndPositionInput.vue'
import DateMixin from "@/mixins/DateMixin.vue"

var lightbox

export default {
  name: "PhotosPage",
  mixins: [AttachmentsMixin, DateMixin],
  components: {
    AppLayout,
    BottomSheet,
    PageSectionHeader,
    AddButton,
    NoContentPlaceholder,
    PhotoEditor,
    CloseIcon,
    TitleAndPositionInput
  },
  data: function() {
    return {
      photoToBeDeleted: null,
      photoToBeDeletedUrl: null,
      saveOnBottomSheetDismiss: false,
      properties: {
        title: null,
        positionNumber: null
      },
      discardMarkerChanges: false,
      saveMarkerChanges: false,
    }
  },
  created() {
    FilePond.setOptions(de_DE);
    FilePond.registerPlugin(FilePondPluginImagePreview);
    FilePond.registerPlugin(FilePondPluginImageTransform);
    FilePond.setOptions({
      credits: false,
      server: {
         // eslint-disable-next-line no-unused-vars
        process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {

          this.savePhotoToDB(file, `photo-${nanoid(10)}.jpg`, file.type).then((id) => {
            console.log("written into DB", id)
            load(id);
          });

          // Should expose an abort method so the request can be cancelled
          return {
            abort: () => {
              // This function is entered if the user has tapped the cancel button

              // Let FilePond know the request has been cancelled
              abort();
            },
          };
        },
      },
    });
    FilePond.create({
      imageResizeTargetWidth: 600,
      imageCropAspectRatio: 1,
      imageTransformVariants: {
        // thumb_medium_: (transforms) => {
        //   transforms.resize = {
        //     size: {
        //       width: 384,
        //       height: 384,
        //     },
        //   };
        //   return transforms;
        // },
        thumb_small_: (transforms) => {
          transforms.resize = {
            size: {
              width: 128,
              height: 128,
            },
          };
          return transforms;
        },
      },
      imageTransformAfterCreateBlob: (blob) => {
        //let id = nanoid(10);
        return new Promise((resolve) => {
          //this.savePhotoToDB(blob, `thumb_${id}_small.jpg`, "image/jpeg");
          resolve(blob);
        });
      },
    });
  },
  mounted() {
    this.loadAttachments()
    FilePond.parse(document.body);
    lightbox = new PhotoSwipeLightbox({
      gallery: '#gallery',
      children: 'a',
      bgOpacity: 0.9,
      //dataSource: this.lightboxDataSource,
      pswpModule: () => import('photoswipe'),
    });
    lightbox.on('uiRegister', () => {
      lightbox.pswp.ui.registerElement({
        name: 'more-item',
        ariaLabel: 'Mehr',
        order: 9,
        isButton: true,
        html: this.moreItem,
        onClick: (/*event, el*/) => {
          lightbox.pswp.close()
          this.$refs.contextMenuBottomSheet.present()
        }
      });
    });
    lightbox.on('change', () => {
      let currPhoto = this.photos[lightbox.pswp.currIndex]
      this.currentPhoto = currPhoto
    });
    lightbox.addFilter('numItems', () => {
      return this.lightboxDataSource.length
    });
    lightbox.addFilter('itemData', (itemData, index) => {
      return this.lightboxDataSource[index]
    })
    lightbox.init()
    console.log("mounted", lightbox)
  },
  computed: {
    moreItem() {
      return `
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
        <g transform="translate(-225.447 -50.447)">
          <g transform="translate(231.724 65.128) rotate(-90)">
            <circle cx="1.5" cy="1.5" r="1.5" transform="translate(-0.319 -0.276)" fill="#ffffff"/>
            <circle cx="1.5" cy="1.5" r="1.5" transform="translate(-0.319 5.724)" fill="#ffffff"/>
            <circle cx="1.5" cy="1.5" r="1.5" transform="translate(-0.319 11.724)" fill="#ffffff"/>
          </g>
          <g transform="translate(225.447 50.447)" fill="none" stroke="#ffffff" stroke-width="2">
            <circle cx="13" cy="13" r="13" stroke="none"/>
            <circle cx="13" cy="13" r="12" fill="none"/>
          </g>
        </g>
      </svg>`
    },
    project() {
      return this.$store.getters["project/currentProject"];
    },
    photos() {
      return this.project?.photos
    },
    itemCount() {
      return this.photos?.length || 0
    },
    lightboxDataSource() {
      let ds = this.photos.map( p => {
        return {
          src: this.attachments[this.getPhotoAttachmentId(p)],
          width: p.width || 800,
          height: p.height || 600
        }
      })
      return ds
    },
    numberOfPhotosTitle() {
      let num = this.photos?.length || 0
      switch (num) {
        case 0: return "Keine Fotos"
        case 1: return "1 Foto"
        default: return num + " Fotos"
      }
    },
    currentPhoto: {
      get() {
        return this.$store.getters["project/currentPhoto"]
      },
      set(photo) {
        this.$store.commit("project/currentPhoto", photo)
        this.properties = {
          title: photo.title,
          positionNumber: photo.positionNumber
        },
        this.saveOnBottomSheetDismiss = true
      }
    },
  },
  methods: {
    onAdd() {
      this.$refs.uploadPhotoInput.click();
    },
    onEditPhoto(photo) {
      this.$store.commit("project/currentPhoto", photo)
      this.$router.push("/photos/edit")
    },
    onRequestDeletePhoto(photo) {
      this.photoToBeDeleted = photo
      this.photoToBeDeletedUrl = this.attachments[this.getPhotoAttachmentId(photo)]
      this.$refs.deletePhotoBottomSheet.present()
    },
    onDeletePhoto() {
      this.$store.dispatch("project/deletePhoto", { project: this.project, photo: this.photoToBeDeleted } )
      this.saveOnLeave = false
      this.$refs.deletePhotoBottomSheet.dismiss()
      this.photoToBeDeleted = null
      this.photoToBeDeletedUrl = null
    },
    onCancelDeletePhoto() {
      this.$refs.deletePhotoBottomSheet.dismiss()
      this.photoToBeDeleted = null
      this.photoToBeDeletedUrl = null
    },
    onContextMenu() {
      this.$refs.contextMenuBottomSheet.present()
    },
    onPhotoClick(photo, index) {
      this.currentPhoto = photo
      lightbox.loadAndOpen(index);
    },
    onBottomSheetDidPresent() {
      this.saveOnBottomSheetDismiss = false
    },
    onBottomSheetDidDismiss() {
      if (this.saveOnBottomSheetDismiss) {
        this.saveProject()
      }
    },
    onAcceptEditProperties() {
      this.currentPhoto.title = this.properties.title
      this.currentPhoto.positionNumber = this.properties.positionNumber
      this.saveOnBottomSheetDismiss = true
      this.$refs.contextMenuBottomSheet.dismiss()
    },
    onCancelEditProperties() {
      this.$refs.contextMenuBottomSheet.dismiss()
    },
    onCancelMarkerChanges() {
      if (this.$refs.discardMarkerChangesBottomSheet) {
        this.$refs.discardMarkerChangesBottomSheet.present()
      }
    },
    onDiscardMarkerChanges() {
      this.discardMarkerChanges = true
      this.$refs.discardMarkerChangesBottomSheet.dismiss()
    },
    onSaveMarkerChanges() {
      this.saveMarkerChanges = true
      this.$refs.discardMarkerChangesBottomSheet.dismiss()
    },
    saveProject() {
      return this.$store.dispatch("project/update", this.project)
    },
    savePhotoToDB(blob, filename, mimetype) {
      return new Promise((resolve) => {
        var img = new Image();
        var urlCreator = window.URL || window.webkitURL
        var imageUrl = urlCreator.createObjectURL(blob)
        img.src = imageUrl
        img.onload = () => {
          const photo = {
            width: img.width,
            height: img.height,
            originalAttachmentId: filename,
            createdAt: new Date(),
          }
          this.$store.dispatch("project/addPhoto", {
            project: this.project,
            photo: photo
          }).then( updatedProject => {
            this.$store.dispatch("db/saveBlobToDB", {
              document: updatedProject,
              blob: blob,
              attachmentId: filename,
              mimetype: mimetype
            }).then ( (attachmentId) => {
              this.currentPhoto = photo
              resolve(attachmentId)
            })
          })
        }
      })
    },
    handleUpload(e) {
      let file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => {
        this.savePhotoToDB(file, `photo-${nanoid(10)}.jpg`, file.type)
      };
    },
    loadAttachments() {
      this.$store.dispatch("project/getPhotoURLs", {
        project: this.project,
      }).then( urlMap => {
        Object.keys(urlMap).forEach( id => {
          this.attachmentIds.add(id)
          // if (id.startsWith('edited-')) {
          //   let newId = id.substring('edited-'.length);
          //   console.log(newId)
          //   this.attachmentIds.add(newId);
          // }
        })
      })
    },
    showEditButton(index) {
      let e = this.$refs.photoeditor[index]
      if (e) {
        e.$el.style.opacity = 1
      }
    }
  },
  watch: {
    photos() {
      this.loadAttachments()
    }
  },
  unmounted() {
    this.disposeAttachments()
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      let action = vm.$store.getters["ui/pendingAction"]
      vm.$store.commit("ui/pendingAction", null)
      if (action == "add") {
        setTimeout( () => {
          vm.onAdd()
        }, 500)
      }
    })
    return true
  },
};
</script>

<style>
.photos-page {
  padding-top: 20px;
}
.pswp__counter {
  font-family: Roboto;
}
.pswp {
  --pswp-root-z-index: 1001;
}
</style>
<style scoped>
.photo-item {
  position: relative;
}

.photo-editor {
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}


/* .gallery-item.has-title .photo-editor {
  bottom: 35px;
} */
#picture {
  display: none;
}

ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex: 0 0 28%;
}
li {
  margin: 4px;
}

.delete-action {
  position: absolute;
  right: 10px;
  top: 10px;
}
:deep(.filepond) {
  display: none;
  opacity: 0;
  pointer-events: none;
}

section.photos-collection ul {
    display: flex;
    flex-direction: column;
}

.thumb {
  cursor: pointer;
  max-width: 90%;
}


*{
    padding: 0;
    margin: 0;
}
.gallery{
    display:flex;
    flex-flow: wrap;
}

.gallery-item {
    flex-grow: 0;
    min-width: 256px;
    background: #eee;
    position: relative;
    margin: 5px;
    cursor: pointer;
}
.photo-title {
  font-weight: 500;
  font-size: 13px;
  background-color: var(--page-bg-color);
  /* position: absolute;
  bottom: 0;
  left: 0; */
  height: 40px;
  width: 100%;
  color: var(--darkest-text-color);
  /* backdrop-filter: blur(5px) brightness(1.5) saturate(0.5); */
  padding: 5px 0;
}
.date {
  color: var(--label-color);
  font-size: 12px;
}
.gallery-item:before{
    content: "";
    padding-top: 100%;
    display: block;
}

.gallery-item__image{
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% - 40px);
    object-fit: cover;
    object-position: center;
}

@media only screen and (max-width : 860px) {
  .gallery-item {
    flex-grow: 1;
  }
}

@media only screen and (min-width : 1024px) {
  :deep(.filepond) {
    display: block;
    opacity: 1;
    pointer-events: all;
  }

  /* section.photos-collection {
    background-color: aquamarine;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  section.photos-collection ul {
    max-width: 80vw;
    flex-grow: 0;
    display: flex;
  }
  .thumb {
    max-height: 26vw;
  } */

}
</style>