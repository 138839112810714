<template>
    <div class="custom-selector window-ledge-inner-selector">
      <SelectorTile>
        <template #figure>
          <img width="200" src="@/assets/svg/infografik-fensterbank-innen.svg">
        </template>
        <template #form>
          <div v-if="configuration.oddLegFrameExtension" class="input-fields">
            <label>Fensterbank innen</label>
            <DropDown :options="windowLedgeOptions.values" v-model="model.windowLedgeInner" :placeholder="windowLedgeOptions.placeholder" :allowNoSelection="false" />
            <NumberInput :disabled="isNone" label="Fensterbank Tiefe" v-model="model.windowLedgeInnerDepth" description="" placeholder="0 mm" :min="0" />
            <label>Material</label>

            <ItemTiles
              class="material-selector"
              v-if="true"
              :disabled="isNone"
              :category="materialCategory"
              :categoryOptions="materialOptions"
              @item:addcustom="onAddCustom" />
            <div v-if="materialOptions.length == 0" :class="'info-box dependency-missing' + (isNone ? ' disabled' : '')">Keine Materialien verfügbar</div>

            <label>Modell</label>

            <ItemTiles
              class="model-selector"
              :disabled="isNone || !configuration[materialCategory.configurationKey]"
              :category="modelCategory"
              :categoryOptions="modelOptions"
              @item:addcustom="onAddCustom" />
            <div v-if="configuration[materialCategory.configurationKey] && modelOptions.length == 0" :class="'info-box dependency-missing' + (isNone ? ' disabled' : '')">Keine Modelle verfügbar</div>

            <label>Dekor</label>
            <ItemTiles
              class="color-selector"
              :disabled="isNone || !configuration[modelCategory.configurationKey]"
              :category="colorCategory"
              :categoryOptions="colorOptions"
              @item:addcustom="onAddCustom" />
            <div v-if="configuration[modelCategory.configurationKey] && colorOptions.length == 0" :class="'info-box dependency-missing' + (isNone ? ' disabled' : '')">Keine Dekore verfügbar</div>

            <CustomConfigurationText :disabled="isNone" v-model="model.windowLedgeInnerCustomText" placeholder="Details zur Fensterbank innen …" />

          </div>
          <WindowLedgeInfoText v-else windowLedgeType="inner" />
        </template>
      </SelectorTile>
    </div>
</template>

<script>
import SelectorTile from "@/components/configuration/SelectorTile.vue";
import { NumberInput } from "@profineberater/configurator-clientlib"
import { DropDown } from "@profineberater/configurator-clientlib"
import ItemTiles from "@/components/configuration/items/ItemTiles.vue"
import WindowLedgeInfoText from "./WindowLedgeInfoText.vue";
import CustomConfigurationText from '@/components/configuration/custom/CustomConfigurationText.vue';

export default {
  name: "WindowLedgeInnerSelector",
  components: {
    SelectorTile,
    NumberInput,
    DropDown,
    ItemTiles,
    WindowLedgeInfoText,
    CustomConfigurationText
  },
  data: function() {
    return {
      model: this.configuration
    }
  },
  props: {
    configuration: {
      type: Object
    },
    category: {
      type: Object
    }
  },
  computed: {
    materialCategory() {
      return this.category?.children.find( c => c.key == "windowLedgeInnerMaterial")
    },
    modelCategory() {
      return this.category?.children.find( c => c.key == "windowLedgeInnerModel")
    },
    colorCategory() {
      return this.category?.children.find( c => c.key == "windowLedgeInnerColor")
    },
    isNone() {
      let metadata = this.$store.getters["configurationOptions/metadataForKey"](this.model.windowLedgeInner)
      return metadata?.isNone
    },
    windowLedgeOptions() {
      //let opts = this.$store.getters["configurationOptions/dropdownOptionsForKey"]("windowRailsType", this.configuration)
      return {
        placeholder: "Bitte wählen",
        values: [
          {
            label: "Ohne Fensterbank innen",
            value: "ohne fensterbank innen"
          },
          {
            label: "Fensterbank innen",
            value: "mit fensterbank innen"
          },
        ]
      }
    },
    materialOptions() {
      return this.$store.getters["configurationOptions/optionsForKey"]("windowLedgeInnerMaterial", this.configuration)
    },
    modelOptions() {
      return this.$store.getters["configurationOptions/optionsForKey"]("windowLedgeInnerModel", this.configuration)
    },
    colorOptions() {
      return this.$store.getters["configurationOptions/optionsForKey"]("windowLedgeInnerColor", this.configuration)
    },
  },
  methods: {
    onAddCustom(item, category) {
      this.$emit('item:addcustom', item, category)
    },
  }
};
</script>

<style scoped>
.extensions-selector {
  display: flex;
  flex-direction: column;
}
.input-fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.input-fields > * {
  margin-right: 10px;
  width: 100%;
}
.input-fields > *:last-child {
  margin-right: 0;
}
.material-selector, .model-selector, .color-selector {
  margin-bottom: 20px;
}

.custom-configuration-text {
  margin-bottom: 0;
}

</style>

