<template>
  <div ref="scrollContainer" class="configuration">
    <div class="configuration-content">
      <transition name="fade" mode="out-in">
        <ConfigurationSummary
          key="summary"
          v-if="showSummary"
          :configuration="configuration"
          @step:selectindex="this.$emit('step:selectindex', $event)"
          @summary:hide="this.$emit('summary:hide')"
        />
        <ConfigurationStep
          v-else
          key="step"
          :step="currentStep"
          :configuration="configuration"
          @category:showdetailview="onShowDetails"
          @item:addcustom="onAddCustom"
          @summary:show="$emit('summary:show')"
        />
      </transition>
    </div>
  </div>
</template>

<script>

import ConfigurationStep from "./ConfigurationStep.vue"
import ConfigurationSummary from "@/components/configuration/summary/ConfigurationSummary.vue"
import OverscrollFixMixin from "@/mixins/OverscrollFixMixin.vue"
import WindowRailsLayoutMixin from "@/components/configuration/windowRails/WindowRailsLayoutMixin.vue"

import { RS_ROLLERSHUTTER_FRONTMOUNTEDBOX, RS_ROLLERSHUTTER_ADDONBOX, RS_ROLLERSHUTTER_ADDONBOXINSULATED } from "@/aufmass.js"

import Aufmass from "@/aufmass.js"


export default {
  name: 'ConfigurationProcess',
  mixins: [OverscrollFixMixin, WindowRailsLayoutMixin],
  props: {
    showSummary: {
      type: Boolean
    }
  },
  data: function() {
    return {
      measurement: new Aufmass(),
      needsFixup: 0,
    }
  },
  components: {
    ConfigurationStep,
    ConfigurationSummary,
  },
  methods: {
    onShowDetails(category) {
      this.$emit("category:showdetailview", category)
    },
    onAddCustom(item, category) {
      this.$emit('item:addcustom', item, category)
    },
    scrollToTop() {
      if (this.$el) {
        this.$el.scrollTop = 0
      }
      let page = document.querySelector(".page")
      if (page) {
        page.scrollTop = 0
      }
    },
    onNextStep() {
      this.$store.commit("configurationProcess/nextStep")
    },
    calculateMeasurement() {
      window.logWatch && console.log("CALCULATE")
      this.measurement.calculate(this.configuration, this.options)
    },
    installModelWatchers() {
      //
      // keys in this table have watchers installed
      // set window.logWatch = 1 or 2 to trace the activity
      //
      // values for the keys can be
      // - undefined: only measurement is triggered
      // - function: returning the list of model keys to clear on change, triggering fixup()
      //   function can return an empty array to only trigger fixup()
      //   function can return undefined to avoid triggering fixup()
      //
      // functions are called with (configuration, newValue, oldValue)
      //
      // layoutInputs is handled as a special case:
      // it is watched deeply and only the uppercase-keys are cleared
      // this is to maintain inputs like frameWidth, innerWidth etc. when switching layouts
      //
      let watchers = {
        // layout might stay the same but the openings may change
        element: () => ['layout', 'opening', 'layoutInputs', 'threshold', 'handle'],

        // color, shellColor, isDoubleSidedColor, sealColor are all dependent
        // but handled specially by fixup()
        // color and shellColor are not cleared here to keep colors that are available across materials
        material: () => ['system'],

        system: () => ['ventilation'],

        layout: () => ['opening', 'layoutInputs'],
        opening: () => [],

        useExtendedMeasurement: () => [],
        layoutInputs: () => [], // watched deeply, triggers fixup to update model.frameWidth etc.

        color: c => {
          if(c.material?.indexOf('/') !== -1 || c.material === 'pvc') {
            this.$emit("winview:switchcameratoshow" + ( c.material == "pvc" && !c.isDoubleSidedColor ? "outside" : "inside"))
          }
          return ['sealColor']
        },

        shellColor: () => {
          this.$emit("winview:switchcameratoshowoutside")
          return undefined
        },

        sealColor: () => [], // trigger a fixup until we have the ui visibility of lichtgrau fixed

        cutPattern: () => {
          this.$emit("winview:switchcameratoshowoutside")
          return undefined
        },

        rollerShutter: () => [], // trigger a fixup for the default rollerShutterHeight / coverConnectionProfile

        blindsType: () => [],
        blindsMaterial: () => [],
        blindsCaseColor: () => [],
        blindsDrive: () => [],


        windowRailsLayoutType: (c, newValue, oldValue) => {
          // see WindowRailsLayoutMixin
          let defaultPadding = 300
          let newPath = `windowRailsType.${c.windowRailsType}.windowRailsLayoutType.${newValue}`
          let oldPath = `windowRailsType.${c.windowRailsType}.windowRailsLayoutType.${oldValue}`
          c.windowRailsHorizontalCount = this.canSetHorizontalCount(newPath) ? (this.canSetHorizontalCount(oldPath) ? c.windowRailsHorizontalCount : 1) : undefined
          c.windowRailsVerticalCount = this.canSetVerticalCount(newPath) ? (this.canSetVerticalCount(oldPath) ? c.windowRailsVerticalCount : 1) : undefined
          c.windowRailsTopPadding = this.canSetTopPadding(newPath) ? (this.canSetTopPadding(oldPath) ? c.windowRailsTopPadding || defaultPadding : defaultPadding) : undefined
          return undefined
        },

        windowLedgeInnerMaterial: () => [],

        // these only trigger measurement calculation

        marginLeft: () => undefined,
        marginRight: () => undefined,
        marginTop: () => undefined,
        marginBottom: () => undefined,

        frameExtensionLeft: () => undefined,
        frameExtensionRight: () => undefined,
        frameExtensionTop: () => undefined,
        frameExtensionBottom: () => undefined,

        oddLegFrameExtension: () => undefined,
        baseSingleColumnProfile: () => undefined,
        rollerShutterHeight: () => undefined,
        coverConnectionProfile: () => undefined,
      }

      for(let key of Object.keys(watchers)) {
        this.$watch(
          `configuration.${key}`, {
            handler(newValue, oldValue) {
              let depWatcher = watchers[key]
              let depKeys = depWatcher ? depWatcher(this.configuration, newValue, oldValue) : undefined
              if(depKeys && oldValue !== undefined) {
                window.logWatch && console.log("FIXUP_CHANGE", key, oldValue, "->", newValue, "clear", depKeys)

                for(let k of depKeys) {
                  if(k === 'layoutInputs') {
                    let newLayoutInputs = { ...this.configuration[k].layoutInputs || {} }
                    for(let input of Object.keys(newLayoutInputs)) {
                      if(input === input.toUpperCase()) { // delete only keys dependent on layout
                        delete newLayoutInputs[input]
                      }
                    }
                    this.configuration[k] = newLayoutInputs
                  } else {
                    //this.configuration[k] = undefined
                  }
                }
                this.needsFixup = (this.needsFixup || 0) + 1
                // calculateMeasurement is triggered by the needsFixup watcher
              } else {
                window.logWatch && console.log("FIXUP_IGNORE", key, oldValue, "->", newValue)
                this.calculateMeasurement()
              }
            },
            deep: key === 'layoutInputs' ? true : false,
        })
      }

      // initial triggers
      this.needsFixup = 1
      this.calculateMeasurement()
    },
    fixup() {
      window.logWatch && console.log("FIXUP")

      let model = this.configuration

      let defaultElement = 'fenster / fenstertür'
      let defaultMaterial = 'pvc'
      let defaultLayouts = {
        "hebeschiebetür": "zweiteilig",
        undefined : "einteilig",
      }

      let fallbackWidth = 1500
      let fallbackHeight = 1000
      let fallBackConfiguration = {
        frameWidth: fallbackWidth,
        frameHeight: fallbackHeight,
        innerWidth: fallbackWidth,
        innerHeight: fallbackHeight
      }

      let element = model.element || defaultElement

      let defaultsKey = element + ',' + model.layout
      let defaults = this.options?.defaultConfigurations?.filter(c => c.title === defaultsKey).pop() || fallBackConfiguration

      model.layoutInputs ||= {}

      model.innerWidth = model.layoutInputs.innerWidth || defaults.innerWidth
      model.innerHeight = model.layoutInputs.innerHeight || defaults.innerHeight
      model.outerWidth = model.layoutInputs.outerWidth || model.innerWidth
      model.outerHeight = model.layoutInputs.outerHeight || model.innerHeight

      for(let key of Object.keys(defaults.layoutInputs || {})) {
        window.logWatch && !model.layoutInputs[key] && console.log(`FIXUP layoutInputs.${key}`, "defaults to", defaults.layoutInputs[key], "from", defaultsKey)
        model.layoutInputs[key] ||= defaults.layoutInputs[key]
      }

      model.frameWidth = model.layoutInputs.frameWidth || defaults.frameWidth
      model.frameHeight = model.layoutInputs.frameHeight || defaults.frameHeight

      // Heights >= 1800 are treated as doors, defaulting to 1050, those below 1800 default to a centered handled
      model.handleHeight = model.layoutInputs.handleHeight || (model.layoutDimensions.H >= 1800 ? 1050 : Math.floor((model.layoutDimensions.H || model.frameHeight)/ 2))

      if (model.layoutInputs.rollerShutterHeight) {
        model.rollerShutterHeight = model.layoutInputs.rollerShutterHeight
      } else {
        switch (model.rollerShutter) {
          case RS_ROLLERSHUTTER_FRONTMOUNTEDBOX: { model.rollerShutterHeight = 185; break }
          case RS_ROLLERSHUTTER_ADDONBOX: { model.rollerShutterHeight = 205; break }
          case RS_ROLLERSHUTTER_ADDONBOXINSULATED: { model.rollerShutterHeight = 260; break }
          default: { model.rollerShutterHeight = 205; break }
        }
      }

      model.coverConnectionProfile = model.layoutInputs.coverConnectionProfile || 15

      let material = model.material || defaultMaterial
      let isWhite = (!model.color || model.color === 'weiß')

      if(material !== 'pvc' || isWhite) {
        model.isDoubleSidedColor = false
      } else {
        model.isDoubleSidedColor ||= false
      }

      // order of keys is important here - for example:
      // color, shellColor and ventilation depend on system, so they come after that
      // sealColor depends on color, so after that
      let defaultsByKey = {
        threshold: [],
        //doorFilling: [],
        cutPattern: [],
        handle: [],

        system: [],
        layout: [defaultLayouts[element] || defaultLayouts[undefined]],
        opening: [defaults.opening],

        color: [
          'weiß', // PVCRohFarben
          /*'',*/ // Folienfarben
          //'anthrazitgrau / ral 7016', // Alufarben
          //'eiche hell (auf kiefer)', // Holzfarben
        ],
        shellColor: [
          'anthrazitgrau / ral 7016', // Alufarben
        ],
        sealColor: [
          'schwarz',
        ],
        ventilation: [],

        rollerShutter: [],
        blindsType: [],
        blindsMaterial: [],
        blindsCaseColor: [
          'weiß', // PVCRohFarben
          'verkehrsweiß / ral 9016' // Alufarben
        ],
        blindsFinColor: [
          'lichtgrau'
        ],
        blindsDrive: [],
        blindsDriveLocation: [],
        blindsMotor: [],

        windowLedgeInnerModel: [],
        windowLedgeInnerColor: [],
        windowLedgeOuterColor: [],
        windowRailsLayoutType: [],

      }


      let fixupAttribute = (key) => {
        let availableOptions = this.$store.getters["configurationOptions/optionsForKey"](key, model) || []
        let value = model[key]
        //window.logWatch && console.log("FIXUP","check", key, value)

        if(!value || (!value.startsWith('custom') && !availableOptions.find(c => c.key === value))) {
          let optionKeys = availableOptions.map(e => e.key)
          model[key] = defaultsByKey[key].filter(dc => optionKeys.indexOf(dc) !== -1)[0] || optionKeys[0] || undefined
          window.logWatch && model[key] !== value && console.log("FIXUP", key, value, "not available, defaulting to", model[key])
        }
      }

      for(let key of Object.keys(defaultsByKey)) {
          fixupAttribute(key)
      }
    },
  },
  computed: {
    currentStep() {
      return this.$store.getters['configurationProcess/currentStep']
    },
    configuration() {
      return this.$store.getters["configuration/configuration"]
    },
    options() {
      return this.$store.getters["configurationOptions/options"]
    },
  },
  mounted() {
    // window.logWatch = 1
    this.installOverscrollFix()
    this.installModelWatchers()
    if(window.configuration) { // use window.configuration = true once to enable as a debug helper
      window.configuration = this.configuration
    }
  },
  beforeUnmount() {
    this.removeOverscrollFix()
  },
  watch: {
    needsFixup(newValue, oldValue) {
      // attribute watchers clear dependent keys and set needsFixup
      if(newValue) {
        window.logWatch > 1 && console.log("needsFixup", oldValue, "->", newValue, "recursive", oldValue && newValue ? true : false)
        this.calculateMeasurement() // needed beforehand so i.e. layoutDimensions is current
        this.fixup()
        this.calculateMeasurement()
        this.needsFixup = 0
      }
    },

    currentStep() {
      this.scrollToTop()
    },
    showSummary() {
      this.$nextTick( () => {
        this.scrollToTop()
      })
    }
  }
}
</script>

<style scoped>

.configuration {
  padding: 1px 0;
}
.configuration-content {
  flex-grow: 1;
  overflow: hidden;
  /* for Firefox */
  min-height: 0;

  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  position: relative;
}

.configuration-title {
  font-size: 28px;
  font-weight: 300;
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dadada;
  width: 100%;
  height: 80px;
  padding-left: 40px;
}

.actions {
  margin: 40px 0;
}

.gradient {
  width: 100%;
  height: 80px;
  background: linear-gradient(0deg, var(--page-bg-color) 30%, rgba(255,255,255,0) 100%);
  position: absolute;
  bottom: 80px;
  visibility: hidden;
  pointer-events: none;
}

@media only screen and (min-width : 1024px) {
  .configuration-content {
    padding: 0 20px;
  }
  .gradient {
    visibility: visible;
  }
}
</style>

