<template>
  <div class="context-menu" :style="style">
      <ul>
          <li v-for="(menuItem, index) in state.menuItems" v-bind:key="index" @click.stop="onClick(menuItem)">
            <div class="title">{{ menuItem.title }}</div>
            <component
                v-if="menuItem.iconComponent"
                :is="menuItem.iconComponent"
            ></component>
          </li>
      </ul>
  </div>
</template>

<script>
import { Util } from "@profineberater/configurator-clientlib"

export default {
  name: 'ContextMenu',
  data: function() {
    return {
        scrollParent: null
    }
  },
  computed: {
    state() {
          return this.$store.state.ui.contextMenuState
    },
    style() {
        return {
            top: this.state.top,
            left: this.state.left,
            opacity: this.state.active ? 1 : 0,
            pointerEvents: this.state.active ? 'initial' : 'none'
        }
    }
  },
  methods: {
      documentClick() {
          this.$store.commit("ui/contextMenuActive", false)
      },
      handleScroll() {
        this.$store.commit("ui/contextMenuActive", false)
      },
      onClick(menuItem) {
          if (menuItem.handler) {
              menuItem.handler()
          }
          this.$store.commit("ui/contextMenuActive", false)
      }
  },
  watch: {
      state: function(newValue) {
        if (newValue.active) {
            this.scrollParent = Util.getScrollParent(newValue.containerEl || this.$el)
            document.addEventListener("click", this.documentClick)
            this.scrollParent?.addEventListener('scroll', this.handleScroll)
        } else {
            document.removeEventListener("click", this.documentClick)
            this.scrollParent?.removeEventListener('scroll', this.handleScroll)
        }
      }
  }
}
</script>

<style scoped>
.context-menu {
    background: var(--primary-color);
    border-radius: 7px;
    color: #fff;
    position: fixed;
    z-index: 999;
    padding: 0 0;
    transition: opacity 0.5s ease-in-out;
}
ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
li {
    height: 48px;
    border-bottom: 1px solid #4099f5;
    font-weight: 400;
    font-size: 13px;
    padding: 10px 20px;
    text-align: left;
    display: flex;
    align-items: center;
}
li:first-child {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

li:last-child {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

li:hover {
    background: #4099f5;

}
.title {
    flex-grow: 1;
    padding-right: 60px;
}
</style>
