<template>
    <div class="blinds-type-selector">
      <div class="info-box" v-if="showNotice">Bitte wählen Sie einen Rollladenaufsatzkasten unter <a @click="jumpToDimensions()">Aufmaß &rarr; Detailliert &rarr; Rollladenaufsatzkasten</a></div>
      <ItemTiles
          ref="itemTiles"
          :disabled="disabled"
          :category="category"
          :categoryOptions="categoryOptions(category)"
          @item:addcustom="onAddCustom" />
    </div>
</template>

<script>

import SelectorMixin from '../SelectorMixin.vue';
import ItemTiles from '../items/ItemTiles.vue';

export default {
  name: "BlindsTypeSelector",
  components: {
    ItemTiles
  },
  mixins: [SelectorMixin],
  methods: {
    categoryOptions(category) {
      let config = this.$store.getters["configuration/configuration"]
      let opts = this.$store.getters["configurationOptions/optionsForKey"](category.key, config)
      return opts
    },
    jumpToDimensions() {
      this.$store.commit("configurationProcess/currentStepKey", "dimensions")
    },
    onAddCustom(item, category) {
      this.$emit('item:addcustom', item, category)
    },
  },
  computed: {
    showNotice() {
      return this.configuration.rollerShutter == "rollerShutter_none"
    }
  }
};
</script>

<style scoped>
.blinds-type-selector {
}
.info-box {
  margin: 0 15px;
}
</style>

