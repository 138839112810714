import { RS_NONE } from "@/aufmass.js"

const initialState = {
  _id: undefined,
  version: "1.2.1",

  title: "",
  positionNumber: undefined,
  element: undefined,
  material: undefined,
  system: undefined,

  innerWidth: 1500,
  outerWidth: 1500,
  innerHeight: 1000,
  outerHeight: 1000,
  marginRight: 12,
  marginLeft: 12,
  marginTop: 12,
  marginBottom: 12,
  stopLeft: 0,
  stopRight: 0,
  stopTop: 0,
  frameWidth: 1500,
  frameHeight: 1000,
  diagonalMeasure: 0,
  customMeasure: undefined,
  useExtendedMeasurement: false,
  rollerShutter: RS_NONE,
  rollerShutterHeight: 0,
  rollerShutterWidth: undefined,
  coverConnectionProfile: 0,
  oddLegFrameExtension: 0,
  baseSingleColumnProfile: 0,
  layout: undefined,
  layoutInputs: {},
  layoutDimensions: {},
  opening: undefined,
  color: undefined,
  shellColor: undefined,
  isDoubleSidedColor: undefined,
  sealColor: undefined,
  filling: undefined,
  cutPattern: undefined,
  handle: undefined,
  handleHeight: 0,
  threshold: undefined,
  windowRailsType: undefined,
  windowRailsLayoutType: undefined,
  windowRailsTopPadding: undefined,
  windowRailsHorizontalCount: undefined,
  windowRailsVerticalCount: undefined,
  glass: undefined,
  ornamentGlass: undefined,
  acousticGlass: undefined,
  securityGlass: undefined,
  connectionProfile: undefined,

  // Verbreiterung
  frameExtensionLeft: undefined,
  frameExtensionRight: undefined,
  frameExtensionTop: undefined,
  frameExtensionBottom: undefined,

  // Fensterbeschlag
  windowHardware: undefined,
  // Fensterbänder
  windowHingeType: undefined,

  ventilation: undefined,
  windowLedgeInner: false,
  windowLedgeInnerDepth: 200,
  windowLedgeInnerMaterial: undefined,
  windowLedgeInnerModel: undefined,
  windowLedgeInnerColor: undefined,
  windowLedgeOuter: false,
  windowLedgeOuterDepth: 200,
  windowLedgeOuterColor: undefined,
  rollerShutterRail: undefined,
  blindsType: undefined,
  blindsMaterial: undefined,
  blindsDimension: undefined,
  blindsCaseColor: undefined,
  blindsFinColor: undefined,
  blindsDrive: undefined,
  blindsDriveLocation: undefined,
  blindsMotor: undefined,
  blindsFixture: "",

  visibility: {},
  errors: {},
  warnings: {},

  customProperties: {

  }

}

export const configuration = {
  namespaced: true,
  state: () => {
    return JSON.parse(JSON.stringify(initialState))
  },
  mutations: {
    _id(state, value) { state._id = value },
    version(state, value) { state.version = value },

    title(state, value) { state.title = value },
    element(state, value) { state.element = value },
    material(state, value) { state.material = value },
    system(state, value) { state.system = value },

    // measurement
    innerWidth(state, value) {state.innerWidth = value },
    outerWidth(state, value) { state.outerWidth = value },
    innerHeight(state, value) { state.innerHeight = value },
    outerHeight(state, value) { state.outerHeight = value },
    marginRight(state, value) { state.marginRight = value },
    marginLeft(state, value) { state.marginLeft = value },
    marginTop(state, value) { state.marginTop = value },
    marginBottom(state, value) { state.marginBottom = value },
    stopLeft(state, value) { state.stopLeft = value },
    stopRight(state, value) { state.stopRight = value },
    stopTop(state, value) { state.stopTop = value },

    frameWidth(state, value) { state.frameWidth = value },
    frameHeight(state, value) { state.frameHeight = value },
    useExtendedMeasurement(state, value) { state.useExtendedMeasurement = value },

    frameExtensionTop(state, value) { state.frameExtensionTop = value },
    frameExtensionRight(state, value) { state.frameExtensionRight = value },
    frameExtensionBottom(state, value) { state.frameExtensionBottom = value },
    frameExtensionLeft(state, value) { state.frameExtensionLeft = value },

    diagonalMeasure(state, value) { state.diagonalMeasure = value },
    customMeasure(state, value) { state.customMeasure = value },

    rollerShutter(state, value) { state.rollerShutter = value },
    rollerShutterHeight(state, value) { state.rollerShutterHeight = value },
    rollerShutterWidth(state, value) { state.rollerShutterWidth = value },
    coverConnectionProfile(state, value) { state.coverConnectionProfile = value },

    oddLegFrameExtension(state, value) { state.oddLegFrameExtension = value },
    baseSingleColumnProfile(state, value) { state.baseSingleColumnProfile = value },

    layout(state, value) { state.layout = value },
    layoutInputs(state, value) { state.layoutInputs = value },
    layoutDimensions(state, value) { state.layoutDimensions = value },
    opening(state, value) { state.opening = value },
    color(state, value) { state.color = value },
    shellColor(state, value) { state.shellColor = value },
    isDoubleSidedColor(state, value) { state.isDoubleSidedColor = value },
    sealColor(state, value) { state.sealColor = value },
    handle(state, value) { state.handle = value },
    handleHeight(state, value) { state.handleHeight = value },
    threshold(state, value) { state.threshold = value },
    filling(state, value) { state.filling = value },
    cutPattern(state, value) { state.cutPattern = value },
    windowRailsType(state, value) { state.windowRailsType = value },
    windowRailsLayoutType(state, value) { state.windowRailsLayoutType = value },
    windowRailsTopPadding(state, value) { state.windowRailsTopPadding = value },
    windowRailsHorizontalCount(state, value) { state.windowRailsHorizontalCount = value },
    windowRailsVerticalCount(state, value) { state.windowRailsVerticalCount = value },
    glass(state, value) { state.glass = value },
    ornamentGlass(state, value) { state.ornamentGlass = value },
    acousticGlass(state, value) { state.acousticGlass = value },
    securityGlass(state, value) { state.securityGlass = value },
    connectionProfile(state, value) { state.connectionProfile = value },
    windowHardware(state, value) { state.windowHardware = value },
    windowHingeType(state, value) { state.windowHingeType = value },
    ventilation(state, value) { state.ventilation = value },
    windowLedgeInner(state, value) { state.windowLedgeInner = value },
    windowLedgeInnerDepth(state, value) { state.windowLedgeInnerDepth = value },
    windowLedgeInnerMaterial(state, value) { state.windowLedgeInnerMaterial = value },
    windowLedgeInnerModel(state, value) { state.windowLedgeInnerModel = value },
    windowLedgeInnerColor(state, value) { state.windowLedgeInnerColor = value },
    windowLedgeOuterDepth(state, value) { state.windowLedgeOuterDepth = value },
    windowLedgeOuter(state, value) { state.windowLedgeOuter = value },
    windowLedgeOuterColor(state, value) { state.windowLedgeOuterColor = value },
    rollerShutterRail(state, value) { state.rollerShutterRail = value },
    blindsType(state, value) { state.blindsType = value },
    blindsMaterial(state, value) { state.blindsMaterial = value },
    blindsDimension(state, value) { state.blindsDimension = value },
    blindsCaseColor(state, value) { state.blindsCaseColor = value },
    blindsFinColor(state, value) { state.blindsFinColor = value },
    blindsDrive(state, value) { state.blindsDrive = value },
    blindsDriveLocation(state, value) { state.blindsDriveLocation = value },
    blindsMotor(state, value) { state.blindsMotor = value },
    blindsFixture(state, value) { state.blindsFixture = value },
    visibility(state, value) { state.visibility = value },
    errors(state, value) { state.errors = value },
    warnings(state, value) { state.warnings = value },
    customProperties(state, value) { state.customProperties = value },
    customProperty(state, value) {
      state.customProperties[value.id] = JSON.parse(JSON.stringify(value))
    },
    removeCustomProperty(state, key) {
      delete state.customProperties[key]
    },
    configuration(state, configuration) {
      Object.keys(configuration).forEach( key => {
        state[key] = configuration[key]
      })
    },
    positionNumber(state, positionNumber) {
      state.positionNumber = positionNumber
    }
  },
  getters: {
    initialConfiguration() {
      return JSON.parse(JSON.stringify(initialState))
    },
    configuration(state /*, getters, rootState, rootGetters*/) {
      return state
      // let disabledOptions = rootGetters["user/disabledOptions"]
      // if (!disabledOptions) {
      //   return state
      // }
      // let filtered = {}
      // Object.keys(state).forEach( key => {
      //   if (disabledOptions[state[key]] !== true) {
      //     filtered[key] = state[key]
      //   }
      // })
      // console.log("filtered", filtered)
      // return filtered
    },
    customPropertiesForKey: (state) => (key) => {
      return state.customProperties?.[key]
    },
    isAllOptionsAvailable: (state, getters, rootState, rootGetters) => (configuration) => {
      let disabledOptions = rootGetters["user/disabledOptions"] || {}
      let keys = Object.keys(configuration)
      for (var i=0; i<keys.length; i++) {
        if (disabledOptions[keys[i]]?.[configuration[keys[i]]]) {
          return false
        }
      }
      return true
    }
  },
  actions: {
    resetConfiguration({commit}) {
      commit("_id", undefined)
      // I don't think we need to exclude coverConnectionProfile here
      // when the other keys are reset, it is zero anyway
      //let excludedKeys = ["coverConnectionProfile"]
      Object.keys(initialState).forEach( key => {
        //if (!excludedKeys.includes(key)) {
          commit(key, initialState[key])
        //}
      })
    },
  }
};