<template>
    <AppLayout>
      <template #right-navigation-item>
        <ProfileNavigationItem />
      </template>
      <template #page-title>
        <div class="header">
          <h1 v-if="isArchive">Archiv</h1>
          <h1 v-else>Kunden</h1>
          <div class="archive"
            v-show="!isArchive"
            :class="isArchive ? 'active' : ''"
            @click="$router.push({ path: '/archive', query: { t: 'next' } })"
            >
            Archiv <ArchiveIcon :solid="true" />
          </div>
        </div>
      </template>
      <template #page>
        <div class="projects-page">
          <UserNotifications @notification:navigate="onNavigate" />
          <transition name="fade" mode="out-in" v-if="this.projects?.length">
            <TextInput

              :showClearButton="showClearButton"
              placeholder="Suchen"
              :isSearch="true"
              :disabled="!searchEnabled"
              class="darker"
              ref="searchField"
              v-model="searchTerm" />
          </transition>
          <Projects
            :isArchive="isArchive"
            :filters="projectFilters"
            @project:select="onSelectProject"
            @project:add="onAdd"
            @project:archive="onArchive"
            @project:unarchive="onUnarchive"
            />
          <AddCustomerSheet ref="addCustomerSheet" v-model="newProject" @customer:save="onConfirmAdd" @randomize="randomizeFields" saveButtonTitle="Hinzufügen">
            <template #title>Neuer Kunde</template>
          </AddCustomerSheet>
          <AddButton @click="onAdd" v-if="!isArchive" />
        </div>
      </template>
    </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout.vue"
import Projects from "@/components/projects/Projects.vue"
import DevUtils from "@/devutils.js"
import AddCustomerSheet from "@/components/projects/AddCustomerSheet.vue"
import AddButton from "@/components/buttons/AddButton.vue"
import ProfileNavigationItem from "@/components/navigationBar/ProfileNavigationItem.vue"
import { TextInput, UserNotifications, ArchiveIcon } from "@profineberater/configurator-clientlib"

export default {
  name: "ProjectsPage",
  components: {
    Projects,
    AddCustomerSheet,
    AddButton,
    ProfileNavigationItem,
    AppLayout,
    UserNotifications,
    TextInput,
    ArchiveIcon
  },
  data: function() {
    return {
      newProject: null,
      searchTerm: null,
    };
  },
  props: {
    isArchive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    searchEnabled() {
      return this.projects?.length > 0;
    },
    projects() {
      return this.$store.state.project.projects.filter( p => !p.isArchived == !this.isArchive )
    },
    projectFilters() {
      if (this.searchTerm) {
        return [{
          type: "search",
          searchTerm: this.searchTerm
        }]
      }
      return []
    },
    showClearButton() {
      return this.searchTerm?.length > 0
    }
  },
  methods: {
    onAdd() {
      let sheet = this.$refs.addCustomerSheet
      this.newProject = this.$store.getters["project/emptyProject"]
      this.newProject.createdAt = new Date()
      this.newProject.modifiedAt = new Date()
      sheet.present()
    },
    onArchive(project) {
      project.isArchived = true
      this.$store.dispatch("project/update", project)
    },
    onUnarchive(project) {
      project.isArchived = false
      this.$store.dispatch("project/update", project)
    },
    onConfirmAdd(project) {
      let sheet = this.$refs.addCustomerSheet
      this.$store.dispatch("project/add", project).then( addedProject => {
        sheet.dismiss()
        setTimeout( () => {
          this.onSelectProject(addedProject)
        }, 500)
      });
    },
    randomizeFields() {
      DevUtils.randomUser().then( result => {
        this.newProject.customer = result
      })
    },
    onSelectProject(project) {
      if (this.$store.getters["team/isProjectCheckedOut"](project) &&
         !this.$store.getters["team/isProjectCheckedOutByMe"](project)) {
        return
      }
      this.$store.dispatch("project/fetchProject", project._id).then( (doc) => {
        this.$store.commit("project/currentProject", doc)
        this.$router.push({ path: '/project', query: { p: project._id } })
      })
    },
    onNavigate(payload) {
      window.open(`${process.env.VUE_APP_BACKOFFICE_URL}${payload.path}`, "_blank")
    }
  },
  created() {
    this.newProject = this.$store.getters["project/emptyProject"]
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      vm.$store.dispatch('configuration/resetConfiguration')
    })
    return true
  },
  beforeRouteLeave (to, from, next) {
    // delete notifications that are marked as to be deleted
    // we don't want some notifications to be deleted instantly, e.g. when toggling the newsletter consent switch
    let profile = this.$store.getters["user/profile"]
    let notifications = profile?.notifications || []
    let deleteIndexes =  notifications.reduce((acc, curr, i) => (curr.markDeleted === true && acc.push(i), acc), []);
    if (deleteIndexes.length) {
      deleteIndexes.forEach(idx => {
        notifications.splice(idx, 1)
      })
    }
    this.$store.dispatch("user/saveProfile").then( () => {
        next()
    }).catch( (err) => {
        console.error("Failed saving profile", err)
        next()
    })
  },

};
</script>

<style scoped>
.projects-page {
  background-image: url("../assets/img/bg_artisan.png");
  background-size: 40vh;
  background-repeat: no-repeat;
  background-position: 50% calc(100% - 20px);
  height: calc(100% - 40px);
  padding: 20px 0 0 0;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header h1 {
  flex-grow: 1;
}
.header .archive {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  padding: 4px 15px;
}
.header .archive svg {
  margin-left: 10px;
}
.header .archive.active {
  background-color: var(--primary-color);
  border-radius: 14px;
  color: #fff
}
h3 {
  margin-top: 20px;
  font-weight: 300;
}
.actions > * {
  margin-right: 20px;
}
.actions > *:last-child {
  margin-right: 0;
}
:deep(.icon.blue) {
  color: var(--primary-color);
}

@media only screen and (min-width: 1024px) {
  .projects-page {
    padding: 20px 10px;
  }
}

</style>