import { createApp } from 'vue'
import App from './App.vue'
import vSelect from 'vue-select'
import store from "./store"
import router from './router'
import InlineSvg from 'vue-inline-svg';

import { PouchDbPlugin } from "@profineberater/configurator-clientlib"

import { Workbox } from 'workbox-window';

import VueKeyCloak from "@dsb-norge/vue-keycloak-js";

import Swal from 'sweetalert2'
import VueGtag from "vue-gtag";

function promptForUpdate() {
  return new Promise( resolve => {
    Swal.fire({
      title: 'Eine neue Version der App ist verfügbar.',
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: 'Aktualisieren',
      denyButtonText: '',
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(true)
      } else if (result.isDenied) {
        resolve(false)
      }
    })
  })
}

if ('serviceWorker' in navigator) {

  const workbox = new Workbox('/service-worker.js');
    // let registration;
  window.$workbox = workbox

    const showSkipWaitingPrompt = async (/*event*/) => {
      // Assuming the user accepted the update, set up a listener
      // that will reload the page as soon as the previously waiting
      // service worker has taken control.
      workbox.addEventListener('controlling', () => {
        // At this point, reloading will ensure that the current
        // tab is loaded under the control of the new service worker.
        // Depending on your web app, you may want to auto-save or
        // persist transient state before triggering the reload.
        window.location.reload();
      });

      // When `event.wasWaitingBeforeRegister` is true, a previously
      // updated service worker is still waiting.
      // You may want to customize the UI prompt accordingly.

      const updateAccepted = await promptForUpdate();

      if (updateAccepted) {
        // window.$localDBSync?.cancel()
        // window.$localContentDBSync?.cancel()
        // window.$localDB?.close().catch( err => console.error("Failed to close localDB", err))
        // window.$remoteDB?.close().catch( err => console.error("Failed to close remoteDB", err))
        setTimeout( () => {
          workbox.messageSkipWaiting()
          console.log("Did send SKIP_WAITING message.")
          //workbox.messageSW({ type: 'SKIP_WAITING' });
        }, 1000)
      }
    }

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    workbox.addEventListener('waiting', (event) => {
      showSkipWaitingPrompt(event);
    });

    workbox.addEventListener('controlling', (event) => {
      console.log("Controlling event received.", event)
      window.location.reload();
    });

    workbox.register();

  // navigator.serviceWorker
  //   .register("/service-worker.js")
  //   .then( () => {
  //     console.log("ServiceWorker registered.")
  //   })
  //   .catch( (err) => {
  //     console.error("Failed to register ServiceWorker", err)
  //   })
}

let app = createApp(App)
console.log("IP",  process.env.VUE_APP_KEYCLOAK_URL)

app.use(store)
app.component('v-select', vSelect)
app.component('inline-svg', InlineSvg)
app.use(PouchDbPlugin, { store: store })

app.use(VueKeyCloak, {
  init: {
    // Use 'login-required' to always require authentication
    // If using 'login-required', there is no need for the router guards in router.js
    // onLoad: "login-required",
    // There is a keycloak property to switch of check-sso (possibly necessary for offline)
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
  },

  config: {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
  },

  onInitError: (error) => {
    // This is called if the user i.e. does not accept the Terms and Conitions. Redirect to login.
    console.log("Keycloak initialization error: " + error)
    if (!window.location.href.endsWith("autherror")) {
      window.location.href = window.location.origin + "/autherror"
    }
  },

  onReady: (keycloak) => {
    window.$keycloak = keycloak
    console.log(`I wonder what Keycloak returns:`,  window.$keycloak)
    app.config.globalProperties.$pouchDbPlugin.reconnect()
    app.config.globalProperties.$pouchDbPlugin.syncContentDb(true)
  }

})

app.use(router)
//router.app = app
app.use(VueGtag, {
  config: {
    id: "G-08174NN2PM",
    params: {
      anonymize_ip: true
    }
  },
  enabled: false
}, router)

app.mount('#app')