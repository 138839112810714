<template>
  <div class="noselect">
    <ToggleButton v-if="categoryOptions.length > 0" elId="toggleProductDetailsButton" v-model="showInfoPage" title="">
        <template #toggle-option-1-name>Produkte</template>
        <template #toggle-option-2-name>Infos</template>
    </ToggleButton>
    <agile v-if="!showInfoPage" class="products-details" ref="productDetailsSlider" :key="categoryOptions.length">
      <ProductDetailItem v-for="item, index in categoryOptions"
        v-bind:key="'product_details_' + index"
        :contentID="item"
        :selected="item == selectedItemId">
      </ProductDetailItem>
    </agile>
    <div v-else>
      <ProductDetailItem :contentID="category.configurationKey || category.key"></ProductDetailItem>
    </div>
  </div>
</template>

<script>

import { VueAgile } from 'vue-agile'
import ProductDetailItem from "./ProductDetailItem.vue"
import ToggleButton from "@/components/buttons/ToggleButton.vue"
import CmsMixin from "@/mixins/CmsMixin.vue"

export default {
  name: 'ProductDetails',
  mixins: [CmsMixin],
  components: {
    agile: VueAgile,
    ProductDetailItem,
    ToggleButton
  },
  props: {
    category: Object,
  },
  data: function() {
    return {
      saveOnLeave: true,
      showVideo: false,
      showInfoPage: true,
    }
  },
  computed: {
    configuration() {
      return this.$store.getters["configuration/configuration"]
    },
    categoryOptions() {
      let opts = this.$store.getters["configurationOptions/optionsForKey"](this.category.key, this.configuration).map( o => o.key )

      let childKeys = (this.category.children || []).map( c => c.key )
      childKeys.forEach( childKey => {
        let o = this.$store.getters["configurationOptions/optionsForKey"](childKey, this.configuration).map( o => o.key )
        opts = opts.concat(o)
      })
      var cmsDetails
      opts = opts.filter( key => {
        cmsDetails = this.details[key]
        return cmsDetails?.visible
      })

      return opts
    },
    metadata() {
      let metadata = this.$store.getters["configurationOptions/metadataForKey"](this.model)
      if (!metadata) {
        console.error("No metadata found for ", this.model)
      }
      return metadata
    },
    title() {
      return this.metadata?.title || "??? " + this.model
    },
    selectedItemId() {
      return this.configuration[this.category.configurationKey]
    },
    categoryDetails() {
      return this.details[this.category.configurationKey]
    },
  },
  methods: {
    onAfterChangeCarousel(e) {
      if (e.currentSlide === 2) {
        this.showVideo = true
        this.playAllVideos()
        } else {
        this.pauseAllVideos()
      }
    },
    playVideo(ref) {
      if (!ref.playing || typeof ref.playing == 'undefined') {
        ref.play()
      }
    },
    pauseVideo(ref) {
      if (ref.paused) {
          ref.pause()
      }
    },
    pauseAllVideos() {
      document.querySelectorAll('video').forEach(vid => vid.pause());
    },
    playAllVideos() {
      document.querySelectorAll('video').forEach(vid => vid.play());
    },
    beforeRouteLeave (to, from, next) {
      if (this.saveOnLeave) {
        this.pauseVideo().then( () => {
          next()
        })
      } else {
        next()
      }
    }
  },
  watch: {
    categoryOptions(newOpts) {
      this.showInfoPage = newOpts.length == 0
    },
  }
}
</script>

<style scoped>
:deep(.agile) {
  position: relative;
}
:deep(.agile:active),
:deep(.agile :active),
:deep(.agile:focus),
:deep(.agile :focus) {
  outline: none;
}
:deep(.agile__list) {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
:deep(.agile__track) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
:deep(.agile__actions) {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  margin-left: -40px;
  width: 100%;
  height: 70px;
  background-color: #fff;
  box-shadow: 0 0 10px var(--default-shadow-color);
  border: none;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
:deep(.agile__slides) {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: unset;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
:deep(.agile--disabled .agile__slides) {
  display: block;
  width: 100%;
}
:deep(.agile__slide) {
  display: block;
  flex-grow: 1;
  flex-shrink: 0;
}
:deep(.agile__slide),
:deep(.agile__slide) * {
  -webkit-user-drag: none;
  max-width: 100%;
}
:deep(.agile--fade .agile__slide) {
  opacity: 0;
  position: relative;
  z-index: 0;
}
:deep(.agile--fade .agile__slide--active) {
  opacity: 1;
  z-index: 2;
}
:deep(.agile--fade .agile__slide--expiring) {
  opacity: 1;
  transition-duration: 0s;
  z-index: 1;
}
:deep(.agile__nav-button[disabled]) {
  cursor: default;
}
:deep(.agile__dots) {
  align-items: center;
  display: flex;
  list-style: none;
  padding: 0;
  white-space: nowrap;
}
:deep(.agile__dot button) {
  background-color: rgba(224, 226, 227, 1);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 8px;
  font-size: 0;
  line-height: 0;
  margin: 0 6px;
  padding: 0;
  transition-duration: .3s;
  width: 8px;
}
:deep(.agile__dot--current button, .agile__dot:hover button) {
  background-color: var(--primary-color);
}
:deep(.agile__nav-button.agile__nav-button--next),
:deep(.agile__nav-button.agile__nav-button--prev){
  display: none;
}
:deep(.product-infos, .product-infos-slide) {
  max-width: 100%;
  /* min-width: 100% */
}
.product-infos, .products-details {
  min-height: 70vh;
  max-width: 100%;
  transition: opacity 1s ease-in-out;
}
.product-infos-img {
  margin-bottom: 20px;
}
.product-infos-kicker {
  font-size: 15px;
  color: var(--primary-color);
  line-height: 1.1em;
  letter-spacing: 0.004em;
  margin-bottom: 15px;
}
.product-infos-headline {
  font-size: 28px;
  font-weight: 400;
  line-height: 1.1em;
  margin-bottom: 20px;
  letter-spacing: 0.004em;
}
.product-infos-headline.headline-0 {
  font-size: 36px;
}
.product-infos-text {
  font-size: 15px;
  color: rgba(103, 109, 124, 1);
  letter-spacing: 0.04em;
}

@media only screen and (min-width : 1024px) {
  .product-infos-text {
    padding: 20px 60px;
  }
}
</style>
