<script>
  export default {
    methods: {
      installOverscrollFix() {
        this.$refs.scrollContainer.scrollTop = 1;
        this.$refs.scrollContainer.addEventListener('scroll', this.fixOverscrollBehaviour)
      },
      removeOverscrollFix() {
        this.$refs.scrollContainer.removeEventListener('scroll', this.fixOverscrollBehaviour)
      },
      async fixOverscrollBehaviour() {
        await new Promise(resolve => window.requestAnimationFrame(resolve))
        const {
          scrollTop,
          scrollLeft,
          scrollHeight,
          clientHeight
        } = this.$refs.scrollContainer
        const atTop = scrollTop === 0
        const beforeTop = 1
        const atBottom = scrollTop === scrollHeight - clientHeight
        const beforeBottom = scrollHeight - clientHeight - 1

        if (atTop) {
          this.$refs.scrollContainer.scrollTo(scrollLeft, beforeTop)
        } else if (atBottom) {
          this.$refs.scrollContainer.scrollTo(scrollLeft, beforeBottom)
        }
      }
    },
  };

  </script>
