<template>
    <div :class="'custom-selector openings-dimensions-selector' + (disabled ? ' disabled' : '')">
      <SelectorTile v-if="configuration.visibility.layoutDimensions">
        <template #figure>
          <IsometricElement :table="table" />
        </template>
        <template #form>
          <div v-if="table">
            <ConfigurationMessages :messages="configuration.errors" include="parts" type="error" title="Fehler:" id="messages"/>
            <NumberInput
              v-for="part in layoutParts"
              v-bind:key="part" v-model="configuration.layoutInputs[part]"
              :min="400"
              :max="10000"
              :label="labelForPart(part) + ' (mm)'"
              :placeholder="placeholderForPart(part)"
              :no-error-message="configuration.errors?.['layoutDimensions.' + part]"
              :no-warning-message="configuration.warnings?.['layoutDimensions.' + part]"
              />
          </div>
          <div v-else>
            Bitte definieren Sie zuerst die Öffnungsarten
          </div>
        </template>
      </SelectorTile>
    </div>
</template>

<script>

import SelectorTile from "@/components/configuration/SelectorTile.vue"
import { NumberInput } from "@profineberater/configurator-clientlib"
import IsometricElement from "@/components/configuration/IsometricElement.vue"
import ConfigurationMessages from "@/components/configuration/ConfigurationMessages.vue"
import LayoutDimensionsMixin from "./LayoutDimensionsMixin.vue"

export default {
  name: 'LayoutDimensionsSelector',
  mixins: [LayoutDimensionsMixin],
  components: {
    SelectorTile,
    NumberInput,
    IsometricElement,
    ConfigurationMessages,
  },
  data: function() {
    return {
    }
  },
  props: {
    disabled: Boolean
  },
  methods: {
    placeholderForPart(part) {
      return (((this.configuration.layoutDimensions || {})[part] || '0') + ' (auto)')
    }
  },
  computed: {
    configuration() {
      return this.$store.getters["configuration/configuration"]
    },
    table() {
      return this.$store.getters["configurationOptions/tableForKey"](this.configuration.layout)
    },
    layoutParts() {
      let table = this.table
      if(!table) {
        return []
      }
      return table.geometry.split('/').filter(p => {
        return (table.rows > 1 && p[0] === 'H') || (table.cols > 1 && p[0] === 'B')
      })
    }
  }
}
</script>

<style scoped>
.openings-dimensions-selector {
  display: flex;
  flex-direction: column;
}
.openings-dimensions-selector.disabled {
  opacity: 0.35;
}

#messages {
  margin-bottom: 20px;
}
</style>

