export default function validates(config, category) {
  if (!config) return false
  if (category.configurationKeys) {
    return category.configurationKeys.reduce( (validates, key) => {
      return validates && config[key] != undefined
    }, false)
  } else {
    return (config[category.configurationKey] != undefined)
  }

}