<template>
    <div class="custom-renderer table-renderer roller-shutter-renderer">
      <ul data-pdfmake="{'margin': [-12, 0, 0, 0], 'markerColor': '#ffffff'}">
          <li>
            <span v-if="isBox">Kasten: </span>
            {{  titleFromMetadata(configuration.rollerShutter) }}
          </li>
          <li v-if="isBox">
            Art: {{ titleFromMetadata(configuration.blindsType) }}
          </li>
          <li v-if="isConnectionProfile">
            Profilhöhe: {{ configuration.coverConnectionProfile }} mm
          </li>
          <li v-if="configuration.visibility.rollerShutterHeight">Kastenhöhe: {{ configuration.rollerShutterHeight }} mm</li>
          <li v-if="configuration.visibility.coverConnectionProfile">{{ titleFromMetadata(configuration.coverConnectionProfile) }}</li>
          <li v-if="configuration.visibility.blindsMaterial && configuration.blindsMaterial">Material Kasten: {{ titleFromMetadata(configuration.blindsMaterial) }}</li>
          <li v-if="configuration.visibility.blindsCaseColor && configuration.blindsCaseColor">Farbe Kasten: {{ titleFromMetadata(configuration.blindsCaseColor) }}</li>
          <li v-if="configuration.visibility.blindsFinColor && configuration.blindsFinColor">Farbe Stäbe/Lamellen: {{ titleFromMetadata(configuration.blindsFinColor) }}</li>
          <li v-if="configuration.visibility.blindsDrive && configuration.blindsDrive">Antriebsart: {{ titleFromMetadata(configuration.blindsDrive) }}</li>
          <li v-if="configuration.visibility.blindsDriveLocation && configuration.blindsDriveLocation">Antriebsseite: {{ titleFromMetadata(configuration.blindsDriveLocation) }}</li>
          <li v-if="configuration.visibility.blindsMotor && configuration.blindsMotor">Motor: {{ titleFromMetadata(configuration.blindsMotor) }}</li>
      </ul>
    </div>
</template>

<script>
import MetadataMixin from "@/mixins/MetadataMixin.vue"

export default {
  name: "RollerShutterRenderer",
  mixins: [MetadataMixin],
  props: {
    configuration: {
      type: Object
    }
  },
  computed: {
    isBox() {
      return (
      this.configuration.rollerShutter == 'rollershutter_addonbox' 
      || this.configuration.rollerShutter == 'rollershutter_addonboxinsulated'
      || this.configuration.rollerShutter == 'rollershutter_frontmountedbox'
      )
    },
    isConnectionProfile() {
      return this.configuration.rollerShutter == 'lintelboxconnectionprofile'
    }
  }
}
</script>
