<template>
    <AppLayout>
      <template v-if="itemCount" #page-title>
        <h1>Skizzen</h1>
      </template>
      <template #navigation-title>
        {{ $store.getters["project/navigationTitle"] }}
      </template>
      <template #page>
        <div :class="'scribbles-page' + (itemCount ? '' : ' no-content')">
          <Scribbles ref="scribblesComponent" v-if="itemCount" @scribble:edit="onEditScribble" @scribble:delete="onRequestDeleteScribble" />
          <NoContentPlaceholder v-else title="Skizzen" text="Erstellen Sie Ihre erste Skizze.">
            <img src="@/assets/svg/illustration-skizzen.svg">
          </NoContentPlaceholder>
        </div>
        <AddButton @click="onAdd" />
      </template>
      <template #modal>
        <BottomSheet ref="deleteScribbleBottomSheet">
          <template #bottom-sheet-title>
            <slot name="bottom-sheet-title">Skizze löschen</slot>
          </template>
          <template #bottom-sheet-content>
            <slot name="bottom-sheet-content">
              <img class="thumb" :src="scribbleToBeDeletedUrl">
            </slot>
          </template>
          <template #bottom-sheet-actions>
            <button class="button primary login tall" @click="onDeleteScribble()">
              <span>Löschen</span>
            </button>
            <button class="button secondary login tall" @click="onCancelDeleteScribble()">
              <span>Abbrechen</span>
            </button>
          </template>
        </BottomSheet>
      </template>
    </AppLayout>
</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"
import BottomSheet from "@/components/modal/BottomSheet.vue"
import Scribbles from "@/components/scribbles/Scribbles.vue"
import AddButton from "@/components/buttons/AddButton.vue"
import NoContentPlaceholder from "@/components/NoContentPlaceholder.vue"
import { AttachmentsMixin } from "@profineberater/configurator-clientlib"

export default {
  name: "ScribblesPage",
  mixins: [AttachmentsMixin],
  components: {
    AppLayout,
    BottomSheet,
    Scribbles,
    AddButton,
    NoContentPlaceholder,
  },
  computed: {
    project() {
      return this.$store.getters["project/currentProject"]
    },
    scribbles() {
      return this.project?.scribbles
    },
    itemCount() {
      return this.scribbles?.length || 0
    },
  },
  data: function() {
    return {
      scribbleToBeDeleted: null,
      scribbleToBeDeletedUrl: null
    }
  },
  methods: {
    onAdd() {
      let scribble = {
        title: "Neue Zeichnung",
        createdAt: new Date(),
      }
      this.$store.dispatch("project/addScribble", { project: this.project, scribble: scribble }).then( () => {
        this.$store.commit("project/currentScribble", scribble)
        this.$router.push("/scribbles/edit")
      })
    },
    onContextMenu() {
      this.$refs.contextMenuBottomSheet.present()
    },
    onEditScribble(scribble) {
      this.$store.commit("project/currentScribble", scribble)
      this.$router.push("/scribbles/edit")
    },
    onRequestDeleteScribble(scribble) {
      this.scribbleToBeDeleted = scribble
      this.scribbleToBeDeletedUrl = this.attachments[scribble.originalAttachmentId]
      this.$refs.deleteScribbleBottomSheet.present()
    },
    onDeleteScribble() {
      this.$store.dispatch("project/deleteScribble", { project: this.project, scribble: this.scribbleToBeDeleted } )
      this.saveOnLeave = false
      this.$refs.deleteScribbleBottomSheet.dismiss()
      this.scribbleToBeDeleted = null
      this.scribbleToBeDeletedUrl = null
    },
    onCancelDeleteScribble() {
      this.$refs.deleteScribbleBottomSheet.dismiss()
      this.scribbleToBeDeleted = null
      this.scribbleToBeDeletedUrl = null
    },
    loadAttachments() {
      this.$store.dispatch("project/getScribbleURLs", {
        project: this.project,
      }).then( urlMap => {
        Object.keys(urlMap).forEach( id => {
          this.attachmentIds.add(id)
        })
      })
    }
  },

  mounted() {
    this.loadAttachments()
  },
  unmounted() {
    this.disposeAttachments()
  },
  // beforeRouteEnter: (to, from, next) => {
  //   next(vm => {
  //   })
  //   return true
  // },
  watch: {
    scribbles() {
      this.loadAttachments()
    }
  },
}
</script>

<style scoped>
.scribbles-page.no-content {
  height: 80%;
}
.thumb {
  max-height: 26vw;
  cursor: pointer;
}
</style>