<template>
  <div :class="clazz" @click="$emit('tabbaritem:selected', path)">
    <slot></slot>
    <!-- <span class="title">{{ title }}</span> -->
  </div>
</template>

<script>

export default {
  name: "TabBarItem",
  props: {
    title: {
      type: String
    },
    path: {
      type: String
    },
    highlightsPathPrefix: {
      type: String
    }
  },
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    clazz() {
      let s = 'tab-bar-item'
      if (this.$route.path.startsWith(this.path) || this.$route.path.startsWith(this.highlightsPathPrefix)) {
        s += ' active'
      }
      return s
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.tab-bar-item {
  width: 72px;
  height: 72px;
  min-width: 72px;
  color: var(--tabbar-item-icon-color);
  cursor: pointer;
}

.tab-bar-item.active {
  color: var(--tabbar-item-icon-active-color);
}

.tab-bar-item:hover:not(.active) {
  color: var(--tabbar-item-icon-hover-color);
}

@media (hover: none) and (pointer: coarse) {
  .tab-bar-item:hover {
    color: var(--tabbar-item-icon-color);
  }
  .tab-bar-item:hover:not(.active) {
    color: var(--tabbar-item-icon-color);
  }
  .tab-bar-item.active {
    color: var(--tabbar-item-icon-active-color);
  }
}

</style>