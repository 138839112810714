export const TYPE_TUER = "Tür";
export const TYPE_FENSTER = "Fenster";

export const RS_NONE = "rollershutter_none";
export const RS_COVER_CONNECTION_PROFILE = "lintelboxconnectionprofile";
export const RS_ROLLERSHUTTER_ADDONBOX = "rollershutter_addonbox";
export const RS_ROLLERSHUTTER_ADDONBOXINSULATED = "rollershutter_addonboxinsulated";
export const RS_ROLLERSHUTTER_FRONTMOUNTEDBOX = "rollershutter_frontmountedbox";

export const typeOptions = {
  [TYPE_TUER]: "Tür",
  [TYPE_FENSTER]: "Fenster",
};

// Note, sind BR-452: Renamed IDs
export const rollerShutterOptions = {
  [RS_NONE]: "rollerShutter_none",
  [RS_COVER_CONNECTION_PROFILE]: "lintelBoxConnectionProfile",
  [RS_ROLLERSHUTTER_ADDONBOX]: "rollerShutter_addOnBox",
  [RS_ROLLERSHUTTER_ADDONBOXINSULATED]: "rollerShutter_addOnBoxInsulated",
  [RS_ROLLERSHUTTER_FRONTMOUNTEDBOX]: "rollerShutter_frontMountedBox",
};

export default class Aufmass {

  calculate(model, options) {
    let doFixUpAndDefaults = false

    let element = model.element

    let rollerShutterAvailable = (element !== 'haustür')

    let useExtendedMeasurement = model.useExtendedMeasurement
    let rollerShutter = model.rollerShutter
    let rollerShutterBoxAvailable = (
      rollerShutter === RS_ROLLERSHUTTER_ADDONBOX
      || rollerShutter === RS_ROLLERSHUTTER_ADDONBOXINSULATED
      || rollerShutter === RS_ROLLERSHUTTER_FRONTMOUNTEDBOX)

    let type = {
      "fenster / fenstertür": TYPE_FENSTER,
      "fenstertür mit schwelle": TYPE_TUER,
      "haustür": TYPE_TUER,
      "hebeschiebetür": TYPE_TUER,
    }[element || ''] || TYPE_FENSTER;

    let {widthMin, widthMax, heightMin, heightMax} = options?.tree?.element?.[element]?.layout?.[model.layout]?.opening?.[model.opening] || {}

    let table = options?.tables[model.opening]



    let visibility = {
          // inputs
          coverConnectionProfile:
            rollerShutter === RS_COVER_CONNECTION_PROFILE,
          rollerShutter: rollerShutterAvailable,
          rollerShutterHeight: rollerShutterBoxAvailable,
          blindsCaseColor: rollerShutterBoxAvailable,
          blindsFinColor: rollerShutterBoxAvailable,
          blindsMaterial: rollerShutterBoxAvailable,
          blindsDrive: rollerShutterBoxAvailable,
          blindsDriveLocation: rollerShutterBoxAvailable,
          blindsMotor: rollerShutterBoxAvailable,
          blindsFixture: true,
          frameExtensionLeft: true,
          frameExtensionRight: true,
          frameExtensionTop: true,
          frameExtensionBottom: true,
          oddLegFrameExtension: type === TYPE_FENSTER,
          baseSingleColumnProfile: type === TYPE_TUER,
          marginLeft: true,
          marginRight: true,
          marginTop: true,
          marginBottom: type === TYPE_FENSTER,
          stopLeft: true,
          stopRight: true,
          stopTop: true,
          innerWidth: true,
          innerHeight: true,
          outerWidth: true,
          outerHeight: true,
          layoutDimensions: false,
          // outputs
          rollerShutterWidth: rollerShutter !== RS_NONE,
          isDoubleSidedColor: false,
          shellColor: false,
          sealColor: true,
        }

    let simpleVisibility = {
      marginLeft: false,
      marginRight: false,
      marginTop: false,
      marginBottom: false,
      stopLeft: false,
      stopRight: false,
      stopTop: false,
      innerWidth: false,
      innerHeight: false,
      outerWidth: false,
      outerHeight: false,
    }

    if(!useExtendedMeasurement) {
      visibility = { ...visibility, ...simpleVisibility }
    }

    let isWhite = (!model.color || model.color === 'weiß')

    visibility.layoutDimensions = !(table?.cols == 1 && table?.rows == 1)
    visibility.isDoubleSidedColor = model.material === "pvc" && !isWhite
    visibility.shellColor = (model.material === "alu/pvc" || model.material === "holz/alu")

    visibility.windowLedgeInner = (type === TYPE_FENSTER)
    visibility.windowLedgeOuter = visibility.windowLedgeInner
    visibility.threshold = (type === TYPE_TUER)
    visibility.doorFilling = (element === 'haustür')
    visibility.cutPattern = visibility.filling
    visibility.windowHingeType = (element !== 'hebeschiebetür') // Fensterbänder

    model.visibility = { ...visibility }
    // avoid NaN errors from uninitialized data
    model.frameExtensionLeft ||= 0
    model.frameExtensionRight ||= 0
    model.frameExtensionTop ||= 0
    model.frameExtensionBottom ||= 0
    model.marginLeft ||= 0
    model.marginRight ||= 0
    model.marginTop ||= 0
    model.marginBottom ||= 0
    model.rollerShutterHeight ||= 0
    model.oddLegFrameExtension ||= 0
    model.baseSingleColumnProfile ||= 0
    model.coverConnectionProfile ||= 0

    let warnings = {}
    let errors = {}

    let frameWidthDeduction = 0
    let frameHeightDeduction = 0
    let marginHorizontal = 0
    let marginVertical = 0

    let extraElementHeight = 0

    if(doFixUpAndDefaults) {
      let material = model.material || 'pvc'
      let isWhite = (!model.color || model.color === 'weiß')

      if(material.indexOf("holz") !== -1) {
        model.sealColor = 'dichtung braun'
      } else {
        if(material.indexOf("alu") !== -1) {
          model.sealColor = 'dichtung schwarz'
        } else {
          if(model.sealColor !== 'dichtung schwarz' && !(material === 'pvc' && isWhite && model.sealColor === 'dichtung lichtgrau')) {
            model.sealColor = 'dichtung schwarz'
          }
        }
      }

      let defaultLayouts = {
        "fenster / fenstertür": "einteilig",
        "fenstertür mit schwelle": "einteilig",
        "haustür": "einteilig",
        "hebeschiebetür": "zweiteilig",
      }

      let fallbackWidth = 1500
      let fallbackHeight = 1000

      let defaultsKey = (element || 'fenster / fenstertür') + ',' + (model.layout || defaultLayouts[element || ''] || 'einteilig')
      let defaults = options?.defaultConfigurations?.filter(c => c.title === defaultsKey).pop() || { innerWidth: fallbackWidth, innerHeight: fallbackHeight }

      window.logMeasurement && console.log("defaultsKey", defaultsKey, "defaults", defaults)

      model.layoutInputs ||= {}

      model.innerWidth = model.layoutInputs.innerWidth || defaults.innerWidth
      model.innerHeight = model.layoutInputs.innerHeight || defaults.innerHeight
      model.outerWidth = model.layoutInputs.outerWidth || model.innerWidth
      model.outerHeight = model.layoutInputs.outerHeight || model.innerHeight

      /* additional code for new switch */
      model.frameWidth = model.layoutInputs.frameWidth || defaults.frameWidth
      model.frameHeight = model.layoutInputs.frameHeight || defaults.frameHeight

      model.coverConnectionProfile ||= defaults.coverConnectionProfile || 15
      /* end additional code for new switch */

      model.layout ||= defaultLayouts[element || ''] || 'einteilig'
      model.opening ||= defaults.opening
      for(let key of Object.keys(defaults.layoutInputs || {})) {
        model.layoutInputs[key] ||= defaults.layoutInputs[key]
      }
    }

    model.layoutDimensions = {}

    /* code taken out of if useExtendedMeasurement */

    frameWidthDeduction += model.frameExtensionLeft * visibility.frameExtensionLeft
    frameWidthDeduction += model.frameExtensionRight * visibility.frameExtensionRight

    frameHeightDeduction += model.frameExtensionBottom * visibility.frameExtensionBottom

    marginVertical += model.marginTop * visibility.marginTop
    marginHorizontal += model.marginLeft * visibility.marginLeft
    marginHorizontal += model.marginRight * visibility.marginRight

    switch (type) {
      case TYPE_TUER:
        frameHeightDeduction += model.baseSingleColumnProfile * visibility.baseSingleColumnProfile
        break
      case TYPE_FENSTER:
        frameHeightDeduction += model.oddLegFrameExtension * visibility.oddLegFrameExtension
        marginVertical += model.marginBottom * visibility.marginBottom
        break
      default:
        console.error(`unknown case: type === ${type}`)
        break
    }

    // not dependent on rollerShutter anymore
    frameHeightDeduction += model.frameExtensionTop * visibility.frameExtensionTop

    switch (rollerShutter) {
      case undefined:
      case RS_NONE:
        break

      case RS_COVER_CONNECTION_PROFILE:
        extraElementHeight += model.coverConnectionProfile * visibility.coverConnectionProfile
        break

      case RS_ROLLERSHUTTER_ADDONBOX:
        frameHeightDeduction += model.rollerShutterHeight * visibility.rollerShutterHeight
        break

        case RS_ROLLERSHUTTER_ADDONBOXINSULATED:
        frameHeightDeduction += model.rollerShutterHeight * visibility.rollerShutterHeight
        break

      case RS_ROLLERSHUTTER_FRONTMOUNTEDBOX:
        // only saved, not included in calculation
        extraElementHeight += 0 * model.rollerShutterHeight * visibility.rollerShutterHeight
        break

      default:
        console.error(`unknown case: rollerShutter === ${rollerShutter}`);
        break
    }

    /* end code taken out of if useExtendedMeasurement */

    if (useExtendedMeasurement) {

      model.stopLeft = (model.innerWidth - model.outerWidth) / 2 // D6
      model.stopRight = model.stopLeft

      model.stopTop = model.innerHeight - model.outerHeight; // D13

      model.elementWidth = model.innerWidth - marginHorizontal

      model.elementHeight = model.innerHeight - marginVertical + extraElementHeight;

      model.frameWidth = model.elementWidth - frameWidthDeduction;
      model.frameHeight = model.elementHeight - frameHeightDeduction - extraElementHeight;

      // update them so they don't fall back to defaultConfiguration values when switching
      // but only if they are not based on defaults
      if(model.layoutInputs.innerWidth) {
        model.layoutInputs.frameWidth = model.frameWidth;
      }
      if(model.layoutInputs.innerHeight) {
        model.layoutInputs.frameHeight = model.frameHeight;
      }
    } else {
      model.elementWidth = model.frameWidth + frameWidthDeduction;
      model.elementHeight = model.frameHeight + frameHeightDeduction + extraElementHeight;

      model.stopLeft = 0;
      model.stopRight = 0;
      model.stopTop = 0;

      model.marginTop = 0;
      model.marginBottom = 0;
      model.marginLeft = 0;
      model.marginRight = 0;

      // update them so they don't fall back to defaultConfiguration values when switching
      // but only if they are not based on defaults
      if(model.layoutInputs.frameWidth) {
        model.layoutInputs.innerWidth = model.elementWidth;
        delete model.layoutInputs.outerWidth; // auto
      }
      if(model.layoutInputs.frameHeight) {
        model.layoutInputs.innerHeight = model.elementHeight;
        delete model.layoutInputs.outerHeight; // auto
      }
    }

    model.rollerShutterWidth = rollerShutter !== RS_NONE ? model.elementWidth : 0; // D18

    function testMinMax(errorKey, value, localizedValue, lowerLimit, localizedLowerLimit, upperLimit, localizedUpperLimit) {
      lowerLimit !== undefined && testMin(errorKey, value, localizedValue, lowerLimit, localizedLowerLimit)
      upperLimit !== undefined && testMax(errorKey, value, localizedValue, upperLimit, localizedUpperLimit)
    }

    function testMax(errorKey, value, localizedValue, limit, localizedLimit) {
      if((value || 0) > limit) {
        errors[errorKey] = `Die ${localizedValue} (${value}) überschreitet die ${localizedLimit} (${limit})`
      }
    }

    function testMin(errorKey, value, localizedValue, limit, localizedLimit) {
      if((value || 0) < limit) {
          errors[errorKey] = `Die ${localizedValue} (${value}) unterschreitet die ${localizedLimit} (${limit})`
      }
    }

    function testEqual(errorKey, value, localizedValue, targetValue, localizedTargetValue) {
      if(value != targetValue) {
        errors[errorKey] = `Die ${localizedValue} (${value}) entspricht nicht der ${localizedTargetValue} (${targetValue})`
      }
    }

    model.frameDimensions = model.frameWidth + " x " + model.frameHeight;
    model.elementDimensions = model.elementWidth + " x " + model.elementHeight;

    for(let key of ['frameExtensionLeft', 'frameExtensionRight']) {
      model[key + 'Dimensions'] = visibility[key] && model[key] ? model[key] + " x " + model.elementWidth : undefined
    }

    for(let key of ['frameExtensionTop', 'frameExtensionBottom', 'coverConnectionProfile', 'oddLegFrameExtension', 'baseSingleColumnProfile']) {
      model[key + 'Dimensions'] = visibility[key] && model[key] ? model[key] + " x " + model.elementHeight : undefined
    }

    model.rollerShutterDimensions = visibility.rollerShutterHeight && model.rollerShutterHeight ? model.rollerShutterHeight + " x " + model.rollerShutterWidth : undefined

    // Es gibt Fälle, in denen die Aussenbreite grösser sein kann als die Innenbreite (Verbleib eines alten Rahmens)
    //testMax('outerWidth', model.outerWidth, "Aussenbreite", model.innerWidth, "Innenbreite")
    //testMax('outerHeight', model.outerHeight, "Aussenhöhe", model.innerHeight, "Innenhöhe")

    if (model.stopLeft > 50 || model.stopRight > 50) {
      warnings.frameExtensionLeftRight =
        `Verbreiterungen links/rechts (je ${model.stopLeft} mm) empfohlen wegen Sichtbarkeit Rahmen oder bei Verwendung Rollladenlaufschienen`;
    }

    if (model.stopTop > 50) {
      warnings.frameExtensionTop =
        `Verbreiterung oben (${model.stopTop} mm) empfohlen wegen Sichtbarkeit Rahmen`;
    }

    if(model.layout && model.opening) {
      if(options && options.tables) {
        let table = options.tables[model.opening]
        let geometry = table?.geometry

        let parts = geometry?.split('/').filter(p => {
          return (table.rows > 1 && p[0] === 'H') || (table.cols > 1 && p[0] === 'B')
        }) || [];
        let widths = parts.filter(p => p[0] === 'B')
        let heights = parts.filter(p => p[0] === 'H')

        let givenWidths = widths.filter(p => model.layoutInputs[p])
        let givenHeights = heights.filter(p => model.layoutInputs[p])

        let omittedWidths = widths.filter(p => !(model.layoutInputs[p]))
        let omittedHeights = heights.filter(p => !(model.layoutInputs[p]))

        let givenWidthsSum = givenWidths.reduce((p,c) => p + +model.layoutInputs[c], 0)
        let givenHeightsSum = givenHeights.reduce((p,c) => p + +model.layoutInputs[c], 0)

        let remainingWidth = model.frameWidth - givenWidthsSum
        let remainingHeight = model.frameHeight - givenHeightsSum

        model.layoutDimensions = {}
        for(let part of givenWidths.concat(givenHeights)) {
          model.layoutDimensions[part] = +model.layoutInputs[part]
        }

        for(let part of omittedWidths) {
          model.layoutDimensions[part] = Math.round(remainingWidth / omittedWidths.length)
        }
        for(let part of omittedHeights) {
          model.layoutDimensions[part] = Math.round(remainingHeight / omittedHeights.length)
        }

        // from now on we use them as the full set
        if(!widths.length) {
          widths = ['B']
          model.layoutDimensions.B = model.frameWidth
        }
        if(!heights.length) {
          heights = ['H']
          model.layoutDimensions.H = model.frameHeight
        }

        let partWidthMin = 300
        let partWidthMax = Math.ceil(Math.min(widthMax, model.frameWidth))
        let partHeightMin = 300
        let partHeightMax = Math.ceil(Math.min(heightMax, model.frameHeight))

        let partsWidth
        let partsHeight
        let roundedPartsWidth
        let roundedPartsHeight

        partsWidth = widths.reduce((p,c) => p + +model.layoutDimensions[c], 0)
        partsHeight = heights.reduce((p,c) => p + +model.layoutDimensions[c], 0)

        if(omittedWidths.length) {
          model.layoutDimensions[omittedWidths[0]] += model.frameWidth - partsWidth
        }
        if(omittedHeights.length) {
          model.layoutDimensions[omittedHeights[0]] += model.frameHeight - partsHeight
        }

        partsWidth = widths.reduce((p,c) => p + +model.layoutDimensions[c], 0)
        partsHeight = heights.reduce((p,c) => p + +model.layoutDimensions[c], 0)
        roundedPartsWidth = Math.round(partsWidth)
        roundedPartsHeight = Math.round(partsHeight)

        let localizedParts = {
            HOL: "Höhe Oberlicht",
            HUL: "Höhe Unterlicht",
            B: "Breite Hauptteil",
            H: "Höhe Hauptteil",
            BL: "Breite links",
            BLM: "Breite Mitte links",
            BM: "Breite Mitte",
            BRM: "Breite Mitte rechts",
            BR: "Breite rechts",
        }

        if(widths.length > 1) {
          for(let part of widths) {
            testMinMax(`layoutDimensions.${part}`, model.layoutDimensions[part], localizedParts[part] || part, partWidthMin, "minimale Teilbreite", partWidthMax, "maximale Teilbreite")
          }
        }

        if(heights.length > 1) {
          for(let part of heights) {
            testMinMax(`layoutDimensions.${part}`, model.layoutDimensions[part], localizedParts[part] || part, partHeightMin, "minimale Teilhöhe", partHeightMax, "maximale Teilhöhe")
          }
        }

        testEqual('partsWidth', roundedPartsWidth, "Summe der Teilbreiten", model.frameWidth, "Rahmenaussenbreite")
        testEqual('partsHeight', roundedPartsHeight, "Summe der Teilhöhen", model.frameHeight, "Rahmenaussenhöhe")

        // would have to respect margins according to useExtendedMeasurement
        //testMax('elementWidth', model.elementWidth, "Elementbreite", model.innerWidth, "Innenbreite")
        //testMax('elementHeight', model.elementWidth, "Elementhöhe", model.innerWidth, "Innenhöhe")

        testMinMax('frameWidth', model.frameWidth, "Rahmenaussenbreite", widthMin, "Minimalbreite", widthMax, "Maximalbreite")
        testMinMax('frameHeight', model.frameHeight, "Rahmenaussenhöhe", heightMin, "Minimalhöhe", heightMax, "Maximalhöhe")

        // trigger reactivity
        model.layoutDimensions = { ... model.layoutDimensions }

        window.logLayout && console.log("AUFMASS", "givenW", givenWidths, "sum", givenWidthsSum, "givenH", givenHeights, "sumH", givenHeightsSum, "remW", remainingWidth, "remH", remainingHeight, "omittedW", omittedWidths, "omittedH", omittedHeights, "partsW", partsWidth, "partsH", partsHeight, "frameW", model.frameWidth, "frameH", model.frameHeight)
      } else {
        console.error("aufmass - no options")
      }
    }

    model.errors = { ...errors }
    model.warnings = { ...warnings }

    window.logMeasurement && console.log("AUFMASS", JSON.stringify(model, null, "  "))

    return null;
  }
}