<template>
  <div class="onboarding-video">
    <p v-if="debug" class="device-info">videoUrl: {{ videoUrl }}<br>
      currentOS: {{ currentOS }}<br>
      currentBrowser: {{ currentBrowser }}<br>
      currentDevice: {{ currentDevice }}<br>
    </p>
    <video
      id="videoplayer"
      ref="videoplayer"
      :src="videoUrl"
      type="video/mp4"
      autoplay
      controls="false"
      muted
      loop
      playsinline
      webkit-playsinline
    ></video>

  </div>
</template>

<script>

const EXTERNAL_VIDEO_BASEURL = "https://www.profine-esolutions.com/assets/video/onboarding/"

import { BrowserCheckMixin } from "@profineberater/configurator-clientlib"
// The detect library delivers:
// Os: Mac OS, iOS, Android OS
// Browser: chrome, ios, samsung, firefox

export default {
  name: 'OnboardingVideo',
  mixins: [BrowserCheckMixin],
  data: function() {
    return {
      devices: {
        android: {
          tablet: {
            chrome: "android_tablet_chrome.mp4",
            firefox: "android_tablet_firefox.mp4",
            samsung: "android_tablet_samsung_internet.mp4",
          },
          phone: {
            chrome: "android_smartphone_chrome.mp4",
            firefox: "android_smartphone_firefox.mp4",
            samsung: "android_smartphone_samsung_internet.mp4",
          }
        },
        ios: {
          tablet: {
            safari: "ios_tablet_safari.mp4"
          },
          phone: {
            safari: "ios_smartphone_safari.mp4"
          }
        }
      }
    }
  },
  mounted() {
    this.checkBrowser()
    this.setCrossOrigin = (this.currentOS == "ios" && this.currentBrowser == "safari")
    if (this.setCrossOrigin) {
      this.$refs.videoplayer.setAttribute("crossorigin", "true");
    }
  },
  computed: {
    videoUrl() {
      const url = this.devices[this.currentOS]?.[this.currentDevice]?.[this.currentBrowser]
      return url ? EXTERNAL_VIDEO_BASEURL + url : null
    }
  },
  methods: {
    show() {
      this.$el.style.display = "block";
    }
  }
}
</script>

<style scoped>
  video {
    height: 50vh;
  }
  .onboarding-video {
    display: none;
  }
  .device-info {
    white-space: wrap;
    word-wrap: break-word;
  }
</style>