<template>
  <svg class="edit-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      d="M13.545 8.835v5.433a.983.983 0 0 1-.984.982H1.74a.983.983 0 0 1-.99-.976V3.468c0-.543.441-.982.984-.982h5.448"
      style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px" />
    <path
      d="m9.638 8.083-2.127.41.412-2.125L13.334.95a.68.68 0 0 1 .963-.001l.001.001.752.752a.68.68 0 0 1 .001.963l-.001.001-5.412 5.416Z"
      style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px" />
  </svg>
</template>

<script>

export default {
  name: 'EditIcon',
}
</script>

<style scoped>
.edit-icon {
  display: block;
  cursor: pointer;
  height: 16px;
  width: 16px;
}
</style>