<template>
  <div class="profile-navigation-item" @click="$router.push('/profile')">
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
      <defs>
      </defs>
      <circle cx="13" cy="13" r="12" style="fill:none;stroke:#1682f3;stroke-width:2px" />
      <g fill="#1682f3">
        <path id="Pfad_12818" d="M16.475 10.146a3.474 3.474 0 1 1-3.48-3.469h.01a3.474 3.474 0 0 1 3.47 3.469"/>
        <path d="M12.999 15.213a7.315 7.315 0 0 0-7.313 7.285c2.026 1.562 4.558 2.501 7.314 2.501s5.288-.939 7.314-2.502a7.314 7.314 0 0 0-7.315-7.285Z" />
      </g>
    </svg>
  </div>
</template>

<script>

export default {
  name: "ProfileNavigationItem",
}
</script>