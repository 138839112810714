<template>
    <AppLayout>
      <template v-if="itemCount" #page-title>
        <h1>Notizen</h1>
      </template>
      <template #navigation-title>
        {{ $store.getters["project/navigationTitle"] }}
      </template>
      <template #page>
        <div :class="'notes-page' + (itemCount ? '' : ' no-content')">
          <Notes v-if="itemCount" @note:delete="onRequestDeleteNote" />
          <NoContentPlaceholder v-else title="Notizen" text="Erstellen Sie Ihre erste Notiz.">
            <img src="@/assets/svg/illustration-notizen.svg">
          </NoContentPlaceholder>
          <AddButton @click="onAdd" />
        </div>
      </template>
      <template #modal>
        <BottomSheet ref="deleteNoteBottomSheet">
            <template #bottom-sheet-title>
              <slot name="bottom-sheet-title">Notiz löschen</slot>
            </template>
            <template #bottom-sheet-content>
              <slot name="bottom-sheet-content">
                <h3 v-if="noteToBeDeleted">{{ noteToBeDeleted.title }}</h3>
                <p v-if="noteToBeDeleted">{{ noteToBeDeleted.content }}</p>
              </slot>
            </template>
            <template #bottom-sheet-actions>
              <button class="button primary login tall" @click="onDeleteNote()">
                <span>Löschen</span>
              </button>
              <button class="button secondary login tall" @click="onCancelDeleteNote()">
                <span>Abbrechen</span>
              </button>
            </template>
          </BottomSheet>
      </template>
    </AppLayout>
</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"
import Notes from "@/components/notes/Notes.vue"
import AddButton from "@/components/buttons/AddButton.vue"
import NoContentPlaceholder from "@/components/NoContentPlaceholder.vue"
import BottomSheet from "@/components/modal/BottomSheet.vue"

export default {
  name: "NotesPage",
  components: {
    AppLayout,
    Notes,
    AddButton,
    NoContentPlaceholder,
    BottomSheet,
  },
  data() {
    return {
      noteToBeDeleted: null,

    };
  },
  computed: {
    project() {
      return this.$store.getters["project/currentProject"]
    },
    itemCount() {
      return this.project?.notes?.length || 0
    }
  },
  methods: {
    onAdd() {
      let note = {
        content: "",
        createdAt: new Date(),
        title: "Neue Notiz",
        positionNumber: null
      }
      this.$store.dispatch("project/addNote", { project: this.project, note: note })
    },
    onContextMenu() {
      this.$refs.contextMenuBottomSheet.present()
    },
    onRequestDeleteNote(note) {
      this.noteToBeDeleted = note
      this.$refs.deleteNoteBottomSheet.present()
    },
    onCancelDeleteNote() {
      this.$refs.deleteNoteBottomSheet.dismiss()
      this.noteToBeDeleted = null
    },
    onDeleteNote() {
      this.$store.dispatch("project/deleteNote", { project: this.project, note: this.noteToBeDeleted } )
      this.$refs.deleteNoteBottomSheet.dismiss()
      this.noteToBeDeleted = null
    },
  },
};
</script>

<style scoped>
.notes-page.no-content {
  height: 80%;
}
</style>