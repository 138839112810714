<template>
  <div class="add-customer-form">
    <form v-if="validationModel">
      <label>
        <TextInput label="Nachname*"
          placeholder="Bitte eingeben"
          v-model="customer.lastName"
          :errorMessage="validationModel.lastName.message"
          :showsErrorOutline="validationModel.lastName.message && validationModel.lastName.message.length > 0"
          :showErrors="validationModel.lastName.dirty"
          @change="$emit('field:change', 'lastName', $event)" />
      </label>
      <label>
        <TextInput label="Vorname"
          placeholder="Bitte eingeben"
          v-model="customer.firstName"
          :errorMessage="validationModel.firstName.message"
          :showsErrorOutline="validationModel.firstName.message && validationModel.firstName.message.length > 0"
          :showErrors="validationModel.firstName.dirty"
          @change="$emit('field:change', 'firstName', $event)" />
      </label>
      <label>
        <TextInput label="Straße/Nr."
          placeholder="Bitte eingeben"
          v-model="customer.streetAndNumber"
          :errorMessage="validationModel.streetAndNumber.message"
          :showsErrorOutline="validationModel.streetAndNumber.message && validationModel.streetAndNumber.message.length > 0"
          :showErrors="validationModel.streetAndNumber.dirty"
          @change="$emit('field:change', 'streetAndNumber', $event)" />
      </label>
      <label>
        <TextInput label="Postleitzahl"
          placeholder="Bitte eingeben"
          v-model="customer.zip"
          :errorMessage="validationModel.zip.message"
          :showsErrorOutline="validationModel.zip.message && validationModel.zip.message.length > 0"
          :showErrors="validationModel.zip.dirty"
          @change="$emit('field:change', 'zip', $event)" />
      </label>
      <label>
        <TextInput label="Ort"
          placeholder="Bitte eingeben"
          v-model="customer.city"
          :errorMessage="validationModel.city.message"
          :showsErrorOutline="validationModel.city.message && validationModel.city.message.length > 0"
          :showErrors="validationModel.city.dirty"
          @change="$emit('field:change', 'city', $event)" />
      </label>
      <label>
        <TextInput label="Telefon"
          optional="false"
          placeholder="Bitte eingeben"
          v-model="customer.phone"
          :errorMessage="validationModel.phone.message"
          :showsErrorOutline="validationModel.phone.message && validationModel.phone.message.length > 0"
          :showErrors="validationModel.phone.dirty"
          @change="$emit('field:change', 'phone', $event)" />
      </label>
      <label>
        <TextInput label="E-Mail"
          placeholder="Bitte eingeben"
          v-model="customer.email"
          :errorMessage="validationModel.email.message"
          :showsErrorOutline="validationModel.email.message && validationModel.email.message.length > 0"
          :showErrors="validationModel.email.dirty"
          @change="$emit('field:change', 'email', $event)" />
      </label>
      <legend class="mandatory">
        * Dies ist ein Pflichtfeld.
      </legend>
    </form>
  </div>
</template>

<script>

import { TextInput } from "@profineberater/configurator-clientlib"
import { useModelWrapper } from '@/modelWrapper.js'

export default {
  name: "AddCustomerForm",
  props: {
    modelValue: {
      type: Object
    },
    validator: {
      type: Object
    },
    validationModel: {
      type: Object
    }
  },
  setup(props, { emit }) {
    return {
      customer: useModelWrapper(props, emit, 'modelValue'),
    }
  },
  components: {
    TextInput
  },
};
</script>

<style scoped>
:deep(.text-input input) {
  margin-bottom: 0;
}
:deep(.text-input label) {
  margin-bottom: 0;
}
.mandatory {
  text-align: left;
  padding: 20px 20px;
  color: var(--text-color);
  font-size: 10px;
  letter-spacing: 0.5px;
}
</style>