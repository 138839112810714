export const leverParameterTable = {
  "defaults": {
    "leverType": "straight", // (FT) curved curved_key curved_knob straight_key straight (HST) pipe handle bend
    "leverMaterial": "WhiteMetal", // Metal Silver StainlessSteel WhiteMetal BrushedMetal Marble (statt schwarz)
    "leverNr": 3, // 1=kurze Stange, 2=lange Stange, 3=Knauf, 4=Garnitur mit flachem Knauf, 5=Garnitur mit Griff innen+außen
    "pzTuelleSecure": false,
  },

  "ohne griff": { "leverType": "none" },
  //    "fenstergriffe"
  // leverType und leverMaterial in WinCell oder WindowProperties, Rest global
  // [leverType, leverMaterial, leverWidth, leverHeight, leverDepth]
  "hoppe-aluminium-dreh-kipp-fenstergriff mit secu100® + secustik® und variofit®, abschließbar_atlanta_alu_silberfarben_0530sus952 100nm": { "leverType": "curved_key", "leverMaterial": "Silver" },
  "hoppe-aluminium-dreh-kipp-fenstergriff mit secu100® + secustik® und variofit®, abschließbar_atlanta_alu_stahlfarben_0530sus952 100nm": { "leverType": "curved_key", "leverMaterial": "StainlessSteel" },
  "hoppe-aluminium-dreh-kipp-fenstergriff mit secu100® + secustik® und variofit®, abschließbar_atlanta_alu_verkehrsweiss_0530sus952 100nm": { "leverType": "curved_key", "leverMaterial": "WhiteMetal" },
  "hoppe-aluminium-dreh-kipp-fenstergriff mit secuforte® und variofit®, abschließbar_hamburg_alu_schwarz matt_0700sud9020": { "leverType": "straight_key", "leverMaterial": "BlackAluminium" },
  "hoppe-aluminium-dreh-kipp-fenstergriff mit secuforte® und variofit®, abschließbar_hamburg_alu_silberfarben_0700sud9020": { "leverType": "straight_key", "leverMaterial": "Silver" },
  "hoppe-aluminium-dreh-kipp-fenstergriff mit secuforte® und variofit®, abschließbar_hamburg_alu_verkehrsweiss_0700sud9020": { "leverType": "straight_key", "leverMaterial": "WhiteMetal" },
  "hoppe-aluminium-dreh-kipp-fenstergriff mit secustik® und variofit®,nicht abschließbar_atlanta_alu_silberfarben_0530us952": { "leverType": "curved", "leverMaterial": "Silver" },
  "hoppe-aluminium-dreh-kipp-fenstergriff mit secustik® und variofit®,nicht abschließbar_atlanta_alu_stahlfarben_0530us952": { "leverType": "curved", "leverMaterial": "StainlessSteel" },
  "hoppe-aluminium-dreh-kipp-fenstergriff mit secustik® und variofit®,nicht abschließbar_atlanta_alu_verkehrsweiss_0530us952": { "leverType": "curved", "leverMaterial": "WhiteMetal" },
  "hoppe-edelstahl-dreh-kipp-fenstergriff_amsterdam_edelstahl_edelstahl matt_e0400u30": { "leverType": "straight", "leverMaterial": "BrushedMetal" },

  //    "haustürgriffe"
  // alle Parameter in DoorProperties, globale leverWidth, leverHeight, leverDepth ohne Wirkung
  // [leverNr, outerLeverMaterial, outerLeverColor, pzTuelleMaterial, pzTuelleColor, pzTuelleSecure, drueckerGarniturMaterial, drueckerGarniturColor, lockMaterial, lockColor]
  "hoppe-edelstahl-bügelgriff, stangenform_e5012_edelstahl_edelstahl matt_500mm": { "leverNr": 1, "leverMaterial": "StainlessSteel" },
  "hoppe-edelstahl-bügelgriff, stangenform_e5012_edelstahl_edelstahl matt_600mm": { "leverNr": 2, "leverMaterial": "StainlessSteel" },
  "hoppe-aluminium-schmalschild-türgriff-halbgarnitur für profiltüren_liverpool_alu_silberfarben_1313g3357sn": { "leverNr": 3, "leverMaterial": "Silver" },
  "hoppe-aluminium-schmalschild-türgriff-halbgarnitur für profiltüren_liverpool_alu_stahlfarben_1313g3357sn": { "leverNr": 3, "leverMaterial": "StainlessSteel" },
  "hoppe-aluminium-schmalschild-türgriff-halbgarnitur für profiltüren_liverpool_alu_verkehrsweiss_1313g3357sn": { "leverNr": 3, "leverMaterial": "WhiteMetal" },
  "hoppe-edelstahl-schmalschild-türgriff-halbgarnitur für profiltüren_amsterdam_edelstahl_edelstahl matt_e1400f3357sn": { "leverNr": 3, "leverMaterial": "BrushedMetal" },
  "hoppe-aluminium-schmalschild-wechsel-garnitur für profiltüren_liverpool_alu_silberfarben_86g3357n1313g": { "leverNr": 4, "leverMaterial": "Silver" },
  "hoppe-aluminium-schmalschild-wechsel-garnitur für profiltüren_liverpool_alu_stahlfarben_86g3357n1313g": { "leverNr": 4, "leverMaterial": "StainlessSteel" },
  "hoppe-aluminium-schmalschild-wechsel-garnitur für profiltüren_liverpool_alu_verkehrsweiss_86g3357n1313g": { "leverNr": 4, "leverMaterial": "WhiteMetal" },
  "hoppe-edelstahl-schutz-schmalschild-türgriff-garnitur mit zylinder-abdeckung für profiltüren_liverpool_edelstahl_edelstahl matt_e1313g3359za3357n":  { "leverNr": 5, "leverMaterial": "BrushedMetal" },
  "hoppe-edelstahl-schiebe-schlüsselrosette für profiltüren_e55s-sr_edelstahl_edelstahl matt": { "leverNr": 3, "leverMaterial": "BrushedMetal" },
  "hoppe-edelstahl-schutz-schiebe-schlüsselrosette mit zylinder-abdeckung für profiltüren_e55s-sr-za_edelstahl_edelstahl matt":  { "leverNr": 3, "leverMaterial": "BrushedMetal", "pzTuelleSecure": true },
  //    "hst-griffe"
  // Alle Parameter global
  // [leverType, leverMaterial, leverWidth, leverHeight, leverDepth]
  "hoppe-aluminium-hebe-schiebe-garnitur, außen mit muschel, für fenstertüren_atlanta_alu_silberfarben_hs-0530431n-as422": { "leverType": "bend", "leverMaterial": "Silver" },
  "hoppe-aluminium-hebe-schiebe-garnitur, außen mit muschel, für fenstertüren_atlanta_alu_stahlfarben_hs-0530431n-as422": { "leverType": "bend", "leverMaterial": "StainlessSteel" },
  "hoppe-aluminium-hebe-schiebe-garnitur, außen mit muschel, für fenstertüren_atlanta_alu_verkehrsweiss_hs-0530431n-as422":  { "leverType": "bend", "leverMaterial": "WhiteMetal" },
  "hoppe-aluminium-hebe-schiebe-garnitur, außen mit muschel, für fenstertüren_hamburg_alu_schwarz_hs-0700431n-as422": { "leverType": "handle", "leverMaterial": "Marble1" },
  "hoppe-aluminium-hebe-schiebe-garnitur, außen mit muschel, für fenstertüren_hamburg_alu_silberfarben_hs-0700431n-as422": { "leverType": "handle", "leverMaterial": "Silver" },
  "hoppe-aluminium-hebe-schiebe-garnitur, außen mit muschel, für fenstertüren_hamburg_alu_verkehrsweiss_hs-0700431n-as422": { "leverType": "handle", "leverMaterial": "WhiteMetal" },
  "hoppe-edelstahl-hebe-schiebe-garnitur, außen mit muschel, für fenstertüren_amsterdam_edelstahl_edelstahl matt_hs-e0400f-25431n-as422": { "leverType": "pipe", "leverMaterial": "BrushedMetal" },
  //    "customhandlegroup"
  "neuer griff": {},
}

export const windowLedgeInnerMaterialMap = {

}

export const windowLedgeOuterMaterialMap = {

}
