<template>
  <div class="drawpad">
    <div class="navigator-wrapper">
      <div class="navigator-canvas">
        <canvas height="60" width="60" id="navigatorcanvas" ref="navigatorcanvas" class="noselect"></canvas>
        <div
          class="navigator-screen"
          :style="{
            'width': navigatorDeviceWidth + 'px',
            'height': navigatorDeviceHeight + 'px',
            'left': navigatorDeviceOffsetLeft + 'px',
            'top': navigatorDeviceOffsetTop + 'px'
          }"
        >
        </div>
      </div>
    </div>
    <div class="toolbar-wrapper">
      <div class="toolbar">
        <div class="colorwell" @click="togglePicker" :style="'background-color: ' + this.currentColor" ref="colorpicker">
          <swatches-picker v-model="colors" v-show="displayPicker" @click="updateFromPicker"></swatches-picker>
        </div>
        <div class="toolbar-group">
          <div :class="eraserActiveClass" @click="onSelectErase">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
              <rect x="2.959" y="3.062" width="6.692" height="7.209" transform="translate(-2.867 6.411) rotate(-45)" stroke="currentColor" style="fill:none; stroke-linecap:round; stroke-linejoin:round; stroke-width:1px;"/>
              <path d="m6.93,11.139l3.847-3.847,2.723,2.723-2.234,2.234h-3.226l-1.11-1.11Z" stroke="currentColor" style="fill:none; stroke-linecap:round; stroke-linejoin:round;"/>
              <line x1="11.266" y1="12.249" x2=".5" y2="12.249" stroke="currentColor" style="fill:none; stroke-linecap:round; stroke-linejoin:round;"/>
            </svg>
          </div>
          <div :class="penActiveClass" @click="onSelectDraw">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
              <path d="m.5,3.427h3.795l9.205,3.573-9.205,3.573H.5" stroke="currentColor" style="fill:none; stroke-linecap:round; stroke-linejoin:round;"/>
              <path d="m8.06,9.111l5.44-2.111-5.44-2.111v4.223Z" stroke="currentColor" style="fill:none; stroke-linecap:round; stroke-linejoin:round;"/>
              <line x1=".5" y1="7" x2="8.06" y2="7" stroke="currentColor" style="fill:none; stroke-linecap:round; stroke-linejoin:round;"/>
            </svg>
          </div>
        </div>
        <div class="range-group">
          <div class="line-bold">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
              <path d="m1,10.208s3.768-5.652,6.123-6.443-4.862,9.086,0,5.869c4.862-3.218,5.877,0,5.877,0" stroke="currentColor" style="fill:none; stroke:#000; stroke-linecap:round; stroke-width:2px;"/>
            </svg>
          </div>
          <div class="range-container">
            <input
            class="range"
            type="range"
            :value="currentStrokeSize"
            :min="1"
            :max="20"
            id="strokeselectorslider"
            ref="strokeselectorslider"
            list="strokesizes"
            @change="setStrokeSize()"
            @input="setStrokeSize()"
            />
            <datalist id="strokesizes">
              <option v-for="(strokeSize, index) in strokeSizes" v-bind:key="index" :value="strokeSize">{{ strokeSize }}</option>
            </datalist>
          </div>
          <div class="line-thin">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
              <path d="m1,10.208s3.768-5.652,6.123-6.443-4.862,9.086,0,5.869c4.862-3.218,5.877,0,5.877,0" stroke="currentColor" style="fill:none; stroke:#000; stroke-linecap:round;"/>
            </svg>
          </div>
        </div>
        <div class="toolbar-group last">
          <div :class="undoClass" @click="undo">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" style="">
                <path d="m.501,3.455h6.007c1.657.005,2.997,1.363,2.992,3.032-.005,1.662-1.341,3.008-2.992,3.013h-2.484" stroke="currentColor" style="fill:none; stroke-linecap:round; stroke-linejoin:round;"/>
                <path d="m3.434,6.409L.5,3.454,3.434.5" stroke="currentColor" style="fill:none; stroke-linecap:round; stroke-linejoin:round;"/>
              </svg>
          </div>
          <div :class="redoClass" @click="redo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
              <path d="m5.975,9.5h-2.484c-1.65-.005-2.987-1.351-2.992-3.013-.005-1.669,1.334-3.027,2.992-3.032h6.007" stroke="currentColor" style="fill:none; stroke-linecap:round; stroke-linejoin:round;"/>
              <path d="m6.566.5l2.934,2.954-2.934,2.955" stroke="currentColor" style="fill:none; stroke-linecap:round; stroke-linejoin:round;"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="canvas-wrapper" :style="{ 'overflow': (isScrollable || isInitialising) ? 'scroll' : 'hidden' }" id="canvasWrapper" ref="canvasWrapper">
      <canvas :height="canvasHeight" :width="canvasWidth" id="drawingCanvas" ref="drawingCanvas" class="noselect"></canvas>
    </div>
  </div>
</template>

<script>
import { Swatches } from '@ckpack/vue-color'

const canvasSize = {
  width: 2000,
  height: 2000
}

export default {
  name: 'DrawPad',
  components: {
    'swatches-picker': Swatches
  },
  props: ['color', 'scribble', 'project', 'isScrollable'],
  methods: {
    getMousePos(e) {
      let ClientRect = this.canvas.getBoundingClientRect()
      let x, y = 0

      if (typeof (e.pageX) === 'undefined') {
        x = e.targetTouches[0].pageX - ClientRect.left
      } else {
        x = e.pageX - ClientRect.left
      }

      if (typeof (e.pageY) === 'undefined') {
        y = e.targetTouches[0].pageY - ClientRect.top
      } else {
        y = e.pageY - ClientRect.top
      }

      return {
        x: x,
        y: y
      }
    },
    handleResize() {
      let clientWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      let clientHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

      let tabBarHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--tab-bar-height'))
      let navigationBarHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--navigation-bar-height'))

      if (clientWidth > clientHeight) {
        this.navigatorDeviceWidth = parseInt((clientWidth - tabBarHeight) * 0.03)
        this.navigatorDeviceHeight = parseInt(clientHeight * 0.03)
      } else {
          this.navigatorDeviceWidth = parseInt(clientWidth * 0.03)
          this.navigatorDeviceHeight = parseInt((clientHeight - tabBarHeight - navigationBarHeight) * 0.03)
      }

      this.navigatorDeviceOffsetTop = parseInt(this.$refs.canvasWrapper.scrollTop * 0.03)
      this.navigatorDeviceOffsetLeft = parseInt(this.$refs.canvasWrapper.scrollLeft * 0.03)
      let canvasContent = this.ctx.getImageData(0, 0, this.canvasWidth, this.canvasHeight)
      let navigatorCanvasContent = this.navigatorCtx.getImageData(0, 0, this.canvasWidth, this.canvasHeight)

      this.$nextTick(() => {
        this.ctx.putImageData(canvasContent, 0, 0)
        this.navigatorCtx.putImageData(navigatorCanvasContent, 0, 0)
        this.centerCanvas()
      })
    },
    centerCanvas(callback) {
      const canvasWrapper = this.$refs.canvasWrapper

      this.$refs.canvasWrapper.addEventListener('scroll', () => {
        this.navigatorDeviceOffsetTop = parseInt(canvasWrapper.scrollTop * 0.03)
        this.navigatorDeviceOffsetLeft = parseInt(canvasWrapper.scrollLeft * 0.03)
      })

      this.canvasCenterX = parseInt((this.canvasWidth - this.$refs.canvasWrapper.offsetWidth) / 2)
      this.canvasCenterY = parseInt((this.canvasHeight - this.$refs.canvasWrapper.offsetHeight) / 2)

      this.$refs.canvasWrapper.scroll({
        top: this.canvasCenterY,
        left: this.canvasCenterX,
        behavior: 'instant'
      })

      if (callback) {
        callback()
      }
    },
    finishInitialising() {
      this.addTouchEventListeners()
      this.isInitialising = false
    },
    onMouseDown(e) {
      this.start(e)
    },
    onMouseMove(e) {
      this.draw(e)
    },
    onMouseUp(e) {
      this.stop(e)
    },
    onSelectDraw() {
      this.isErasing = false
      this.globalCompositeOperation = 'source-over'
    },
    onSelectErase() {
      this.isErasing = true
      this.globalCompositeOperation = 'destination-out'
    },
    start(e) {
      this.updateFromPicker()
      this.setStrokeSize()

      this.currMousePos = this.getMousePos(e)

      this.isDrawing = true
      this.currentOperation = 'hasStarted'

      this.writeHistory()
      this.paint()

      this.preventDefault(e)
    },
    draw(e) {
      this.currMousePos = this.getMousePos(e)

      this.updateFromPicker()
      this.setStrokeSize()

      if (this.isDrawing) {
        this.currentOperation = 'isExecuted'
        this.writeHistory()
        this.paint()
      }

      this.preventDefault(e)
    },
    stop(e) {
      if (this.isDrawing) {
        this.currentOperation = 'hasEnded'
        this.writeHistory()
        this.paint()

        this.isDrawing = false
      }

      if (this.canvasHasContent()) {
        this.redoHistory = []
        this.hasContent = true
      }

      this.preventDefault(e)
    },
    erase(e) {
      this.currMousePos = this.getMousePos(e)
      this.currentOperation = 'isExecuted'

      if (this.isDrawing) {
        this.writeHistory()
        this.paint()
      }

      this.preventDefault(e)
    },
    undo(e) {
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
      this.navigatorCtx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)

      let redoStep = this.history.pop()
      this.redoHistory.push(redoStep)

      if (this.history.length <= 0) {
        this.history = []
        this.historyStep = []
      }

      if (e.type !== 'mouseout') {
        this.history.forEach((strokeInfo) => {
          this.historyStep = strokeInfo
          this.paint()
        })
      }
    },
    redo(e) {
      if (e.type !== 'mouseout') {
        this.historyStep = this.redoHistory[(this.redoHistory.length - 1)]
        this.redoHistory.pop()
        this.history.push(this.historyStep)
        this.paint()
      }
    },
    writeHistory() {
      switch (this.currentOperation) {
        case 'hasStarted':
          this.strokeInfoStarted = {
            'currentOperation': this.currentOperation,
            'timestamp': Date.now(),
            'globalCompositeOperation': this.globalCompositeOperation,
            'x': this.currMousePos.x,
            'y': this.currMousePos.y
          }
          if (typeof (this.historyStep) === 'undefined') {
            this.historyStep = []
          }
          this.historyStep.push(this.strokeInfoStarted)
        break

        case 'isExecuted':
          this.strokeInfoExecuted = {
            'currentOperation': this.currentOperation,
            'timestamp': Date.now(),
            'strokeStyle': this.currentColor,
            'lineWidth': this.currentStrokeSize,
            'globalCompositeOperation': this.globalCompositeOperation,
            'x': this.currMousePos.x,
            'y': this.currMousePos.y
          }
          this.historyStep.push(this.strokeInfoExecuted)
        break

        case 'hasEnded':
          this.strokeInfoEnded = {
            'currentOperation': this.currentOperation,
            'timestamp': Date.now()
          }

          if (Object.keys(this.strokeInfoExecuted).length > 0) {
            this.historyStep.push(this.strokeInfoEnded)
            this.history.push(this.historyStep)
          }

          this.historyStep = []
          this.strokeInfoExecuted = {}
        break
      }

    },
    paint() {
      if (typeof (this.historyStep) === 'undefined' || this.historyStep === null) {
        return
      }

      this.historyStep.forEach((strokeInfo) => {
        switch (strokeInfo.currentOperation) {
          case 'hasStarted':
            this.ctx.beginPath()
            this.ctx.moveTo(strokeInfo.x, strokeInfo.y)

            this.navigatorCtx.beginPath()
            this.navigatorCtx.moveTo(strokeInfo.x, strokeInfo.y)
          break

          case 'isExecuted':
            this.ctx.globalCompositeOperation = strokeInfo.globalCompositeOperation
            this.ctx.strokeStyle = strokeInfo.strokeStyle
            this.ctx.lineWidth = strokeInfo.lineWidth
            this.ctx.lineJoin = 'round'
            this.ctx.lineCap = 'round'
            this.ctx.lineTo(strokeInfo.x, strokeInfo.y)
            this.ctx.stroke()

            this.navigatorCtx.globalCompositeOperation = strokeInfo.globalCompositeOperation
            this.navigatorCtx.strokeStyle = strokeInfo.strokeStyle
            this.navigatorCtx.lineWidth = strokeInfo.lineWidth * 0.5
            this.navigatorCtx.lineJoin = 'round'
            this.navigatorCtx.lineCap = 'round'
            this.navigatorCtx.lineTo(strokeInfo.x, strokeInfo.y)
            this.navigatorCtx.stroke()
          break

          case 'hasEnded':
            this.ctx.stroke()
            this.ctx.closePath()

            this.navigatorCtx.stroke()
            this.navigatorCtx.closePath()
          break
        }
      })
    },
    canvasHasContent() {
      return new Uint32Array(this.ctx.getImageData(0, 0, this.canvasWidth, this.canvasHeight).data.buffer).some(x => x !== 0)
    },
    setStrokeSize() {
      this.currentStrokeSize = parseInt(this.strokeSelectorSlider.value)
    },
    showPicker() {
      document.addEventListener('click', this.documentClick)
      this.displayPicker = true
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick)
      this.displayPicker = false
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker()
    },
    updateFromPicker() {
      this.currentColor = typeof this.colors.hex !== 'undefined' ? this.colors.hex : '#000000'
    },
    documentClick(e) {
      let el = this.$refs.colorpicker
      let target = e.target
      if (el === null || (el !== target && !el.contains(target))) {
        this.hidePicker()
      }
    },
    getBlob() {
      let b = this.getContentBounds(50)
      var tmpCanvas = document.createElement('canvas');
      tmpCanvas.width = b.width;
      tmpCanvas.height = b.height;
      var tmpContext = tmpCanvas.getContext('2d');
      tmpContext.drawImage(this.canvas, b.minX, b.minY, b.width, b.height, 0, 0, b.width, b.height);
      return new Promise(resolve => tmpCanvas.toBlob(resolve))
    },
    getData() {
      return this.ctx.getImageData(0, 0, this.canvasWidth, this.canvasHeight)
    },
    // return the area with actual content in it
    // see BR-517
    getContentBounds(padding = 0, quadratic = true) {
      let bounds = this.history.reduce( (bounds, historyStep) => {
        historyStep.forEach( drawingOperation => {
          if (drawingOperation.x < bounds.minX) bounds.minX = drawingOperation.x;
          if (drawingOperation.y < bounds.minY) bounds.minY = drawingOperation.y;
          if (drawingOperation.x > bounds.maxX) bounds.maxX = drawingOperation.x;
          if (drawingOperation.y > bounds.maxY) bounds.maxY = drawingOperation.y;
        })
        return bounds
      }, {
        minX: 2000,
        minY: 2000,
        maxX: 0,
        maxY: 0,
      })
      bounds.minX -= padding
      bounds.minY -= padding
      bounds.maxX += padding
      bounds.maxY += padding
      bounds.minX = Math.max(0, bounds.minX)
      bounds.minY = Math.max(0, bounds.minY)
      bounds.maxX = Math.min(canvasSize.width, bounds.maxX)
      bounds.maxY = Math.min(canvasSize.height, bounds.maxY)
      bounds.width = bounds.maxX - bounds.minX
      bounds.height = bounds.maxY - bounds.minY
      if (quadratic) {
        bounds.width = bounds.height = Math.max(bounds.width, bounds.height)
      }
      return bounds;
    },
    addTouchEventListeners() {
      this.canvas.addEventListener('touchstart', this.onMouseDown, false)
      this.canvas.addEventListener('touchmove', this.onMouseMove, false)
      this.canvas.addEventListener('touchend', this.onMouseUp, false)
      this.canvas.addEventListener('mousedown', this.onMouseDown, false)
      this.canvas.addEventListener('mousemove', this.onMouseMove, false)
      this.canvas.addEventListener('mouseup', this.onMouseUp, false)
    },
    removeTouchEventListeners() {
      this.canvas.removeEventListener('touchstart', this.onMouseDown)
      this.canvas.removeEventListener('touchmove', this.onMouseMove)
      this.canvas.removeEventListener('touchend', this.onMouseUp)
      this.canvas.removeEventListener('mousedown', this.onMouseDown)
      this.canvas.removeEventListener('mousemove', this.onMouseMove)
      this.canvas.removeEventListener('mouseup', this.onMouseUp)
    },
    releaseCanvases() {
      this.canvas.width = this.navigatorCanvas.width = 1
      this.canvas.height = this.navigatorCanvas.height = 1

      this.ctx.clearRect(0, 0, 1, 1)
      this.navigatorCtx.clearRect(0, 0, 1, 1)
    },
    preventDefault(e) {
      e.preventDefault()
    },
    selectedPosition(val) {
      return ((val - this.min) / (this.max - this.min)) * 100
    },
  },
  computed: {
    eraserActiveClass() {
      return 'eraser ' + (this.isErasing ? ' active' : 'disabled')
    },
    penActiveClass() {
      return 'pen ' + (!this.isErasing ? ' active' : 'disabled')
    },
    redoClass() {
      return 'redo ' + (this.redoHistory.length > 0 ? ' active' : 'disabled')
    },
    undoClass() {
      return 'undo ' + (this.history.length > 0 ? ' active' : 'disabled')
    }
  },
  data: function() {
    return {
      canvas: null,
      currentColor: '#000000',
      colors: {
        hex: this.currentColor,
      },
      displayPicker: false,
      strokeSizes: 20,
      currentStrokeSize: 1,
      isInitialising: true,
      hasContent: false,
      isDrawing: false,
      isErasing: false,
      globalCompositeOperation: 'source-over',
      canvasWidth: canvasSize.width,
      canvasHeight: canvasSize.height,
      canvasCenterX: 0,
      canvasCenterY: 0,
      navigatorDeviceWidth: 0,
      navigatorDeviceHeight: 0,
      navigatorDeviceOffsetLeft: 0,
      navigatorDeviceOffsetTop: 0,
      currMousePos: {},
      prevMousePos: {},
      strokeInfoStarted: {},
      strokeInfoExecuted: {},
      strokeInfoEnded: {},
      historyStep: [],
      history: [],
      redoHistory: [],
      currentOperation: null
    }
  },
  mounted() {
    this.canvas = this.$refs.drawingCanvas
    this.navigatorCanvas = this.$refs.navigatorcanvas

    this.ctx = this.canvas.getContext('2d', { willReadFrequently: true })

    this.navigatorCtx = this.navigatorCanvas.getContext('2d')
    this.navigatorCtx.scale(0.03, 0.03)
    this.navigatorCtx.translate(0.5, 0.5)

    this.strokeSelectorSlider = this.$refs.strokeselectorslider
    this.setStrokeSize(1)

    this.$store.dispatch('db/getJSONAttachment', {
      project: this.project,
      attachmentId: this.scribble.drawDataAttachmentId
    }).then( json => {
      if (json) {
        this.hasContent = !json.length ? false : true
        this.history = json

        this.history.forEach((strokeInfo) => {
          this.historyStep = strokeInfo
          this.paint()
        })
      }
    })

    window.addEventListener('resize', this.handleResize)
    // document.addEventListener('contextmenu', (ev) => {
    //   ev.preventDefault()
    //   return false
    // }, false)

    this.handleResize()

    this.$nextTick(() => {
      this.centerCanvas(this.finishInitialising)
    })
  },
  beforeUnmount() {
    this.removeTouchEventListeners()
    window.removeEventListener('resize', this.handleResize)
    // document.removeEventListener('contextmenu', this.preventDefault)
    this.releaseCanvases()
  },
  watch: {
    isScrollable: {
      handler() {
        if (!this.isScrollable) {
          this.addTouchEventListeners()
        } else {
          this.removeTouchEventListeners()
        }
      }
    }
  }
}
</script>

<style scoped>
.drawpad {
  background-repeat: repeat;
  display: flex;
  flex-direction: row;
}
.navigator-wrapper {
  z-index: 1000;
  position: fixed;
  top: 120px;
  right: 20px;
}
.navigator-canvas {
  width: 60px;
  height: 60px;
  background-color: #fafafa;
  border-radius: 7px;
  border: 1px solid #E8E8EA;
}
.navigator-screen {
  position: absolute;
  border: 1px solid red;
}
.canvas-wrapper {
  padding: 20px;
}
#drawingCanvas {
  border-radius: 7px;
  background-color: #fff;
  box-shadow: var(--default-box-shadow);
}
.toolbar-wrapper {
  z-index: 1000;
  position: fixed;
  top: calc(100vh / 2 - (252px));
}
.toolbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 7px;
  background: #fff;
  width: 69px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: var(--default-box-shadow);
}
.toolbar-group.first {
  margin-top: 30px;
}
.toolbar-group {
  margin-top: 28px;
}
.toolbar-group.last {
  margin-top: 18px;
  margin-bottom: 30px;
}
.colorwell {
  width: 30px;
  height: 30px;
  border-radius: 30px;;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-top: 28px;
}
.colorwell.active {
  border: var(--primary-color);
}
.eraser {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 35px;
  border: 1px solid #ECEEF4;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  color: #D3D7E3;
  cursor: pointer;
  margin-bottom: -1px;
  background-color: #fff;
}
.eraser.active {
  color: #1682F3;
  background-color: #F7F8FA;
}
.eraser svg {
  width: 12px;
  height: 12px;
}
.pen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border: 1px solid #ECEEF4;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  color: #D3D7E3;
  background-color: #fff;
}
.pen.active {
  color: #1682F3;
  background-color: #F7F8FA;
}
.pen svg {
  width: 12px;
  height: 12px;
}
.undo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 34px;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  color: #D3D7E3;
  cursor: pointer;
}
.undo.active {
  background-color: #F7F8FA;
  color: #66778E;
}
.undo svg {
  width: 8.33px;
  height: 8.26px;
}
.redo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 34px;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  color: #D3D7E3;
  cursor: pointer;
  margin-top: 10px;
}
.redo.active {
  background-color: #F7F8FA;
  color: #66778e;
}
.redo svg {
  width: 8.33px;
  height: 8.26px;
}
.disabled {
  cursor: pointer;
}
.range-group {
  margin-top: 28px;
  width: 24px;
}
.line-bold {
  margin-bottom: 17px;
}
.line-bold svg {
  width: 14px;
}
.line-thin {
  margin-top: 7px;
}
.line-thin svg {
  width: 14px;
}
.range-container {
  height: 134px;
}
.range {
  transform: rotate(-90deg);
  transform-origin: left;
  position: relative;
  left: 50%;
  top: 115px;
  margin: 0;
  padding: 0;
  height: 24px;
  width: 134px;
  cursor: default;
}
.range[aria-disabled] {
  pointer-events: none;
}
.range:focus {
  outline: none;
}
.range::-webkit-slider-thumb {
  border: 1px solid #EDEFF4;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background: #fff;
  cursor: pointer;
}
.range::-moz-range-thumb {
  border: 1px solid #EDEFF4;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background: #fff;
  cursor: pointer;
}
.range::-ms-thumb {
  border: 1px solid #EDEFF4;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background: #fff;
  cursor: pointer;
}
.range::-webkit-slider-runnable-track {
  height: 5px;
}
.range::-ms-track {
  height: 3px;
}
.range::-moz-range-track {
  height: 1px;
}

:deep(.vc-compact) {
  width: 450px !important;
  min-width: 42px !important;
  max-width: 450px !important;
  border-radius: 5px !important;
  margin-top: -3px;
  margin-left: -5px;
}
:deep(.vc-compact > .vc-compact-colors > .vc-compact-color-item) {
  width: 32px;
  height: 32px;
  border-radius: 5px;
}
:deep(.vc-compact > .vc-compact-colors > .vc-compact-color-item > .vc-compact-dot) {
  width: 8px;
  height: 8px;
  bottom: initial;
  top: 12px;
  right: initial;
  left: 12px;
}
:deep(.vc-swatches) {
  width: 221px !important;
  margin-left: 45px;
  border-radius: 7px !important;
}
:deep(.vc-swatches-color-group) {
  margin-right: 0 !important;
  width: 32px;
}
:deep(.vc-swatches-color-it) {
  border-radius: 0 !important;
  width: 32px !important;
  height: 32px !important;
  margin-bottom: 0 !important;
}
:deep(.vc-swatches-pick) {
  margin-left: 1px !important;
  margin-top: 3px !important;
}

@media only screen and (max-width : 1023px) {
  .toolbar-wrapper {
    left: 0;
  }
  .canvas-wrapper {
    width: 100vw;
    height: calc(100vh - var(--navigation-bar-height) - var(--tab-bar-height) - 20px);
  }
}
@media only screen and (min-width : 1024px) {
  .toolbar-wrapper {
    left: 120px;
  }
  .canvas-wrapper {
    width: 100vw - var(--navigation-bar-height);
    height: calc(100vh - var(--tab-bar-height) - 20px);
  }
}
</style>