<template>
    <div class="measurement-visualization" v-if="model && model.visibility">
      <table border="0">
        <tr v-if="model.visibility.stopLeft">
          <td></td>
          <td colspan="3" class="stop">
            {{
              "Anschlag links/rechts je " +
              ((model.stopLeft + model.stopRight) / 2) +
              ", oben " +
              model.stopTop
            }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="3" class="elementDimensions">
            {{ `Elementmass ${model.elementDimensions} (${model.element})` }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="3" class="rollerShutterDependent">
            <div
              v-if="model.visibility.rollerShutterInLintelBox"
            >
              {{
                "Rollladenkasten im Sturzkasten " +
                (model.rollerShutterDimensions || "(keiner)")
              }}
            </div>
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="3" class="rollerShutterDependent">
            <div v-if="model.visibility.coverConnectionProfile">
              {{
                "Deckelanschlussprofil (bauseitiger Sturzkasten) " +
                model.coverConnectionProfileDimensions
              }}
            </div>
          </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="3" class="margin">
            <div v-if="model.visibility.marginTop">
              {{ "Einbauluft oben " + model.marginTop }}
            </div>
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="vertical margin" :width="verticalWidth" rowspan="6">
            <div
              class="rotate"
              v-if="model.visibility.marginLeft"
            >
              {{
                "Einbauluft links " + model.marginLeft
              }}
            </div>
          </td>
          <td colspan="3" class="rollerShutterDependent">
            <div v-if="model.visibility.rollerShutter">
              {{
                "Rollladenaufsatzkasten " +
                (model.rollerShutterDimensions || "(keine)")
              }}
            </div>
          </td>
          <td class="vertical margin" :width="verticalWidth" rowspan="6">
            <div
              class="rotate"
              v-if="model.visibility.marginRight"
            >
              {{
                "Einbauluft rechts " + model.marginRight
              }}
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="rollerShutterDependent frameExtension">
            <div v-if="model.visibility.frameExtensionTop">
              {{
                "Verbreiterung oben " + (model.frameExtensionTopDimensions || "(keine)")
              }}
            </div>
          </td>
        </tr>
        <tr>
          <td class="vertical frameExtension" :width="verticalWidth">
            <div
              class="rotate"
              v-if="model.visibility.frameExtensionLeft"
            >
              {{
                "Verbreiterung links " +
                (model.frameExtensionLeftDimensions || "(keine)")
              }}
            </div>
          </td>
          <td class="frameDimensions">
            {{ "Rahmenaussenmass " + model.frameDimensions }}
          </td>
          <td class="vertical frameExtension" :width="verticalWidth">
            <div
              class="rotate"
              v-if="model.visibility.frameExtensionRight"
            >
              {{
                "Verbreiterung rechts " +
                (model.frameExtensionRightDimensions || "(keine)")
              }}
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="frameExtension">
            <div v-if="model.visibility.frameExtensionBottom">
              {{
                "Verbreiterung unten " +
                (model.frameExtensionBottomDimensions || "(keine)")
              }}
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="typeDependent">
            <div v-if="model.visibility.oddLegFrameExtension">
              {{ "Fensterbankanschlussprofil " + (model.oddLegFrameExtensionDimensions || "(keine)") }}
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="typeDependent">
            <div v-if="model.visibility.baseSingleColumnProfile">
              {{
                "Bodeneinstandsprofil " +
                (model.baseSingleColumnProfileDimensions || "(keine)")
              }}
            </div>
          </td>
        </tr>
        <tr>
          <td></td>
          <td colspan="3" class="margin">
            <div v-if="model.visibility.marginBottom">
              {{ "Einbauluft unten " + model.marginBottom }}
            </div>
          </td>
          <td></td>
        </tr>
      </table>
    </div>
</template>

<script>

export default {
  name: "MeasurementVisualization",
  props: {
    model: {
      type: Object
    }
  },
  computed: {
    verticalWidth() {
      return this.model.useExtendedMeasurement
        ? "18pt"
        : "1pt";
    },
  }
};
</script>

<style scoped>
.measurement-visualization {
  display: none;
}

div.measurement {
  text-align: left;
}

#inputArea,
#displayArea {
  display: inline-block;
  margin: 10px;
  padding: 4px;
  border: 1px solid lightgrey;
  vertical-align: top;
  width: 520px;
}

div.input,
div.output {
  /* position: relative; */
}

div.input {
  /* width: 500px; */
  display: flex;
  justify-content: space-between;
}

div.message {
  font-size: small;
  font-weight: bold;
  border: 1px solid orange;
}

.rollerShutterDependent > label {
  background: lightblue;
}

.typeDependent > label {
  background: yellow;
}

table {
  width: 100%;
}

td.typeDependent {
  background: yellow;
}

td.rollerShutterDependent {
  background: lightblue;
}

#fragen {
  margin: 4pt;
}
p {
  margin: 10pt;
}

input.input,
select.input {
  /* position: absolute; */
  /* right: 0; */
  text-align: right;
  width: 100px;
}
select.input {
  width: 250px;
}

td {
  text-align: center;
  vertical-align: middle;
  font-size: 11pt;
}

.rotate {
  white-space: nowrap;
  writing-mode: vertical-lr;
}

.margin {
  background: cyan;
}

.frameExtension {
  background: orange;
}

.frameDimensions {
  vertical-align: middle;
  border: 1px solid red;
  background: rgba(255, 0, 0, 0.25);
}

.elementDimensions {
  border-bottom: 1px dotted gray;
  font-weight: bold;
}
</style>