<template>
  <div class="configuration-step">
      <ConfigurationHeader class="header">
        <template #title>
          {{ step.title }}
        </template>
        <template #abstract>
          {{ step.description }}
        </template>
      </ConfigurationHeader>
      <template v-for="(category, index) in categories"
           v-bind:key="index"
           :step="step"
           :category="category"
           :ref="'accordion_' + index"
           @didexpand="didExpand">
        <div class="category"
             v-if="showCategory(category) && (category.component || categoryOptions(category).length)">
          <ConfiguratorSectionHeader
            :title="categoryTitle(category, configuration)"
            :completed="isCompleted(category)"
            :category="category"
            :actionTitle="category.actionTitle"
            :configuration="configuration"
            @action:click="onShowDetails(category)"
            :id="'configurator-category-' + category.key" />
          <ItemTiles ref="itemTiles"
            v-if="!category.component"
            :disabled="isDisabled(category, configuration)"
            :category="category"
            :categoryOptions="categoryOptions(category)"
            @item:addcustom="onAddCustom" />
          <keep-alive v-else>
            <component
              :disabled="isDisabled(category, configuration)"
              :is="categoryComponent(category)"
              :configuration="configuration"
              :category="category"
              :step="step"
              @item:addcustom="onAddCustom"
              @measurement:calculate="calculate"></component>
          </keep-alive>
        </div>
      </template>

      <PrevNextStepNavigation @summary:show="$emit('summary:show')"/>
  </div>
</template>

<script>

import ItemTiles from "./items/ItemTiles.vue"

import ConfiguratorSectionHeader from "@/components/configuration/ConfiguratorSectionHeader.vue"
import PrevNextStepNavigation from "@/components/configuration/PrevNextStepNavigation.vue"
import ConfigurationHeader from "@/components/configuration/ConfigurationHeader.vue"
import DimensionsSelector from "@/components/configuration/dimensions/DimensionsSelector.vue"

import OpeningsSelector from "@/components/configuration/openings/OpeningsSelector.vue"
import LayoutDimensionsSelector from "@/components/configuration/openings/dimensions/LayoutDimensionsSelector.vue"
import ConfigurationSummary from "@/components/configuration/summary/ConfigurationSummary.vue"
import ExtensionsSelector from "@/components/configuration/extensions/ExtensionsSelector.vue"
import BaseProfilesSelector from "@/components/configuration/baseProfiles/BaseProfilesSelector.vue"
import RollerShutterSelector from "@/components/configuration/rollerShutter/RollerShutterSelector.vue"
import BlindsFixtureSelector from "@/components/configuration/blinds/BlindsFixtureSelector.vue"
import FillingSelector from "@/components/configuration/filling/FillingSelector.vue"
import WindowRailsTypeSelector from "@/components/configuration/windowRails/WindowRailsTypeSelector.vue"
import WindowRailsLayoutSelector from "@/components/configuration/windowRails/WindowRailsLayoutSelector.vue"
import WindowLedgeInnerSelector from "@/components/configuration/windowLedge/WindowLedgeInnerSelector.vue"
import WindowLedgeOuterSelector from "@/components/configuration/windowLedge/WindowLedgeOuterSelector.vue"
import DoubleSidedColorSelector from "@/components/configuration/color/DoubleSidedColorSelector.vue"
import BlindsTypeSelector from "@/components/configuration/blinds/BlindsTypeSelector.vue"
import ConfigurationMixin from "@/mixins/ConfigurationMixin.vue"
import ConfigurationNotes from "@/components/configuration/ConfigurationNotes.vue"

export default {
  name: 'ConfigurationStep',
  mixins: [ConfigurationMixin],
  components: {
    ItemTiles,
    ConfiguratorSectionHeader,
    PrevNextStepNavigation,
    ConfigurationHeader
  },
  props: {
    step: {
      type: Object
    },
    configuration: {
      type: Object
    },
  },
  methods: {
    categoryOptions(category) {
      let config = this.$store.getters["configuration/configuration"]
      let opts = this.$store.getters["configurationOptions/optionsForKey"](category.key, config)
      //console.log("category", category.key, "options", opts, "color", config.color)
      return opts
    },
    showCategory(category) {
      let visible = true
      if (category.visibility != undefined) {
        visible = this.configuration.visibility[category.visibility]
      }
      if (visible && category.display != undefined) {
        visible = category.display.includes("tiles")
      }
      return visible
    },
    calculate(model) {
      this.$emit("measurement:calculate", model)
    },
    onAddCustom(item, category) {
      this.$emit('item:addcustom', item, category)
    },
    onShowDetails(category) {
      this.$emit("category:showdetailview", category)
    },
    categoryComponent(category) {
      switch (category.component) {
        case "DimensionsSelector": return DimensionsSelector
        case "OpeningsSelector": return OpeningsSelector
        case "LayoutDimensionsSelector": return LayoutDimensionsSelector
        case "ConfigurationSummary": return ConfigurationSummary
        case "ExtensionsSelector": return ExtensionsSelector
        case "BaseProfilesSelector": return BaseProfilesSelector
        case "RollerShutterSelector": return RollerShutterSelector
        case "BlindsFixtureSelector": return BlindsFixtureSelector
        case "FillingSelector": return FillingSelector
        case "WindowRailsTypeSelector": return WindowRailsTypeSelector
        case "WindowRailsLayoutSelector": return WindowRailsLayoutSelector
        case "WindowLedgeInnerSelector": return WindowLedgeInnerSelector
        case "WindowLedgeOuterSelector": return WindowLedgeOuterSelector
        case "DoubleSidedColorSelector": return DoubleSidedColorSelector
        case "BlindsTypeSelector": return BlindsTypeSelector
        case "ConfigurationNotes": return ConfigurationNotes

        default: return null
      }
    },
    isCompleted(category) {
      return this.$store.getters["configuration/configuration"][category.configurationKey] != undefined
    },
    isVisible(category) {
      switch (category.key) {
        case "layoutDimensions": return this.configuration.visibility.layoutDimensions
        default: return true
      }
    }
  },
  computed: {
    categories() {
      if(!this.step) {
        return []
      }
      return this.step.categories.filter(c => {
        return this.isVisible(c) && (this.configuration.useExtendedMeasurement || !c.useExtendedMeasurement)
      })
    },
  }
}
</script>

<style scoped>
.category {
  margin-bottom: 80px;
}
.header {
  margin-bottom: 60px;
}
</style>

