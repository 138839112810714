<template>
  <div></div>
</template>

<script>

export default {
  name: "SelectorMixin",
  props: {
    category: {
      type: Object
    },
    step: {
      type: Object
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    configuration() {
      return this.$store.getters["configuration/configuration"]
    },
  },
};
</script>
