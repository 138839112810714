<template>
    <div class="custom-renderer table-renderer layout-dimensions-renderer">
      <div v-if="Object.keys(layoutDimensions).length">
        <ul data-pdfmake="{'margin': [-12, 0, 0, 0], 'markerColor': '#ffffff'}">
          <li v-for="(value, name) in layoutDimensions" v-bind:key="name">
            {{ labelForPart(name) }}: {{ value }} mm</li>
        </ul>
      </div>
      <div v-else>
        Nicht ausgewählt
      </div>
    </div>
</template>

<script>
import LayoutDimensionsMixin from "./LayoutDimensionsMixin.vue"

export default {
  name: 'LayoutDimensionsRenderer',
  mixins: [LayoutDimensionsMixin],
  data: function() {
    return {
      model: this.configuration
    }
  },
  props: {
    configuration: {
      type: Object
    }
  },
  computed: {
    layoutDimensions() {
      return this.configuration.layoutDimensions
      // return Object.keys(this.configuration.layoutDimensions || {}).map( key => {
      //   return {
      //     key: key,
      //     value: this.configuration.layoutDimensions[key]
      //   }
      // })
    }
  }
}
</script>
