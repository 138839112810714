<template>
  <div class="custom-properties-renderer">
    <span v-if="label">{{ label }}&nbsp;</span>
    <ul v-if="customProperties(configuration[configurationKey])" data-pdfmake="{'margin': [-12, 0, 0, 0], 'markerColor': '#ffffff'}">
      <li v-if="customProperties(configuration[configurationKey]).name"><b>Name:</b> {{ customProperties(configuration[configurationKey]).name }}</li>
      <li v-if="customProperties(configuration[configurationKey]).itemNumber"><b>Artikelnummer:</b> {{ customProperties(configuration[configurationKey]).itemNumber }}</li>
      <li v-if="customProperties(configuration[configurationKey]).description"><b>Beschreibung:</b> {{ customProperties(configuration[configurationKey]).description }}</li>
    </ul>
    <span v-else>{{ title }}
      <slot name="subtitle"></slot>
    </span>
  </div>
</template>

<script>

export default {
  name: "CustomPropertiesRenderer",
  props: {
    configuration: {
      type: Object
    },
    configurationKey: {
      type: String
    },
    title: {
      type: String
    },
    label: {
      type: String
    }
  },
  methods: {
    customProperties(option) {
      return this.configuration.customProperties[option]
    },
  }
}
</script>

<style scoped>
  ul {
    list-style-type: none;
  }
</style>
