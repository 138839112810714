<template>
    <div class="custom-selector dimensions-configurator">

      <MeasurementVisualization :model="model" />
      <div class="info-box">
          Elementmaß: {{model.elementDimensions}} mm<br>
          Rahmenaussenmaß: {{model.frameDimensions}} mm
        <span v-if="model.stopLeft"><br/>Anschlag links/rechts: je {{model.stopLeft}} mm</span>
        <span v-if="model.stopTop"><br/>Anschlag oben: {{model.stopTop}} mm</span>
      </div>
      <ToggleButton elId="toggleDimensionsDetailsButton" v-model="model.useExtendedMeasurement" title="">
        <template #toggle-option-1-name>Rahmenaußenmaß</template>
        <template #toggle-option-2-name>Elementmaß</template>
      </ToggleButton>
      <div class="toggle-description" v-if="model.useExtendedMeasurement">
        Ermittlung des Elementmaßes sowie des RAM durch Eingabe des Lochmaßes, Einbauluft sowie aller Anbauteile → Optimal für exakte Aufmaßwerte
      </div>
      <div class="toggle-description" v-else>
        Ermittlung des Elementmaßes durch Eingabe des RAM sowie aller Anbauteile. → Optimal für Preiskalkulation
      </div>
      <div v-if="model.useExtendedMeasurement">
        <NumberInput v-model="model.layoutInputs.innerWidth" label="Innenbreite der Öffnung (mm)" unit="mm" description="" :placeholder="model.innerWidth + ' mm'"  class="darker" :no-error-message="configuration.errors?.frameWidth" :min="0" :max="10000"/>
        <NumberInput v-model="model.layoutInputs.outerWidth" label="Außenbreite der Öffnung (mm)" unit="mm" description="" :placeholder="model.outerWidth + ' mm (auto)'" class="darker" :no-error-message="configuration.errors?.outerWidth" :min="0" :max="10000"/>
        <NumberInput v-model="model.layoutInputs.innerHeight" label="Innenhöhe der Öffnung (mm)" unit="mm" description="" :placeholder="model.innerHeight + ' mm'" class="darker" :no-error-message="configuration.errors?.frameHeight" :min="0" :max="10000"/>
        <NumberInput v-model="model.layoutInputs.outerHeight" label="Außenhöhe der Öffnung (mm)" unit="mm" description="" :placeholder="model.outerHeight + ' mm (auto)'" class="darker" :no-error-message="configuration.errors?.outerHeight" :min="0" :max="10000"/>
        <NumberInput v-model="model.layoutInputs.handleHeight" label="Griffhöhe (mm)" unit="mm" description="" :placeholder="model.handleHeight + ' mm'"  class="darker" :no-error-message="configuration.errors?.handleHeight" :min="100" :max="1050"/>
        <NumberInput v-model="model.marginTop" label="Einbauluft oben (mm)" unit="mm" description="" placeholder="0 mm" class="darker" :min="0" :max="50"/>
        <NumberInput v-model="marginLeftRight" label="Einbauluft (mm) rechts/links je" unit="mm" description="" placeholder="0 mm" class="darker" :min="0" :max="50"/>
        <NumberInput v-model="model.marginBottom" v-if="model.visibility.marginBottom" label="Einbauluft unten (mm)" unit="mm" description="" placeholder="0 mm" class="darker" :min="0" :max="50"/>
        <NumberInput v-model="model.diagonalMeasure" label="Diagonalmaß (mm)" unit="mm" description="" placeholder="0 mm" class="darker" />
        <label class="textarea-label">Eigenes Maß</label>
        <textarea
          v-model="model.customMeasure"
          placeholder="Freitext"
          rows="5"></textarea>
      </div>
      <div v-else>
        <NumberInput v-model="model.layoutInputs.frameWidth" label="Rahmenbreite (mm)" unit="mm" description="" :placeholder="model.frameWidth + ' mm'" class="darker" :no-error-message="configuration.errors?.frameWidth" :min="0" :max="10000"/>
        <NumberInput v-model="model.layoutInputs.frameHeight" label="Rahmenhöhe (mm)" unit="mm" description="" :placeholder="model.frameHeight + ' mm'" class="darker" :no-error-message="configuration.errors?.frameHeight" :min="0" :max="10000"/>
        <NumberInput v-model="model.layoutInputs.handleHeight" label="Griffhöhe (mm)" unit="mm" description="" :placeholder="model.handleHeight + ' mm'"  class="darker" :no-error-message="configuration.errors?.handleHeight" :min="100" :max="1050"/>
        <label class="textarea-label">Eigenes Maß</label>
        <textarea
          v-model="model.customMeasure"
          placeholder="Freitext"
          rows="5"></textarea>
      </div>
    </div>
</template>

<script>

import { NumberInput } from "@profineberater/configurator-clientlib"
import ToggleButton from "@/components/buttons/ToggleButton.vue"
import MeasurementVisualization from "@/components/configuration/dimensions/MeasurementVisualization.vue"

export default {
  name: 'DimensionsSelector',
  components: {
    NumberInput,
    ToggleButton,
    MeasurementVisualization
  },
  data: function() {
    return {
      model: this.configuration
    }
  },
  props: {
    configuration: {
      type: Object
    }
  },
  computed: {
    marginLeftRight: {
      get() {
        return (
          (this.model?.marginLeft || 0) +
          (this.model?.marginRight || 0)
        ) / 2
      },
      set(value) {
        this.model.marginLeft = value
        this.model.marginRight = value
      }
    },
  },
}
</script>

<style scoped>
.dimensions-configurator {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.toggle-label {
  text-align: left;
  color: var(--label-color);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.25px;
}

.toggle-description {
  text-align: left;
  color: var(--text-color);
  font-size: 12px;
  margin-bottom: 40px;
}
:deep(.toggle-button) {
  margin-bottom: 0px;
}
:deep(.toggle-button label) {
  width: unset;
}
</style>

