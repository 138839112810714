<template>
    <AppLayout>
      <template #navigation-title>
        Kundendaten
      </template>
      <template #right-navigation-item>
        <MoreNavigationItem v-if="false" @click="onContextMenu" />
      </template>
      <template #page>
        <EditProjectForm
          v-if="project && project.customer"
          class="edit-project"
          v-model="project"
          @project:delete="$refs.deleteProjectSheet.present()" />
      </template>
      <template #modal>
        <DeleteProjectSheet ref="deleteProjectSheet" />
      </template>
    </AppLayout>
</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"
import MoreNavigationItem from "@/components/navigationBar/MoreNavigationItem.vue"
import EditProjectForm from "@/components/projects/EditProjectForm.vue"
import DeleteProjectSheet from "./DeleteProjectSheet.vue"

export default {
  name: "EditProjectPage",
  components: {
    AppLayout,
    MoreNavigationItem,
    EditProjectForm,
    DeleteProjectSheet
  },
  computed: {
    project() {
      return this.$store.getters["project/currentProject"]
    },
  },
  methods: {
    onContextMenu() {
      this.$refs.contextMenuBottomSheet.present()
    },
    onDeleteScribble() {
      this.$store.dispatch("project/deleteScribble", { project: this.project, scribble: this.scribble } )
      this.saveOnLeave = false
      this.$router.go(-1)
    },
  },
  beforeRouteLeave (/*to, from, next*/) {
    // if (this.saveOnLeave) {
    //   this.saveBlob().then( () => {
    //     next()
    //   })
    // } else {
    //   next()
    // }
  },
}
</script>

<style scoped>

</style>