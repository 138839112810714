<template>
    <div class="configuration-table">
        <div v-for="step, stepIndex in $store.state.configurationProcess.steps" v-bind:key="'step_' + stepIndex">
          <!-- <div v-if="filteredCategories(step).length" class="title">
            <h5 data-pdfmake="{'color': '#000000', 'bold': true, 'fontSize': '14', 'alignment': 'left', 'margin': [0, 10, 0, 10] }">{{ step.title }}</h5>
          </div> -->
          <table v-if="filteredCategories(step).length" data-pdfmake="{'layout': 'lightHorizontalLines', 'widths': [ '*', '*' ]}">
            <tr>
              <th colspan="2" data-pdfmake="{ 'bold': true }">{{ step.title }}</th>
            </tr>
            <tr v-for="category, cfgIndex in filteredCategories(step)" v-bind:key="'step_' + stepIndex + '_cfg_' + cfgIndex">
              <td class="left option-title" v-if="category.tableRendererTitle !== undefined">{{ category.tableRendererTitle }}</td>
              <td class="left option-title" v-else>{{ categoryTitle(category, configuration) }}</td>
              <td class="right">
                <div v-if="category.tableRendererComponent">
                  <component
                    :is="categoryComponent(category)"
                    :configuration="configuration">
                  </component>
                </div>
                <div v-else-if="category.isFreeText">
                  <CustomConfigurationRenderer :value="configuration[category.configurationKey]"/>
                </div>
                <div v-else-if="configuration[category.configurationKey]">
                  <CustomPropertiesRenderer class="config-option-value" :configuration="configuration" :configurationKey="category.configurationKey" :title="title(category)">
                    <template #subtitle>
                      <span class="config-option-value-sutitle" v-if="subtitle(configuration[category.configurationKey])">({{ subtitle(configuration[category.configurationKey]) }})</span>
                    </template>
                  </CustomPropertiesRenderer>
                </div>
                <span v-else>Keine Auswahl</span>
              </td>
            </tr>
          </table>
          <hr v-if="filteredCategories(step).length" data-pdfmake="{'color': '#ffffff', 'margin': [0, 10, 0, 10]}">
        </div>
    </div>
</template>

<script>

import MetadataMixin from "@/mixins/MetadataMixin.vue"

import CustomPropertiesRenderer from "@/components/configuration/CustomPropertiesRenderer.vue"

import DimensionsRenderer from "@/components/configuration/dimensions/DimensionsRenderer.vue"
import LayoutDimensionsRenderer from "@/components/configuration/openings/dimensions/LayoutDimensionsRenderer.vue"
import ExtensionsRenderer from "@/components/configuration/extensions/ExtensionsRenderer.vue"
import CustomConfigurationRenderer from "@/components/configuration/custom/CustomConfigurationRenderer.vue"
import BaseProfilesRenderer from "@/components/configuration/baseProfiles/BaseProfilesRenderer.vue"
import WindowRailsLayoutRenderer from "@/components/configuration/windowRails/WindowRailsLayoutRenderer.vue"
import WindowLedgeInnerRenderer from "@/components/configuration/windowLedge/WindowLedgeInnerRenderer.vue"
import WindowLedgeOuterRenderer from "@/components/configuration/windowLedge/WindowLedgeOuterRenderer.vue"
import DoubleSidedColorRenderer from "@/components/configuration/color/DoubleSidedColorRenderer.vue"
import ConfigurationMixin from "@/mixins/ConfigurationMixin.vue"
import RollerShutterRenderer from "@/components/configuration/rollerShutter/RollerShutterRenderer.vue"
import ConfigurationNotesRenderer from "@/components/configuration/ConfigurationNotesRenderer.vue"

export default {
  name: 'ConfigurationTable',
  mixins: [ConfigurationMixin, MetadataMixin],
  props: {
    configuration: {
      type: Object
    },
    showEditLinks: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CustomConfigurationRenderer,
    CustomPropertiesRenderer,
},
  methods: {
    metadata(option) {
      return this.$store.getters["configurationOptions/metadataForKey"](option)
    },
    title(category) {
      let key = this.configuration[category.configurationKey]
      if (typeof key === 'string') {
        if (category.isFreeText) {
          return key
        } else {
          return this.metadata(key)?.title || "??? " + key
        }
      } else {
        // temp solution. FIXME.
        return JSON.stringify(key, null, 2)
      }
    },
    subtitle(option) {
      if (typeof option === 'string') {
        return (this.metadata(option)?.productInfoShort || "").replaceAll(",", ", ")
      }
      return null
    },
    categoryComponent(category) {
      switch (category.tableRendererComponent) {
        case "DimensionsRenderer": return DimensionsRenderer
        case "LayoutDimensionsRenderer": return LayoutDimensionsRenderer
        case "ExtensionsRenderer": return ExtensionsRenderer
        case "CustomConfigurationRenderer": return CustomConfigurationRenderer
        case "BaseProfilesRenderer": return BaseProfilesRenderer
        case "WindowRailsLayoutRenderer": return WindowRailsLayoutRenderer
        case "WindowLedgeInnerRenderer": return WindowLedgeInnerRenderer
        case "WindowLedgeOuterRenderer": return WindowLedgeOuterRenderer
        case "DoubleSidedColorRenderer": return DoubleSidedColorRenderer
        case "RollerShutterRenderer": return RollerShutterRenderer
        case "ConfigurationNotesRenderer": return ConfigurationNotesRenderer
        default: return null
      }
    },
    filteredCategories(step) {
      return step.categories.filter( cat => {
        let visible = true
        if (cat.visibility && !this.configuration.visibility[cat.visibility]) {
          visible = false
        }
        if (cat.display && !cat.display.includes("summary")) {
          visible = false
        }
        if (!cat.tableRendererComponent && !this.configuration[cat.configurationKey]) {
          visible = false
        }
        if (cat.tableRendererComponent) {
          if (this.categoryComponent(cat)?.methods?.showInConfigurationTable) {
            visible = this.categoryComponent(cat).methods.showInConfigurationTable(this.configuration)
          }
        }
        let metadata = this.metadata(this.configuration[cat.configurationKey])
        if (metadata && metadata.isNone) {
          visible = false
        }
        if (this.isDisabled(cat, this.configuration)) {
          visible = false
        }
        if (!visible) {
          // do not display
        } else {
          return cat
        }
      })
    }
  },
  computed: {
    // config() {
    //   return this.configuration || this.$store.getters["configuration/configuration"]
    // },
    currentStep() {
      return this.$store.getters['configurationProcess/currentStep']
    },
  }
}
</script>

<style scoped>
.configuration-table {
  text-align: left;
  font-weight: 400px;
}
h5 {
  text-align: left;
  padding: 0 20px;
}
table {
  border-spacing: 0;
  width: 100%;
  padding: 10px 20px 20px 20px;
  font-size: 12px;
  line-height: 1.5;
}
td:first-child {
  width: 50%;
}
td:last-child {
  width: 50%;
}
td {
  vertical-align: top;
}
td.step-title {
  text-align: left;
  font-weight: 500;
  width: 100px;
  display: none;
}
td.option-title {
  text-align: left;
  font-weight: 400px;
}
td:last-child {
  /* text-align: right; */
  color: var(--text-color);
}
hr {
  border: 1px solid #edeff4;
  margin: 0 20px 20px 20px;
}
.title {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: baseline;
}
.title > h5 {
  font-size: 14px;
}
.title > * {
  flex-grow: 1;
}
.title a {
  text-align: right;
  font-size: 12px;
  text-decoration: none;
  margin-right: 20px;
  font-weight: 500;
}
ul {
  list-style-type: none;
}
ul.summary-abstract {
  list-style-type: none;
  font-size: 14px;
  padding: 0 40px;
  font-weight: 400;
}

.config-option-value-sutitle {
  /* white-space: nowrap; */
  white-space: break-spaces;
}
td.left {
  width: 32%;
}
</style>

