<template>
  <div class="no-content-placeholder">
    <slot></slot>
    <h3 class="title">{{ title }}</h3>
    <p>{{ text }}</p>
  </div>
</template>

<script>

export default {
  name: 'NoContentPlaceholder',
  props: {
    title: String,
    text: String
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style scoped>
.no-content-placeholder {
  text-align: center;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
h3 {
  margin: 60px 0 20px;
}
:deep(img) {
  width: 50%;
  height: auto;
}

@media only screen and (min-width : 1024px) {
  :deep(img) {
    width: 25%;
    height: auto;
  }
}
</style>