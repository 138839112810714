<template>
  <div class="custom-selector window-ledge-outer-selector">
    <SelectorTile>
      <template #figure>
        <img width="200" height="258" src="@/assets/svg/infografik-fensterbank-aussen.svg">
      </template>
      <template #form>
        <div v-if="configuration.oddLegFrameExtension" class="input-fields">
          <label>Fensterbank aussen</label>
          <DropDown :options="windowLedgeOptions.values" v-model="model.windowLedgeOuter" :placeholder="windowLedgeOptions.placeholder" :allowNoSelection="false" />
          <NumberInput :disabled="isNone" label="Fensterbank Tiefe" v-model="model.windowLedgeOuterDepth" description="" placeholder="0 mm" :min="0" :max="500" />
          <label>Farbe</label>
          <ItemTiles
            v-if="true"
            :disabled="isNone"
            :category="colorCategory"
            :categoryOptions="colorOptions"
            @item:addcustom="onAddCustom" />
          <div v-if="colorOptions.length == 0" :class="'info-box dependency-missing' + (isNone ? ' disabled' : '')">Keine Farben verfügbar</div>
          <CustomConfigurationText :disabled="isNone" v-model="model.windowLedgeOuterCustomText" placeholder="Details zur Fensterbank aussen …" />
        </div>
        <WindowLedgeInfoText v-else windowLedgeType="outer" />
      </template>
    </SelectorTile>
  </div>
</template>

<script>
import SelectorTile from "@/components/configuration/SelectorTile.vue";
import { DropDown } from "@profineberater/configurator-clientlib"
import ItemTiles from "@/components/configuration/items/ItemTiles.vue"
import WindowLedgeInfoText from "./WindowLedgeInfoText.vue";
import { NumberInput } from "@profineberater/configurator-clientlib"
import CustomConfigurationText from '@/components/configuration/custom/CustomConfigurationText.vue';

export default {
name: "WindowLedgeOuterSelector",
components: {
  SelectorTile,
  DropDown,
  ItemTiles,
  WindowLedgeInfoText,
  NumberInput,
  CustomConfigurationText
},
data: function() {
  return {
    model: this.configuration
  }
},
props: {
  configuration: {
    type: Object
  },
  category: {
    type: Object
  }
},
computed: {
  colorCategory() {
    return this.category?.children.find( c => c.key == "windowLedgeOuterColor")
  },
  isNone() {
    let metadata = this.$store.getters["configurationOptions/metadataForKey"](this.model.windowLedgeOuter)
    return metadata?.isNone
  },
  windowLedgeOptions() {
    return {
      placeholder: "Bitte wählen",
      values: [
        {
          label: "Ohne Fensterbank aussen",
          value: "ohne fensterbank außen"
        },
        {
          label: "Fensterbank aussen",
          value: "mit fensterbank außen"
        },
      ]
    }
  },
  colorOptions() {
    return this.$store.getters["configurationOptions/optionsForKey"]("windowLedgeOuterColor", this.configuration)
  },

},
methods: {
  onAddCustom(item, category) {
    this.$emit('item:addcustom', item, category)
  },
}
};
</script>

<style scoped>
.extensions-selector {
display: flex;
flex-direction: column;
}
.input-fields {
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
}
.input-fields > * {
margin-right: 10px;
width: 100%;
}
.input-fields > *:last-child {
margin-right: 0;
}

.custom-configuration-text {
  margin-bottom: 0;
}
</style>

