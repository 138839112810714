<template>
  <div class="properties">
    <TextInput
      label="Titel"
      class="properties-input"
      v-model="model.title"
    ></TextInput>
    <TextInput
      label="Positionsnummer"
      class="properties-input"
      v-model="model.positionNumber"
      :thousandsDecimalPoints="false"
    ></TextInput>
    <span class="message" v-if="validationResult && validationResult.positionNumber && !validationResult.positionNumber.validates">{{ validationResult.positionNumber.message }}</span>
  </div>
</template>

<script>

import { TextInput } from "@profineberater/configurator-clientlib"

export default {
  name: 'TitleAndPositionInput',
  components: {
    TextInput
  },
  props: {
    modelValue: {
      type: Object,
    },
    validationResult: {
      type: Object
    }
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit("update:modelValue", value)
      }
    }
  }
}
</script>

<style scoped>

.properties {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.properties-input {
  width: 90%;
}
.message {
  font-size: 14px;
  font-weight: 500;
  width: 75%;
}
</style>