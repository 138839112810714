<template>
  <div class="release-notes-page">
    <AppLayout>
      <template #page>
        <ReleaseNotesDocument />
      </template>
    </AppLayout>
  </div>
</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"
import {
  ReleaseNotesDocument
} from "@profineberater/configurator-clientlib"


export default {
  name: 'ReleaseNotesPage',
  components: {
    AppLayout,
    ReleaseNotesDocument
  },
}
</script>
<style>
.release-notes-page ul {
  margin-left: 20px;
}
.release-notes-page {
  color: var(--text-color);
}
.release-notes-page hr {
  margin: 20px 0;
}
</style>