<template>
  <div class="notes">
    <section>
      <PageSectionHeader :title="numberOfNotesTitle" />
      <div class="notes-list">
        <SimpleCard class="note-item" v-for="(note, index) in notes" v-bind:key="index" @click.stop="onSelectNote(index)">
          <CloseIcon class="delete-action" @click.stop="$emit('note:delete', note)"/>
          <div v-if="note">
            <h3 >{{ note.title }} <span v-if="note.positionNumber" class="position-number">({{ note.positionNumber }})</span></h3>
            <span class="date">{{ formatDate(note) }}</span>
            <p>{{ note.content }}</p>
          </div>
        </SimpleCard>
      </div>
    </section>
  </div>
</template>

<script>

import PageSectionHeader from "@/components/page/PageSectionHeader.vue"
import { Util, CloseIcon, SimpleCard } from "@profineberater/configurator-clientlib"

export default {
  name: 'Notes',
  components: {
    SimpleCard,
    PageSectionHeader,
    CloseIcon,
  },
  props: {
  },
  data: function() {
    return {
    }
  },
  methods: {
    onSelectNote(index) {
      this.$store.commit("project/currentNoteIndex", index)
      this.$router.push("/notes/edit")
    },
    formatDate(note) {
      return Util.formatDate(note.createdAt)
    }
  },
  computed: {
    project() {
      return this.$store.getters["project/currentProject"]
    },
    notes() {
      return this.project?.notes || []
    },
    numberOfNotesTitle() {
      let num = this.notes.length
      switch (num) {
        case 0: return "Keine Notiz"
        case 1: return "1 Notiz"
        default: return num + " Notizen"
      }
    }
  },
}
</script>

<style scoped>
.notes-list {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.position-number {
  color: var(--text-color);
}
.note-item {
  position: relative;
}
span.date {
  color: var(--text-color);
  font-size: 12px;
  margin-bottom: 10px;
  display: block;
}
h3 {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin: 0 0 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
p {
  color: var(--text-color);
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
textarea {
    padding: 20px;
    margin: 20px 0 20px 0px;
    border: 1px solid #dadada;
    border-radius: 7px;
    width: 100%;
}
:deep(.simple-card) {
  margin-bottom: 10px;
}

.delete-action {
  position: absolute;
  right: 20px;
  top: 16px;
}
</style>