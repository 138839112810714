<template>
  <div class="window-model">
    <div @click="switchCamera" id="windisplay" class="window-model-3d" data-testid="winview" :style="`opacity: ${windowModelLoaded ? 1 : 0}`">
    </div>
    <div class="window-model-buttons">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>

import * as WinViewLib from '@profineberater/winviewlib';
import { leverParameterTable } from "./configurationMapping.js"

import {
  RS_ROLLERSHUTTER_ADDONBOX,
  RS_ROLLERSHUTTER_ADDONBOXINSULATED,
  RS_ROLLERSHUTTER_FRONTMOUNTEDBOX
} from "@/aufmass.js"

let PVC_WHITE = 'pvc_weiss_3dlib' //'weiß / ral 9016'

function getLeverParameters(handle) {
  let parameters = { ...leverParameterTable['defaults'], ...(leverParameterTable[handle || ''] || {}) }
  return parameters
}

export default {
  components: {
  },
  data() {
    let lighting = (window.lighting ||= {
      "ambientColor": 15790335,
      "ambientIntensity": 1.06,
      "lights": [
        {
          "color": 15790335,
          "intensity": 3.98,
          "shadow": true,
          "direction": { "x": 215.2, "y": 52, "z": 116 },
          "customId": "light_inside"
        },
        {
          "color": 15790335,
          "intensity": 2.23,
          "shadow": true,
          "direction": { "x": 55.2, "y": 52, "z": -116 },
          "customId": "light_outside"
        }
      ]
    })

    return {
      lighting: lighting,
      windowModelLoaded: false,
      cameraPositionIndex: 0,
      cameraPositions: [
        { pos: [-3000, 0.1, 3600], showButtons: false, showMeasurements: true, isInsideVisible: true, lighting: lighting }, // front from left
        { pos: [0, 0, 2200], showButtons: false, showMeasurements: true, isInsideVisible: true, lighting: lighting }, // front
        { pos: [-3000, 0.0, -2600], showButtons: false, showMeasurements: true, isOutsideVisible: true, lighting: lighting }, // back from right
        { pos: [0, 0, -2200], showButtons: false, showMeasurements: true, isOutsideVisible: true, lighting: lighting }, // back
        { pos: [-3000, 0.0, 10], showButtons: false, showMeasurements: true }, // left side
      ],
    }
  },
  computed: {
    fullWindow() {
      // not used anymore, kept it because it is a good way to find/identify measurement parameters
      let scaleFactor = 1

      let configuration = this.$store.getters["configuration/configuration"]
      let options = this.$store.getters["configurationOptions/options"]

      let layout = configuration.layout
      let opening = configuration.opening
      let layoutTable = options.tables[opening || layout]

      if (window.logConfig) {
        if (window.logConfig === true) {
          console.log("WINVIEW config", JSON.stringify(configuration, null, "  "))
        } else {
          for (let key of window.logConfig) {
            console.log("WINVIEW", "config", key, configuration[key])
          }
        }
      }

      // bail out early if we can't render in a useful way
      if (!layout || !opening || !layoutTable) {
        console.log("WINVIEW not enough information to render")
        return undefined
      }

      let rows = layoutTable.rows
      let cols = layoutTable.cols

      let parts = layoutTable.geometry?.split('/') || []
      let colWidths = []
      let rowHeights = []
      for (let part of parts) {
        let value = configuration.layoutDimensions?.[part]
        window.logMeasurement && console.log("WINVIEW value for", part, value)
        if (value) {
          (part[0] === 'H' ? rowHeights : colWidths).push(value / scaleFactor)
        }
      }

      // bail out if geometry calculation failed (not expected to happen)
      if (colWidths.length !== cols || rowHeights.length !== rows) {
        console.warn("WINVIEW", `#colWidths (${colWidths.length}) != cols (${cols}) or #rowHeights (${rowHeights.length}) != rows (${rows}) - faking parts (${parts})`)
        return undefined
      }

      let material = configuration.material || 'pvc'

      let frameColor = this.resolveColor(configuration.color || { // fake some material colors
        'holz': 'nussbaum (auf kiefer)',
        'holz/alu': 'nussbaum (auf kiefer)',
        'alu': 'schwarzgrau / ral 7021',
        'pvc': PVC_WHITE,
        'alu/pvc': PVC_WHITE,
      }[material])

      let frameMaterial = {
        'holz': 'Oak', // overridden in case of holzfarben
        'holz/alu': 'Nussbaum', // ""
        'alu': 'Aluminium',
        'pvc': 'WhitePlastic', // overridden in case of folienfarben
        'alu/pvc': 'WhitePlastic',
      }[material]

      let shellColor = this.resolveColor(configuration.shellColor || {
        'holz/alu': 'schiefergrau / ral 7015',
        'alu/pvc': 'anthrazitgrau'
      }[material] || frameColor)

      let shellMaterial = {
        'holz/alu': 'Aluminium',
        'alu/pvc': 'Aluminium',
      }[material] || frameMaterial

      if (material.indexOf('holz') !== -1 || material.indexOf('pvc') !== -1) {
        let materialName = this.frameMaterialNameFromMetadata(configuration.color)
        if (materialName) {
          frameMaterial = materialName
          frameColor = '#eeeeee'
        }
      }

      if (!material.includes('/')) { // materials without shell
        shellMaterial = frameMaterial
        shellColor = frameColor
      }

      if (configuration.visibility.isDoubleSidedColor) {
        shellColor = frameColor
        shellMaterial = frameMaterial

        if (!configuration.isDoubleSidedColor) {
          frameColor = this.resolveColor(PVC_WHITE)
          frameMaterial = 'WhitePlastic'
        }
      }

      let sealColor = this.resolveColor(configuration.sealColor, '#141414')

      let windowLedgeInnerMetadata = this.$store.getters["configurationOptions/metadataForKey"](configuration.windowLedgeInner)
      let windowLedgeOuterMetadata = this.$store.getters["configurationOptions/metadataForKey"](configuration.windowLedgeOuter)

      let hasInnerLedge = !(windowLedgeInnerMetadata?.isNone) || false
      let hasOuterLedge = !(windowLedgeOuterMetadata?.isNone) || false

      let innerLedgeMaterial = this.frameMaterialNameFromMetadata(configuration.windowLedgeInnerColor, "Aluminium")
      let outerLedgeMaterial = this.frameMaterialNameFromMetadata(configuration.windowLedgeOuterColor, "Aluminium")

      let depth = 70
      let thicknessInner = depth
      let thicknessOuter = depth

      let glassDepth = 5
      let glassType = 'clear'

      let [defConstructor, defSetter, needsCells, usesDoorCell, insideBoxDepth, extensionDepth] = {
        'haustür': ['FrontDoorDef', 'setFrontDoorDefinition', true, true, 0, depth],
        'hebeschiebetür': ['SlidingDoorDef', 'setSlidingDoorDefinition', false, false, 250, 2*depth],
      }[configuration.element] || ['WinDef', 'setWindowDefinition', true, false, 200, depth]

      let rollerShutterBoxType

      switch (configuration.rollerShutter) {
        case RS_ROLLERSHUTTER_ADDONBOX:
        case RS_ROLLERSHUTTER_ADDONBOXINSULATED:
          rollerShutterBoxType = "inside"
          break;
        case RS_ROLLERSHUTTER_FRONTMOUNTEDBOX:
          rollerShutterBoxType = "outside"
          break;
        default:
          rollerShutterBoxType = "none"
      }

      let rollerShutterInsulationThickness = (configuration.rollerShutter === RS_ROLLERSHUTTER_ADDONBOXINSULATED ? 20 : 0)
      // subtract insulationThickness because winViewLib puts it on top and we don't calculate like that
      let rollerShutterHeight = (configuration.visibility.rollerShutterHeight * ((configuration.rollerShutterHeight || 0) - rollerShutterInsulationThickness)) / scaleFactor

      let rollerShutterBoxColor = this.resolveColor(configuration.blindsCaseColor)
      let slatsColor = this.resolveColor(configuration.blindsFinColor, PVC_WHITE)

      // floorProfile doubles as baseSingleColumnProfile (FrontDoor, SlidingDoor) and oddLegFrameExtension (Window[Door])
      // not yet supported for WindowDef
      let floorProfileHeight = (configuration.visibility.baseSingleColumnProfile ? configuration.baseSingleColumnProfile : 0)
        || (configuration.visibility.oddLegFrameExtension ? configuration.oddLegFrameExtension : 0)

      let leverParameters = getLeverParameters(configuration.handle)

      let createGlazingBarsDef = (visible) => {
        let windowRailsType = configuration.windowRailsType
        let windowRailsLayoutType = configuration.windowRailsLayoutType
        let windowRailsTopPadding = configuration.windowRailsTopPadding
        let showsGlazingBars = (visible && windowRailsType && windowRailsType !== 'keine sprossen' && windowRailsLayoutType)

        let [barColumns, barRows, barUsesTopPadding, barCells] = {
          'mittelpfosten': [2, 1, false],
          'kreuzförmig': [2, 2, false],
          '6-felder': [2, 3, false],
          '8-felder': [2, 4, false],
          '10-felder': [2, 5, false],
          'variable anzahl': [parseInt(configuration.windowRailsHorizontalCount || 0) + 1, parseInt(configuration.windowRailsVerticalCount || 0) + 1, false],
          't-förmig': [2, 2, true, [[2, 1], [1, 1], [1, 1]]],
          '2-felder oben': [2, 2, true, [[1, 1], [1, 1], [2, 1]]],
          '3-felder oben': [3, 2, true, [[1, 1], [1, 1], [1, 1], [3, 1]]],
          'riegel': [1, 2, true],
        }[showsGlazingBars ? windowRailsLayoutType : ''] || [1, 1, false]

        let barRowHeights = undefined
        let mainHeight = configuration.layoutDimensions?.H

        if (barUsesTopPadding && windowRailsTopPadding && mainHeight) {
          let topPaddingPercent = windowRailsTopPadding / (mainHeight / 100)
          barRowHeights = [topPaddingPercent, 100 - topPaddingPercent] // % not mm
        }

        // WinViewLib does not like setting glazingBars to undefined, always needs an object
        let barsDef = new WinViewLib.GlazingBarsDef()

        // avoid configuring bars when topPadding is not defined / calculable
        if (!barUsesTopPadding || barRowHeights) {
          Object.assign(barsDef, {
            colWidths: Array(barColumns).fill(100 / barColumns), // % not mm
            rowHeights: barRowHeights || Array(barRows).fill(100 / barRows), // % not mm
            cells: (barCells || Array(barColumns * barRows).fill([1, 1])).map(c => new WinViewLib.GlazingBarsCellDef(...c)),
          })
        }
        return barsDef
      }

      let createCellFromLayoutTableEntry = (entry) => {

        let part = entry.part || ''
        let opening = entry.opening || 'F'
        let fix = opening.endsWith('F')
        //let left = opening.endsWith('L')
        let right = opening.endsWith('R')
        //let kipp = opening.endsWith('K')
        let isSkyLight = (part.indexOf('O') !== -1)
        let isSubLight = (part.indexOf('U') !== -1)

        let colspan = entry.colspan || 1
        let rowspan = entry.rowspan || 1

        let handleHeight = (isSkyLight || isSubLight) ? 0 : configuration.handleHeight

        let createDoorCell = () => {
          let isDoorPart = !(fix || isSkyLight || isSubLight)

          let createWindowPartProperties = () => {
            return Object.assign(new WinViewLib.WindowProperties(), {
              glassType: glassType,
              glassDepth: glassDepth,
              openable: (isSkyLight || isSubLight) && !fix,
              leverPos: 'top',
              leverOrientation: 'horiz',
              leverType: leverParameters.leverType,
              leverMaterial: leverParameters.leverMaterial,
              handleHeight: 0, // defaulting because only sky/sublights get handles on Window parts of doors
              glazingBars: createGlazingBarsDef(!isSkyLight && !isSubLight),
              ...(window.windowProperties || {})
            })
          }

          let createDoorPartProperties = () => {

            let leverMaterial = leverParameters.leverMaterial
            let leverColor = { 'WhiteMetal': '#eeeeee', 'Marble1': '#303030' }[leverMaterial] || '#bbbbbb'

            return Object.assign(new WinViewLib.DoorProperties(), {
              leverNr: leverParameters.leverNr,
              outerLeverMaterial: leverMaterial, // ok - aber beinflusst Material der Fenstergriffe
              outerLeverColor: leverColor, // ok
              handleHeight: handleHeight,
              pzTuelleMaterial: leverMaterial, // ok
              pzTuelleColor: leverColor, // ok
              pzTuelleSecure: leverParameters.pzTuelleSecure,
              drueckergarniturMaterial: leverMaterial, // ok
              drueckergarniturColor: leverColor, // ok
              lockColor: leverColor, // ok
              doorFrameType: (configuration.cutPattern === 'flächenversetzt_aussen' ? 'inset' : 'flush'),
              fillingNr: 1, //(configuration.doorFilling === 'modern' ? 3 : (configuration.doorFilling === 'exklusiv' ? 2 : 1)),

              // panelMaterial: frameMaterial, // doesn't work when panelColor is not set, other material parameters do, probably dark default color
              // panelColor: frameColor,
              // filling1GlassFrameMaterial: frameMaterial,
              // filling1GlassFrameColor: frameMaterial,
              // filling2StripeMaterial: 'Aluminium',
              // filling2StripeColor: 0x999999,
              // filling3GlassMaterial: 'Glass',

              leverPos: right ? 'left' : 'right',
              //wallSideMaterial: "WhitePlastic", // keine Wirkung, weil immer geschlossen?
              //wallSideColor: 0xff0000,
              ...(window.doorProperties || {})
            })
          }

          return new WinViewLib.DoorCell(
            colspan,
            rowspan,
            isDoorPart ? "door" : "window",
            isDoorPart ? createDoorPartProperties() : createWindowPartProperties()
          )
        }

        let createWinCell = () => {
          return new WinViewLib.WinCell(colspan, rowspan, Object.assign(new WinViewLib.WinCellProperties(), {
            glassType: glassType,
            glassDepth: glassDepth,
            openable: fix ? false : true,
            leverPos: right ? 'left' : (isSubLight || isSkyLight ? 'top' : 'right'),
            leverOrientation: isSubLight || isSkyLight ? 'horiz' : 'vert',
            leverType: leverParameters.leverType,
            leverMaterial: leverParameters.leverMaterial,
            handleHeight: handleHeight,
            glazingBars: createGlazingBarsDef(!isSkyLight && !isSubLight),
            ...(window.winCellProperties || {})
          }))
        }

        return usesDoorCell ? createDoorCell() : createWinCell()
      }

      let cells = needsCells ? layoutTable.cells.map(createCellFromLayoutTableEntry) : []

      // Ein String der die Aufteilung einer Hebeschiebetür definiert.
      // | "L": Schiebetürelement das nach links öffnet, Griff rechts
      // | "R": Schiebetürelement das nach rechts öffnet, Griff links
      // | "S": statisches Fensterelement
      // | Wird das Element als Großbuchstabe angegeben, so wird das Element auf der innen
      // | liegenden Schiene positioniert. Als Kleinbuchstabe auf der äußeren.
      let winViewSchema = needsCells ? undefined : (layoutTable.winViewSchema || "SsSs".substring(0, colWidths.length))

      // SlidingDoorDef does not have cells, passes some parameters at top-level
      let slidingDoorParameters = (configuration.element === 'hebeschiebetür') ? {
        thickness: 70 / scaleFactor,
        handleHeight: configuration.handleHeight / scaleFactor,
        leverType: leverParameters.leverType,
        leverMaterial: leverParameters.leverMaterial,
        //outerFrameThickness:  70 / scaleFactor,
        schema: winViewSchema,
        glazingBars: createGlazingBarsDef(true),
      } : {}

      let topExtensions = []
      if (configuration.visibility.frameExtensionTop && configuration.frameExtensionTop) {
        topExtensions.push(configuration.frameExtensionTop / scaleFactor)
      }
      if (configuration.visibility.coverConnectionProfile && configuration.coverConnectionProfile) {
        topExtensions.push(configuration.coverConnectionProfile / scaleFactor)
      }

      let spec = {
        // for later usage in updateView()
        _defConstructor: defConstructor,
        _defSetter: defSetter,

        rowHeights: rowHeights,
        colWidths: colWidths,

        depth: depth / scaleFactor,
        thicknessInner: thicknessInner / scaleFactor, // WindowDef, FrontDoorDef
        thicknessOuter: thicknessOuter / scaleFactor, // WindowDef, FrontDoorDef

        sealColor: sealColor,

        frameColor: frameColor,
        frameMaterial: frameMaterial,
        shellColor: shellColor,
        shellMaterial: shellMaterial,

        wallSideColor: frameColor,
        wallSideMaterial: frameMaterial,

        // let them default to element specific values in WinViewLib
        //leverWidth: 30 / scaleFactor,
        //leverHeight: 140 / scaleFactor,
        //leverDepth: 50 / scaleFactor,

        cells: cells,

        // Extensions
        topExtensions: topExtensions,
        rightExtensions: configuration.visibility.frameExtensionRight && configuration.frameExtensionRight ? [configuration.frameExtensionRight / scaleFactor] : [],
        bottomExtensions: configuration.visibility.frameExtensionBottom && configuration.frameExtensionBottom ? [configuration.frameExtensionBottom / scaleFactor] : [],
        leftExtensions: configuration.visibility.frameExtensionLeft && configuration.frameExtensionLeft ? [configuration.frameExtensionLeft / scaleFactor] : [],
        extensionColor: this.resolveColor(PVC_WHITE),
        extensionDepth: extensionDepth / scaleFactor,

        // extensionShellMaterial: frameMaterial,
        // extensionFrameMaterial: frameMaterial,
        // extensionWallMaterial: frameMaterial,

        // extensionWallColor: this.resolveColor(PVC_WHITE), //frameColor,
        // extensionShellColor: this.resolveColor(PVC_WHITE), //frameColor,
        // extensionFrameColor: this.resolveColor(PVC_WHITE), //frameColor,

        // Ledge
        hasLedge: hasOuterLedge,
        //ledgeY: 10 / scaleFactor,
        //ledgeDepth: 200 / scaleFactor,
        //ledgeHeight: 100 / scaleFactor,
        //ledgeExtensionSize: 600 / scaleFactor,
        ledgeDepth: (configuration.windowLedgeOuterDepth || 200) / scaleFactor,

        hasInnerLedge: hasInnerLedge,
        //innerLedgeHeight: 100 / scaleFactor,
        innerLedgeDepth: (configuration.windowLedgeInnerDepth || 200) / scaleFactor,
        innerLedgeMaterial: innerLedgeMaterial,
        ledgeMaterial: outerLedgeMaterial,

        //plissee: true,

        // Raffstore
        raffstore: configuration.blindsType === "raffstore" ? rollerShutterBoxType : 'none',
        raffstoreInsulated: configuration.rollerShutter === RS_ROLLERSHUTTER_ADDONBOXINSULATED,
        raffstoreInsulationThickness: rollerShutterInsulationThickness / scaleFactor,
        raffstoreBoxHeight: rollerShutterHeight,
        raffstoreDepth: 80 / scaleFactor,
        raffstoreBoxDepth: (rollerShutterBoxType === 'inside' ? insideBoxDepth : 80) / scaleFactor,
        raffstoreBoxColor: rollerShutterBoxColor, // Farbe des Kastens des Raffstore
        raffstoreRailColor: rollerShutterBoxColor, // Farbe der Laufschienen des Raffstore
        raffstoreSlatColor: slatsColor, // Farbe der Lamellen des Raffstore
        raffstoreCordColor: 0xffffff, // Farbe der Kordel die an den Raffstorelamellen befestigt ist
        raffstoreStringColor: 0xffffff, // Farbe des inneren Führungsmetalls innerhalb der Lamellen befestigt ist
        raffstoreEndColor: slatsColor, // Farbe der Leiste die am unteren Ende der Raffstore befestigt ist

        // RollerShutter
        rollerShutter: configuration.blindsType === "rollladen" ? rollerShutterBoxType : 'none',
        rollerShutterInsulated: configuration.rollerShutter === RS_ROLLERSHUTTER_ADDONBOXINSULATED,
        rollerShutterInsulationThickness: rollerShutterInsulationThickness / scaleFactor,
        rollerShutterHeight: rollerShutterHeight,
        rollerShutterBoxColor: rollerShutterBoxColor,
        rollerShutterRailColor: rollerShutterBoxColor,
        slatsColor: slatsColor,
        lastSlatColor: slatsColor,

        floorProfile: floorProfileHeight ? true : false,
        floorProfileHeight: floorProfileHeight,
        floorProfileThickness: 67 / scaleFactor,
        floorProfileColor: this.resolveColor(PVC_WHITE),

        // Setup Measurements
        showMeasurements: true, // false produces errors in 1.1.10
        measureDistance: 150 / scaleFactor,
        measureEndWidth: 10 / scaleFactor,
        measureLineColor: 0x979797,
        measureLineElementSizeColor: 0x1682f3,

        // Setup Buttons to show
        showButtons: false,
        buttons: [],

        //overrideTotalWidth: configuration.elementWidth,
        // enabled to have correct measurement in spite of wrong line rendering when using "Vorbaukasten"
        overrideTotalHeight: configuration.elementHeight,

        // extra parameters (not for WinView) to convey measurements (for total width/height workaround in 1.1.5)
        // still used for debugging (search for patchMeasurement)
        frameWidth: configuration.frameWidth,
        frameHeight: configuration.frameHeight,
        elementWidth: configuration.elementWidth,
        elementHeight: configuration.elementHeight,

        ...slidingDoorParameters,
      }
      return spec
    },
  },
  watch: {
    fullWindow() {
      if (!this.windowModelLoaded) {
        window.logWindowDef && console.log("WINVIEW model not loaded yet")
        return
      }

      window.logWindowDef && console.log("WINVIEW fullWindow change")
      this.needsUpdate = true
      this.$nextTick(() => {
        if (!this.needsUpdate) {
          window.logWindowDef && console.log("WINVIEW tick does not need update")
          return
        }
        this.needsUpdate = false
        this.updateView(this.fullWindow)
      });
    }
  },
  async mounted() {
    this.initWindowModel()
  },

  methods: {
    updateView(windowSpec) {
      if (!windowSpec) {
        console.log("WINVIEW no windowSpec, not rendering")
        return
      }

      if (window.windowSpec) { // allows for testing attributes from browser console
        Object.assign(windowSpec, window.windowSpec)
      }

      window.logWindowDef && console.log("WINVIEW windowSpec", JSON.stringify(windowSpec, null, "  "))

      let windowDef = Object.assign(new WinViewLib[windowSpec._defConstructor](), windowSpec)

      window.logWindowDef && console.log("WINVIEW windowDef", JSON.stringify(windowDef, null, "  "))

      this.winDisplay[windowDef._defSetter](windowDef)

      if (window.patchMeasurement) {
        let measurements = document.getElementsByClassName("winlib-overlay-text-content")
        if (measurements.length >= 2) {
          let winViewHeight = parseFloat(measurements[0].innerHTML)
          let winViewWidth = parseFloat(measurements[1].innerHTML)
          measurements[0].innerHTML = windowSpec.elementHeight + " mm" + (windowSpec.frameHeight == winViewHeight ? "" : "*")
          measurements[1].innerHTML = windowSpec.elementWidth + " mm" + (windowSpec.frameWidth == winViewWidth ? "" : "*")
        }
      }

      if (window.log3D) {
        document.getElementsByTagName("canvas")[0].toBlob(blob => {
          let img = document.createElement('img')
          let url = URL.createObjectURL(blob)
          img.onload = function () { URL.revokeObjectURL(url) }
          img.src = url
          document.body.appendChild(img);
        })
      }
    },

    buttonFrameCallback() {
      alert('Frame for config selected')
    },
    buttonGlassCallback() {
      alert('Glass for config selected')
    },
    buttonLeverCallback() {
      alert('Lever for config selected')
    },
    switchCamera() {
      if (window.rotateScene) {
        this.sceneRotation ||= 0
        this.winDisplay._renderer.getScene().rotation.set(0, 2 * Math.PI / 36 * ++this.sceneRotation, 0)
        this.winDisplay._renderer.render()
      } else {
        this.cameraPositionIndex = (this.cameraPositionIndex + 1) % this.cameraPositions.length
        this.winDisplay.setCameraPosition(this.cameraPositionIndex, true, 1.5)
      }
      //console.log("scene", JSON.stringify(this.winDisplay._renderer.getScene()), null, "  ")
    },
    switchCameraToShowOutside() {
      this.$nextTick(() => {
        if (!this.cameraPositions[this.cameraPositionIndex].isOutsideVisible) {
          this.cameraPositionIndex = 2
          this.winDisplay.setCameraPosition(this.cameraPositionIndex, true, 1.5)
        }
      })
    },
    switchCameraToShowInside() {
      this.$nextTick(() => {
        if (!this.cameraPositions[this.cameraPositionIndex].isInsideVisible) {
          this.cameraPositionIndex = 0
          this.winDisplay.setCameraPosition(this.cameraPositionIndex, true, 1.5)
        }
      })
    },
    initWindowModel() {
      //const queryString = window.location.search;
      //const urlParams = new URLSearchParams(queryString);
      //const debug = urlParams.get('debug') === 'true'

      this.winDisplay = new WinViewLib.WindowDisplay('windisplay', {
        enableOrbitControls: window.enableOrbitControls ? true : false,
        orbitLighting: this.lighting,
        orbitShowMeasurements: false,
        //orbitShowButtons: false,

        cameraPositions: this.cameraPositions,

        autoFitCamera: true,
        decelerateCameraTime: window.decelerateCameraTime || 10000, // not respected?

        quality: 3,
        // xxxeslint-disable-next-line prettier/prettier
        backgroundColor: "#F7F8FA",
        ambientLight: 1 * 0x404040,
        debug: false,
        baseUrl: window.location.origin + '/'
      })

      this.winDisplay.init(() => {
        this.windowModelLoaded = true,
          // Initially define a Window to be shown
          this.updateView(this.fullWindow)

        // Make sure we correct the aspect and render resolution when the size changes
        window.addEventListener('resize', () => {
          this.winDisplay.handleResize()
        })
      })
      window.$winDisplay = this.winDisplay
    },
    pngSnapshot() {
      return this.winDisplay.renderImage(512, 512, "uint8array")
    },
    resolveColor(color, fallback) {
      let colorFromMetadata = (colorNameOrHex) => {
        if (!colorNameOrHex) {
          return undefined
        }
        return colorNameOrHex.startsWith('#') ? colorNameOrHex : this.$store.getters["configurationOptions/metadataForKey"](colorNameOrHex)?.hex
      }
      return colorFromMetadata(color || fallback)
        || colorFromMetadata(fallback || PVC_WHITE)
        || colorFromMetadata(PVC_WHITE)
        || '#ff0000' // to notice errors
    },
    frameMaterialNameFromMetadata(color, defaultMaterialName = 'WhitePlastic') {
      if (color?.startsWith('custom')) return defaultMaterialName
      let colorMetadata = this.$store.getters["configurationOptions/metadataForKey"](color)
      return colorMetadata?.materialName || defaultMaterialName
    },
  },
}
</script>

<style scoped>
.window-model {
  position: relative;
  width: 100%;
  height: 50vh;
}

.window-model-buttons {
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

.window-model-3d {
  transition: opacity 150ms;
  height: 45vh;
}

#windisplay {
  position: relative;
}

.rotate-button svg {
  margin-right: 10px;
}

.rotate-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
}

canvas {
  background-color: aqua;
}

@media only screen and (min-width : 1024px) {
  .window-model-3d {
    height: 75vh;
  }
}
</style>