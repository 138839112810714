<template>
  <div class="dpa-page">
    <AppLayout>
      <template #page>
        <DpaDocument />
      </template>
    </AppLayout>
  </div>
</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"
import {
  DpaDocument
} from "@profineberater/configurator-clientlib"

export default {
  name: 'DpaPage',
  components: {
    AppLayout,
    DpaDocument
  }
}
</script>
