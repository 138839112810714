<template>
    <ProjectsPage :isArchive="true" />
</template>

<script>
import ProjectsPage from './ProjectsPage.vue';

export default {
  name: "ArchivePage",
  components: {
    ProjectsPage,
  },
}
</script>
