<template>
    <div :data-testid="category.key" :class="'item-tiles' + (disabled ? ' disabled' : '')">
      <div class="grid" :data-selected-item="selectedItemId">
        <ItemTile
          v-for="option, index in limitedOptions"
          v-bind:key="index"
          :model="option.key || '!!EMPTY KEY!!'"
          :selected="option.key == selectedItemId"
          @click="selectItem(option)"
          :isCustom="option.isCustom"
          :isNotAvailable="option.isDisabled && option.key == selectedItemId"
          :isLongTile="isLongTile"
          >
        </ItemTile>
      </div>
      <div v-show="showUnlimitedOptionsButton">
        <p class="info" v-html="limitedResultInfoText"></p>
        <button type="primary" class="limit-options button rounded primary" @click="limitOptions = !limitOptions">
          <span v-if="limitOptions">Alle Optionen anzeigen</span>
          <span v-else>Weniger Optionen anzeigen</span>
        </button>
      </div>
    </div>
</template>

<script>
import ItemTile from "./ItemTile.vue"

const optionsLimit = 8

export default {
  name: 'ItemTiles',
  components: {
      ItemTile,
  },
  props: {
    category: Object,
    categoryOptions: Array,
    disabled: Boolean,
  },
  data: function() {
    return {
      limitOptions: true
    }
  },
  methods: {
    metadata(item) {
      return this.$store.getters["configurationOptions/metadataForKey"](item)
    },
    selectItem(item) {
      if (this.disabled || item.isNotAvailable) return

      if (item.isCustom) {
        this.$emit("item:addcustom", item.key, this.category)
      } else {
        if(this.category.configurationKey === 'layout') {
          this.$store.commit("configuration/opening", undefined)
        }
        this.$store.commit("configuration/" + this.category.configurationKey, item.key)
      }
    },
    checkForSelectedOptionsOutOfRange(opts, selectedItemId) {
      let selectedOptionExeedsLimitedList = opts.findIndex( o => o.key == selectedItemId) > optionsLimit
      //console.log("check", opts, selectedOptionExeedsLimitedList, selectedItemId, opts.findIndex( o => o.key == selectedItemId))
      this.limitOptions = !selectedOptionExeedsLimitedList
    }
  },
  computed: {
    limitCount() {
      return optionsLimit
    },
    limitedOptions() {
      let opts = JSON.parse(JSON.stringify(this.categoryOptions))
      if (opts.length == 0) {
        return []
      }

      let customEntryFromTable = (opts.filter(o => o.key.startsWith("custom")))[0] 

      opts = opts.filter( o => o !== customEntryFromTable && (!o.isDisabled || o.key == this.selectedItemId))
      if (this.limitOptions) {
        opts = opts.slice().splice(0, optionsLimit)
      }
      // Add custom option if defined in configurationProcess.module
      if(customEntryFromTable) {
        opts.push({...customEntryFromTable, isCustom: true})
        this.category.custom && console.warn(`redundant 'custom' entry for '${this.category.key}' in configurationProcess`)
      } else {
        if (this.category.custom) {
          opts.push({
            key: this.category.custom.id,
            isCustom: true
          })
        }
      }
      return opts
    },
    showUnlimitedOptionsButton() {
      return this.categoryOptions.filter( o => !o.isDisabled ).length > optionsLimit
    },
    limitedResultInfoText() {
      return this.limitOptions ? `${this.limitCount} von ${this.categoryOptions.filter( o => !o.isDisabled ).length} Optionen` : '&nbsp;'
    },
    itemIds() {
      return this.categoryOptions
    },
    selectedItemId() {
      return this.$store.getters["configuration/configuration"][this.category.configurationKey]
    },
    isLongTile() {
      for (var i=0; i<this.limitedOptions.length; i++) {
        let customProps = this.$store.getters["configuration/customPropertiesForKey"](this.limitedOptions[i])
        let productInfoShort = this.metadata(this.limitedOptions[i].key)?.productInfoShort
        if (productInfoShort?.includes(",")) {
          return true
        }
        if (customProps?.description || customProps?.itemNumber) {
          return true
        }
      }
      return false
    },
  },
  mounted() {
    this.checkForSelectedOptionsOutOfRange(this.categoryOptions, this.selectedItemId)

  },
  watch: {
    selectedItemId(newId) {
      this.checkForSelectedOptionsOutOfRange(this.categoryOptions, newId)
    },
    categoryOptions(newOptions) {
      this.checkForSelectedOptionsOutOfRange(newOptions, this.selectedItemId)
    }
  },
}
</script>

<style scoped>
.item-tiles.disabled {
  opacity: 0.35;
}
:deep(.item-tiles.disabled .item-tile) {
  cursor: not-allowed;
}
.item-tiles .grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 164px);
  grid-gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
}

.header {
  grid-column: 1 / 3; /* span from grid column line 1 to 3 (i.e., span 2 columns) */
}
p.info {
  margin: 40px 0 20px;
  font-size: 14px;
}
button.limit-options {
  margin-bottom: 40px;
  background: none;
  background-color: #edeff4;
  color: #181d29;
}
button.limit-options:not(:disabled):hover {
  background-color: #e9ebf2;
}

/* darker, smaller, without drop shadow when inside a custom selector */
.selector-tile .item-tiles .grid {
  grid-template-columns: repeat(auto-fill, 144px);

}
</style>

