<template>
  <div class="add-item-card">
    <SimpleCard class="card">
      <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g transform="translate(-97 -279)">
          <circle cx="15" cy="15" r="15" transform="translate(97 279)" fill="#fff"/>
          <g transform="translate(-278.59 229.521)">
            <g transform="translate(385.459 64.479)">
              <path d="M6595.461,66.416h10.261" transform="translate(-6595.461 -66.416)" fill="none" stroke="#1682f3" stroke-linecap="round" stroke-width="2"/>
            </g>
            <g transform="translate(390.59 59.349) rotate(90)">
              <path d="M0,0H10.261" transform="translate(0 0)" fill="none" stroke="#1682f3" stroke-linecap="round" stroke-width="2"/>
            </g>
          </g>
        </g>
      </svg>
      <slot></slot>
    </SimpleCard>
  </div>
</template>

<script>

import { SimpleCard } from "@profineberater/configurator-clientlib"

export default {
  name: 'AddItemCard',
  components: {
    SimpleCard
  },
}
</script>

<style scoped>
.add-item-card .card {
  background: var(--primary-color);
  font-weight: 500;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
}
.icon {
  margin-bottom: 10px;
}
</style>