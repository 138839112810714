<template>
  <div class="add-item-form">
    <div v-if="metadata" class="item-detail-image">
      <img v-if="detailImageUrl" :src="detailImageUrl">
    </div>
    <h5 v-if="metadata" class="item-detail-kicker" v-html="metadata.kicker"></h5>
    <h3 v-if="metadata" class="item-detail-headline" v-html="metadata.title"></h3>

    <TextInput class="textfield" v-model="item.name" label="Name" placeholder="Bitte eingeben"/>
    <TextInput class="textfield" v-model="item.itemNumber" label="Artikelnummer" placeholder="Bitte eingeben"/>
    <TextInput class="textfield description" v-model="item.description" label="Beschreibung" placeholder="Bitte eingeben"/>

  </div>
</template>

<script>

import { TextInput, AttachmentsMixin } from "@profineberater/configurator-clientlib"

export default {
  name: 'AddItemForm',
  components: {
    TextInput
  },
  mixins: [AttachmentsMixin],
  data: function() {
    return {
      item: this.modelValue
    }
  },
  props: {
    modelValue: {
      type: Object
    },
  },
  methods: {
    onChange() {
      this.$emit("update:modelValue", this.item)
    }
  },
  computed: {
    metadata() {
      return this.modelValue?.id ? this.$store.getters["configurationOptions/metadataForKey"](this.modelValue.id) : null
    },
    detailImageUrl() {
      if (!this.item?.id) return null
      let url = this.attachments[this.attachmentIdForIdAndKey(this.item.id.toLowerCase(), 'detailImageUrl')]
      return url
    }
  }
}
</script>

<style scoped>
.item-detail-image {
  margin-bottom: 20px;
}
.item-detail-image img {
  max-height: 12vh;
}
.item-detail-kicker {
  font-size: 15px;
  color: var(--primary-color);
  line-height: 1.1em;
  letter-spacing: 0.004em;
  margin-bottom: 15px;
}
:deep(.description input) {
  margin-bottom: 0;
}
h3 {
  margin: 30px 0 30px 0;
}
</style>

