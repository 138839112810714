<template>
  <div class="toggle-button">
    {{ title }}
    <input type="checkbox"
      @input="(event) => $emit('update:modelValue', event.target.checked)"
      :id="elId"
      :checked="modelValue"
    />
    <div class="toggle-button">
      <label :for="elId">
        <div class="toggle"></div>
        <div class="names">
          <div class="option-1-name">
            <slot name="toggle-option-1-name"></slot>
          </div>
          <div class="option-2-name">
            <slot name="toggle-option-2-name"></slot>
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ToggleButton',
  props: {
    elId: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    modelValue: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
label {
  display: block;
  text-align: left;
  padding: 10px;
  color: var(--label-color);
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.5px;
  height: 44px;
  border-radius: 100px;
  background-color: rgba(236, 237, 240, 1);
  border-radius: 100px;
  position: relative;
  margin: 1rem auto;
  cursor: pointer;
  padding: 0;
  width: 253px;
}
.toggle {
  height: 37px;
  width: 49%;
  margin-top: 3.5px;
  margin-left: 3px;
  border-radius: 100px;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(102, 119, 142, 0.4);
  transition: transform 0.25s ease-in-out;
}
.names {
  font-weight: normal;
  font-size: 95%;
  width: 100%;
  margin-top: 14px;
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  user-select: none;
}
 .option-1-name,  .option-2-name {
  text-align: center;
  width: 100%;
 }
[type="checkbox"] {
  display: none;
}
[type="checkbox"]:checked + .toggle-button .toggle {
  transform: translateX(100%);
}
[type="checkbox"]:not(:checked) + .toggle-button .option-1-name {
  color: #000;
  transition: all 0.25s ease-in-out;
}
[type="checkbox"]:checked + .toggle-button .option-2-name {
  color: #000;
  transition: all 0.25s ease-in-out;
}
</style>