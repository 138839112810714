<template>
  <div :class="'navigation-bar' + ($store.state.ui.isNavigationBarTransparent ? ' transparent' : '')">
    <div class="accessory-view">
      <slot name="navigation-accessories"></slot>
    </div>
    <div class="left">
      <transition name="fadebacknavigationitem">
        <BackNavigationItem v-if="backNavigation" :backNavigation="backNavigation" />
      </transition>
    </div>
    <div class="center title">
        <slot name="navigation-title"></slot>
    </div>
    <div class="right">
      <slot name="right-navigation-item"></slot>
    </div>
  </div>
</template>

<script>
import BackNavigationItem from "./BackNavigationItem.vue";

export default {
  name: "NavigationBar",
  components: {
    BackNavigationItem,
  },
  data: function () {
    return {
    };
  },
  watch: {
  },
  computed: {
    backNavigation() {
      return this.$store.getters["ui/backNavigation"]
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.navigation-bar {
  min-height: var(--navigation-bar-height);
  max-height: var(--navigation-bar-height);
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  width: calc(100vw - 100px);
  position: relative;
  left: 20px;
  background: var(--page-bg-color);
  width: 100%;
}
.app.has-tabbar .navigation-bar {
  left: 50px;
  width: calc(100% - var(--navigation-bar-height));
}
.navigation-bar.transparent {
  background: none;
}

.accessory-view {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.left {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* flex-basis: 200px; */
}
.left .logo {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
:deep(.left > *) {
  margin-left: 10px;
}
:deep(.left > *:first-child) {
  margin-left: 0;
}
.right {
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  min-width: 30px;
  flex-basis: 30px;
}
:deep(.right) > * {
  margin-right: 20px;
}
:deep(.right) > *:last-child {
  margin-right: 0;
}
.center {
  text-align: center;
  flex-grow: 1;
}
.title {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  padding: 0 60px 1px 0;
}

.fadebacknavigationitem-enter-from {
  opacity: 0;
}
.fadebacknavigationitem-enter-active {
  transition: all 1s ease-in 0.75s;
}
.fadebacknavigationitem-enter-to {
  opacity: 1;
}


.fadebacknavigationitem-leave-from {
  opacity: 1;
}
.fadebacknavigationitem-enter-active {
  transition: all 1s ease-in 0.75s;
}
.fadebacknavigationitem-leave-to {
  opacity: 0;
}


@media only screen and (max-width : 1023px) {
  .navigation-bar {
    min-height: var(--navigation-bar-height);
    max-height: var(--navigation-bar-height);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    left: 0;
  }
  .app.has-tabbar .navigation-bar {
    left: 0;
    width: 100%;
  }
}
@media only screen and (min-width : 1024px) {
  .right {
    padding-right: 50px;
    min-width: 50px;
    flex-basis: 50px;
  }
}
</style>