<template>
  <div class="back-navigation-item" @click="onClick" v-if="backNavigation">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.602 18.374">
      <g>
        <path d="m9.894 17.667-8.48-8.48 8.48-8.48" fill="none" stroke="currentColor" stroke-width="2"/>
      </g>
    </svg>
    <div class="title">{{ backNavigation.title }}</div>
  </div>
</template>

<script>

export default {
  name: "BackNavigationItem",
  components: {
  },
  props: {
    backNavigation: {
      type: Object,
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    onClick() {
      this.$store.commit("ui/pendingTransition", this.backNavigation.transitionName || "prev")
      this.$router.replace(this.backNavigation.path)
    }
  },
};
</script>

<style scoped>
.back-navigation-item {
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600px;
}

.back-navigation-item svg {
  width:  11px;
  height:  19px;
  margin-right: 10px;
}
.title {
  transform: translateY(-1px);
}
</style>