<template>
  <div class="custom-renderer table-renderer window-ledge-inner-renderer">
    <ul v-if="configuration.windowLedgeOuter" data-pdfmake="{'margin': [-12, 0, 0, 0], 'markerColor': '#ffffff'}">
      <li>{{ titleFromMetadata(configuration.windowLedgeOuter) }}</li>
      <li v-if="configuration.windowLedgeOuterDepth"><span class="nowrap">Tiefe: {{ configuration.windowLedgeOuterDepth }} mm</span></li>
      <li v-if="configuration.windowLedgeOuterColor">
        <CustomPropertiesRenderer label="Farbe:" :configuration="configuration" configurationKey="windowLedgeOuterColor" :title="titleFromMetadata(configuration.windowLedgeOuterColor, '')" />
      </li>
      <li v-if="configuration.windowLedgeOuterCustomText"><span class="nowrap">Details: {{ configuration.windowLedgeOuterCustomText }}</span></li>
    </ul>
    <span v-else>Ohne Fensterbank aussen</span>
  </div>
</template>

<script>

import MetadataMixin from "@/mixins/MetadataMixin.vue"
import CustomPropertiesRenderer from "@/components/configuration/CustomPropertiesRenderer.vue"

export default {
name: "WindowLedgeOuterRenderer",
mixins: [MetadataMixin],
components: {
  CustomPropertiesRenderer
},
props: {
    configuration: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>

