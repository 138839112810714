<template>
  <table :class="'ios-style' + (disabled ? ' disabled' : '')">
    <tr v-if="!omitName">
      <td class="noselect" @click="$refs.lastName.focus()">Nachname</td>
      <td><TextInput :disabled="disabled" ref="lastName" v-model="address.lastName" /></td>
    </tr>
    <tr v-if="!omitName">
      <td class="noselect" @click="$refs.firstName.focus()">Vorname</td>
      <td><TextInput :disabled="disabled" ref="firstName" v-model="address.firstName" /></td>
    </tr>
    <tr>
      <td class="noselect" @click="$refs.streetAndNumber.focus()">Straße/Nr.</td>
      <td><TextInput :disabled="disabled" ref="streetAndNumber" v-model="address.streetAndNumber" /></td>
    </tr>
    <tr>
      <td class="noselect" @click="$refs.zip.focus()">Postleitzahl</td>
      <td><TextInput :disabled="disabled" ref="zip" v-model="address.zip" /></td>
    </tr>
    <tr>
      <td class="noselect" @click="$refs.city.focus()">Ort</td>
      <td><TextInput :disabled="disabled" ref="city" v-model="address.city" /></td>
    </tr>
  </table>
</template>

<script>

import { TextInput } from "@profineberater/configurator-clientlib"
import { useModelWrapper } from '@/modelWrapper.js'

export default {
  name: "AddressForm",
  props: {
    modelValue: {
      type: Object
    },
    omitName: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  setup(props, { emit }) {
    return {
      address: useModelWrapper(props, emit, 'modelValue'),
    }
  },
  components: {
    TextInput
  },
};
</script>

<style scoped>
.disabled {
  opacity: 0.5;
}
</style>
