<template>
  <div class="tab-bar noselect">
    <TabBarItem title="Projekt" path="/project" @tabbaritem:selected="onClick">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
        <g>
          <text transform="translate(36 51.136)" fill="currentColor" font-size="7" font-family="Roboto-Regular, Roboto" letter-spacing=".03em"><tspan x="-11.591" y="0">Projekt</tspan></text>
          <g>
            <path fill="none" stroke="currentColor" stroke-linecap="square" stroke-width="1.5" d="M37.08 27.534h4.687v4.687H37.08z"/>
            <g>
              <g fill="none" stroke="currentColor" stroke-linecap="square" stroke-width="1.5">
                <path d="M41.767 27.534h4.687v4.687h-4.687z"/>
                <path d="M37.08 32.221h4.687v4.687H37.08z"/>
                <path d="M41.767 32.221h4.687v4.687h-4.687z"/>
                <path d="M33.806 34.908h-8.259V24.52l8.259-8.259 7.7 7.7"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </TabBarItem>
    <TabBarItem title="Konfigurator" path="/configurations" highlightsPathPrefix="/configur" @tabbaritem:selected="onClick">
      <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
        <g>
          <g>
            <g>
              <g transform="translate(26.323 15.141)" fill="none" stroke="currentColor" stroke-width="1.5">
                <path stroke-linecap="square" d="M11.2 1.038h7.118V21.79H11.2z"/>
                <path stroke-linecap="square" d="M18.318 6.289h-2.203"/>
                <path stroke-linecap="square" d="M18.318 11.413h-2.203"/>
                <path stroke-linecap="square" d="M18.318 16.538h-2.203"/>
                <path stroke-linecap="round" d="M1.315 14.185h3.489v7.604H1.315z"/>
                <path d="m4.805 5.165-.987-4.127H2.302l-.987 4.127Z" stroke-linecap="round"/>
                <path stroke-linecap="round" d="M3.06 14.185V5.164"/>
              </g>
            </g>
            <text transform="translate(36 52.136)" fill="currentColor" font-size="8" font-family="Roboto-Regular, Roboto" letter-spacing=".03em"><tspan x="-23.425" y="0">Konfigurator</tspan></text>
          </g>
        </g>
      </svg>
    </TabBarItem>
    <TabBarItem title="Skizzen" path="/scribbles" @tabbaritem:selected="onClick">
      <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
        <g>
          <g>
            <g>
              <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5" transform="translate(24.586 15.141)">
                <path d="M4.746 21.808H1.018V18.08L17.659 1.44l3.727 3.728Z"/>
                <path d="m1.018 18.08 3.728 3.728"/>
              </g>
            </g>
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5" d="m38.551 20.275 3.728 3.728"/>
            <g>
              <g transform="translate(24.586 15.141)">
                <path fill="none" stroke="currentColor" stroke-linecap="square" stroke-width="1.5" d="M1.018 21.808h20.368"/>
              </g>
            </g>
          </g>
          <text transform="translate(36 51.136)" fill="currentColor" font-size="7" font-family="Roboto-Regular, Roboto" letter-spacing=".03em"><tspan x="-12.584" y="0">Skizzen</tspan></text>
        </g>
      </svg>
    </TabBarItem>
    <TabBarItem title="Notizen"  path="/notes" @tabbaritem:selected="onClick">
      <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
        <g>
          <g>
            <g>
              <g fill="none" stroke="currentColor" stroke-linecap="square" stroke-width="1.5" transform="translate(26.561 15.141)">
                <path d="M1.038 3.011h16.804v18.778H1.038z"/>
                <path d="M12.906 1.038v3.948"/>
                <path d="M5.972 1.038v3.948"/>
              </g>
            </g>
            <path fill="none" stroke="currentColor" stroke-linecap="square" stroke-width="1.5" d="M32.533 25.735h6.934"/>
            <path fill="none" stroke="currentColor" stroke-linecap="square" stroke-width="1.5" d="M32.533 29.683h6.934"/>
          </g>
          <text transform="translate(36 51.136)" fill="currentColor" font-size="7" font-family="Roboto-Regular, Roboto" letter-spacing=".03em"><tspan x="-12.634" y="0">Notizen</tspan></text>
        </g>
      </svg>
    </TabBarItem>
    <TabBarItem title="Fotos"  path="/photos" @tabbaritem:selected="onClick">
      <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
        <g>
          <g transform="translate(25 16.282)" fill="none" stroke="currentColor" stroke-linecap="square" stroke-width="1.5">
            <path d="M16.24 3.602h0A2.651 2.651 0 0 0 13.589.951H9.237a2.651 2.651 0 0 0-2.651 2.651H.951v15.217h20.924V3.602Z"/>
            <path d="M15.391 11.212a3.977 3.977 0 1 1-3.973-3.983 3.978 3.978 0 0 1 3.973 3.983Z"/>
          </g>
          <text transform="translate(36.414 51.136)" fill="currentColor" font-size="7" font-family="Roboto-Regular, Roboto" letter-spacing=".03em"><tspan x="-9.225" y="0">Fotos</tspan></text>
        </g>
      </svg>
    </TabBarItem>
  </div>
</template>

<script>

import TabBarItem from "./TabBarItem.vue"

export default {
  name: "TabBar",
  components: {
    TabBarItem
  },
  data() {
    return {
    };
  },
  methods: {
    onClick(path) {
      this.$router.replace({ path: path, query: { p: this.$route.query.p, t: "none" }})
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.tab-bar {
  height: 100%;
  width: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  bottom: 0;
  flex-direction: column;
  position: fixed;
  box-shadow: 0 0 20px rgba(63, 95, 137, 0.15);
  z-index: 1000;
  background: #fff;
  top: 0;
  left: 0;
  height: 100%;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;

}

@media only screen and (max-width : 1023px) {
  .tab-bar {
    min-height: 110px;
    max-height: 110px;
    flex-basis: 110px;
    flex-direction: row;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 0;
    top: initial;
    bottom: 0;
    height: 110px;
    width: 100%;
  }
}
</style>