<template>
  <button class="button rounded primary edit-photo" @click="showMarkerArea(photo)">
    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" x="0" y="0" width="10" height="10" version="1.1" viewBox="0 0 10 10">
      <g transform="translate(.5 .5)" fill="none" stroke="#fff" stroke-linecap="square" stroke-width="1.5" stroke-miterlimit="10">
        <path d="M1 9h7" />
        <path d="M7.4 1.9 2.7 6.7H1V4.9L5.7.2c.3-.3.8-.3 1.2 0 0 0 0 0 0 0l.6.6c.2.3.2.8-.1 1.1z" />
      </g>
    </svg>
    <span>Bearbeiten</span>
  </button>
</template>

<script>
import * as markerjs2 from 'markerjs2'
import { AttachmentsMixin, Util } from '@profineberater/configurator-clientlib'
let debounceTimeout;

export default {
  name: 'PhotoEditor',
  mixins: [AttachmentsMixin],
  components: {
  },
  props: {
    photo: {
      type: Object,
    },
    project: {
      type: Object,
    },
    photoRef: {
      type: String,
    },
    discardMarkerChanges: {
      type: Boolean,
      default: false,
    },
    saveMarkerChanges: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    discardMarkerChanges() {
      this.handleDiscardMarkerChanges()
    },
    saveMarkerChanges() {
      this.handleSaveMarkerChanges()
    },
  },
  data() {
    return {
      markerArea: null,
      markerCopied: null,
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeydown)
    window.addEventListener("resize", this.debounceResizeHandler)
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeydown)
    window.removeEventListener("resize", this.debounceResizeHandler)
  },
  methods: {

    debounceResizeHandler() {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(this.onResize, 100);
    },
    onResize() {
      this.customizeToolboxIcons()
      this.addCustomButtons()
    },
    async showMarkerArea(photo) {
      let id = await this.$store.dispatch('db/getImageAttachment', {
        db: this.$store.getters['db/database'],
        documentId: this.project._id,
        attachmentId: photo.originalAttachmentId
      })

      this.attachmentIds.add(id)
      let originalImage = await Util.promisifiedLoadDomImage(this.attachments[photo.originalAttachmentId])
      originalImage.style.position = 'fixed'
      originalImage.style.visibility = 'hidden'
      originalImage.style.top = 0
      originalImage.style.left = 0
      document.body.appendChild(originalImage)

      this.markerArea = new markerjs2.MarkerArea(originalImage)

      const markerConfig = {
        availableMarkerTypes: this.markerArea.ALL_MARKER_TYPES,
        uiStyleSettings: {
          canvasBackgroundColor: '#f7f8fa',
          redoButtonVisible: true,
          notesButtonVisible: false,
          zoomButtonVisible: true,
          zoomOutButtonVisible: true,
          clearButtonVisible: false,
          closeButtonVisible: false,
          toolbarHeight: 42,
          toolbarStyleColorsClassName: 'marker-toolbar-bg',
          toolbarButtonStyleColorsClassName: 'marker-toolbar-button',
          toolbarActiveButtonStyleColorsClassName: 'marker-toolbar-button-active',
          toolbarOverflowBlockStyleColorsClassName: 'marker-toolbar-bg',
          toolboxColor: '#ffffff',
          toolboxAccentColor: '#ffffff',
          toolboxStyleColorsClassName: 'marker-toolbox-bg',
          toolboxButtonRowStyleColorsClassName: 'marker-toolbox-bg',
          toolboxPanelRowStyleColorsClassName: 'marker-toolbox-bg',
          toolboxButtonStyleColorsClassName: 'marker-toolbox-button',
          toolboxActiveButtonStyleColorsClassName: 'marker-toolbox-button-active',
        },
        renderAtNaturalSize: true,
        renderImageType: 'image/jpg',
        renderMarkersOnly: false,
        /**
         * Overwrite settings defaults...
         * https://markerjs.com/reference/classes/settings
         */
        settings: {
          defaultCaptionText: 'Ihr Text hier',
          defaultColor: '#ffffff',
          defaultColorSet: ['#ffffff', '#000000', '#1682f3', '#fdbc2c', '#ff5f7e', '#34b99a'],
          defaultFillColor: 'rgba(22, 130, 243, 1)',
          defaultFontFamilies: ['Times, "Times New Roman", serif','Helvetica, Arial, sans-serif','Courier, "Courier New", monospace','cursive',],
          defaultHighlightColor: '#fdbc2c',
          displayMode: 'popup',
        }
      }

      Object.keys(markerConfig).forEach(key => {
       if (key === 'uiStyleSettings' || key === 'settings') {
          Object.keys(markerConfig[key]).forEach(setting => {
            this.markerArea[key][setting] = markerConfig[key][setting]
          })
        } else {
          this.markerArea[key] = markerConfig[key]
        }
      })

      this.markerArea.availableMarkerTypes = ['FreehandMarker', 'HighlightMarker', 'TextMarker', 'LineMarker', 'CurveMarker', 'FrameMarker', 'CoverMarker', 'EllipseFrameMarker', 'EllipseMarker', 'CalloutMarker', 'CaptionFrameMarker', 'ArrowMarker', 'MeasurementMarker']

      /**
       * Overwrite toolbar titles and icons...
       */
      markerjs2.ArrowMarker.title = 'Pfeil'
      markerjs2.ArrowMarker.icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M6.5 9.5h-4v-4M9.5 2.5l-7 7" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
      markerjs2.CalloutMarker.title = 'Textblase'
      markerjs2.CalloutMarker.icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M10 8.5H7.5l-1.5 2-1.5-2H2a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1ZM3 4h6M3 6h2.5" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
      markerjs2.CaptionFrameMarker.title = 'Textrahmen'
      markerjs2.CaptionFrameMarker.icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M4.25 4.25h2.5M3.247 1.75h5.507c.55 0 .997.447.997.997v6.507c0 .55-.447.997-.997.997H2.997a.748.748 0 0 1-.747-.747V2.747c0-.55.447-.997.997-.997Z" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
      markerjs2.CoverMarker.title = 'Rechteck'
      markerjs2.CoverMarker.icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M1 2h10v8H1z" style="fill:rgba(17,109,224,.15);stroke-linecap:round;stroke-linejoin:round"/></svg>`
      markerjs2.CurveMarker.title = 'Kurve'
      markerjs2.CurveMarker.icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M3.151 7.899a4.986 4.986 0 0 1 4.986-4.986h0" style="stroke-linecap:round;stroke-linejoin:round"/><path d="M3.151 7.899a1.186 1.186 0 1 1 0 2.374 1.186 1.186 0 1 1 0-2.374ZM9.324 1.727a1.186 1.186 0 1 1 0 2.374 1.186 1.186 0 1 1 0-2.374Z" style="stroke-linejoin:round"/><path d="M8.137 2.914H2.201" style="stroke-linecap:round;stroke-linejoin:round"/><path d="M2.201 2.914h0" style="stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px"/></svg>`
      markerjs2.EllipseFrameMarker.title = 'Elliptischer Rahmen'
      markerjs2.EllipseFrameMarker.icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><ellipse cx="6" cy="6" rx="5.5" ry="4.5" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
      markerjs2.EllipseMarker.title = 'Ellipse'
      markerjs2.EllipseMarker.icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><ellipse cx="6" cy="6" rx="5.5" ry="4.5" style="fill:rgba(17,109,224,.15);stroke-linecap:round;stroke-linejoin:round"/></svg>`
      markerjs2.FrameMarker.title = 'Rahmen'
      markerjs2.FrameMarker.icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M1 2h10v8H1z" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
      markerjs2.FreehandMarker.title = 'Freihand zeichnen'
      markerjs2.FreehandMarker.icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="m4.298 8.207 4.95-4.95a.859.859 0 0 0 0-1.214l-.582-.58a.859.859 0 0 0-1.214 0L2.501 6.414v1.798l1.797-.004ZM2.5 10.212s1.597 1.297 3.5 0 3.5 0 3.5 0" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
      markerjs2.HighlightMarker.title = 'Markieren'
      markerjs2.HighlightMarker.icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M3.39 3.677h6.553v3.34H3.39z" style="stroke-linecap:round;stroke-linejoin:round;stroke-width:1px" transform="rotate(-45 6.666 5.348)"/><path d="m3.169 6.483-.906 2.086L3.444 9.75l2.086-.905-2.361-2.362zM2.854 9.16l-1.018.991" style="stroke-linecap:round;stroke-linejoin:round;stroke-width:1px"/></svg>`
      markerjs2.LineMarker.title = 'Linie'
      markerjs2.LineMarker.icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M11 6H1" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
      markerjs2.MeasurementMarker.title = 'Lineal'
      markerjs2.MeasurementMarker.icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M.815 4.114h10.371v3.771H.815z" style="stroke-linecap:round;stroke-linejoin:round" transform="rotate(135 6 6)"/><path d="m3.667 7.667-1-1M6 6 4.667 4.667M7.667 3.667l-1-1" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
      markerjs2.TextMarker.title = 'Text'
      markerjs2.TextMarker.icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="m2.5 9.5 3.5-7 3.5 7M3.75 7h4.5" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`

      this.markerArea.addEventListener('render', event => {
        document.getElementById(this.photoRef).src = event.dataUrl
        originalImage.src = event.dataUrl
        photo.markerState = event.state

        const markerBlob = this.dataURLtoBlob(event.dataUrl)

        photo.editedAttachmentId = `edited-${photo.originalAttachmentId}`

        return new Promise( (resolve, reject) => {
          this.$store.dispatch('project/update', this.project)
          .then( () => {
            this.$store.dispatch('db/saveBlobToDB', {
              document: this.project,
              blob: markerBlob,
              attachmentId: photo.editedAttachmentId,
              mimetype: markerBlob.type,
              markerState: event.state
            }).then( () => {
              resolve()
            }).catch( err => reject(err))
          })
        })
      })

      /**
       * check if canvas is dirty
       */
      this.markerArea.addEventListener('beforeclose', (e) => {
        let currentState = JSON.stringify(this.markerArea.getState().markers)
        console.log(currentState)
        if (currentState) {
          if (photo.markerState) {
            let originalState =  JSON.stringify(photo.markerState.markers)
            console.log(currentState)
            console.log(originalState)
            if (currentState !== originalState) {
              this.$emit('onCancel')
              e.preventDefault()
            }
          }
        }
      })

      /**
       * overwrite the toolbox icons (we got to do this every time the content of the toolbox changes)
       */
      let customizeToolboxIconsEvents = [
        'markercreating',
        'markerselect',
        'markerdeselect',
      ]
      customizeToolboxIconsEvents.forEach( event => {
        this.markerArea.addEventListener(event, () => {
          this.customizeToolboxIcons()
        })
      })
      this.markerArea.addEventListener('show', () => {
        this.customizeToolbarIcons()
        setTimeout( () => {
          this.customizeToolboxIcons()
          this.addCustomButtons()
        }, 100)

      })
      this.markerArea.addEventListener('close', () => {
        document.body.removeChild(originalImage)
        let addedEventListeners = [
          'markercreating',
          'markerselect',
          'markerdeselect',
          'show',
          'close',
          'render',
          'beforeclose'
        ]
        addedEventListeners.forEach( event => {
          this.markerArea.removeEventListener(event)
        })
        this.markerArea = null
      })

      /**
       * let the show begin
       */
      this.markerArea.show()

      if (photo.markerState) {
        this.markerArea.restoreState(photo.markerState)
      }
    },
    customizeToolbarIcons() {
      const buttonsToCustomize = {
        'select': {
          title: 'Auswählen',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M6.564 10.625 5.249 8.101l-1.748.928V1.375l5 5-.953.506" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
        },
        'delete': {
          title: 'Löschen',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M9.283 4.113h-6.57l.445 5.934c.039.537.486.953 1.025.953h3.629c.539 0 .986-.416 1.025-.953l.446-5.934ZM9.8 2.123H2.2M5.999 2.123V1" style="fill:none;stroke:#b5c0d1;stroke-linecap:round;stroke-linejoin:round"/></svg>`
        },
        'undo': {
          title: 'Rückgängig',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M1.5 3.709h5.604a3.396 3.396 0 1 1 0 6.791h-1" style="stroke-linecap:round;stroke-linejoin:round"/><path d="M3.709 5.918 1.5 3.708 3.709 1.5" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
        },
        'redo': {
          title: 'Wiederherstellen',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M5.896 10.5h-1a3.396 3.396 0 0 1 0-6.791H10.5" style="stroke-linecap:round;stroke-linejoin:round"/><path d="M8.291 1.5 10.5 3.708l-2.209 2.21" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
        },
        'zoom': {
          title: 'Vergrössern',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M7.473 7.473 10.5 10.5M4.999 8.498a3.5 3.5 0 0 1 0-6.998M4.999 1.502a3.498 3.498 0 0 1 0 6.998M5 4v2M6 5H4" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
        },
        'zoom-out': {
          title: 'Verkleinern',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M4.999 8.498a3.5 3.5 0 0 1 0-6.998M4.999 1.502a3.498 3.498 0 0 1 0 6.998M7.473 7.473 10.5 10.5M6 5H4" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
        },
        'render': {
          title: 'Speichern',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M10 3 4 9 2 7" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
        },
        'close': {
          title: 'Schliessen',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="m3 3 6 6M9 3 3 9" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
        },
      }

      // give the image a wee more space
      //let canvasArea = document.querySelector('div.marker-toolbar-bg')
      //canvasArea.nextSibling.style.padding = '15px'

      //let image = document.querySelector('div.__markerjs2_ img');
      //image.style.border = '20px solid white';
      //image.style.borderRadius = '7px';
      //image.style.boxShadow = 'var(--default-box-shadow)';

      if (this.markerArea) {
        Object.keys(buttonsToCustomize).forEach( button => {
          const buttonElement = document.querySelector(`div[data-action=${button}]`)
          if (buttonElement) {
            buttonElement.ariaLabel = buttonsToCustomize[button].title
            buttonElement.title = buttonsToCustomize[button].title
            buttonElement.innerHTML = buttonsToCustomize[button].icon
            buttonElement.addEventListener('click', () => {
              this.customizeToolboxIcons()
            })
            // if (button === 'delete') {
            //   buttonElement.parentNode.removeChild(buttonElement)
            // }
          }
        })

        /**
         * add copy, paste and duplicate buttons
         */
        this.addCustomButtons()
      }
    },
    addCustomButtons() {
      //const insertAfter = document.querySelector(`div[data-action=zoom-out]`)
      const insertAfter = document.querySelector('div[data-type-name=MeasurementMarker]')
      const customButtons = {
        'copy': {
          title: 'Kopieren',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M5 1.875H1.875V5M10.125 5V1.875H7M7 10.125h3.125V7M1.875 7v3.125H5M6.938 7.406H5.063V4.594h1.875" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`,
          func: this.copyMarker
        },
        'paste': {
          title: 'Einfügen',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M5.063 4.594h1.875v1.875H5.063zM5.063 4.594v2.812" style="stroke-linecap:round;stroke-linejoin:round"/><path d="M1.875 1.875h8.25v8.25h-8.25z" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`,
          func: this.pasteMarker
        },
        'duplicate': {
          title: 'Duplizieren',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M2 2h5v5H2z" style="fill:none;stroke:#116de0;stroke-linecap:round;stroke-linejoin:round"/><path d="M9 5h1v5H5V9" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`,
          func: this.duplicateMarker
        },
      }

      Object.keys(customButtons).reverse().forEach( button => {
        const toolbarButton = document.createElement('div')
        toolbarButton.className = '__markerjs2__0_toolbar_button marker-toolbar-button'
        toolbarButton.setAttribute('role', 'button')
        toolbarButton.setAttribute('aria-label', customButtons[button].title)
        toolbarButton.setAttribute('title', customButtons[button].title)
        toolbarButton.setAttribute('data-action', customButtons[button].action)
        toolbarButton.innerHTML = customButtons[button].icon
        toolbarButton.addEventListener('click', customButtons[button].func)
        insertAfter.insertAdjacentElement('afterend', toolbarButton)
      })
    },
    customizeToolboxIcons() {
      const buttonsToCustomize = {
        'arrow-type-panel': {
          title: 'Pfeilart',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M3.209 8.21 1 6l2.209-2.208M8.791 3.79 11 6 8.791 8.208M11 6H1" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
        },
        'color-picker-panel': {
          title: 'Farbe',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><g style="stroke-linecap:round;stroke-linejoin:round;stroke-width:1.3px"><path d="M10.5 6s-.056 1.779-1.572 1.779-2.928-.237-3.48.859c-.523 1.039.551 1.862.551 1.862A4.507 4.507 0 0 1 1.62 4.94a4.468 4.468 0 0 1 3.319-3.318 4.507 4.507 0 0 1 5.56 4.379Z" style="stroke-linecap:round;stroke-linejoin:round"/><path d="M3.675 6.265h0M4.169 4.311h0M6.193 3.563h0M7.964 4.676h0"/></g></svg>`
        },
        'fill-color-panel': {
          title: 'Füllfarbe',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M1 6.532c0 .263.1.525.301.726l1.27 1.27 1.27 1.27a1.028 1.028 0 0 0 1.456 0L6.93 8.165l1.633-1.633H1Z" class="fill-color-fluid" style="stroke-linecap:round;stroke-linejoin:round"/><path d="m3.841 9.798-1.27-1.27-1.27-1.27a1.025 1.025 0 0 1 0-1.452l1.634-1.634 1.634-1.634 1.997 1.997 1.997 1.997L6.93 8.165 5.297 9.798a1.028 1.028 0 0 1-1.456 0Z" style="stroke-linecap:round;stroke-linejoin:round"/><path d="M1.238 2.238c.251-.251.579-.376.908-.376s.657.125.908.376.376.579.376.908-.125.657-.376.908" style="stroke-linecap:round;stroke-linejoin:round"/><path class="fill-color-drop" d="M11 9.139a1 1 0 0 1-2 0c0-.23.081-.439.211-.607h-.005l.592-.891a.242.242 0 0 1 .403 0l.592.891h-.004c.13.169.211.378.211.607Z" style="stroke-width:0"/></svg>`
        },
        'font-family-panel': {
          title: 'Schriftart',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M2 5.125h3.009M2 7.125V2.693c0-.314.249-.568.557-.568h1.895c.308 0 .557.255.557.568v4.432" style="stroke-linecap:round;stroke-linejoin:round"/><path d="M9.313 9.875v-5.5H8.177l-2.793 5.5M4.825 9.875h1.429M8.625 9.875H10M9.313 7.375h-2.66M7.113 4.375h2.2" style="stroke-linecap:round;stroke-linejoin:round;stroke-width:.6px"/></svg>`
        },
        'line-style-panel': {
          title: 'Linienstil',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M1.25 2.437h9.5" style="stroke-linecap:round;stroke-linejoin:round"/><path d="M1 7.187h2.398M8.602 7.187H11M4.801 7.141h2.398" style="stroke-linecap:round;stroke-linejoin:round;stroke-width:.5px"/><path d="M1.125 4.812h4M6.875 4.812h4" style="stroke-linecap:round;stroke-linejoin:round;stroke-width:.75px"/><path d="M1.067 9.563h0M2.476 9.563h0M3.886 9.563h0M5.295 9.563h0M6.705 9.563h0M8.114 9.563h0M9.524 9.563h0M10.933 9.563h0" style="stroke-linecap:round;stroke-linejoin:round;stroke-width:.6px"/></svg>`
        },
        'line-width-panel': {
          title: 'Linienstärke',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M10.75 2.437h-9.5" style="stroke-linecap:round;stroke-linejoin:round"/><path d="M11.073 9.563H.927" style="stroke-linecap:round;stroke-linejoin:round;stroke-width:.35px"/><path d="M1 7.187h10" style="stroke-linecap:round;stroke-linejoin:round;stroke-width:.5px"/><path d="M1.125 4.812h9.75" style="stroke-linecap:round;stroke-linejoin:round;stroke-width:.75px"/></svg>`
        },
        'opacity-panel': {
          title: 'Deckkraft',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M8.475 4.249 6.002 1.776 3.56 4.218l-.035.032C2.892 4.883 2.5 5.758 2.5 6.725S2.892 8.566 3.525 9.2c.633.633 1.508 1.025 2.475 1.025S7.841 9.833 8.475 9.2C9.108 8.567 9.5 7.692 9.5 6.725S9.108 4.884 8.475 4.25Z" style="stroke-linecap:round;stroke-linejoin:round"/><path d="M2.5 6.724c0 .967.392 1.841 1.025 2.475.633.633 1.508 1.025 2.475 1.025s1.841-.392 2.475-1.025A3.49 3.49 0 0 0 9.5 6.724h-7Z" class="opacity-panel-droplet" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
        },
        'stroke-color-panel': {
          title: 'Linienfarbe',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M10.5 6s-.056 1.779-1.572 1.779-2.928-.237-3.48.859c-.523 1.039.551 1.862.551 1.862A4.507 4.507 0 0 1 1.62 4.94a4.468 4.468 0 0 1 3.319-3.318 4.507 4.507 0 0 1 5.56 4.379Z" style="stroke-linecap:round;stroke-linejoin:round"/><path d="M3.675 6.265h0M4.169 4.311h0M6.193 3.563h0M7.964 4.676h0" style="stroke-linecap:round;stroke-linejoin:round;stroke-width:1.3px"/></svg>`
        },
        'text-color-panel': {
          title: 'Textfarbe',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="m2.5 9.5 3.5-7 3.5 7M3.75 7h4.5" style="stroke-linecap:round;stroke-linejoin:round"/></svg>`
        },
      }

      const elementToSearch = document.querySelector('div.__markerjs2__0_toolbox-panel-row')
      const elementsToReplace = {
        panagram: {
          search: 'The quick brown fox jumps over the lazy dog',
          replace: 'Zwölf Boxkämpfer jagen Viktor quer über den großen Sylter Deich.',
        },
        opacityIcon: {
          search: `<path d="M2,5.27L3.28,4L20,20.72L18.73,22L15.65,18.92C14.5,19.3 13.28,19.5 12,19.5C7,19.5 2.73,16.39 1,12C1.69,10.24 2.79,8.69 4.19,7.46L2,5.27M12,9A3,3 0 0,1 15,12C15,12.35 14.94,12.69 14.83,13L11,9.17C11.31,9.06 11.65,9 12,9M12,4.5C17,4.5 21.27,7.61 23,12C22.18,14.08 20.79,15.88 19,17.19L17.58,15.76C18.94,14.82 20.06,13.54 20.82,12C19.17,8.64 15.76,6.5 12,6.5C10.91,6.5 9.84,6.68 8.84,7L7.3,5.47C8.74,4.85 10.33,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C12.69,17.5 13.37,17.43 14,17.29L11.72,15C10.29,14.85 9.15,13.71 9,12.28L5.6,8.87C4.61,9.72 3.78,10.78 3.18,12Z"></path>`,
          replace:`<path d="M3.407 3.951a3.317 3.317 0 0 0-.491.806 3.326 3.326 0 0 0 1.827 4.325 3.326 3.326 0 0 0 2.498 0c.288-.117.554-.29.806-.491M2.673 3.217l6.11 6.11M9.158 6.993c.103-.326.169-.661.169-1a3.322 3.322 0 0 0-.972-2.347 3.314 3.314 0 0 0-2.347-.972c-.339 0-.674.066-1 .169" style="fill:none;stroke:#ffffff;stroke-linecap:round;stroke-linejoin:round"/>`,
        },
      }

      Object.keys(buttonsToCustomize).forEach( button => {
        const buttonElement = document.querySelector(`div[data-action=${button}]`)
        if (buttonElement) {
          buttonElement.ariaLabel = buttonsToCustomize[button].title
          buttonElement.title = buttonsToCustomize[button].title
          buttonElement.innerHTML = buttonsToCustomize[button].icon
          buttonElement.addEventListener('click', () => {
            Object.keys(elementsToReplace).forEach(key => {
              this.replaceRecursively(elementToSearch, elementsToReplace[key].search, elementsToReplace[key].replace)
            })
          })
        }
      })

      const deleteButton = document.querySelector('div[data-action=delete]')
      if (deleteButton) {
        if (deleteButton.style.pointerEvents) {
          if (deleteButton.style.pointerEvents === 'all') {
            deleteButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M9.283 4.113h-6.57l.445 5.934c.039.537.486.953 1.025.953h3.629c.539 0 .986-.416 1.025-.953l.446-5.934ZM9.8 2.123H2.2M5.999 2.123V1" style="fill:none;stroke:#116de0;stroke-linecap:round;stroke-linejoin:round"/></svg>`
          } else {
            deleteButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path d="M9.283 4.113h-6.57l.445 5.934c.039.537.486.953 1.025.953h3.629c.539 0 .986-.416 1.025-.953l.446-5.934ZM9.8 2.123H2.2M5.999 2.123V1" style="fill:none;stroke:#b5c0d1;stroke-linecap:round;stroke-linejoin:round"/></svg>`
          }
        }
      }

    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while(n--){
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], {type:mime})
    },
    handleDiscardMarkerChanges() {
      if (this.markerArea) {
        this.markerArea.close(true)
      }
    },
    handleSaveMarkerChanges() {
      if (this.markerArea) {
        this.markerArea.startRenderAndClose()
      }
    },
    handleKeydown(e) {
      if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
        this.copyMarker()
        e.preventDefault()
      }
      if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
        this.pasteMarker()
        e.preventDefault()
      }
    },
    copyMarker() {
      if (this.markerArea && this.markerArea.currentMarker) {
        this.markerCopied = JSON.parse(JSON.stringify(this.markerArea.currentMarker.getState()))
      }
    },
    pasteMarker() {
      if (this.markerArea && this.markerCopied) {
        let state = JSON.parse(JSON.stringify(this.markerArea.getState()))

        this.markerCopied.left += 10
        this.markerCopied.top += 10
        if (this.markerCopied.x1 !== undefined) {
          this.markerCopied.x1 += 30
        }
        if (this.markerCopied.x2 !== undefined) {
          this.markerCopied.x2 += 30
        }
        if (this.markerCopied.y1 !== undefined) {
          this.markerCopied.y1 += 30
        }
        if (this.markerCopied.y2 !== undefined) {
          this.markerCopied.y2 += 30
        }

        state.markers.push(JSON.parse(JSON.stringify(this.markerCopied)))

        this.markerArea.restoreState(state)
      }
    },
    duplicateMarker() {
      if (this.markerArea) {
        this.copyMarker()
        this.pasteMarker()
      }
    },
    replaceRecursively(element, from, to) {
      if (!element) {
        return
      }

      if (element.nodeType === Node.TEXT_NODE) {
        const cont = element.textContent
        if (cont) {
          const regex = new RegExp(from, 'g')
          element.textContent = cont.replace(regex, to)
        }
      } else if (element instanceof SVGElement) {
        const regex = new RegExp(from, 'g')
        const oldInnerHTML = element.innerHTML
        const newInnerHTML = oldInnerHTML.replace(regex, to)
        if (oldInnerHTML !== newInnerHTML) {
          element.innerHTML = newInnerHTML
          element.setAttribute('viewBox', '0 0 12 12')
        }
      } else if (element.nodeType === Node.ELEMENT_NODE) {
        for (let i = 0; i < element.childNodes.length; i++) {
          this.replaceRecursively(element.childNodes[i], from, to)
        }
      }
    }
  },
}
</script>

<style>
.marker-toolbar-bg {
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid #E9EBF2;
}
.marker-toolbar-button {
  background-color: none;
  border-radius: 7px;
  height: 16px !important;
  padding: 8.5px !important;
  width: 16px !important;
  margin: 2.5px;
}
.marker-toolbar-button:hover {
  background-color: #edeff4;
}
.marker-toolbar-button svg {
  fill: none;
  height: 16px !important;
  stroke: var(--primary-color);
  width: 16px !important;
}
.marker-toolbar-button-active {
  background-color: var(--primary-color);
  border-radius: 7px;
  height: 16px !important;
  padding: 8.5px !important;
  width: 16px !important;
  margin: 2.5px;
}
.marker-toolbar-button-active svg {
  fill: none;
  height: 16px !important;
  stroke: #fff;
  width: 16px !important;
}
.marker-toolbox-bg {
  background-color: var(--primary-color);
  color: #fff;
}
.marker-toolbox-button {
  background-color: none;
  border-radius: 7px;
  height: 16px !important;
  padding: 8.5px !important;
  width: 16px !important;
  margin: 5px;
}
.marker-toolbox-button:hover {
  background-color: #116DE0;
}
.marker-toolbox-button svg {
  fill: none;
  height: 16px !important;
  stroke: #fff;
  width: 16px !important;
}
.marker-toolbox-button svg .fill-color-drop {
  fill: #fff;
}
.marker-toolbox-button svg .fill-color-fluid,
.marker-toolbox-button svg .opacity-panel-droplet {
  fill:rgba(255,255,255,.15);
}
.marker-toolbox-button-active {
  background-color: white;
  border-radius: 7px;
  height: 16px !important;
  padding: 8.5px !important;
  width: 16px !important;
  margin: 5px;
}
.marker-toolbox-button-active svg {
  stroke: var(--primary-color);
  fill: none;
  height: 16px !important;
  width: 16px !important;
}
.marker-toolbox-button-active svg .fill-color-drop {
  fill: var(--primary-color);
}
.marker-toolbox-button-active svg .fill-color-fluid,
.marker-toolbox-button-active svg .opacity-panel-droplet {
  fill :rgba(17,109,224,.15);
}
.__markerjs2__0_toolbox-panel-row,
.__markerjs2__1_toolbox-panel-row {
  background-color: #116DE0;
}
.__markerjs2__0_toolbox-panel-row,
.__markerjs2__1_toolbox-panel-row {
  /* height: 42px !important; */
}
.__markerjs2__0_toolbar-overflow-block_colors {
  background-color: #fff;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.edit-photo {
  /* display: block;
  cursor: pointer;
  height: 24px;
  width: 24px;
  position: absolute;
  left: 10px;
  top: 10px; */
}

.marker-toolbar-button[data-action=close] {
  display: none;
}

.__markerjs2__0_toolbox-panel-row.marker-toolbox-bg > div[style*="border-radius: 16.75px;"]{
  overflow: scroll hidden !important;
}
div[style*="border-radius: 16.75px;"]{
  overflow: hidden;
  margin: 0;
  transform: scale(0.8) translateY(0px) !important;
}

/* .__markerjs2__0_toolbox-panel-row.marker-toolbox-bg > div > div {
  overflow: scroll hidden !important;
} */

button.button.edit-photo {
  padding: 0 20px;
}
button.button.edit-photo svg {
  margin-right: 5px;
}

</style>