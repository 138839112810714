<template>
    <AppLayout>
      <template #right-navigation-item>
      </template>
      <template v-if="itemCount" #page-title>
        <h1>Konfigurationen</h1>
      </template>
      <template #navigation-title>
        {{ $store.getters["project/navigationTitle"] }}
      </template>
      <template #page>
        <div :class="'configurations-page' + (itemCount ? '' : ' no-content')">
          <Configurations v-if="itemCount"
            :project="project"
            @configuration:select="onSelectConfiguration"
            @configuration:rename="onRenameConfiguration"
            @configuration:duplicate="onDuplicateConfiguration"
            @configuration:delete="onDeleteConfiguration"
          />
          <NoContentPlaceholder v-else title="Konfigurationen" text="Erstellen Sie Ihre erste Konfiguration.">
            <img src="@/assets/svg/illustration-konfiguration.svg">
          </NoContentPlaceholder>
          <AddButton @click="onAdd" />
        </div>
      </template>
      <template #modal>
        <AddConfigurationSheet ref="addConfigurationSheet" @configuration:add="onSubmitConfiguration"/>
        <BottomSheet ref="deleteConfigurationSheet">
          <template #bottom-sheet-title>
            <slot name="bottom-sheet-title">Konfiguration löschen</slot>
          </template>
          <template #bottom-sheet-content>
            <slot name="bottom-sheet-content">
              <img v-if="configurationToBeDeleted" class="thumb" :src="attachments[configurationToBeDeleted.renderingAttachmentId]">
              <div v-if="configurationToBeDeleted" class="title"><h5>{{  configurationToBeDeleted.title }}</h5></div>
            </slot>
          </template>
          <template #bottom-sheet-actions>
            <button class="button primary login tall" @click="doDeleteConfiguration()">
              <span>Löschen</span>
            </button>
            <button class="plain" @click="onCancelDeleteConfiguration()">
              <span>Abbrechen</span>
            </button>
          </template>
        </BottomSheet>
        <BottomSheet ref="renameConfigurationSheet">
          <template #bottom-sheet-title>
            <slot name="bottom-sheet-title">Konfiguration umbenennen</slot>
          </template>
          <template #bottom-sheet-content>
            <slot name="bottom-sheet-content">
              <TextInput
                v-if="titleAfterRenaming != null"
                label="Neuer Name"
                placeholder="Bitte geben Sie einen Namen ein"
                v-model="titleAfterRenaming"
                :errorMessage="renamingError"
                :showsErrorOutline="renamingError"
                :showErrors="renamingError" />
            </slot>
          </template>
          <template #bottom-sheet-actions>
            <button :disabled="renameButtonDisabled" class="button primary login tall" @click="doRenameConfiguration()">
              <span>Umbenennen</span>
            </button>
            <button class="plain" @click="onCancelRenameConfiguration()">
              <span>Abbrechen</span>
            </button>
          </template>
        </BottomSheet>
        <BottomSheet ref="duplicateConfigurationSheet">
          <template #bottom-sheet-title>
            <slot name="bottom-sheet-title">Konfiguration duplizieren</slot>
          </template>
          <template #bottom-sheet-content>
            <slot name="bottom-sheet-content">
              <div class="duplicate-name-fields" v-for="(dup, index) in configurationsToBeDuplicated" v-bind:key="index">
                <TextInput
                  placeholder="Name des Duplikates"
                  v-model="dup.configuration.title"
                  :errorMessage="dup.error"
                  :showsErrorOutline="dup.error"
                  :showErrors="dup.error" />
                <MinusIcon class="minus-icon" v-if="index > 0 || (index > 0 && index != configurationsToBeDuplicated.length - 1)" @click="removeDuplicate(index)" />
                <PlusIcon class="plus-icon" v-if="index == 0" @click="addDuplicate()" />
              </div>
            </slot>
          </template>
          <template #bottom-sheet-actions>
            <button :disabled="duplicateButtonDisabled" class="button primary login tall" @click="doDuplicateConfiguration()">
              <span>Duplizieren</span>
            </button>
            <button class="plain" @click="onCancelDuplicateConfiguration()">
              <span>Abbrechen</span>
            </button>
          </template>
        </BottomSheet>
      </template>
    </AppLayout>
</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"
import AddConfigurationSheet from "@/components/modal/AddConfigurationSheet.vue"
import Configurations from "@/components/configuration/Configurations.vue"
import AddButton from "@/components/buttons/AddButton.vue"
import NoContentPlaceholder from "@/components/NoContentPlaceholder.vue"
import BottomSheet from "@/components/modal/BottomSheet.vue";
import { AttachmentsMixin, TextInput, PlusIcon, MinusIcon } from "@profineberater/configurator-clientlib"
import { nanoid } from 'nanoid'

export default {
  name: "NotesPage",
  mixins: [AttachmentsMixin],
  components: {
    AppLayout,
    AddConfigurationSheet,
    Configurations,
    AddButton,
    NoContentPlaceholder,
    BottomSheet,
    TextInput,
    PlusIcon,
    MinusIcon
  },
  data() {
    return {
      newConfiguration: null,
      configurationToBeDeleted: null,
      configurationToBeRenamed: null,
      configurationsToBeDuplicated: [],
      duplicationTemplate: null,
      renamingError: null,
      titleAfterRenaming: null,
    };
  },
  computed: {
    project() {
      return this.$store.getters["project/currentProject"]
    },
    itemCount() {
      return this.project?.configurations?.length || 0
    },
    duplicateButtonDisabled() {
      return this.configurationsToBeDuplicated.filter( dup => dup.configuration.title ).length != this.configurationsToBeDuplicated.length
    },
    renameButtonDisabled() {
      return !(this.titleAfterRenaming?.length > 0)
    }
  },
  mounted() {
    this.loadAttachments()
  },
  unmounted() {
    this.disposeAttachments()
  },
  methods: {
    loadAttachments() {
      this.$store.dispatch("project/getConfigurationThumbnailURLs", { project: this.project })
      .then( urlMap => {
        Object.keys(urlMap).forEach( id => {
          this.attachmentIds.add(id)
        })
      })
    },
    onAdd() {
      this.$refs.addConfigurationSheet.present()
    },
    addConfiguration(newConfiguration) {
      if (!this.project.configurations) {
        this.project.configurations = []
      }
      newConfiguration.positionNumber = this.$store.getters["project/nextConfigurationPositionNumber"](this.project)
      this.project.configurations.push(newConfiguration)
    },
    onSubmitConfiguration(newConfiguration) {
      this.addConfiguration(newConfiguration)
      this.$store.dispatch("project/update", this.project)
      this.$refs.addConfigurationSheet.dismiss()
      setTimeout( () => {
        this.onSelectConfiguration(newConfiguration)
      }, 250)
    },
    onSelectConfiguration(configuration) {
      this.$store.commit("configuration/configuration", configuration)
      this.$router.push({ path: '/configurator', query: { p: this.project._id, c: configuration._id }})
    },
    onRenameConfiguration(configuration) {
      this.configurationToBeRenamed = configuration
      this.titleAfterRenaming = configuration.title
      this.$refs.renameConfigurationSheet.present()
    },
    onDuplicateConfiguration(configuration) {
      this.configurationsToBeDuplicated = []
      this.duplicationTemplate = JSON.parse(JSON.stringify(configuration))
      this.addDuplicate()
      this.$refs.duplicateConfigurationSheet.present()
    },
    onDeleteConfiguration(configuration) {
      this.configurationToBeDeleted = configuration
      this.$refs.deleteConfigurationSheet.present()
    },
    onCancelDeleteConfiguration() {
      this.configurationToBeDeleted = null
      this.$refs.deleteConfigurationSheet.dismiss()
    },
    onCancelRenameConfiguration() {
      this.configurationToBeRenamed = null
      this.titleAfterRenaming = null
      this.$refs.renameConfigurationSheet.dismiss()
    },
    onCancelDuplicateConfiguration() {
      this.configurationsToBeDuplicated = []
      this.duplicationTemplate = null
      this.$refs.duplicateConfigurationSheet.dismiss()
    },
    addDuplicate() {
      let duplicate = JSON.parse(JSON.stringify(this.duplicationTemplate))
      delete duplicate.renderingAttachmentId
      duplicate._id = "configuration-" + nanoid(10)
      duplicate.title += ` (${this.configurationsToBeDuplicated.length + 1})`
      this.configurationsToBeDuplicated.push({
        configuration: duplicate,
        error: null
      })
    },
    removeDuplicate(index) {
      this.configurationsToBeDuplicated.splice(index, 1)
    },
    doDeleteConfiguration() {
      let idx = this.project.configurations.indexOf(this.configurationToBeDeleted)
      if (idx > -1) {
        this.project.configurations.splice(idx, 1)
      }
      this.$store.dispatch("project/update", this.project)
      this.configurationToBeDeleted = null
      this.$refs.deleteConfigurationSheet.dismiss()
    },
    doRenameConfiguration() {
      this.configurationToBeRenamed.title = this.titleAfterRenaming
      this.titleAfterRenaming = null
      this.$store.dispatch("project/update", this.project)
      this.$refs.renameConfigurationSheet.dismiss()
    },
    doDuplicateConfiguration() {
      this.configurationsToBeDuplicated.forEach(duplicate => {
        this.addConfiguration(duplicate.configuration)
      });
      this.duplicationTemplate = null
      this.$store.dispatch("project/update", this.project)
      this.configurationsToBeDuplicated = []
      this.$refs.duplicateConfigurationSheet.dismiss()
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      window.$localContentDB.get("configurationOptions").then( doc => {
        console.log("DOC", doc)
      })
      vm.$store.dispatch('configuration/resetConfiguration')
      let action = vm.$store.getters["ui/pendingAction"]
      vm.$store.commit("ui/pendingAction", null)
      if (action == "add") {
        setTimeout( () => {
          vm.onAdd()
        }, 500)

      }
    })
    return true
  },
};
</script>

<style scoped>
.configurations-page.no-content {
  height: 80%;
}
.thumb {
  max-height: 25vw;
  cursor: pointer;
}
.title {
  margin-top: 20px;
}
.duplicate-name-fields {
  display: flex;
  width: 100%;
  align-items: center;
}
.duplicate-name-fields > *:first-child {
  flex-grow: 1;
  width: 100%;
  margin-right: 10px;
}
.minus-icon, .plus-icon {
  transform: translateY(-10px)
}
</style>