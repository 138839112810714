<template>
    <div class="custom-renderer base-profiles-renderer table-renderer">
      <ul data-pdfmake="{'margin': [-12, 0, 0, 0], 'markerColor': '#ffffff'}">
        <li v-if="configuration.frameExtensionTop && model.visibility.frameExtensionTop">Verbreiterung oben: {{ model.frameExtensionTop }} mm</li>
        <li v-if="configuration.frameExtensionBottom && model.visibility.frameExtensionBottom">Verbreiterung unten: {{ model.frameExtensionBottom }} mm</li>
        <li v-if="configuration.baseSingleColumnProfile && model.visibility.baseSingleColumnProfile">Bodeneinstandsprofil: {{ model.baseSingleColumnProfile }} mm</li>

        <li v-if="configuration.oddLegFrameExtensionType == 'custom' && model.visibility.oddLegFrameExtension">Fensterbankanschlussprofil: Eigenes Anschlussprofil {{ model.oddLegFrameExtension }} mm</li>
        <li v-if="configuration.oddLegFrameExtensionType != 'custom' && model.visibility.oddLegFrameExtension">Fensterbankanschlussprofil: {{ oddLegFrameExtensionType }}</li>
      </ul>
    </div>
</template>

<script>

export default {
  name: "BaseProfilesRender",
  components: {
  },
  data: function() {
    return {
      model: this.configuration
    }
  },
  props: {
    configuration: {
      type: Object
    }
  },
  computed: {
    oddLegFrameExtensionType() {
      return this.configuration.oddLegFrameExtensionType === 'none' ? 'Ohne' : this.configuration.oddLegFrameExtensionType
    }
  },
  methods: {
    showInConfigurationTable(config) {
      return config.frameExtensionTop || config.baseSingleColumnProfile || config.oddLegFrameExtension || config.frameExtensionBottom
    }
  }
}
</script>
