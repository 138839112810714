<template>
  <AppLayout class="full-width configurator">
    <template #right-navigation-item>
      <MoreNavigationItem @click="onContextMenu" />
    </template>
    <template #navigation-title class="project-title">
      {{ configuration.title }}
    </template>
    <template #page>
      <div class="configurator-page">
        <div class="container noselect">
          <div class="main">
            <div id="lilgui"></div>
            <div class="grid">
              <div class="preview">
                <div ref="stepNavigation" class="step-navigation">
                  <transition name="fadestepnavi">
                    <ConfigurationStepNavigation class="step-navigation-text" v-show="!showSummary" />
                  </transition>
                  <transition name="fadestepnavi">
                    <ConfigurationStepNavigation class="step-navigation-numbers" :numbersOnly="true" v-show="!showSummary" />
                  </transition>
                </div>
                <div class="configurator-messages">
                  <div class="info-box info-box error" v-show="hasErrors">
                    <ConfigurationMessages :messages="configuration.errors" type="error" exclude="parts|layoutDimensions" title="Hinweis" class="messages"/>
                    <ConfigurationMessages :messages="configuration.errors" type="error" include="parts|layoutDimensions" title="Layout" class="messages"/>
                  </div>
                  <div class="info-box info-box warning" v-show="hasWarnings">
                    <ConfigurationMessages :messages="configuration.warnings" type="warning" title="Warnungen:" class="messages"/>
                  </div>
                </div>
                <div class="preview-3d">
                  <WinView ref="winView">
                    <template #actions>
                      <button type="button" :class="'summary button rounded primary' + (showSummary ? ' active' : '')" @click="onShowSummary">{{ !showSummary ? 'Zur Zusammenfassung' : 'Zurück' }}</button>
                    </template>
                  </WinView>
                </div>
              </div>
              <ConfigurationProcess
                class="config-process"
                :showSummary="showSummary"
                @category:showdetailview="onShowDetails"
                @step:selectindex="onSelectStepByIndex"
                @summary:hide="showSummary=false"
                @summary:show="showSummary=true"
                @item:addcustom="addCustomItem"
                @winview:switchcameratoshowoutside="this.$refs.winView.switchCameraToShowOutside()"
                @winview:switchcameratoshowinside="this.$refs.winView.switchCameraToShowInside()"
                />
            </div>
          </div>
        </div>
        <BottomSheet ref="contextMenuBottomSheet" :showTitle="bottomSheetContent == 'contextMenu'"
          @sheet:presented="onBottomSheetDidPresent"
          @sheet:dismissed="onBottomSheetDidDismiss">
          <!-- Shows either CMS content or context menu in bottom sheet -->
          <template #bottom-sheet-content>
            <ProductDetails v-if="bottomSheetContent == 'details'" :category="currentCategory" />
            <div v-else>
              <h2 class="sheet-headline">Konfiguration</h2>
              <div class="configuration-properties">
                <TitleAndPositionInput v-model="configurationProperties" :validationResult="editPropertiesValidates" />
              </div>
            </div>
          </template>
          <!-- Shows context menu actions in bottom sheet -->
          <template v-if="bottomSheetContent != 'details'" #bottom-sheet-actions>
            <button :disabled="!editPropertiesValidates.validates" class="button primary" @click.stop="onAcceptEditProperties">Übernehmen</button>
            <button
              class="plain"
              @click.stop="onCancelEditProperties">
              Abbrechen
            </button>
          </template>
        </BottomSheet>

        <BottomSheet ref="addItemBottomSheet" class="add-item-sheet" :showTitle="false">
          <template #bottom-sheet-content>
            <AddItemForm v-model="pendingCustomItemToAdd" />
          </template>
          <template #bottom-sheet-actions>
            <button :disabled="!customItemValidates" class="button primary" @click.stop="onSubmitCustomItem">Übernehmen</button>
            <button
              class="plain"
              :disabled="!customItemValidates"
              @click.stop="onResetCustomItem">
              Zurücksetzen
            </button>
          </template>
        </BottomSheet>
      </div>
    </template>
  </AppLayout>

</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"

import BottomSheet from "@/components/modal/BottomSheet.vue"
import ProductDetails from "@/components/modal/ProductDetails.vue"
import ConfigurationProcess from "@/components/configuration/ConfigurationProcess.vue"
import ConfigurationMessages from "@/components/configuration/ConfigurationMessages.vue"
import WinView from "@/components/winview/WinView.vue"
import ConfigurationStepNavigation from "@/components/configuration/ConfigurationStepNavigation.vue"

import MoreNavigationItem from "@/components/navigationBar/MoreNavigationItem.vue"

import { ModalKeys } from "@/store/ui.module";

import AddItemForm from "@/components/configuration/AddItemForm.vue"

//import ToggleButton from "@/components/buttons/ToggleButton.vue"

import { AttachmentsMixin } from "@profineberater/configurator-clientlib"
import TitleAndPositionInput from '@/components/modal/TitleAndPositionInput.vue'

const bottomSheetContents = Object.freeze({
  CONTEXTMENU: "contextMenu",
  DETAILS: "details"
})

export default {
  name: "ConfiguratorPage",
  mixins: [AttachmentsMixin],
  props: {
    uuid: String,
  },
  data: function() {
    return {
      bottomSheetContent: bottomSheetContents.CONTEXTMENU,
      currentCategory: null,
      pendingCustomItemToAdd: {
        configurationKey: null,
        name: undefined,
        itemNumber: undefined,
        description: undefined,
      },
      saveOnBottomSheetDismiss: false,
      lastScrollTop: 0,
      showSummary: false,
      configurationProperties: {
        title: null,
        positionNumber: null
      },
    }
  },
  components: {
    AppLayout,
    BottomSheet,
    ProductDetails,
    ConfigurationProcess,
    ConfigurationMessages,
    WinView,
    ConfigurationStepNavigation,
    MoreNavigationItem,
    //ToggleButton,
    AddItemForm,
    TitleAndPositionInput
  },
  created() {
    this.MODAL_NONE = ModalKeys.NONE;
    this.MODAL_DRAWING_PAD = ModalKeys.DRAWING_PAD;
    this.MODAL_NOTES = ModalKeys.NOTES;
    this.MODAL_ITEMS = ModalKeys.ITEMS;
    this.$store.state.ui.currentModalKey = ModalKeys.ITEMS
    this.$store.dispatch("user/fetchAssortment")
  },
  computed: {
    project() {
      return this.$store.getters["project/currentProject"]
    },
    configuration() {
      return this.$store.getters["configuration/configuration"]
    },
    configurationOptions() {
      return this.$store.getters["configurationOptions/options"]
    },
    customItemValidates() {
      return this.pendingCustomItemToAdd?.name?.length >= 1
    },
    currentStepKey() {
      return this.$store.state.configurationProcess.currentStepKey
    },
    hasErrors() {
      return Object.keys(this.configuration.errors).length > 0
    },
    hasWarnings() {
      return Object.keys(this.configuration.warnings).length > 0
    },
    editPropertiesValidates() {
      let props = this.configurationProperties
      if (props.positionNumber) {
        if (Math.round(props.positionNumber / 10) * 10 != props.positionNumber) {
          return {
            validates: false,
            positionNumber: {
              validates: false,
              message: "Bitte vergeben Sie Positionsnummern für Konfigurationen in Zehnerschritten."
            }
          }
        }
        if (this.configuration.positionNumber &&
            this.configuration.positionNumber != props.positionNumber &&
            this.$store.getters["project/doesConfigurationPositionNumberExist"](this.project, props.positionNumber))
        {
          return {
            validates: false,
            positionNumber: {
              validates: false,
              message: "Die Positionsnummer ist bereits vergeben."
            }
          }
        }
      }
      return {
        validates: true,
        positionNumber: {
          validates: true,
          message: ""
        }
      }
    },
  },
  watch: {
    currentStepKey: {
      immediate: true,
      handler: function() {
        this.$nextTick( () => {
          setTimeout( () => {
            document.querySelector(".page").scrollTop = 0
            const cfgEl = document.querySelector(".configuration")
            if (cfgEl) cfgEl.scrollTop = 0
          }, 100)
        })
      }
    },
    configuration: {
      deep: true,
      immediate: true,
      handler: function() {
        let scrollTop = this.lastScrollTop
        this.$nextTick( () => {
          setTimeout( () => {
            const cfgEl = document.querySelector(".configuration")
            if (cfgEl) cfgEl.scrollTop = scrollTop
          }, 100)
        })
      }
    }
  },
  methods: {
    onScroll() {
      this.lastScrollTop = document.querySelector(".configuration").scrollTop
    },
    onContextMenu() {
      this.bottomSheetContent = bottomSheetContents.CONTEXTMENU
      this.configurationProperties = {
        title: this.configuration.title,
        positionNumber: this.configuration.positionNumber
      },
      this.$refs.contextMenuBottomSheet.present()
    },
    onShowDetails(category) {
      this.bottomSheetContent = bottomSheetContents.DETAILS
      this.currentCategory = category
      this.$refs.contextMenuBottomSheet.present()
    },
    onDelete() {
      this.$store.dispatch("project/deleteConfiguration", {
        project: this.project,
        configuration: this.configuration
      }).then( () => {
        this.$router.go(-1)
      })
    },
    addCustomItem(item, category) {
      this.currentCategory = category
      let existingCustomProperties = this.$store.getters["configuration/customPropertiesForKey"](item)
      this.pendingCustomItemToAdd.id = item
      this.pendingCustomItemToAdd.configurationKey = category.configurationKey
      this.pendingCustomItemToAdd.name = existingCustomProperties?.name
      this.pendingCustomItemToAdd.itemNumber = existingCustomProperties?.itemNumber
      this.pendingCustomItemToAdd.description = existingCustomProperties?.description
      this.$refs.addItemBottomSheet.present()
    },
    onSubmitCustomItem() {
      this.$store.commit("configuration/" + this.currentCategory.configurationKey, this.pendingCustomItemToAdd.id)
      this.$store.commit("configuration/customProperty", this.pendingCustomItemToAdd)
      this.$refs.addItemBottomSheet.dismiss()
    },
    onResetCustomItem() {
      // let defaultsKey = (this.configuration.element || 'fenster / fenstertür') + ',' + (this.configuration.layout || 'einteilig')
      // let defaultConfig = this.$store.getters["configurationOptions/defaultConfigurationForKey"](defaultsKey)
      // let defaultValue = defaultConfig?.[this.currentCategory.configurationKey] || null
      this.$store.commit("configuration/" + this.currentCategory.configurationKey, null)
      this.$store.commit("configuration/removeCustomProperty", this.pendingCustomItemToAdd.id)
      this.$refs.addItemBottomSheet.dismiss()
    },
    onShowSummary() {
      this.showSummary = !this.showSummary
    },
    saveSnapshot(project) {
      console.log("saving snapshot")
      let pngData = this.$refs.winView?.pngSnapshot()
      let blob = new Blob([pngData], {type: "image/png"})

      return this.$store.dispatch("db/saveBlobToDB", {
          document: project,
          blob: blob,
          attachmentId: this.configuration.renderingAttachmentId,
          mimetype: "image/png"
      })
    },
    saveConfig() {
      let attachmentId = "configuration_winview_" + this.configuration._id
      this.configuration.renderingAttachmentId = attachmentId
      let idx = this.project.configurations.findIndex( c => c._id == this.configuration._id)
      if (idx > -1) {
        this.project.configurations[idx] = this.configuration
      }
      return this.$store.dispatch("project/update", this.project)
    },
    onBottomSheetDidPresent() {
      this.saveOnBottomSheetDismiss = false
    },
    onBottomSheetDidDismiss() {
      if (this.saveOnBottomSheetDismiss) {
        this.saveConfig()
      }
    },
    onSelectStepByIndex(idx) {
      this.showSummary = false
      this.$store.commit("configurationProcess/selectStepByIndex", idx)
    },

    onAcceptEditProperties() {
      this.$refs.contextMenuBottomSheet.dismiss()
      this.configuration.title = this.configurationProperties.title
      this.configuration.positionNumber = this.configurationProperties.positionNumber
    },
    onCancelEditProperties() {
      this.$refs.contextMenuBottomSheet.dismiss()
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.commit("configurationProcess/resetCurrentStepKey")
    })
  },
  beforeRouteLeave (to, from, next) {
    if (to.path == "/login") {
      next()
    } else {
      this.saveConfig().then( (updatedProject) => {
        this.saveSnapshot(updatedProject).then( () => {
          next()
        })
      })
    }
  },
  mounted() {
    // if (!this.$store.getters["db/contentDbAvailable"]) {
    //   this.$router.push("/configurations")
    // }
    this.loadConfigurationOptionsAttachments()
    document.querySelector(".configuration")?.addEventListener("scroll", this.onScroll)
  },
  unmounted() {
    this.disposeAttachments()
    document.querySelector(".configuration")?.removeEventListener("scroll", this.onScroll)
  },
};
</script>

<style scoped>

.configurator-messages {
  position: absolute;
  display: block;
  top: 60px;
  left: 50%;
  right: 50%;
  width: 100%;
  z-index: 9999;
  max-width: 80%;
  transform: translate(-50%, 0);
  padding: 10px 20px;
}
:deep(.configurator-messages .info-box) {
  margin: 0 0 20px 0;
}
.step-navigation {
  width: 100%;
  display: flex;
  height: 44px;
}
.configurator {
  position: relative;
}
.container {
  width: 100%;
  height: 100vh;
}
.main {
  display: flex;
  flex-direction: column;
}

.preview {
  text-align: left;
  padding: 0;
  position: relative;
}
.preview-title {
  font-size: 28px;
  font-weight: 300;
  height: 80px;
  display: flex;
  align-items: left;
  justify-content: flex-end;
  flex-direction: column;
}

.project-title {
  font-size: 14px;
}
.configuration-properties {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.configuration-properties-input {
  width: 90%;
}

/* Transitions */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-in;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

:deep(.add-item-sheet .sheet-content) {
  padding: 0 40px 10px 40px;
}

:deep(button.min-width) {
  min-width: 80px;
}
.step-navigation-text {
  display: none;
}

button.summary {
  width: 135px;
  transition: width 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.summary.active {
  width: 40px;
  transition: width 0.25s ease-in-out;
}

h2.sheet-headline {
  margin-bottom: 60px;
}
/* Transitions */
.fadestepnavi-leave-active {
  transition: all 0.25s ease-in-out;
}

.fadestepnavi-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.30s;
}

.fadestepnavi-enter-from,
.fadestepnavi-leave-to {
  opacity: 0;
}

:deep(.sheet-content) {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 0;
}

@media only screen and (max-width : 1023px) {
  :deep(.sheet-content) {
    padding: 10px;
  }
}

@media only screen and (min-width : 768px) {
  .step-navigation-text {
    display: inline-block;
  }
  .step-navigation-numbers {
    display: none;
  }
}

@media only screen and (max-width : 1024px) {
  .preview-3d {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width : 1024px) {

  .configurator-page {
    height: calc(100vh - var(--navigation-bar-height));
    overflow: hidden;
  }
  .grid {
    width: unset;
    display: grid;
    grid-template-columns: 1fr 428px;
    gap: 0px 0px;
    grid-template-areas:
      ". .";
    height: calc(100vh - var(--navigation-bar-height));
    overflow: hidden;
  }
  .config-process {
    overflow: auto;
    overscroll-behavior: contain;
  }
  .actions {
    margin-top: 20px;
  }
}

@media only screen and (min-width : 1400px) {
  .configurator-messages {
    max-width: 50%;
  }
}

</style>
