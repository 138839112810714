<template>
  <div class="edit-project-form">
    <form>

      <h5>Projekt</h5>
      <table class="ios-style">
        <tr>
          <td class="noselect" @click="$refs.projectNumber.focus()">Projektnummer</td>
          <td><TextInput ref="projectNumber" v-model="project.customer.projectNumber" /></td>
        </tr>
      </table>

      <h5>Lieferadresse</h5>
      <AddressForm v-model="project.customer" />

      <h5>Kontakt</h5>
      <table class="ios-style">
        <tr>
          <td class="noselect" @click="$refs.phone.focus()">Telefon</td>
          <td><TextInput ref="phone" v-model="project.customer.phone" /></td>
        </tr>
        <tr>
          <td class="noselect" @click="$refs.email.focus()">E-Mail</td>
          <td><TextInput ref="email" v-model="project.customer.email" /></td>
        </tr>
      </table>
      <div class="toggle-invoice-address">
        <span>Rechnungsadresse identisch mit Lieferadresse</span>
        <div class="check"><input type="checkbox" :checked="!project.invoiceAddressEnabled" @change="onChangeInvoiceAddressEnabled"></div>
      </div>
      <h5 :class="!project.invoiceAddressEnabled ? 'disabled' : ''">Rechnungsadresse</h5>
      <AddressForm :disabled="!project.invoiceAddressEnabled" v-model="project.invoiceAddress" />

      <!-- Delete project option moved into configurator-admin -->
      <a v-show="false" :class="'delete-project no-underline thick noselect' + (isDeleteProjectDisabled ? ' disabled' : '')" @click="$emit('project:delete')">Projekt löschen</a>
    </form>
  </div>
</template>

<script>

import { TextInput } from "@profineberater/configurator-clientlib"
import { useModelWrapper } from '@/modelWrapper.js'
import AddressForm from "./AddressForm.vue"

export default {
  name: "EditProjectForm",
  props: {
    modelValue: {
      type: Object
    }
  },
  setup(props, { emit }) {
    return {
      project: useModelWrapper(props, emit, 'modelValue'),
    }
  },
  methods: {
    onChangeInvoiceAddressEnabled(e) {
      this.project.invoiceAddressEnabled = !e.target.checked
    }
  },
  computed: {
    isDeleteProjectDisabled() {
      return this.$store.getters["team/isProjectCheckedOut"](this.project)
    }
  },
  components: {
    TextInput,
    AddressForm,
  },
};
</script>

<style scoped>
table {
  margin-bottom: 40px;
}
a.delete-project {
  display: block;
  margin-bottom: 40px;
}
h5 {
  text-align: left;
  margin-bottom: 20px;
  margin-left: 10px;
}
h5.disabled {
  opacity: 0.5;
}
input[type=checkbox] {
  transform: translateY(1.5px);
  margin-left: 10px;
}
.toggle-invoice-address {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: right;
  width: 100%;
  margin-bottom: 10px;
}
.toggle-invoice-address span {
  flex-grow: 1;
}
.toggle-invoice-address .check {
  min-width: 20px;
  max-width: 20px;
  flex-basis: 20px;
  margin-right: 4px;
}
/*
@media only screen and (max-width : 1280px) {
  .toggle-invoice-address {
    padding-bottom;
  }
} */
</style>