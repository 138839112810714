<template>
  <div class="page-section-header">
    <h6>{{ title }}</h6>
    <a v-if="showAction && actionTitle" @click="onActionClick">{{ actionTitle }}</a>
  </div>
</template>

<script>

export default {
  name: "PageSectionHeader",
  props: {
    title: {
      type: String
    },
    actionTitle: {
      type: String
    },
    showAction: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onActionClick(e) {
      this.$emit("action:click", e)
    }
  }
};
</script>

<style>
.page-section-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 20px 0;
}
.page-section-header h6 {
  position: relative;
  padding: 0 0 0 15px;
  margin-left: 10px;
}
.page-section-header h6::before {
  content: " ";
  position: absolute;
  top: 4px;
  left: 0px;
  width: 6px;
  height: 6px;
  background: var(--primary-color);
}
.page-section-header a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}

</style>