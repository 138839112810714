<template>
  <div class="tos-page">
    <AppLayout>
      <template #page>
        <HtmlDocument :htmlDocumentKey="htmlDocumentKey" :showVersionNumber="false"/>
      </template>
    </AppLayout>
  </div>
</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"

import {
  HTML_DOCUMENT_KEYS,
  HtmlDocument
} from "@profineberater/configurator-clientlib"

export default {
  name: 'ImprintPage',
  components: {
    AppLayout,
    HtmlDocument
  },
  computed: {
    htmlDocumentKey() {
      return HTML_DOCUMENT_KEYS.IMPRINT
    }
  }
}
</script>
