<template>
  <div class="configurations">
    <section>
      <PageSectionHeader title="Zuletzt erstellt" />
      <SimpleCard :class="'configuration-card' + (isAllOptionsAvailable(configuration) ? '' : ' invalid options-missing')"
        v-for="(configuration, index) in configurations" v-bind:key="index"
        @click="$emit('configuration:select', configuration)"
        :data-testid="configuration._id">
        <div ref="moreAction" class="more-action" @click.stop="onMoreActionClick($event, configuration, index)">
          <DotsIcon class="dots" />
        </div>
        <div class="config">
          <div class="thumb">
            <img v-if="attachments[configuration.renderingAttachmentId]" :src="attachments[configuration.renderingAttachmentId]">
            <img v-else src="~@/assets/img/configuration/placeholder.png">
            <div class="badge" v-if="!isAllOptionsAvailable(configuration)">
              <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="enable-background:new 0 0 24 24" viewBox="0 0 24 24"><path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z" style="fill:#facd37"/><path d="M12 1a10.925 10.925 0 0 0-7.778 3.222C2.144 6.299 1 9.062 1 12s1.144 5.701 3.222 7.778C6.299 21.856 9.062 23 12 23s5.701-1.144 7.778-3.222C21.856 17.701 23 14.938 23 12s-1.144-5.701-3.222-7.778A10.925 10.925 0 0 0 12 1m0-1c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z" style="opacity:.03"/><path d="M12 7.282v5.642" style="fill:none;stroke:#fff;stroke-width:2;stroke-linecap:round"/><path d="M12 16.71v.008" style="fill:none;stroke:#fff;stroke-width:2.5;stroke-linecap:round"/></svg>
            </div>
          </div>
          <div class="description">
            <h5><span class="position" v-if="configuration.positionNumber">{{ configuration.positionNumber }}.</span> {{ configuration.title }}</h5>
            <ul>
              <li>{{ titleFromMetadata(configuration.element, "Kein Element ausgewählt") }}</li>
              <li>{{ configuration.innerWidth }} x {{ configuration.innerHeight }} mm</li>
              <li>{{ openingDescription(configuration, "Keine Öffnungsart ausgewählt") }}</li>
            </ul>
          </div>
        </div>
      </SimpleCard>
    </section>
  </div>
</template>

<script>

import PageSectionHeader from "@/components/page/PageSectionHeader.vue"
import MetadataMixin from "@/mixins/MetadataMixin.vue"
import { AttachmentsMixin, DuplicateIcon, SimpleCard, DeleteIcon } from "@profineberater/configurator-clientlib"
import DotsIcon from "@/components/ui/DotsIcon.vue"
import EditIcon from "@/components/ui/EditIcon.vue"
import { markRaw } from "vue"

export default {
  name: 'Configurations',
  components: {
    SimpleCard,
    PageSectionHeader,
    DotsIcon
  },
  mixins: [MetadataMixin, AttachmentsMixin],
  props: {
    project: {
      type: Object
    },
  },
  methods: {
    loadAttachments() {
      this.$store.dispatch("project/getConfigurationThumbnailURLs", { project: this.project })
      .then( urlMap => {
        Object.keys(urlMap).forEach( id => {
          this.attachmentIds.add(id)
        })
      })
    },
    isAllOptionsAvailable(configuration) {
      return this.$store.getters["configuration/isAllOptionsAvailable"](configuration)
    },
    onMoreActionClick(e, configuration, index) {
      if (this.isContextMenuActive) {
        this.$store.commit("ui/contextMenuActive", false)
        return
      }

      let rect = this.$refs.moreAction[index].getBoundingClientRect()
      let height = document.body.offsetHeight
      let y = rect.top
      let x = Math.max(10, rect.left - 160);
      if (y > height / 2) {
        y -= 150
      } else {
        y += 40
      }

      this.$store.commit("ui/contextMenuState", {
        active: true,
        containerEl: this.$el,
        top: y + "px",
        left: x + "px",
        menuItems: [
          {
            title: "Umbenennen",
            handler: () => {
              this.$emit('configuration:rename', configuration)
            },
            iconComponent: markRaw(EditIcon)
          },
          {
            title: "Duplizieren",
            handler: () => {
              this.$emit('configuration:duplicate', configuration)
            },
            iconComponent: markRaw(DuplicateIcon)
          },
          {
            title: "Löschen",
            handler: () => {
              this.$emit('configuration:delete', configuration)
            },
            iconComponent: markRaw(DeleteIcon)
          }
        ]
      })
    }
  },
  mounted() {
    this.loadAttachments()
  },
  unmounted() {
    this.disposeAttachments()
  },
  computed: {
    configurations() {
      return this.project?.configurations.slice().reverse() || []
    },
    isContextMenuActive() {
      return this.$store.getters["ui/contextMenuState"]?.active
    }
  },
  watch: {
    configurations() {
      this.loadAttachments()
    }
  },
}
</script>

<style scoped>

  .configurations section {
    text-align: left;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .configuration-card {
    width: calc(50% - 20px);
    margin-right: 20px;
    position: relative;
  }
  .configuration-card:nth-child(odd) {
    margin-right: 0;
  }
  .invalid.options-missing {
    background-color: #eff1f6;
    box-shadow: none;
  }

  .simple-card.invalid.options-missing .config .thumb, .simple-card.invalid.options-missing .delete-icon {
    mix-blend-mode: multiply;
  }
:deep(.context-menu .delete-icon) {
  color: #fff;
  stroke: #fff;
}
.config {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
h5 {
  text-align: left;
}
.thumb {
  width: 120px;
  height: 120px;
  margin-right: 20px;
  position: relative;
}
.thumb img {
  width: 120px;
  height: 120px;
  border: 1px solid #eff0f2;
  border-radius: 7px;
}
.thumb .badge {
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
}
ul {
  list-style-type: none;
  color: var(--text-color);
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
  margin-top: 10px;
}
:deep(.simple-card) {
  margin-bottom: 20px;
}
span.position {
  color: var(--text-color);
}

.delete-action {
  position: absolute;
  right: 10px;
  top: 10px;
}
.more-action {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 40px;
  height: 40px;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dots {
  transition: color 0.25s ease-in-out;
}

@media only screen and (max-width : 1024px) {
  .configuration-card {
    width: calc(100%);
    margin-right: 0;
  }
}

</style>

