<template>
    <div></div>
</template>

<script>

export default {
  name: 'LayoutDimensionsMixin',
  methods: {
    labelForPart(part) {
      switch (part) {
        case "HOL": return "Höhe Oberlicht"
        case "HUL": return "Höhe Unterlicht"
        case "B": return "Rahmenbreite"
        case "H": return "Rahmenhöhe"
        case "BL": return "Breite links"
        case "BLM": return "Breite Mitte links"
        case "BM": return "Breite Mitte"
        case "BRM": return "Breite Mitte rechts"
        case "BR": return "Breite rechts"
        default: return part;
      }
    }
  }
}

</script>

