<template>
    <AppLayout>
      <template #right-navigation-item>
        <button type="button" class="print-pdf button rounded primary" @click="onDownloadPDF">Download</button>
      </template>
      <template #page>
        <div class="container">
          <div id="pdfhtml" class="pdf-page">
            <section class="configurations">
              <div class="customer-wrapper">
                <p class="company pdf-only" v-if="company">
                  <span data-pdfmake="{'bold': true}" v-if="company.companyName">{{ company.companyName }}</span><br>
                  <span v-if="company.streetAndNumber">{{ company.streetAndNumber }}<br></span>
                  <span v-if="company.zip">{{ company.zip }}</span> <span v-if="company.city">{{ company.city }}<br></span>
                  <span v-if="company.phone">{{ company.phone }}<br></span>
                  <span v-if="company.email">{{ company.email }}</span>
                </p>
                <h3 class="summary-title" data-pdfmake="{'fontSize': '16', 'color': '#000000', 'margin': [0, 20, 0, 20]}">Ihre Konfiguration</h3>
                <table class="project-summary" data-pdfmake="{'layout': 'lightHorizontalLines', 'widths': [ '*', '*' ]}">
                  <tr class="pdf-only">
                    <th colspan="2">Kontaktdaten</th>
                  </tr>
                  <tr v-if="customer.projectNumber">
                    <td class="left customer-details-title" data-pdfmake="{'bold': true}">Projektnummer</td>
                    <td class="right">{{ customer.projectNumber }}</td>
                  </tr>
                  <tr>
                    <td class="left customer-details-title" data-pdfmake="{'bold': true}">Lieferadresse</td>
                    <td class="right">
                      {{ customer.firstName }} {{ customer.lastName }}<br>
                      {{ customer.streetAndNumber }}<br>
                      {{ customer.zip }} {{ customer.city }}
                    </td>
                  </tr>
                  <tr v-if="customer.phone || customer.email">
                    <td class="left customer-details-title" data-pdfmake="{'bold': true}">Kontakt</td>
                    <td class="right">
                      {{ customer.phone }}<br>
                      {{ customer.email }}
                    </td>
                  </tr>
                  <tr v-if="project.invoiceAddressEnabled && project.invoiceAddress">
                    <td v-if="hasInvoiceAddress" class="left customer-details-title" data-pdfmake="{'bold': true}">Rechnungsadresse</td>
                    <td class="right">
                      <span v-if="project.invoiceAddress.firstName || project.invoiceAddress.lastName">{{ project.invoiceAddress.firstName }} {{ project.invoiceAddress.lastName }}<br></span>
                      <span v-if="project.invoiceAddress.streetAndNumber">{{ project.invoiceAddress.streetAndNumber }}<br></span>
                      <span v-if="project.invoiceAddress.zip || project.invoiceAddress.city">{{ project.invoiceAddress.zip }} {{ project.invoiceAddress.city }}</span>
                    </td>
                  </tr>
                </table>
                <hr class="short-hr" data-pdfmake="{'color': '#ffffff', 'margin': [0, 10, 0, 10]}">
              </div>
              <h2 v-if="configurations.length" data-pdfmake="{'fontSize': '18', 'margin': [0, 10, 0, 10]}" class="photos-title">Elemente</h2>
              <h3 v-else>Es sind keine Konfigurationen vorhanden</h3>
              <!-- <h1 data-pdfmake="{'fontSize': '18', 'margin': [0, 10, 0, 10]}">Konfigurationen</h1> -->
              <div v-for="(configuration, index) in configurations" v-bind:key="index">
                <!-- <div :class="index > 0 ? 'pdf-pagebreak-before' : ''"><span class="position-number-pdf-only" v-if="configuration.positionNumber" data-pdfmake="{'fontSize': '16', 'bold': true}">{{ configuration.positionNumber }}. </span>&nbsp;</div> -->

                <h2 data-pdfmake="{'fontSize': '16', 'margin': [0, 10, 0, 10], 'alignment': 'left'}" class="configuration-title"><span v-if="configuration.positionNumber" class="position-number-screen-only">{{ configuration.positionNumber }}. </span>{{ configuration.title }}</h2>

                <table data-pdfmake="{'layout': 'lightHorizontalLines', 'widths': [ '*', '*' ]}">
                  <tr class="pdf-only">
                    <th colspan="2" data-pdfmake="{ 'bold': true }">Zusammenfassung</th>
                  </tr>
                  <tr>
                    <td class="three-rendering">
                      <img data-pdfmake="{'width': 128, 'height': 128, 'margin': [0, 20, 0, 0]}" class="configuration-rendering" v-if="attachments[configuration.renderingAttachmentId]" :src="attachments[configuration.renderingAttachmentId]">
                    </td>
                    <td>
                      <p class="pdf-only" data-pdfmake="{'bold': 'true', 'margin': [0, 10, 0, 0]}">
                        {{ titleFromMetadata(configuration.element, "Kein Element ausgewählt") }}<br>
                        {{ configuration.elementDimensions }} mm<br>
                        {{ openingDescription(configuration, "Keine Öffnungsart ausgewählt") }}
                      </p>
                    </td>
                  </tr>
                </table>

                <div class="summary-abstract">
                  <h6 data-pdfmake="{'fontSize': '1', 'color': '#ffffff'}">{{ titleFromMetadata(configuration.element, "Kein Element ausgewählt") }}</h6>
                  <h6 data-pdfmake="{'fontSize': '1', 'color': '#ffffff'}">{{ configuration.elementDimensions }} mm</h6>
                  <h6 data-pdfmake="{'fontSize': '1', 'color': '#ffffff'}">{{ openingDescription(configuration, "Keine Öffnungsart ausgewählt") }}</h6>
                </div>


                <ConfigurationTable :configuration="configuration" :showEditLinks="false"></ConfigurationTable>
              </div>
            </section>
            <!-- Notizen -->
            <section class="notes" v-if="notes.length">
              <div class="pdf-pagebreak-before">&nbsp;</div>
              <h2 data-pdfmake="{'fontSize': '18', 'margin': [0, 10, 0, 10]}" class="photos-title">Notizen</h2>
              <div class="note" v-for="(note, index) in notes" v-bind:key="index">
                <span class="position-number-pdf-only" v-if="note.positionNumber" data-pdfmake="{'fontSize': '16', 'bold': true}">{{ note.positionNumber }}. </span>
                <span class="no-pagebreak-before note-title" data-pdfmake="{'fontSize': '14', 'bold': true, 'margin': [0, 20, 0, 0]}">{{ note.title }} <span v-if="note.positionNumber" class="position-number-screen-only">({{ note.positionNumber }})</span></span>
                <p v-html="noteContent(note)"></p>
              </div>
            </section>
            <hr v-if="notes.length" class="short-hr" data-pdfmake="{'color': '#ffffff', 'margin': [0, 10, 0, 10]}">
            <!-- Scribbles -->
            <section class="scribbles" v-if="scribbles.length">
              <div class="pdf-pagebreak-before">&nbsp;</div>
              <h2 data-pdfmake="{'fontSize': '18', 'margin': [0, 10, 0, 10]}" class="scribbles-title">Zeichnungen</h2>
              <div v-for="(scribble, index) in scribbles" v-bind:key="index">
                <div v-if="index > 0" class="pdf-pagebreak-before">&nbsp;</div>
                <span class="position-number-pdf-only" v-if="scribble.positionNumber" data-pdfmake="{'fontSize': '16', 'bold': true}">{{ scribble.positionNumber }}. </span>
                <img v-if="attachments[scribble.originalAttachmentId]" :src="attachments[scribble.originalAttachmentId]" data-pdfmake="{'fit': [400, 400]}" />
                <h6>{{ scribble.title }} <span v-if="scribble.positionNumber" class="position-number-screen-only">({{ scribble.positionNumber }})</span> vom <span class="date" v-html="createdAt(scribble)"></span></h6>
              </div>
            </section>
            <hr v-if="scribbles.length" class="short-hr" data-pdfmake="{'color': '#ffffff', 'margin': [0, 10, 0, 10]}">
            <!-- Fotos -->
            <section class="photos" v-if="photos.length">
              <div class="pdf-pagebreak-before">&nbsp;</div>
              <h2 data-pdfmake="{'fontSize': '18', 'margin': [0, 10, 0, 10]}" class="photos-title">Fotos</h2>
              <div v-for="(photo, index) in photos" v-bind:key="index">
                <div v-if="index > 0" class="pdf-pagebreak-before">&nbsp;</div>
                <span class="position-number-pdf-only" v-if="photo.positionNumber" data-pdfmake="{'fontSize': '16', 'bold': true, 'margin': [0, 10, 0, 10]}">{{ photo.positionNumber }}. </span>
                <img v-if="attachments[this.getPhotoAttachmentId(photo)]" v-bind:key="index" :src="attachments[this.getPhotoAttachmentId(photo)]" data-pdfmake="{'fit': [400, 400]}" />
                <h6 class="photo-title" data-pdfmake="{'fontSize': '14', 'margin': [0, 10, 0, 10]}"><span>{{ photo.title }}<span v-if="photo.positionNumber" class="position-number-screen-only"> ({{ photo.positionNumber }})</span></span></h6>
              </div>
            </section>
            <hr v-if="photos.length" class="short-hr" data-pdfmake="{'color': '#ffffff', 'margin': [0, 10, 0, 10]}">
            <footer data-pdfmake="{'margin': [0, 20, 0, 40], 'color': '#677b92'}">
              Diese Konfiguration wurde mit der Framework App der profine eSolutions GmbH erstellt. Der Nutzer dieser App ist für die korrekte Angabe der Maße, Farben und
              Ausführungen selbst verantwortlich. Wir beraten nicht zu Maßen und übernehmen für Messfehler aufgrund fehlerhafter Angaben keine Haftung. Das Risiko
              über unpassende Fenstermaße trägt allein der Nutzer mit seinen Angaben, die er an uns übermittelt.
            </footer>
          </div>
        </div>
      </template>
    </AppLayout>
</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"
import PDFRenderer from '@/pdfrenderer.js';
import ConfigurationTable from '@/components/configuration/summary/ConfigurationTable.vue'
import MetadataMixin from "../mixins/MetadataMixin.vue";
import { AttachmentsMixin } from "@profineberater/configurator-clientlib"
import DateMixin from "@/mixins/DateMixin.vue"

export default {
  name: "PdfPage",
  mixins: [MetadataMixin, AttachmentsMixin, DateMixin],
  components: {
    AppLayout,
    ConfigurationTable,
  },
  created() {
    this.$store.dispatch("user/fetchProfile")
  },
  computed: {
    company() {
      let team = this.$store.getters["team/team"]
      if (team) {
        return team.company
      }
      return this.profile?.user
    },
    project() {
      return this.$store.getters["project/currentProject"]
    },
    scribbles() {
      return this.project?.scribbles || []
    },
    photos() {
      return this.project?.photos || []
    },
    notes() {
      return this.project?.notes || []
    },
    customer() {
      return this.project?.customer
    },
    configurations() {
      return this.project?.configurations || []
    },
    profile() {
      return this.$store.getters["user/profile"]
    },
    hasInvoiceAddress() {
      return Object.values(this.project.invoiceAddress || []).filter( v => v ).length > 0
    },
  },
  methods: {
    onContextMenu() {
    },
    noteContent(note) {
      return (note.content || "").replaceAll("\n", "<br>")
    },
    onDownloadPDF() {
      //let filename = this.project.title.split(/[^a-zA-Z0-9\-_äöüÄÖÜß]/gi).join('_');
      let filename = "Projekt "
      if (this.customer.projectNumber) {
        filename += this.customer.projectNumber + " "
      }
      filename += (this.customer.firstName || '') + " " + (this.customer.lastName || '')
      filename = filename.replaceAll(/[^a-zA-Z0-9\-_äöüÄÖÜß]/gi, '_')
      return this.createPDF(this.project).download(filename);
    },
    createPDF() {
      let renderer = new PDFRenderer({});

      let pdfTarget = document.getElementById("pdfhtml");
      // ref="pdfTarget" doesn't work on a div inside <template #page>
      //let pdfTarget = this.$refs.pdfTarget
      let html = pdfTarget ? pdfTarget.outerHTML : "no pdfTarget";
      let reDataSet = /data-pdfmake="(.*?)"/ // replace outer double quotes with single quotes
      let reHR = /<hr.*?>/gm

      var attr
      var match = html.match(reDataSet)
      while (match?.length) {
        attr = match[0]
        attr = attr.replaceAll("'", "\"")
        attr = attr.replaceAll("\"{", "'{")
        attr = attr.replaceAll("}\"", "}'")
        html = html.replace(reDataSet, `${attr}`)
        match = html.match(reDataSet)
      }

      html = html.replace(reHR, "")

      let pdf = renderer.createPDF({
        project: this.project,
        html: html,
        link: window.location.href,
        user: this.profile.user,
      });
      return pdf;
    },
    onOpenPDF() {
      return this.createPDF(this.project).open();
    },
    loadAttachments() {
      this.$store.dispatch("project/getConfigurationThumbnailURLs", { project: this.project })
      .then( urlMap => {
        Object.keys(urlMap).forEach( id => {
          this.attachmentIds.add(id)
        })
      })
      this.$store.dispatch("project/getScribbleURLs", { project: this.project })
      .then( urlMap => {
        Object.keys(urlMap).forEach( id => {
          this.attachmentIds.add(id)
        })
      })
      this.$store.dispatch("project/getPhotoURLs", { project: this.project })
      .then( urlMap => {
        Object.keys(urlMap).forEach( id => {
          this.attachmentIds.add(id)
        })
      })
    },
  },
  mounted() {
    this.loadAttachments()
  },
  unmounted() {
    this.disposeAttachments()
  },
}
</script>

<style>
  .pdf-page hr {
    border: 1px solid #edeff4;
    margin: 0 20px 20px 20px;

  }
  .pdf-page ul {
    list-style-type: none;
  }
  .pdf-page .customer-info {
    margin-top: 40px;
  }
  .pdf-page footer {
    color: var(--text-color);
    font-size: 14px;
  }
  .pdf-page button {
    overflow: unset;
  }
  .pdf-page .configuration-rendering {
  }
  .pdf-page section {
    margin: 0;
  }
  .pdf-page section:last-of-type {
    margin: 0;
  }
  .pdf-page img {
    max-width: 75vw;
    max-height: 50vh;
  }
  .pdf-page section {
    padding: 0;
  }
  .pdf-page span.position-number-pdf-only {
    display: none;
  }
  .pdf-page span.position-number-screen-only {

  }

  .pdf-only {
    display: none;
  }
</style>
<style scoped>
  .container {
    display: flex;
    justify-content: center;
  }
  :deep(.navigation-bar .right) {
    flex-grow: 1;
  }
  :deep(.navigation-bar .right button) {
    margin-right: 0;
  }
  .customer-hr {
    margin: 40px 0 0 0;
  }

  .summary-abstract {
    color: var(--text-color);
    margin-bottom: 40px;
  }
  h2.configuration-title {
    margin: 20px 0 0 0;
  }
  hr.short-hr {
    margin: 20px 25%;
  }
  h6 {
    font-weight: 400;
    color: var(--text-color);
    font-size: 14px;
  }
  h3.page-title {
    color: var(--text-color);
    text-align: center;
  }
  section.scribbles, section.photos {
    margin-bottom: 40px;
  }
  h2.scribbles-title, h2.photos-title, h2.notes-title {
    margin-bottom: 40px;
  }
  .note {
    margin: 20px 0;
  }
  .note-title {
    font-weight: 500;
  }
  .photo-title {
    margin: 20px 0;
  }
  button.button.print-pdf {
    padding: 0 20px;
  }

  h5.customer-details, h5.project-details {
    margin: 10px 0;
  }
  td.customer-details-title {
    text-align: left;
    font-weight: 500;
    width: 100px;
  }
  table {
    border-spacing: 0;
    padding: 40px 20px 20px 20px;
    font-size: 12px;
    line-height: 1.5;
    width: 100%;
  }
  table.project-summary td:first-child {
    width: 50%;
  }
  table.project-summary td:last-child {
    color: var(--text-color);
  }
  td {
    vertical-align: top;
    text-align: left;
  }
  td.three-rendering {
    text-align: center;
  }

  @media only screen and (min-width : 1280px) {
    .pdf-page {
      width: 65%;
    }
}
</style>