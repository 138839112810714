<template>
    <div class="custom-selector extensions-selector">
      <SelectorTile>
        <template #figure>
          <img src="@/assets/svg/isometric-extensions.svg">
        </template>
        <template #form>
          <div class="input-fields">
            <NumberInput data-testid="frameExtensionLeft" v-model="model.frameExtensionLeft" label="Links" placeholder="0 mm" unit="mm" :min="0" :max="500"/>
            <NumberInput data-testid="frameExtensionRight" v-model="model.frameExtensionRight" label="Rechts" placeholder="0 mm" unit="mm" :min="0" :max="500"/>
          </div>
        </template>
      </SelectorTile>
    </div>
</template>

<script>
import SelectorTile from "@/components/configuration/SelectorTile.vue";
import { NumberInput } from "@profineberater/configurator-clientlib"

export default {
  name: "ExtensionsSelector",
  components: {
    SelectorTile,
    NumberInput,
  },
  data: function() {
    return {
      model: this.configuration
    }
  },
  props: {
    configuration: {
      type: Object
    }
  },
};
</script>

<style scoped>
.extensions-selector {
  display: flex;
  flex-direction: column;
}
.input-fields {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.input-fields > * {
  margin-right: 10px;
  width: 100%;
}
.input-fields > *:last-child {
  margin-right: 0;
}
</style>

