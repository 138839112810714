<template>
    <AppLayout>
      <template #navigation-title>
        Fehler bei der Anmeldung
      </template>
      <template #page>
        <div class="auth-error-page">
          Bei der Anmeldung ist ein Fehler aufgetreten.<br>
          <a class="logout no-underline thick noselect" @click="onLoginClick">Zurück zum Login</a>
        </div>
      </template>
    </AppLayout>
</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"

export default {
  name: "AuthErrorPage",
  components: {
    AppLayout,
  },
  methods: {
    onLoginClick() {
      this.$router.push("/login")
    },
  },
}
</script>
