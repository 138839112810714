<template>
  <div class="add-configuration-sheet">
    <BottomSheet ref="bottomSheet" :isExpanded="isExpanded" @sheet:dismissed="sheetDidDismiss">
      <template #bottom-sheet-top-bar-item-left v-if="step > 0"><div @click="onCancelStep">Abbrechen</div></template>
      <template #bottom-sheet-title>
        <span v-if="step==0">Neue Konfiguration</span>
        <span v-if="step==1 && templateType=='blank'">Leere Vorlagen</span>
        <span v-if="step==1 && templateType=='own'">Eigene Vorlagen</span>
      </template>
      <template #bottom-sheet-content>
        <div v-if="step == 0" class="select-template-type-step">
          <div>
            <div class="template-type" data-testid="blank" @click="onSelectTemplateType('blank')">
              <div class="icon">
                <img src="@/assets/svg/config-template-type-blank.svg">
              </div>
              <div>
                <h5>Leere Vorlagen</h5>
                <span>Standard Konfiguration</span>
              </div>
            </div>
            <div class="template-type" data-testid="own" @click="onSelectTemplateType('own')">
              <div class="icon">
                <img width="74" src="@/assets/img/config-template-type-own.png">
              </div>
              <div>
                <h5>Eigene Vorlagen</h5>
                <span>Eigene Konfiguration</span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="step == 1">
          <TextInput v-if="templateType == 'own'" v-model="searchTerm" placeholder="Suchen" :showClearButton="true" />
          <div class="grid">
            <SimpleTile v-for="(template, index) in templates" v-bind:key="index" :data-testid="template.element" @click="onSelectTemplate(template)">
              <template #image>
                <img v-if="template.img" :src="template.img"/>
                <img v-else src="~@/assets/img/configuration/placeholder.png">
              </template>
              <template #title>
                <h6 v-html="template.title"></h6>
                <span class="subtitle" v-html="template.subtitle"></span>
              </template>
            </SimpleTile>
          </div>
        </div>

        <div v-if="step == 2" class="large-tile">
          <SimpleTile class="no-hover">
            <template #image>
              <img :src="template.img"/>
            </template>
            <template #title>
              <h6 v-html="template.title"></h6>
              <span class="subtitle" v-html="template.subtitle"></span>
            </template>
          </SimpleTile>
          <TextInput class="configuration-name" v-model="name" placeholder="Wohnzimmer" />
        </div>

      </template>
      <template #bottom-sheet-actions v-if="step == 2">
          <button :disabled="!isAddButtonEnabled" class="button primary min-width" @click.stop="onSubmit">Hinzufügen</button>
      </template>
    </BottomSheet>
  </div>
</template>

<script>

import BottomSheet from "@/components/modal/BottomSheet.vue"
import SimpleTile from "@/components/tiles/SimpleTile.vue"

import { TextInput, AttachmentsMixin } from "@profineberater/configurator-clientlib"
import { nanoid } from 'nanoid'

export default {
  name: "AddConfigurationSheet",
  mixins: [AttachmentsMixin],
  components: {
    BottomSheet,
    SimpleTile,
    TextInput
  },
  data: function() {
    return {
      newConfiguration: null,
      step: 0,
      templateType: null,
      template: null,
      name: "",
      searchTerm: "",
      emptyTemplates: [
      {
        element: "fenster / fenstertür",
        title: "Fenster &amp; Fenstertür",
        subtitle: "Leere Vorlage",
        img: require('@/assets/svg/configuration-template-fenster.svg'),
        configuration: this.$store.getters["configurationOptions/defaultConfigurationForKey"]("fenster / fenstertür,einteilig")
      },
      {
        element: "fenstertür mit schwelle",
        title: "Fenstertür mit Schwelle",
        subtitle: "Leere Vorlage",
        img: require('@/assets/svg/configuration-template-fenstertuer-schwelle.svg'),
        configuration: this.$store.getters["configurationOptions/defaultConfigurationForKey"]("fenstertür mit schwelle,einteilig")
      },
      {
        element: "haustür",
        title: "Haustür",
        subtitle: "Leere Vorlage",
        img: require('@/assets/svg/configuration-template-haustuer.svg'),
        configuration: this.$store.getters["configurationOptions/defaultConfigurationForKey"]("haustür,einteilig")
      },
      {
        element: "hebeschiebetür",
        title: "Hebeschiebetür",
        subtitle: "Leere Vorlage",
        img: require('@/assets/svg/configuration-template-hebeschiebetuer.svg'),
        configuration: this.$store.getters["configurationOptions/defaultConfigurationForKey"]("hebeschiebetür,zweiteilig")
      }
      ]
    }
  },
  computed: {
    isExpanded() {
      return this.step > 0
    },
    isAddButtonEnabled() {
      return this.step == 2 && this.name.length
    },
    projects() {
      return this.$store.state.project.projects
    },
    attachments() {
      return this.$store.getters["db/attachments"]
    },
    configurations() {
      let configurations = []
      this.projects.forEach( p => {
        this.$store.dispatch("project/getConfigurationThumbnailURLs", {
          project: p,
        }).then( urlMap => {
          Object.keys(urlMap).forEach( id => {
            this.attachmentIds.add(id)
          })
        })
        configurations = configurations.concat( p.configurations )
      });
      return configurations
    },
    disabledOptions() {
      return this.$store.getters["user/disabledOptions"]
    },
    filteredEmptyTemplates() {
      let filtered = []
      this.emptyTemplates.forEach( template => {
        if (this.disabledOptions?.['element']?.[template.element] !== true) {
          filtered.push(template)
        }
      })
      return filtered
    },
    templates() {
      if (this.templateType == 'blank') {
        return this.filteredEmptyTemplates
      }
      let templates = this.configurations.filter( c => c?.element != undefined ).map( c => {
        let description = (this.$store.getters["configurationOptions/metadataForKey"](c.element))?.title || ""
        return {
          element: c.element,
          configuration: c,
          title: c.title,
          subtitle: description,
          img: this.attachments[c.renderingAttachmentId],
        }
      })
      templates = templates.filter( config => {
        return this.$store.getters["configuration/isAllOptionsAvailable"](config)
      })
      if (this.searchTerm.length) {
        templates = templates.filter( t => t.title.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 )
      }
      return templates
    }
  },
  methods: {
    onSelectTemplateType(type) {
      this.templateType = type
      this.step = 1
    },
    onSelectTemplate(template) {
      this.template = template
      this.step = 2
    },
    onCancelStep() {
      this.step = 0
      this.name = ""
      this.template = null
    },
    onSubmit() {
      if (this.template.configuration) {
        let id = this.newConfiguration._id
        this.newConfiguration = JSON.parse(JSON.stringify(this.template.configuration))
        this.newConfiguration._id = id
      } else {
        this.newConfiguration.element = this.template.element
      }

      this.newConfiguration.title = this.name
      this.$emit("configuration:add", this.newConfiguration)
      this.step = 0
    },
    present() {
      this.$refs.bottomSheet.present()
    },
    dismiss() {
      this.$refs.bottomSheet.dismiss()
    },
    sheetDidDismiss() {
      this.onCancelStep()
    }
  },
  created() {
    this.$store.dispatch("project/getAll")
    this.newConfiguration = JSON.parse(JSON.stringify(this.$store.getters["configuration/initialConfiguration"]))
    this.newConfiguration._id = "configuration-" + nanoid(10)
  }
};
</script>

<style scoped>
.template-type {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: var(--page-bg-color);
  border-radius: 7px;
  padding: 20px;
  text-align: left;
  cursor: pointer;
  margin-bottom: 20px;
  border: 1px solid transparent;
}
.template-type:last-child {
  margin-bottom: 0;
}
.template-type:hover {
  border: 1px solid var(--accent-color);
}
.icon {
  margin: 0 40px;
  width: 60px;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
}
.subtitle {
  font-size: 12px;
  color: var(--text-color);
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  grid-auto-rows: 1fr;
  gap: 20px;
}
.grid::before {
  content: '';
  width: 0;
  padding-bottom: 100%;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.grid > *:first-child {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}
/*
:deep(.simple-tile) {
  aspect-ratio: 1;
} */




.large-tile {
  display: flex;
  flex-direction: column;
  aspect-ratio: unset;
  border: 1px solid transparent;
}

:deep(.large-tile > *) {
  flex-grow: 1;
  aspect-ratio: unset;
}

:deep(.large-tile .image) {
  aspect-ratio: unset;
}

:deep(.simple-tile .image) {
  border: 1px solid transparent;
}

:deep(.simple-tile .image:hover) {
  border: 1px solid var(--accent-color);
}

:deep(.simple-tile.no-hover .image:hover) {
  border: 1px solid transparent;
}
img {
  max-width: 128px;
  max-height: 128px;
}

.configuration-name {
  margin-top: 20px;
}
@media only screen and (min-width : 1024px) {
  .grid {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

</style>