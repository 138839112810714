<template>
    <div class="custom-selector base-profiles-selector">
      <SelectorTile>
        <template #figure>
          <img src="@/assets/svg/infografik-aufbauprofile.svg">
        </template>
        <template #form>
          <div class="input-fields">
            <NumberInput data-testid="frameExtensionTop" v-model="model.frameExtensionTop" v-if="model.visibility.frameExtensionTop" label="Verbreiterung oben" placeholder="0 mm" unit="mm" :min="0" :max="500"/>
            <NumberInput data-testid="frameExtensionBottom" v-model="model.frameExtensionBottom"  v-if="model.visibility.frameExtensionBottom" label="Verbreiterung unten" placeholder="0 mm" unit="mm" :min="0" :max="500"/>
            <NumberInput data-testid="baseSingleColumnProfile" v-model="model.baseSingleColumnProfile"  v-if="model.visibility.baseSingleColumnProfile" label="Bodeneinstandsprofil" placeholder="0 mm" unit="mm" :min="0" :max="200"/>

            <label v-if="model.visibility.oddLegFrameExtension">Fensterbankanschlussprofil</label>
            <DropDown
              v-if="model.visibility.oddLegFrameExtension"
              data-testid="oddLegFrameExtensionType"
              placeholder="Bitte wählen"
              :options="oddLegFrameExtensionOptions"
              v-model="model.oddLegFrameExtensionType"
              :allowNoSelection="false"
              @update:modelValue="onChangeOddLegFrameExtensionType"
            />

            <NumberInput
              v-if="model.visibility.oddLegFrameExtension && model.oddLegFrameExtensionType === 'custom'"
              v-model="model.oddLegFrameExtension"
              data-testid="oddLegFrameExtension"
              placeholder="0 mm"
              unit="mm"
              :min="0"
              :max="200"/>
          </div>
        </template>
      </SelectorTile>
    </div>
</template>

<script>
import SelectorTile from "@/components/configuration/SelectorTile.vue";
import { DropDown, NumberInput } from "@profineberater/configurator-clientlib"

export default {
  name: "BaseProfilesSelector",
  components: {
    SelectorTile,
    NumberInput,
    DropDown,
  },
  data: function() {
    return {
      model: this.configuration
    }
  },
  props: {
    configuration: {
      type: Object
    }
  },
  computed: {
    oddLegFrameExtensionOptions() {
      return [
        {
          label: "Ohne",
          value: "none",
        },
        {
          label: "76717 Neubau 35 mm",
          value: "76717 Neubau 35 mm",
        },
        {
          label: "76751 Renovierung 30 mm",
          value: "76751 Renovierung 30 mm",
        },
        {
          label: "76752 Renovierung 50 mm",
          value: "76752 Renovierung 50 mm",
        },
        {
          label: "Eigenes Anschlussprofil",
          value: "custom",
        },
      ]
    },
  },
  methods: {
    onChangeOddLegFrameExtensionType(value) {
      switch (value) {
        case "76717 Neubau 35 mm":
          this.model.oddLegFrameExtension = 35
          break
        case "76751 Renovierung 30 mm":
          this.model.oddLegFrameExtension = 30
          break
        case "76752 Renovierung 50 mm":
          this.model.oddLegFrameExtension = 50
          break
        case "custom":
          this.model.oddLegFrameExtension = 30
          break
        case "none":
          this.model.oddLegFrameExtension = 0
          break
      }
    }
  },
}
</script>

<style scoped>
.base-profiles-selector {
  display: flex;
  flex-direction: column;
}
</style>

