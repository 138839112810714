<template>
    <div class="isometric-element">
      <div class="frame" v-html="table?.svg"></div>
    </div>
</template>

<script>
export default {
  name: 'IsometricElement',
  props: {
    table: {
      type: Object
    }
  }
}
</script>

<style scoped>
.isometric-element {
  color: rgb(22, 130, 243);
}

.frame, .frame svg {
  width: 220px;
  height: 200px;
}
</style>

