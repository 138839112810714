export default class DevUtils {

  static randomDataOfType(type) {
    console.log("randomDataOfType")
    let db = window.$localTestdataDB
    var randomId
    return new Promise((resolve, reject) => {
      db.find({
        selector: {
          type: type
        },
        fields: ['_id'],
      }).then(function (result) {
        randomId = Math.min(result.docs.length, Math.floor(Math.random() * result.docs.length))
        if (randomId > 1000) {
          console.log("Random ID should not be > 1000. Most likely the testdata DB has been recreated and replicated on top of the existing local testdata. You should destroy your local testdata db and reload: window.$localTestdataDB.destroy()")
          randomId = Math.floor(Math.random() * 1000)
        }
        db.find({
          selector: {
            type: type,
            int_id: randomId
          },
        }).then(function (result) {
          if (!result.docs[0]) {
            throw new Error("No document of type customer found for int_id", randomId)
          }
          resolve(result.docs[0])
        }).catch(function (err) {
          reject(err)
        })
      })
    })
  }

  static randomUser() {
    return this.randomDataOfType("customer")
  }
  static randomScribble() {
    return this.randomDataOfType("scribble")
  }
  static randomPhoto() {
    return this.randomDataOfType("photo")
  }
}