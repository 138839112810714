<template>
  <div class="tos-page">
    <AppLayout>
      <template #page>
        <TosDocument />
      </template>
    </AppLayout>
  </div>
</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"
import {
  TosDocument
} from "@profineberater/configurator-clientlib"

export default {
  name: 'TosPage',
  components: {
    AppLayout,
    TosDocument
  },
}
</script>
