<template>
  <div class="add-project-sheet">
  <BottomSheet ref="bottomSheet">
    <template #bottom-sheet-title>
      <slot name="title"></slot>
    </template>
    <template #bottom-sheet-content class="padding-40">
      <a v-if="$store.getters['ui/testDataEnabled']" class="randomize" @click="onRandomize">Mit Zufallsdaten ausfüllen</a>
      <AddCustomerForm v-if="validator" v-model="project.customer" :validator="validator" :validationModel="validationModel" @field:change="onFormFieldChange" />
    </template>
    <template #bottom-sheet-actions>
        <button class="button primary min-width" @click.stop="onSubmit" :disabled="!validationModel.validates">{{ saveButtonTitle }}</button>
    </template>
  </BottomSheet>
  </div>
</template>

<script>

import { useModelWrapper } from '@/modelWrapper.js'
import BottomSheet from "@/components/modal/BottomSheet.vue"
import AddCustomerForm from "@/components/projects/AddCustomerForm.vue"
import { CustomerFormValidator } from '@profineberater/configurator-clientlib';
import { reactive, defineComponent } from "vue";

export default defineComponent({
  name: "AddCustomerSheet",
  props: {
    modelValue: {
      type: Object
    },
    saveButtonTitle: {
      type: String,
      default: "Speichern"
    }
  },
  setup(props, { emit }) {
    return {
      project: useModelWrapper(props, emit, 'modelValue'),
      validatorInstance: null,
      validationModel: reactive({
        firstName: {},
        lastName: {},
        streetAndNumber: {},
        zip: {},
        city: {},
        email: {},
        phone: {},
      }),
    }
  },
  components: {
    BottomSheet,
    AddCustomerForm
  },
  methods: {
    onRandomize() {
      this.$emit('randomize')
      setTimeout( () => {
        this.validator.markAllDirty()
        this.validator.validate(this.customer)
      }, 200)
    },
    onSubmit() {
      this.$emit("customer:save", this.modelValue)
    },
    present() {
      this.validator.reset()
      this.$refs.bottomSheet.present()
    },
    dismiss() {
      this.$refs.bottomSheet.dismiss()
    },
    onFormFieldChange(key) {
      this.validator.validate(this.customer, {
        [key]: {
          dirty: true
        }
      })
    }
  },
  computed: {
    customer() {
      return this.project.customer
    },
    validator() {
      return this.validatorInstance ? this.validatorInstance : new CustomerFormValidator(this.validationModel)
    },
  }
})
</script>

<style scoped>
a.randomize {
  display: inline-block;
  margin-bottom: 20px;
}
</style>