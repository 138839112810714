<template>
  <div :class="clazz" v-if="renderedMessageKeys.length">
      <h5 v-if="title" class="title">{{ title }}</h5>
      <div :class="'message ' + type" v-for="messageKey in renderedMessageKeys" v-bind:key="messageKey">
        {{messages?.[messageKey]}}
      </div>
  </div>
</template>

<script>

export default {
  name: 'ConfigurationMessages',
  props: {
    messages: {
      type: Object,
    },
    include: {
      type: String,
      default: undefined,
    },
    exclude: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'error'
    },
  },
  data: function() {
    return { }
  },
  computed: {
    renderedMessageKeys() {
      let keys = Object.keys(this.messages || {}).sort()

      if(this.include) {
        let re = new RegExp(this.include);
        keys = keys.filter(k => re.test(k))
      }

      if(this.exclude) {
        let re = new RegExp(this.exclude);
        keys = keys.filter(k => !re.test(k))
      }

      return keys
    },
    clazz() {
      return "messages" + (this.renderedMessageKeys.length ? " shows-message" : "")
    }
  },

}
</script>

<style scoped>

h5.title {
  display: block;
  padding: 0;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 0;
  padding-top: 0;
}
.message {
  display: block;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 10px;
}

.error {

}
.warning {
  color: var(--warning-color);
}

</style>

