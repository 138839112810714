<template>
  <div class="configuration-header">
    <h2>
      <slot name="title"></slot>
    </h2>
    <p class="abstract">
      <slot name="abstract"></slot>
    </p>
  </div>
</template>

<script>

export default {
  name: 'ConfigurationHeader',
}
</script>

<style scoped>
h2 {
  text-align: center;
  margin: 0 0 20px 0;
}
p.abstract {
  margin: 0 0 40px 0;
  font-weight: 300;
  padding: 0 20px;
  line-height: 1.5;
}
</style>

