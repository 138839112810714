<template>
    <div v-if="hasWindowRails" class="custom-renderer table-renderer window-rails-layout-renderer">
      {{ titleForKey(configuration.windowRailsLayoutType) }}
      <ul data-pdfmake="{'margin': [-12, 0, 0, 0], 'markerColor': '#ffffff'}">
        <li v-if="canSetTopPadding(windowRailsLayoutBasePath)">Abstand von oben: {{ configuration.windowRailsTopPadding }} mm</li>
        <li v-if="canSetHorizontalCount(windowRailsLayoutBasePath) && configuration.windowRailsHorizontalCount" >Sprossen Horizontal: {{ configuration.windowRailsHorizontalCount }}</li>
        <li v-if="canSetVerticalCount(windowRailsLayoutBasePath) && configuration.windowRailsVerticalCount" >Sprossen Vertikal: {{ configuration.windowRailsVerticalCount }}</li>
      </ul>
    </div>
</template>

<script>

import WindowRailsLayoutMixin from "./WindowRailsLayoutMixin.vue"

export default {
  name: "WindowRailsLayoutRenderer",
  mixins: [WindowRailsLayoutMixin],
  props: {
    configuration: {
      type: Object
    }
  },
  methods: {
    metadataForKey(key) {
      return this.$store.getters["configurationOptions/metadataForKey"](key)
    },
    titleForKey(key) {
      return this.metadataForKey(key)?.title || "???" + key
    },
  },
  computed: {
    windowRailsLayoutBasePath() {
      if (!this.configuration.windowRailsType || !this.configuration.windowRailsLayoutType) return undefined
      return `windowRailsType.${this.configuration.windowRailsType}.windowRailsLayoutType.${this.configuration.windowRailsLayoutType}`
    },
    hasWindowRails() {
      return !this.metadataForKey(this.configuration.windowRailsType)?.isNone
    },
  }
}
</script>

<style scoped>
.window-rails-layout-renderer {
}

</style>
