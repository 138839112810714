<template>
    <div class="selector-tile">
        <div class="image" :style="style"><slot name="figure"></slot></div>
        <div class="form">
          <slot name="form"></slot>
        </div>
    </div>
</template>

<script>

export default {
  name: 'SelectorTile',
  props: {
      imageUrl: {
        type: String
      },
      selected: {
        type: Boolean
      }
  },
  methods: {
  },
  computed: {
    style() {
      let s = {}
      if (this.imageUrl) {
        s.background = "url(" + this.imageUrl + ")"
      }
      return s
    },
  }
}
</script>

<style scoped>
.selector-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  cursor: pointer;
  background: #fff;
  box-shadow: var(--default-box-shadow);
  border-radius: 7px;
}

.image {
  position: absolute;
  top: -80px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 220px;
  height: 220px;
  /* border: 1px solid #dadada; */
  /* background: #F8F8F9; */
  background-size: contain !important;
  background-position: 50% 50% !important;
  background-repeat: no-repeat !important;
  /* transform: perspective(400px) rotateY(20deg); */
}

.form {
  margin: 200px 0 40px 0;
  width: 100%;
  padding: 0 20px;
}
:deep(.form .text-input input) {
  background: #F6F7F9;
}
:deep(.form .dropdown) {
  background: #F6F7F9;
  border-radius: 7px;
  width: 100%;
}

</style>

