<template>
  <div :class="'entire-screen' + (this.isPresented ? ' is-presented' : '')">
    <div class="dimmer" @touchmove="onTouchMove"></div>
    <div :class="'bottom-sheet' + (isExpanded ? ' expanded' : '')">
      <div class="sheet-top-bar">
        <div class="top-bar-item-left">
          <slot name="bottom-sheet-top-bar-item-left"></slot>
        </div>
        <div class="top-bar-item-right" @click="dismiss">
          <svg class="dismiss-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><circle cx="15" cy="15" r="15" style="fill:#fff"/><path d="m11.005 11.005 7.99 7.99M18.995 11.005l-7.99 7.99" style="fill:none;stroke:#94a1b4;stroke-linecap:round;stroke-width:1.5px"/></svg>
        </div>
      </div>
      <div v-if="showTitle" class="sheet-title">
        <h3><slot name="bottom-sheet-title"></slot></h3>
      </div>
      <div class="sheet-content">
        <slot name="bottom-sheet-content"></slot>
      </div>
      <div v-if="$slots['bottom-sheet-actions']" class="sheet-actions">
        <slot name="bottom-sheet-actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: "BottomSheet",
    props: {
        showTitle: {
            type: Boolean,
            default: true
        },
        isExpanded: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            isPresented: false,
        };
    },
    methods: {
        onTouchMove(e) {
            if (this.isPresented) {
                e.preventDefault();
            }
        },
        present() {
            this.isPresented = true;
            this.$emit("sheet:presented");
        },
        dismiss() {
            this.isPresented = false;
            this.$emit("sheet:dismissed");
        },
        handleKeyUp(event) {
          if (event.key === 'Escape') {
            this.dismiss();
          }
        },
    },
    mounted() {
      window.addEventListener('keyup', this.handleKeyUp);
    },
    beforeUnmount() {
      window.removeEventListener('keyup', this.handleKeyUp);
    }
}
</script>

<style scoped>

.entire-screen.is-presented {
  pointer-events: all;
}

h3 {
  margin-bottom: 20px;
}
.bottom-sheet {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  max-height: 90%;
  box-shadow: 0 0 30px rgba(1, 21, 43, 0.56);
  background: #fff;
  border-radius: 16px;
  overflow: hidden;
  z-index: 1021;
  transition: transform 0.4s ease-in-out;
  transform: translateY(110%);
  will-change: transform;
  display: flex;
  flex-direction: column;
}

.bottom-sheet.expanded {
  height: 100vh;
}

.entire-screen.is-presented .bottom-sheet {
  bottom: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateY(0);
}

.sheet-top-bar {
  min-height: 60px;
  max-height: 60px;
}
.top-bar-item-left {
  cursor: pointer;
  color: var(--primary-color);
  position: absolute;
  top: 0;
  left: 20px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-bar-item-right {
  cursor: pointer;
  color: var(--primary-color);
  position: absolute;
  top: 0;
  right: 0;
}
.sheet-title {
  font-size: 18px;
  font-weight: 300;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-top: 67px; */
}
.sheet-content {
  padding: 40px;
  overflow: auto;
  flex-grow: 1;
  border: none;
}
.sheet-actions {
  min-height: 180px;
  max-height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 80px 20px 80px;
}
:deep(.sheet-actions button:first-child) {
  margin-bottom: 20px;
}
:deep(.sheet-actions button) {
  width: 100%;
  margin: 0 10px;
}
.dismiss-icon {
  width: 30px;
  height: 30px;
  filter: drop-shadow(0px 0px 10px rgba(102, 119, 142, 0.2));
  margin-right: 20px;
  margin-top: 20px;
}
@media only screen and (min-width : 1024px) {
  .bottom-sheet {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
    width: 50%;
    max-height: 90%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    background: #fff;
    border-radius: 16px;
    overflow: hidden;
    z-index: 1021;
    transition: transform 0.4s ease-in-out;
    transform: translateY(120%);
    will-change: transform;
    display: flex;
    flex-direction: column;
  }
  :deep(.sheet-actions button) {
    width: 100%;
    margin: 0 40px;
  }

}

</style>