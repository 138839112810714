<template>
    <div class="blinds-fixture-selector">
      <CustomConfigurationText v-model="this.$store.state.configuration.blindsFixture" placeholder="Hier bitte Details zur Ausstattung eingeben …" />
    </div>
</template>

<script>

import CustomConfigurationText from '@/components/configuration/custom/CustomConfigurationText.vue';
import SelectorMixin from '../SelectorMixin.vue';

export default {
  name: "BlindsFixtureSelector",
  components: {
    CustomConfigurationText
  },
  mixins: [SelectorMixin],
};
</script>

<style scoped>
.blinds-fixture-selector {
  margin: 0 15px;
}
</style>

