<template>
    <AppLayout>
      <template #navigation-title>
        Profil
      </template>
      <template #right-navigation-item>
        <MoreNavigationItem v-if="false" @click="onContextMenu" />
      </template>
      <template #page>
        <div class="profile-page">
          <ProfileForm ref="profileForm" />
          <TosLinks />
          <table class="backoffice ios-style">
            <tr @click="onOpenBackoffice()">
              <td class="noselect cursor-pointer">Backoffice</td>
              <td class="noselect cursor-pointer"><ArrowRight /></td>
            </tr>
          </table>
          <AppInfo />
          <a class="logout no-underline thick noselect" @click="onLogout">Ausloggen</a>
        </div>
      </template>
      <template #modal>
        <BottomSheet ref="bottomSheet">
          <template #bottom-sheet-title>
            Profile Actions
          </template>
          <template #bottom-sheet-content>
            Profile Action Content goes here...
          </template>
        </BottomSheet>
      </template>
    </AppLayout>
</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"
import MoreNavigationItem from "@/components/navigationBar/MoreNavigationItem.vue"
import BottomSheet from "@/components/modal/BottomSheet.vue"
import { ProfileForm, AppInfo, TosLinks, ArrowRight } from "@profineberater/configurator-clientlib"

export default {
  name: "ProfilePage",
  components: {
    AppLayout,
    MoreNavigationItem,
    BottomSheet,
    ProfileForm,
    AppInfo,
    TosLinks,
    ArrowRight
  },
  data() {
    return {
      lastSavedProfile: null
    }
  },
  computed: {
  },
  methods: {
    onLogout() {
      this.$router.push("/logout")
    },
    onContextMenu() {
    },
    onOpenBackoffice() {
      window.open(process.env.VUE_APP_BACKOFFICE_URL, "_blank")
    }
  },
  beforeRouteLeave (to, from, next) {
    let validationModel = this.$store.getters["ui/profileValidationModel"]
    if (validationModel.validates === false) {
      if (this.lastSavedProfile) {
        this.$store.commit("user/profile", this.lastSavedProfile)
      }
      next()
    } else {
      this.$store.dispatch("user/saveProfile").then( () => {
          next()
      }).catch( (err) => {
          console.error("Failed saving profile", err)
          next()
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.lastSavedProfile = vm.$store.getters["user/profile"]
      vm.$refs.profileForm.validateForm()
    })
  }
};
</script>

<style scoped>
.tos-details-links {
  margin-bottom: 20px;
}
.profile-page {
  padding-bottom: 40px;
}
.backoffice {
  margin-bottom: 20px;
}
</style>