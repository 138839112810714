<template>
    <AppLayout>
      <template #navigation-title>
        <h5 class="note-title" @click="$refs.contextMenuBottomSheet.present()">{{ note.title }}</h5>
      </template>
      <template #right-navigation-item>
        <MoreNavigationItem @click="$refs.contextMenuBottomSheet.present()" />
      </template>
      <template #page>
        <div id="noteeditor"></div>
      </template>
      <template #modal>
        <BottomSheet ref="contextMenuBottomSheet"
          @sheet:presented="onBottomSheetDidPresent"
          @sheet:dismissed="onBottomSheetDidDismiss">
          <template #bottom-sheet-title>
            <slot name="bottom-sheet-title">Notiz bearbeiten</slot>
          </template>
          <template #bottom-sheet-content>
            <slot name="bottom-sheet-content">
              <TitleAndPositionInput v-model="properties" />
            </slot>
          </template>
          <template #bottom-sheet-actions>
            <button class="button primary" @click.stop="onAcceptEditProperties">Übernehmen</button>
            <button
              class="plain"
              @click.stop="onCancelEditProperties">
              Abbrechen
            </button>
          </template>
        </BottomSheet>
      </template>
    </AppLayout>
</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"
import MoreNavigationItem from "@/components/navigationBar/MoreNavigationItem.vue"
import BottomSheet from "@/components/modal/BottomSheet.vue"

import TitleAndPositionInput from '@/components/modal/TitleAndPositionInput.vue'

import Quill from "quill"
import "quill/dist/quill.snow.css"

var quill

export default {
  name: "EditNotePage",
  components: {
    AppLayout,
    MoreNavigationItem,
    BottomSheet,
    TitleAndPositionInput
  },
  data() {
    return {
      saveOnLeave: true,
      properties: {
        title: null,
        positionNumber: null
      },
    }
  },
  computed: {
    project() {
      return this.$store.getters["project/currentProject"]
    },
    currentNoteIndex() {
      return this.$store.getters["project/currentNoteIndex"]
    },
    currentNote: {
      get() {
        return this.$store.getters["project/currentNote"]
      },
      set(note) {
        this.$store.commit["project/currentNote", note]
        this.saveOnBottomSheetDismiss = true
      }
    },
    note() {
      return this.project.notes[this.currentNoteIndex]
    }
  },
  methods: {
    onBottomSheetDidPresent() {
      this.properties = {
        title: this.note.title,
        positionNumber: this.note.positionNumber
      },
      this.saveOnBottomSheetDismiss = false
    },
    onBottomSheetDidDismiss() {
      if (this.saveOnBottomSheetDismiss) {
        this.saveProject()
      }
    },
    saveProject() {
      this.$store.dispatch("project/update", this.project)
    },
    onShareNote() {
    },
    onDeleteNote() {
      this.$store.dispatch("project/deleteNote", { project: this.project, noteIndex: this.note } )
      this.saveOnLeave = false
      this.$router.go(-1)
    },
    onAcceptEditProperties() {
      this.note.title = this.properties.title
      this.note.positionNumber = this.properties.positionNumber
      this.saveOnBottomSheetDismiss = true
      this.$refs.contextMenuBottomSheet.dismiss()
    },
    onCancelEditProperties() {
      this.$refs.contextMenuBottomSheet.dismiss()
    }
  },
  mounted() {
    quill = new Quill('#noteeditor', {
      modules: { toolbar: true },
      theme: 'snow'
    });
    if (this.currentNote.formattedContent != undefined) {
      quill.setContents(JSON.parse(this.currentNote.formattedContent), 'api')
    } else {
      quill.setText(JSON.parse(JSON.stringify(this.currentNote.content)), 'api')
    }
    setTimeout( () => {
      quill.focus()
    }, 750)
  },
  beforeRouteLeave (to, from, next) {
    if (this.saveOnLeave) {
      this.currentNote.content = quill.getText()
      this.currentNote.formattedContent = JSON.stringify(quill.getContents())
      this.$store.dispatch("project/update", this.project)
    }
    next()
  },
};
</script>

<style>
.note-title {
  cursor: pointer;
}
.ql-container {
  font-family: Roboto !important;
  font-size: 20px !important;
  height: unset;
}
.ql-container.ql-snow {
  border: none;
}
.ql-toolbar.ql-snow {
  border: none;
}
</style>