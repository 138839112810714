<template>
    <div class="configuration-summary">
        <ConfigurationHeader>
          <template #title>
            {{ configuration.title }}
          </template>
          <template #abstract>
            <ul class="summary-abstract">
              <li>{{ titleFromMetadata(configuration.element, "Kein Element ausgewählt") }}</li>
              <li>{{ configuration.innerWidth }} x {{ configuration.innerHeight }} mm</li>
              <li>{{ openingDescription(configuration, "Keine Öffnungsart ausgewählt") }}</li>
            </ul>
            <!-- <a class="open-pdf" @click="onOpenPDF">
              <svg version="1.1" width="14" height="14" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 10 10" xml:space="preserve"><g stroke="currentColor" fill="#ffffff"><path d="M9.499 6.036V9.5H.501V6.036"/><path d="M5.001 0v5.611"/><path d="M7.248 3.835 5 6.083 2.752 3.835"/></g></svg>
              PDF herunterladen
            </a> -->
          </template>
        </ConfigurationHeader>

        <ConfigurationTable :configuration="configuration" />

        <div class="actions">
          <button
            class="button primary min-width"
            @click.stop="onSaveConfiguration">Konfiguration speichern</button>

          <button
            class="back"
            @click.stop="onHideSummary">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 7" xml:space="preserve">
              <path fill="currentColor" d="M11 2.994H1.92L4.178.715 3.469 0 0 3.5 3.469 7l.709-.715-2.259-2.279H11z"/>
            </svg>
            Zurück zu {{ currentStep.title }}
          </button>
        </div>
    </div>
</template>

<script>

import ConfigurationHeader from "@/components/configuration/ConfigurationHeader.vue"
import ConfigurationTable from "@/components/configuration/summary/ConfigurationTable.vue"
import MetadataMixin from "@/mixins/MetadataMixin.vue"

export default {
  name: 'ConfigurationSummary',
  props: {
    configuration: {
      type: Object
    }
  },
  mixins: [MetadataMixin],
  components: {
    ConfigurationHeader,
    ConfigurationTable
  },
  methods: {
    metadata(option) {
      return this.$store.getters["configurationOptions/metadataForKey"](option)
    },
    title(option) {
      if (typeof option === 'string') {
        return this.metadata(option)?.title || "??? " + option
      } else {
        // temp solution. FIXME.
        return JSON.stringify(option, null, 2)
      }
    },
    onJumpToStep(idx) {
      this.$emit("step:selectindex", idx)
    },
    onOpenPDF() {

    },
    onSaveConfiguration() {
      this.$router.push("/configurations")
    },
    onHideSummary() {
      this.$emit("summary:hide")
    }
  },
  computed: {
    config() {
      return this.configuration || this.$store.getters["configuration/configuration"]
    },
    currentStep() {
      return this.$store.getters['configurationProcess/currentStep']
    },
  }
}
</script>

<style scoped>
.configuration-summary {
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
}
h5 {
  text-align: left;
  padding: 0 20px;
}
table {
  border-spacing: 0;
  width: 100%;
  padding: 20px 20px 40px 20px;
  font-size: 12px;
  line-height: 2;
}
td:first-child {
  text-align: left;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  font-weight: 400px;
}
td:last-child {
  text-align: right;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  color: var(--text-color);
}
hr {
  border: 1px solid #edeff4;
  margin: 0 20px 40px 20px;
}
.title {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: baseline;
}
.title > * {
  flex-grow: 1;
}
.title a {
  text-align: right;
  font-size: 12px;
  text-decoration: none;
  margin-right: 20px;
  font-weight: 500;
}
ul.summary-abstract {
  list-style-type: none;
  font-size: 14px;
  padding: 0 40px;
  font-weight: 400;
}
a.open-pdf {
  text-decoration: none;
  font-weight: 500;
  margin: 20px 0;
  display: inline-block;
}
a.open-pdf svg {
  margin-right: 10px;
}
.configuration-header hr {
  margin: 20px 0 0 0;
}

.actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  margin: 80px 0 60px 0;
}
.button {
  align-self: stretch;
}
</style>

