<template>
    <div :class="itemTileClazz" :data-option-value="model" :data-testid="model">
        <div class="bg"></div>
        <div class="badge" v-show="selected">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16.972 17.172">
              <g transform="translate(11666.949 15797.552)">
              <ellipse cx="8.486" cy="8.586" rx="8.486" ry="8.586" transform="translate(-11666.949 -15797.552)" fill="currentColor"/>
              <path d="M134.666,154.462l2,2.024,4-4.047" transform="translate(-11795.958 -15943.315)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
              </g>
          </svg>
        </div>
        <div class="badge" v-show="isNotAvailable">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="23.789" height="23.789" viewBox="0 0 23.789 23.789">
            <path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z" style="fill:#facd37"/>
            <path d="M12 1a10.925 10.925 0 0 0-7.778 3.222C2.144 6.299 1 9.062 1 12s1.144 5.701 3.222 7.778C6.299 21.856 9.062 23 12 23s5.701-1.144 7.778-3.222C21.856 17.701 23 14.938 23 12s-1.144-5.701-3.222-7.778A10.925 10.925 0 0 0 12 1m0-1c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z" style="opacity:.03"/><path d="M12 7.282v5.642" style="fill:none;stroke:#fff;stroke-width:2;stroke-linecap:round"/><path d="M12 16.71v.008" style="fill:none;stroke:#fff;stroke-width:2.5;stroke-linecap:round"/>
          </svg>
        </div>
        <div class="add-button" v-show="isCustom && !customProperties">
          <svg xmlns="http://www.w3.org/2000/svg" width="23.789" height="23.789" viewBox="0 0 23.789 23.789">
            <g transform="translate(-292.366 -1593.233)">
              <g transform="translate(304.261 1593.983)">
                <path d="M16523,10550.983v22.289" transform="translate(-16523 -10550.983)" fill="none" stroke="#1682F3" stroke-linecap="round" stroke-width="1.5"/>
              </g>
              <g transform="translate(315.405 1605.128) rotate(90)">
                <path d="M0,0V22.289" transform="translate(0)" fill="none" stroke="#1682F3" stroke-linecap="round" stroke-width="1.5"/>
              </g>
            </g>
          </svg>
        </div>
        <div v-show="isShowProductImage" class="product-image" :style="style"></div>
        <div v-if="isColor && (!isCustom || (isCustom && customProperties))" class="colorplane" :style="colorPlaneStyle"></div>
        <div v-if="model" class="product-info">
          <span v-show="!customProperties" class="title hyphens">{{ title }}</span>
          <span v-if="customProperties" class="title hyphens">{{ customProperties.name }}</span>
          <p v-if="productInfoShort" class="description truncate" v-html="productInfoShort"></p>
          <div class="debug">{{ model }}</div>
        </div>
        <div class="slot">
          <slot></slot>
        </div>
    </div>
</template>

<script>

import { AttachmentsMixin } from "@profineberater/configurator-clientlib"

export default {
  name: 'ItemTile',
  mixins: [AttachmentsMixin],
  props: {
    model: {
        type: String
      },
      selected: {
        type: Boolean
      },
      isCustom: {
        type: Boolean
      },
      isLongTile: {
        type: Boolean,
        default: false
      },
      isNotAvailable: {
        type: Boolean
      },
  },
  methods: {
  },
  computed: {
    itemTileClazz() {
      let s = 'item-tile'
      if (this.isCustom) {
        s += ' is-custom'
      }
      if (this.isColor) {
        s += ' is-color'
      }
      if (this.selected) {
        s += ' selected'
      }
      if (this.isLongTile) {
        s += ' long'
      }
      if (this.isNotAvailable) {
        s += ' not-available'
      }
      return s
    },
    isColor() {
      return this.hex != null
    },
    isShowProductImage() {
      if (this.isColor) {
        return false
      }
      if (this.isCustom) {
        return this.customProperties
      }
      return true
    },
    hex() {
      return this.metadata?.hex
    },
    metadata() {
      let metadata = this.$store.getters["configurationOptions/metadataForKey"](this.model)
      if (!metadata) {
        console.error("No metadata found for ", this.model)
      }
      return metadata
    },
    title() {
      return this.metadata?.title || "??? " + this.model
    },
    productInfoShort() {
      let s = ""
      if (this.isCustom && this.isLongTile && this.customProperties) {
        let desc = this.customProperties?.description || ""
        let itemNumber = this.customProperties?.itemNumber
        s = itemNumber || ""
        if (s.length && desc) {
          s += "<br>"
        }
        s += desc || ""
      } else {
        s = this.metadata?.productInfoShort?.replaceAll(",", "<br>") || ""
      }
      if (this.isNotAvailable) {
        s += "<br>Nicht mehr verfügbar"
      }
      return s
    },
    hasDescription() {
      return this.productInfoShort?.length
    },
    style() {
      let s = {}

      let url = this.attachments[this.attachmentIdForIdAndKey(this.model, "url")]
      if (this.metadata && this.metadata.url) {
        //s.backgroundImage = "url(" + this.metadata.url.replace('.png', '@2x.png') + ")"
        s.backgroundImage = `url(${url})`
      }
      return s
    },
    colorPlaneStyle() {
      let s = this.style
      s.backgroundColor = this.hex
      return s
    },
    customProperties() {
      return this.$store.getters["configuration/customPropertiesForKey"](this.model)
    }
  }
}
</script>

<style scoped>
.item-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 164px;
  height: 164px;
  margin: 20px 0 0 0;
  cursor: pointer;
  line-height: 1.5;
}
.item-tile:nth-child(0) {
  margin-right: 0;
}

.item-tile.long {
  height: 200px;
}
.badge {
  position: absolute;
  width: 29px;
  height: 29px;
  right: 0;
  top: 0;
  transform: translate(6px, 15px);
  background: linear-gradient(45deg, rgba(196,204,216,1) 0%, rgba(247,248,250,1) 100%);
  border-radius: 14px;
  color: var(--primary-color);
}
.badge svg {
  transform: translate(0px, 2.5px);
}
.product-info {
  color: var(--darkest-text-color);
  padding: 20px 0 0 0;
  font-weight: 700;
  letter-spacing: 0.25px;
  font-size: 12px;
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-info p.description {
  color: #677B92;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 4px;
  width: 120px;
}
.colorplane {
  width: 100px;
  height: 100px;
  border: 1px solid #dadada;
  position: absolute;
  border-radius: 7px;
  background: transparent;
  background-size: contain !important;
  background-position: 50% 50% !important;
  background-repeat: no-repeat !important;
}

.product-image {
  position: absolute;
  top: 0;
  left: 30px;
  width: 100px;
  height: 100px;
  border-radius: 7px;
  background: transparent;
  background-size: contain !important;
  background-position: 50% 50% !important;
  background-repeat: no-repeat !important;
}
.item-tile.is-custom.is-color .product-image {
  border: 1px solid #dadada;
}

.add-button {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-tile.selected .bg {
  border: 1px solid rgba(22, 130, 243, 1);
}
.item-tile.not-available .bg {
  border: 1px solid var(--warning-color);
  background-color: #eff1f6;
  cursor: not-allowed;
  box-shadow: none;
}
.item-tile.not-available {
  cursor: not-allowed;
}

.bg {
  background: #fff;
  box-shadow: var(--default-box-shadow);
  width: 164px;
  height: 164px;
  position: absolute;
  top: 20px;
  left: 0;
  border-radius: 7px;
}
.item-tile.long .bg {
  height: 200px;
}
.debug {
  font-family: 'Courier New', Courier, monospace;
  display: block;
  background: #ccc;
  white-space: nowrap;
  display: none;
}
span.title {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 140px;
  max-height: calc(12px * 1.25 * 2); /* Fallback for non-webkit */
  font-size: 12px;
  line-height: 1.25;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.hyphens {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

/* darker, smaller, without drop shadow when inside a custom selector */
.selector-tile .item-tile {
  width: 144px;
  height: 144px;
}

.selector-tile .bg {
  background: var(--page-bg-color);
  box-shadow: none;
  width: 144px;
  height: 144px;
}

.selector-tile .product-image {
  left: 20px;
}
.selector-tile .add-button {
  left: 20px;
}

.selector-tile .item-tile.long {
  height: 160px;
}

.selector-tile .item-tile.long .bg {
  height: 160px;
}

.slot {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
}
</style>

