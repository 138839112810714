<template>
    <div :class="'custom-selector window-rails-layout-selector' + (disabled ? ' disabled' : '')">
      <SelectorTile>
        <template #figure>
          <img :src="imageURL">
        </template>
        <template #form>
          <label>Aufteilung</label>
          <DropDown :disabled="disabled" :options="options.values" v-model="config.windowRailsLayoutType" :placeholder="options.placeholder" :allowNoSelection="false" />

          <label v-show="canSetTopPadding(windowRailsLayoutBasePath)">Abstand von oben</label>
          <NumberInput v-show="canSetTopPadding(windowRailsLayoutBasePath)" :disabled="disabled" v-model="config.windowRailsTopPadding" description="" placeholder="0 mm" />

          <label v-show="canSetHorizontalCount(windowRailsLayoutBasePath)" >Sprossen Horizontal</label>
          <div v-show="canSetHorizontalCount(windowRailsLayoutBasePath)" class="row">
            <input type="range" class="slider" v-model.number="config.windowRailsHorizontalCount"  min="0" max="6" />
            <NumberInput :disabled="disabled" v-model="config.windowRailsHorizontalCount" description="" placeholder="1" :min="0" :max="6"/>
          </div>
          <label v-show="canSetVerticalCount(windowRailsLayoutBasePath)">Sprossen Vertikal</label>
          <div v-show="canSetVerticalCount(windowRailsLayoutBasePath)" class="row">
            <input type="range" class="slider" v-model.number="config.windowRailsVerticalCount" min="0" max="6" />
            <NumberInput :disabled="disabled" v-model="config.windowRailsVerticalCount" description="" placeholder="1" :min="0" :max="6"/>
          </div>
        </template>
      </SelectorTile>
    </div>
</template>

<script>
import SelectorTile from "@/components/configuration/SelectorTile.vue";
import { DropDown, NumberInput } from "@profineberater/configurator-clientlib"
import WindowRailsLayoutMixin from "./WindowRailsLayoutMixin.vue"

// https://stackoverflow.com/questions/18389224/how-to-style-html5-range-input-to-have-different-color-before-and-after-slider

export default {
  name: "WindowRailsLayoutSelector",
  components: {
    SelectorTile,
    DropDown,
    NumberInput
  },
  mixins: [WindowRailsLayoutMixin],
  data: function () {
    return {
      selectedOptions: null,
      config: this.configuration,
    };
  },
  props: {
    configuration: {
      type: Object
    },
    disabled: {
      type: Boolean
    },
  },
  computed: {
    windowRailsLayoutBasePath() {
      if (!this.config.windowRailsType || !this.config.windowRailsLayoutType) return undefined
      return `windowRailsType.${this.config.windowRailsType}.windowRailsLayoutType.${this.config.windowRailsLayoutType}`
    },
    imageURL() {
      return this.$store.getters["configurationOptions/metadataForKey"](this.config.windowRailsLayoutType || "kreuzförmig")?.url
    },
    options() {
      let opts = this.$store.getters["configurationOptions/dropdownOptionsForKey"]("windowRailsLayoutType", this.config)
      return {
        placeholder: "Bitte wählen",
        values: opts
      }
    },
  },
};
</script>

<style scoped>
.window-rails-layout-selector.disabled {
  opacity: 0.35;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.row .slider {
  min-width: 75%;
  margin-right: 20px;
}
.row .number-input {
  margin-bottom: 0;
}
</style>

<style>
  input[type=range] {
    height: 33px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px var(--default-shadow-color);
    background: #1682F3;
    border-radius: 16px;
    border: 1px solid #0F7BEB;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 7px var(--default-shadow-color);
    border: 1px solid #E9EBF2;
    height: 26px;
    width: 26px;
    border-radius: 26px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -10px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #1682F3;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px var(--default-shadow-color);
    background: #1682F3;
    border-radius: 16px;
    border: 1px solid #0F7BEB;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 7px var(--default-shadow-color);
    border: 1px solid #E9EBF2;
    height: 26px;
    width: 26px;
    border-radius: 26px;
    background: #FFFFFF;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #1682F3;
    border: 1px solid #0F7BEB;
    border-radius: 32px;
    box-shadow: 0px 0px 0px var(--default-shadow-color);
  }
  input[type=range]::-ms-fill-upper {
    background: #1682F3;
    border: 1px solid #0F7BEB;
    border-radius: 32px;
    box-shadow: 0px 0px 0px var(--default-shadow-color);
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 7px var(--default-shadow-color);
    border: 1px solid #E9EBF2;
    height: 26px;
    width: 26px;
    border-radius: 26px;
    background: #FFFFFF;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #1682F3;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #1682F3;
  }

</style>
