<template>
  <div
    :class="'configurator-section-header' + (isDisabled(category, configuration) ? ' disabled' : '')"
    :data-category-key="this.category.key">
    <h4>{{ title }}</h4>
    <a v-if="actionTitle && actionTitleEnabled" @click="onActionClick">{{ actionTitle }}</a>
  </div>
</template>

<script>
//import { ROLES } from "@profineberater/configurator-clientlib"
import ConfigurationMixin from "@/mixins/ConfigurationMixin.vue"

export default {
  mixins: [ConfigurationMixin],
  name: "ConfiguratorSectionHeader",
  props: ["category", "title", "actionTitle", "completed", "configuration"],
  methods: {
    onActionClick(e) {
      if (!this.isDisabled(this.category, this.configuration)) {
        this.$emit("action:click", e)
      }
    }
  },
  computed: {
    actionTitleEnabled() {
      let isVisible = this.$store.getters["content/details"]?.[this.category.key]?.visible
      return isVisible && process.env.VUE_APP_CMS_ENABLED === "true" //window.$keycloak.hasRealmRole(ROLES.CMS_PREVIEW)
    },
  }
};
</script>

<style>
.configurator-section-header {
  display: flex;
  flex-direction: row;
  width: calc(100% - 15px);
  justify-content: space-between;
  align-items: center;
  margin: 80px 0px 40px 15px;
}
.configurator-section-header:first-child {
  margin: 10px 0px 40px 15px;
}

.configurator-section-header h2 {
  position: relative;
  padding: 0 0 0 15px;
  margin-left: 10px;
}
.configurator-section-header a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
}
h2 img {
  transform: translateY(-2px);
}

.configurator-section-header.disabled {
  opacity: 0.25;
}

.configurator-section-header.disabled a {
  cursor: default;
}
</style>