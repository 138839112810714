<template>
  <div :data-testid="project._id" class="project-card">
    <SimpleCard :class="(projectCheckoutInfo && !isProjectCheckedOutByMe ? 'disabled' : '')">
      <div
        ref="moreAction" class="more-action"
        v-show="!isProjectCheckedOut || isProjectCheckedOutByMe"
        @click.stop="onMoreActionClick">
          <DotsIcon class="dots" />
      </div>
      <div v-if="project && project.customer" class="project">
        <InitialsBadge
          :user="project.customer"
          :archived="project.isArchived"
          :disabled="(projectCheckoutInfo && !(project.owner && project.importedAt))"
        >
          <LockIcon v-if="isLocked" class="lock"/>
        </InitialsBadge>
        <h5>
          {{ project.customer.firstName }} {{ project.customer.lastName }}
          <div v-if="projectCheckoutInfo" class="checkout-info">
            <span v-if="project.owner && project.importedAt" class="owner">Erstellt von {{ project.ownerName }}</span>
            <span v-else>In Bearbeitung von {{  projectCheckoutInfo.assignedToName }}</span>
          </div>
        </h5>
        <div class="city-label">{{ project.customer.zip }} {{ project.customer.city }}&nbsp;</div>
        <div class="archived-label" v-if="project.isArchived">Archiviert</div>
        <h6 class="project-no" v-if="project.customer.projectNumber">{{ project.customer.projectNumber }}</h6>
        <div v-if="displayFullDetails">
          <div class="address">
            <ul>
              <li>{{ project.customer.streetAndNumber }}</li>
              <li>{{ project.customer.zip }} {{ project.customer.city }}</li>
              <li>{{ project.customer.phone }}</li>
              <li class="email"><a :href="'mailto:'+project.customer.email">{{ project.customer.email }}</a></li>
            </ul>
          </div>
        </div>
      <!-- <div class="counter">{{ numberOfConfigurations }}</div> -->
      </div>
    </SimpleCard>
  </div>
</template>

<script>

import { InitialsBadge, SimpleCard, LockIcon, ArchiveIcon } from "@profineberater/configurator-clientlib"
import DotsIcon from "@/components/ui/DotsIcon.vue"
import { markRaw } from "vue"

export default {
  name: 'ProjectCard',
  components: {
    SimpleCard,
    InitialsBadge,
    LockIcon,
    DotsIcon,
  },
  props: {
    project: {
      type: Object
    },
    displayFullDetails: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    numberOfConfigurations() {
      let count = this.project?.configurations?.length
      return count ? ("+" + count) : "0"
    },
    projectCheckoutInfo() {
      return this.$store.getters["team/projectCheckoutInfo"](this.project)
    },
    isProjectCheckedOut() {
      return this.$store.getters["team/isProjectCheckedOut"](this.project)
    },
    isProjectCheckedOutByMe() {
      return this.$store.getters["team/isProjectCheckedOutByMe"](this.project)
    },
    isLocked() {
      return this.projectCheckoutInfo != null && !this.isProjectCheckedOutByMe
    },
    isContextMenuActive() {
      return this.$store.getters["ui/contextMenuState"]?.active
    }
  },
  methods: {
    onMoreActionClick() {
      if (this.isContextMenuActive) {
        this.$store.commit("ui/contextMenuActive", false)
        return
      }

      let rect = this.$refs.moreAction.getBoundingClientRect()
      let height = document.body.offsetHeight
      let y = rect.top
      let x = Math.max( 10, rect.left - 160);
      if (y > height / 2) {
        y -= 50
      } else {
        y += 40
      }

      this.$store.commit("ui/contextMenuState", {
        active: true,
        containerEl: this.$el,
        top: y + "px",
        left: x + "px",
        menuItems: [
          {
            title: this.project.isArchived ? "Wiederherstellen" : "Archivieren",
            handler: () => {
              this.$emit(this.project.isArchived ? 'project:unarchive' : 'project:archive', this.project)
            },
            iconComponent: markRaw(ArchiveIcon)
          },
        ]
      })
    }
  }

}
</script>

<style scoped>
.project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  hyphens: auto;
}
.project-card {
  width: 100%;
  position: relative;
}
.disabled {
  cursor: initial;
}

:deep(.simple-card) {
  aspect-ratio: 1;
}

:deep(.initials-badge) {
  margin-bottom: 10px;
}
.counter {
  min-width: 28px;
  min-height: 28px;
  max-width: 28px;
  max-height: 28px;
  background: var(--primary-color);
  color: #fff;
  font-weight: 500;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  margin: 20px;
}
h5 {
  margin: 4px;
  font-size: 12px;
}
div.address {
  font-size: 14px;
  color: var(--text-color);
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.2px;
}
div.address ul {
  margin-top: 20px;
  list-style-type: none;
}
div.address ul a {
  text-decoration: none;
}
.email {
  margin-top: 10px;
}
.project-no {
  color: var(--text-color);
  margin-top: 2px;
  font-size: 12px;
  max-width: 75%;
  height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.checkout-info {
  margin-top: 4px;
  font-size: 10px;
  color: var(--primary-color);
}

.more-action {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 40px;
  height: 40px;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.archived-label {
  background-color: #B5C0D1;
  border-radius: 5px;
  padding: 4px 6px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 900;
  font-size: 8px;
  letter-spacing: 2px;
  border: 1px solid #afbaca;
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 18px;
}

.city-label {
  font-size: 12px;
  color: var(--text-color);
  margin-top: 2px;
  max-width: 75%;
  height: 20px;
}
.lock {
  position: absolute;
  top: -4px;
  right: -3px;
}
:deep(.lock-icon) {
  width: 16px;
  height: 16px;
}

:deep(.simple-card) {
    padding: 20px 10px;
}

@media only screen and (min-width : 1024px) {
  h5 {
    font-size: 15px;
    margin: 20px 0 0 0;
  }
  .checkout-info {
    margin-top: 20px;
  }
  .checkout-info {
    font-size: 12px;
  }
  .lock {
    top: -4px;
    right: -3px;
  }
  :deep(.lock-icon) {
    width: 24px;
    height: 24px;
  }
  .city-label {
    font-size: 14px;
  }
}

@media only screen and (min-width : 1200px) {
  .archived-label {
    padding: 6px 15px;
    font-size: 10px;
  }
}

</style>