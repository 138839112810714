<template>
  <div class="configuration-notes-renderer">
    <p v-if="configuration.notesEnabled" data-pdfmake="{'margin': [0, 0, 0, 0]}">
      {{  configuration.notes }}
    </p>
  </div>
</template>

<script>

export default {
  name: "ConfigurationNotesRenderer",
  props: {
    configuration: {
      type: Object
    },
  },
  methods: {
    showInConfigurationTable(config) {
      return config.notesEnabled
    }
  }
}
</script>

<style scoped>
</style>
