<template>
  <div></div>
</template>
<script>

export default {
  methods: {
    metadata(key) {
      return this.$store.getters["configurationOptions/metadataForKey"](key)
    },
    titleFromMetadata(key, fallback) {
      let metadata = this.metadata(key)
      return metadata?.title || fallback
    },
    openingDescription(config, fallback) {
      let layout = this.titleFromMetadata(config.layout) || "--"
      let opening = this.titleFromMetadata(config.opening) || "--"
      if (layout && opening) {
        return `${layout} / ${opening}`
      }
      return fallback
    },
  },
};
</script>
