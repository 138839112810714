<template>
    <div :class="'custom-selector openings-selector' + (disabled ? ' disabled' : '')">
      <SelectorTile>
        <template #figure>
          <IsometricElement :table="table" />
        </template>
        <template #form>
          <DropDown :options="options" v-model="selectedOption" placeholder="Bitte wählen" :allowNoSelection="false" />
          <!--
            @update:modelValue="onOptionSelect($event, value)"
            <DropDown v-if="hasTopLight" :options="options[0].values" v-model="selectedOptions[0]" @update:modelValue="onOptionSelect($event, idx)" :placeholder="options[0].placeholder" />
          <DropDown v-for="(num, index) in numberOfColumns" v-bind:key="index" :options="options[index + 1].values" v-model="selectedOptions[index + 1]" :placeholder="options[index + 1].placeholder" />
          <DropDown v-if="hasSubLight" :options="options[options.length - 1].values" v-model="selectedOptions[options.length - 1]" @update:modelValue="onOptionSelect($event, idx)" :placeholder="options[options.length - 1].placeholder" /> -->
        </template>
      </SelectorTile>
    </div>
</template>

<script>
import SelectorTile from "@/components/configuration/SelectorTile.vue";
import IsometricElement from "@/components/configuration/IsometricElement.vue";
import { DropDown } from "@profineberater/configurator-clientlib"

export default {
  name: "OpeningsSelector",
  components: {
    SelectorTile,
    IsometricElement,
    DropDown,
  },
  props: {
    disabled: Boolean
  },
  data: function () {
    return {
      numberOfColumns: 3,
      hasTopLight: true,
      hasSubLight: false,
    };
  },
  computed: {
    selectedOption: {
      get() {
        return this.configuration.opening
      },
      set(opening) {
        this.$store.commit("configuration/opening", opening)
      }
    },
    table() {
      return this.$store.getters["configurationOptions/tableForKey"](this.selectedOption)
    },
    configuration() {
      return this.$store.getters["configuration/configuration"]
    },
    options() {
      return this.$store.getters["configurationOptions/dropdownOptionsForKey"]("opening", this.configuration)
    },
  },
};
</script>

<style scoped>
.openings-selector {
  display: flex;
  flex-direction: column;
}
.openings-selector.disabled {
  opacity: 0.35;
}
</style>

