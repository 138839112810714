<template>
  <div class="projects-header">
    <div class="left">
      <div class="dot"></div> {{ numberOfProjectsText }}
    </div>
    <div class="right">
      <DropDown
        bgColor="#fff"
        :divider="true"
        :isMultiselect="true"
        fixedTitle="Sortieren"
        :options="sortOptions.values"
        v-model="sortCriteria"
        :placeholder="sortOptions.placeholder"
        :allowNoSelection="false"
      />
    </div>
  </div>
  <div class="projects grid">
    <AddItemCard
      v-if="projects.length == 0 && !isArchive"
      class="add-project-card"
      @click="onAdd"
      v-show="loaded"
    >
      Neuer Kunde
    </AddItemCard>
    <transition-group v-else name="fade" mode="out-in">
      <ProjectCard
        v-for="(project, index) in filteredProjects"
        v-bind:key="index"
        :project="project"
        @click="$emit('project:select', project)"
        @project:archive="$emit('project:archive', project)"
        @project:unarchive="$emit('project:unarchive', project)"
      >
      </ProjectCard>
    </transition-group>

  </div>
</template>

<script>
import ProjectCard from "@/components/cards/ProjectCard.vue"
import AddItemCard from "@/components/cards/AddItemCard.vue"
import { DropDown } from "@profineberater/configurator-clientlib"

export default {
  emits: ["project:select", "project:add"],
  props: {
    filters: {
      type: Array
    },
    isArchive: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProjectCard,
    AddItemCard,
    DropDown
  },
  data: function() {
    return {
      loaded: false,
    }
  },
  mixins: [],
  created() {
    this.$store.dispatch("project/getAll").then( () => {
      this.loaded = true
    })
  },
  computed: {
    sortCriteria: {
      get() {
        let prefs = this.$store.getters["user/preferences"];
        return {
          sortByKeyPath: prefs?.sort?.projects?.sortByKeyPath || this.sortOptions.defaultValue,
          sortOrder: prefs?.sort?.projects?.sortOrder || this.sortOptions.defaultSortOrder
        }
      },
      set(crit) {
        let prefs = this.$store.getters["user/preferences"];
        prefs.sort.projects = {...prefs.sort.projects , ...crit}
        this.$store.commit("user/preferences", prefs)
      }
    },
    projects() {
      return this.$store.state.project.projects.filter( p => !p.isArchived == !this.isArchive )
    },
    filteredProjects() {
      let filteredProjects = this.projects
      let search = this.filters?.find( f => f.type == "search" );
      if (search?.searchTerm) {
        let term = search.searchTerm.toLowerCase()
        filteredProjects = this.projects.filter( p => {
          let projectNumber = p.customer?.projectNumber ? ("" + p.customer?.projectNumber) : null
          return p.customer?.lastName?.toLowerCase().includes(term) ||
                 p.customer?.firstName?.toLowerCase().includes(term) ||
                 p.customer?.city?.toLowerCase().includes(term) ||
                 projectNumber?.toLowerCase().includes(term)
        })
      }
      let keypath = this.sortCriteria.sortByKeyPath
      if (keypath) {
        filteredProjects = filteredProjects.sort((a, b) => {
          const valueA = keypath.split('.').reduce((previous, current) => previous[current], a);
          const valueB = keypath.split('.').reduce((previous, current) => previous[current], b);
          if (keypath.endsWith("createdAt")) {
            let dateA = Date.parse(valueA)
            let dateB = Date.parse(valueB)
            if (dateA == dateB) return 0
            if (dateA > dateB) return 1
            return -1
          } else {
            return (valueA || "")?.toLowerCase().localeCompare((valueB || "")?.toLowerCase())
          }
        })
      }
      if (this.sortCriteria.sortOrder == "descending") {
        filteredProjects = filteredProjects.reverse()
      }
      return filteredProjects
    },
    numberOfProjectsText() {
      let n = this.filters?.length ? this.filteredProjects.length : this.projects.length
      n = this.filteredProjects.length
      if (n == 0) {
        return "Keine Ergebnisse"
      }
      if (n == 1) {
        return "1 Kunde"
      }
      return n + " Kunden"
    },
    sortOptions() {
      return this.$store.getters["project/sortOptions"]
    },
  },
  methods: {
    onAdd() {
      this.$emit('project:add')
    },

  }
};
</script>

<style scoped>
.container {
  width: 100%;
}
.projects {
  gap: 20px;
  padding-bottom: 20px;
}
.projects-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.projects-header .left {
  flex-grow: 1;
  text-align: left;
  display: flex;
  align-items: center;
  justify-items: center;
  font-weight: 600;
}
.projects-header .left .dot {
  margin-right: 10px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  grid-auto-rows: 1fr;
}

.grid::before {
  content: '';
  width: 0;
  padding-bottom: 100%;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.grid > *:first-child {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

:deep(.project-card .simple-card) {
  height: 100%;
}
:deep(.add-item-card .simple-card) {
  height: 100%;
}
.add-project-card {
  padding: 10px;
}
:deep(.selectbox) {
  height: 40px;
  border: 1px solid #E4E7ED;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath d='M9.133 3.857 6.027.751l-3.16 3.16M2.867 8.143l3.106 3.106 3.16-3.16' style='fill:none;stroke:%23181d29;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}
.projects-header .dropdown {
  margin-bottom: 0;
  height: 40px;
}
@media only screen and (min-width : 1024px) {
  .projects {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
  }
}
</style>
