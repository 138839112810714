export default class HingeModel {
  constructor(id, name) {
      this.id = id
      this.name = name
  }

  static allHingeModels() {
      return allHingeModelsEnum
  }
}

const allHingeModelsEnum = Object.freeze({
  hingeDrehKippLinks: new HingeModel("hingeDrehKippLinks", "DK links"),
  hingeDrehKippRechts: new HingeModel("hingeDrehKippRechts", "DK rechts"),
  hingeFest: new HingeModel("hingeFest", "fest"),
  hingeDrehLinks: new HingeModel("hingeDrehLinks", "Dreh links"),
  hingeDrehRechts: new HingeModel("hingeDrehRechts", "Dreh rechts"),
  hingeOlKipp: new HingeModel("hingeOlKipp", "ol Kipp"),
  hingeUlKipp: new HingeModel("hingeUlKipp", "ul Kipp"),
  hingeOlFest: new HingeModel("hingeOlFest", "ol fest"),
  hingeUlFest: new HingeModel("hingeUlFest", "ul fest"),
})