<template>
  <div class="root-layout">
    <AppStatus />
    <PageTitle>
      <template #page-title>
        <slot name="page-title"></slot>
      </template>
    </PageTitle>
    <div class="page">
      <slot name="page"></slot>
    </div>
  </div>
</template>

<script>
import AppStatus from "@/components/AppStatus.vue"
import PageTitle from "@/components/page/PageTitle.vue"

export default {
  name: "RootLayout",
  components: {
    AppStatus,
    PageTitle
  },
  computed: {
    currentProjectId() {
      return this.$route.query.p
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.root-layout {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
.page {
  flex-grow: 1;
  background-color: var(--page-bg-color);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
}
.blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0.95;
  z-index: 1010;
}
:deep(.page-title) {
  margin: 0 20px 20px;
}

@media only screen and (min-width : 1024px) {
  :deep(.page-title) {
    margin: 0 60px;
  }
}
</style>