<template>
  <div class="simple-tile">
    <div class="image">
      <slot name="image"></slot>
    </div>
    <div class="title">
      <slot name="title"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SimpleTile',
}
</script>

<style scoped>
.simple-tile {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.title {
  margin-top: 20px;
}
.image {
  border-radius: 7px;
  background: var(--page-bg-color);
  padding: 20px;
  margin: 0 0 0 0;
  height: 100%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}
</style>