<template>
  <div class="custom-configuration-text">
    <label class="textarea-label">{{ label }}</label>
    <textarea
      :disabled="disabled"
      :value="modelValue"
      @change="$emit('update:modelValue', $event.target.value)"
      :placeholder="placeholder"
      rows="10"></textarea>
  </div>
</template>

<script>

export default {
name: 'CustomConfigurationText',
props: {
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String
    },
    placeholder: {
      type: String
    },
    label: {
      type: String,
      default: "Details"
    }
},
emits: ['update:modelValue'],
data: function() {
  return {
    model: this.modelValue
  }
},
methods: {
},
computed: {
}
}
</script>

<style scoped>
.custom-configuration-text {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-bottom: 80px;
}

</style>

