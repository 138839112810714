<template>
    <div class="custom-configuration-renderer table-renderer">
      <p data-pdfmake="{'margin': [0, 0, 0, 0], 'markerColor': '#ffffff'}">{{ value }}</p>
    </div>
</template>

<script>

export default {
  name: 'CustomConfigurationRenderer',
  props: {
      value: {
        type: String
      },
      placeholder: {
        type: String
      }
  },
}
</script>

<style scoped>
.custom-configuration-renderer {
}

</style>

