<template>
    <div class="configuration-note">
      <div class="toggle-note">
        <span>Beschreibung hinzufügen</span>
        <VueToggle
          activeColor="#1682F3"
          :toggled="model.notesEnabled"
          ref="toggle"
          title=""
          @toggle="toggleNotes" name="vue-toggle"/>
      </div>
      <transition name="fade">
        <CustomConfigurationText
          v-show="model.notesEnabled"
          v-model="model.notes"
          label=""
          :disabled="disabled"
          placeholder="Hier bitte allgemeine Informationen eingeben …"
          @update:modelValue="$emit('update:modelValue', model)"
        ></CustomConfigurationText>
      </transition>
    </div>
</template>

<script>

import CustomConfigurationText from '@/components/configuration/custom/CustomConfigurationText.vue';
import VueToggle from 'vue-toggle-component';

export default {
  name: 'ConfigurationTextNote',
  components: {
    CustomConfigurationText,
    VueToggle
  },
  data: function() {
    return {
      model: this.configuration
    }
  },
  props: {
    configuration: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  methods: {
    toggleNotes(value) {
      this.model.notesEnabled = value
      this.$emit('update:modelValue', this.model)
    },
  },
  computed: {
  }
}
</script>

<style scoped>
.configuration-note {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  background-color: #fff;
  border-radius: 7px;
  padding: 20px 20px;
  margin: 0 15px;
  box-shadow: var(--default-box-shadow);
}

.toggle-note {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
}
.toggle-note span {
  flex-grow: 1;
  text-align: left;
}

.toggle-note span {
  font-weight: 500;
}
.toggle-note section {
  padding: 0;
}

:deep(.custom-configuration-text) {
  margin-bottom: 0;
}

</style>

