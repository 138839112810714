<template>
  <div></div>
</template>
<script>

export default {
  computed: {
    details() {
      return this.$store.getters["content/details"] || {}
    },
  },
};
</script>

