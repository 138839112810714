<template>
    <div class="custom-selector roller-shutter-selector">
      <SelectorTile>
        <template #figure>
          <img src="@/assets/svg/roller-shutter.svg">
        </template>
        <template #form>
          <label>Typ</label>
          <DropDown data-testid="rollerShutter" :options="options.values" v-model="configuration.rollerShutter" :placeholder="options.placeholder" :allowNoSelection="false" />
          <label v-if="isBox">Art</label>
          <DropDown data-testid="blindsType" v-if="isBox" placeholder="Bitte wählen" :options="blindsTypeOptions" v-model="configuration.blindsType" :allowNoSelection="false" />
          <NumberInput data-testid="rollerShutterHeight" v-if="configuration.visibility.rollerShutterHeight" v-model="configuration.layoutInputs.rollerShutterHeight" label="Kastenhöhe" unit="mm" description="" :placeholder="configuration.rollerShutterHeight + ' mm'" :min="50" :max="500"/>
          <NumberInput data-testid="coverConnectionProfile" v-if="configuration.visibility.coverConnectionProfile" v-model="configuration.layoutInputs.coverConnectionProfile" label="Profilhöhe" unit="mm" description="" :placeholder="configuration.coverConnectionProfile + ' mm'" :min="0" :max="500"/>
        </template>
      </SelectorTile>
    </div>
</template>

<script>
import SelectorTile from "@/components/configuration/SelectorTile.vue";
import { DropDown, NumberInput } from "@profineberater/configurator-clientlib"

export default {
  name: "RollerShutterSelector",
  components: {
    SelectorTile,
    DropDown,
    NumberInput
  },
  data: function () {
    return {
      selectedOptions: null,
    };
  },
  props: {},
  methods: {
  },
  computed: {
    configuration() {
      return this.$store.getters["configuration/configuration"]
    },
    options() {
      let opts = this.$store.getters["configurationOptions/dropdownOptionsForKey"]("rollerShutter", this.configuration)
      return {
        placeholder: "Bitte wählen",
        values: opts
      }
    },
    blindsTypeOptions() {
      return [
        {
          label: "Rollladen",
          value: "rollladen"
        },
        {
          label: "Raffstore",
          value: "raffstore"
        },
      ]
    },
    isBox() {
      return (
        this.configuration.rollerShutter == 'rollershutter_addonbox'
      || this.configuration.rollerShutter == 'rollershutter_addonboxinsulated' 
      || this.configuration.rollerShutter == 'rollershutter_frontmountedbox'
      )
    }
  },
};
</script>

<style scoped>
.roller-shutter-selector {
  display: flex;
  flex-direction: column;
}
</style>

