import dimensionsValidates from "./validators/dimensionsValidates.js"
import defaultValidates from "./validators/defaultValidates.js"
import frameExtensionValidates from "./validators/frameExtensionValidates.js"
import baseProfileValidates from "./validators/baseProfileValidates.js"
import rollerShutterValidates from "./validators/rollerShutterValidates.js"
import layoutDimensionsValidates from "./validators/layoutDimensionsValidates.js"
import shellColorValidates from "./validators/shellColorValidates.js"

const validatorMap = {
  "defaultValidator": defaultValidates,
  "dimensions": dimensionsValidates,
  "frameExtension": frameExtensionValidates,
  "baseProfile": baseProfileValidates,
  "rollerShutter": rollerShutterValidates,
  "layoutDimensions": layoutDimensionsValidates,
  "shellColorValidates": shellColorValidates,
}

const initialState = {
  steps: [
    {
      key: "basis",
      index: 0,
      title: "Basis",
      description: "Kein Fenster und keine Tür ohne das passende Material und Profil. Konfigurieren Sie gemeinsam mit Ihrem Kunden die gewünschten Basis-Einstellungen.",
      categories: [
        {
          key: "notes",
          configurationKey: "notes",
          tableRendererTitle: "Beschreibung",
          title: "",
          component: "ConfigurationNotes",
          tableRendererComponent: "ConfigurationNotesRenderer",
          isFreeText: true,
        },
        {
          key: "element",
          configurationKey: "element",
          title: "Elementtyp",
          actionTitle: "Details",
        },
        {
          key: "material",
          configurationKey: "material",
          title: "Material",
          actionTitle: "Details",
          dependsOn: ["element"]
        },
        {
          key: "system",
          configurationKey: "system",
          title: "System",
          actionTitle: "Details",
          REPLACED_custom: {
            id: "customSystem",
          },
          dependsOn: ["material"]
        },
        {
          key: "layout",
          configurationKey: "layout",
          title: "Aufteilung",
          dependsOn: ["element"],
          actionTitle: "Details",
        },
        // Ober / Unterlicht missing
        {
          key: "opening",
          configurationKey: "opening",
          title: "Öffnungsarten",
          component: "OpeningsSelector",
          dependsOn: ["layout"],
          actionTitle: "Details",
        },
      ]
    },
    {
      key: "dimensions",
      index: 1,
      title: "Maße",
      description: "Die Einbausituation gibt die möglichen Dimensionen des Fensters oder der Tür vor. Konfigurieren Sie gemeinsam mit Ihrem Kunden die benötigten Maße.",
      categories: [
        {
          key: "dimensions",
          title: "Aufmaß",
          component: "DimensionsSelector",
          tableRendererComponent: "DimensionsRenderer",
          validatorKey: "dimensions",
          actionTitle: "Details",
        },
        {
          key: "frameExtension",
          title: "Verbreiterung",
          component: "ExtensionsSelector",
          tableRendererComponent: "ExtensionsRenderer",
          validatorKey: "frameExtension",
          useExtendedMeasurement: false,
          actionTitle: "Details",
        },
        {
          key: "baseProfile",
          configurationKey: "baseProfile",
          title: "Aufbauprofile",
          component: "BaseProfilesSelector",
          tableRendererComponent: "BaseProfilesRenderer",
          validatorKey: "baseProfile",
          useExtendedMeasurement: false,
          actionTitle: "Details",
        },
        {
          key: "rollerShutter",
          configurationKey: "rollerShutter",
          title: "Rollladen und Raffstore",
          component: "RollerShutterSelector",
          validatorKey: "",
          visibility: "rollerShutter", // checks for configuration.visibility.rollerShutter
          display: ["tiles"],
          useExtendedMeasurement: false,
          actionTitle: "Details",
        },
        {
          key: "layoutDimensions",
          configurationKey: "layoutDimensions",
          title: "Einteilung",
          component: "LayoutDimensionsSelector",
          tableRendererComponent: "LayoutDimensionsRenderer",
          dependsOn: ["opening"],
          validatorKey: "layoutDimensions",
          actionTitle: "Details",
        },
      ]
    },
    {
      key: "design",
      index: 2,
      title: "Design",
      description: "Ob klassisch oder eher ausgefallen. Über Geschmack lässt sich nicht streiten. Konfigurieren Sie gemeinsam mit Ihrem Kunden das gewünschte Design.",
      categories: [
        {
          key: "color",
          actionTitle: "Details",
          configurationKey: "color",
          custom: {
            id: "customColor",
          }
        },
        {
          key: "isDoubleSidedColor",
          configurationKey: "isDoubleSidedColor",
          title: "Einseitig/Beidseitig",
          component: "DoubleSidedColorSelector",
          tableRendererComponent: "DoubleSidedColorRenderer",
          visibility: "isDoubleSidedColor",
          actionTitle: "Details",
        },
        {
          key: "shellColor",
          configurationKey: "shellColor",
          title: "Aussenfarbe",
          validatorKey: "shellColorValidates",
          visibility: "shellColor",
          custom: {
            id: "customShellColor",
          },
          actionTitle: "Details",
        },
        {
          key: "sealColor",
          configurationKey: "sealColor",
          title: "Dichtung",
          dependsOn: ["color"],
          actionTitle: "Details",
          //visibility: "sealColor",
        },
        {
          key: "filling",
          configurationKey: "filling",
          title: "Füllungen",
          actionTitle: "Details",
          component: "FillingSelector",
          isFreeText: true,
        },
        {
          key: "handle",
          configurationKey: "handle",
          title: "Griffe",
          actionTitle: "Details",
          custom: {
            id: "customHandle",
          }
        },
        {
          key: "windowHardware",
          configurationKey: "windowHardware",
          title: "Fensterbeschläge",
          actionTitle: "Details",
          custom: {
            id: "customWindowHardware",
          }
        },
        {
          key: "windowHingeType",
          configurationKey: "windowHingeType",
          visibility: "windowHingeType",
          title: "Fensterbänder",
          actionTitle: "Details",
          custom: {
            id: "customWindowHingeType",
          }
        },
        {
          key: "threshold",
          configurationKey: "threshold",
          title: "Schwelle",
          actionTitle: "Details",
          visibility: "threshold",
          custom: {
            id: "customThreshold",
          },
          dependsOn: ["element"]
        },
        // {
        //   key: "doorFilling",
        //   configurationKey: "doorFilling",
        //   title: "Türfüllungen",
        //   actionTitle: "Details",
        //   visibility: "doorFilling",
        //   dependsOn: ["element"]
        // },
        // {
        //   key: "cutPattern",
        //   configurationKey: "cutPattern",
        //   title: "Schnittmuster",
        //   actionTitle: "Details",
        //   visibility: "cutPattern",
        //   dependsOn: ["element"]
        // },
      ]
    },
    {
      key: "glass",
      index: 3,
      title: "Glas",
      description: "Glas ist nicht gleich Glas. Die Themen Wärmeschutz, Schallschutz und Sicherheit sind maßgeblich. Konfigurieren Sie gemeinsam mit Ihrem Kunden das gewünschte Glas.",
      categories: [
        {
          key: "glass",
          configurationKey: "glass",
          title: "Glas",
          custom: {
            id: "customGlass",
          },
          actionTitle: "Details",
        },
        {
          key: "ornamentGlass",
          configurationKey: "ornamentGlass",
          title: "Ornamentglas",
          custom: {
            id: "customOrnamentGlass",
          },
          actionTitle: "Details",
        },
        {
          key: "acousticGlass",
          configurationKey: "acousticGlass",
          title: "Schallschutzglas",
          actionTitle: "Details",
          custom: {
            id: "customAcousticGlass",
          }
        },
        {
          key: "securityGlass",
          configurationKey: "securityGlass",
          title: "Sicherheitsglas",
          custom: {
            id: "customSecurityGlass",
          },
          actionTitle: "Details",
        },
        {
          key: "windowRailsType",
          configurationKey: "windowRailsType",
          title: "Sprossentyp",
          component: "WindowRailsTypeSelector",
          actionTitle: "Details",
        },
        {
          key: "windowRailsLayoutType",
          configurationKey: "windowRailsLayoutType",
          title: "Sprossen Aufteilung",
          component: "WindowRailsLayoutSelector",
          tableRendererComponent: "WindowRailsLayoutRenderer",
          dependsOn: ["windowRailsType"],
          actionTitle: "Details",
        },
      ]
    },
    {
      key: "extras",
      index: 4,
      title: "Extras",
      description: "Erst die Pflicht und jetzt die Kür. Ob Lüftung,  Fensterbank innen und/oder außen. Konfigurieren Sie gemeinsam mit Ihrem Kunden die gewünschten Extras.",
      categories: [
        // {
        //   key: "connectionProfile",
        //   configurationKey: "connectionProfile",
        //   title: "Anschlussprofil",
        //   custom: {
        //     id: "customConnectionProfile",
        //   },
        // },
        {
          key: "ventilation",
          configurationKey: "ventilation",
          title: "Lüftung",
          REPLACED_custom: {
            id: "customVentilation",
          },
          actionTitle: "Details",
        },
        {
          key: "windowLedgeInner",
          configurationKey: "windowLedgeInner",
          title: "Fensterbank innen",
          component: "WindowLedgeInnerSelector",
          tableRendererComponent: "WindowLedgeInnerRenderer",
          visibility: "windowLedgeInner",
          actionTitle: "Details",
          children: [
            {
              "key": "windowLedgeInnerMaterial",
              "configurationKey": "windowLedgeInnerMaterial",
              "title": "Material",
              custom: {
                id: "customWindowLedgeInnerMaterial",
              },
            },
            {
              "key": "windowLedgeInnerModel",
              "configurationKey": "windowLedgeInnerModel",
              "title": "Modell",
              custom: {
                id: "customWindowLedgeInnerModel",
              },
            },
            {
              "key": "windowLedgeInnerColor",
              "configurationKey": "windowLedgeInnerColor",
              "title": "Farbe",
              custom: {
                id: "customWindowLedgeInnerColor",
              },
            }
          ]
        },
        {
          key: "windowLedgeOuter",
          configurationKey: "windowLedgeOuter",
          title: "Fensterbank aussen",
          component: "WindowLedgeOuterSelector",
          visibility: "windowLedgeOuter",
          tableRendererComponent: "WindowLedgeOuterRenderer",
          children: [
            {
              "key": "windowLedgeOuterColor",
              "configurationKey": "windowLedgeOuterColor",
              "title": "Farbe",
              custom: {
                id: "customWindowLedgeOuterColor",
              },
            }
          ],
          actionTitle: "Details",
        },
        {
          key: "rollerShutterRail",
          configurationKey: "rollerShutterRail",
          title: "Rollladenführungsschienen",
          custom: {
            id: "customRollerShutterRail",
          },
          actionTitle: "Details",
        },
      ]
    },
    {
      key: "blinds",
      index: 5,
      title: "Sichtschutz",
      description: "Ein Schutz, viele Möglichkeiten. Ob vor der Sonne oder den Blicken der Nachbarn. Konfigurieren Sie gemeinsam mit Ihrem Kunden den gewünschten Sichtschutz.",
      categories: [
        {
          key: "rollerShutter",
          configurationKey: "rollerShutter",
          title: "Konfiguration",
          component: "RollerShutterSelector",
          validatorKey: "",
          visibility: "rollerShutter", // checks for configuration.visibility.rollerShutter
          display: ["summary"],
          useExtendedMeasurement: false,
          tableRendererComponent: "RollerShutterRenderer",
          actionTitle: "Details",
        },
        // {
        //   key: "blindsType",
        //   configurationKey: "blindsType",
        //   title: "Art",
        //   visibility: "blindsType",
        //   component: "BlindsTypeSelector",
        //   display: ["summary"],
        // },
        {
          key: "blindsMaterial",
          configurationKey: "blindsMaterial",
          title: "Material",
          visibility: "blindsMaterial",
          display: ["tiles"],
          actionTitle: "Details",
        },
        {
          key: "blindsCaseColor",
          configurationKey: "blindsCaseColor",
          title: "Farbe Kasten",
          custom: {
            id: "customBlindsCaseColor",
          },
          visibility: "blindsCaseColor",
          dependsOn: ["blindsMaterial"],
          display: ["tiles"],
          actionTitle: "Details",
        },
        {
          key: "blindsFinColor",
          configurationKey: "blindsFinColor",
          title: "Farbe Stäbe/Lamellen",
          custom: {
            id: "customBlindsFinColor",
          },
          visibility: "blindsFinColor",
          dependsOn: ["blindsMaterial"],
          display: ["tiles"],
          actionTitle: "Details",
        },
        {
          key: "blindsDrive",
          configurationKey: "blindsDrive",
          title: "Antrieb",
          REPLACED_custom: {
            id: "customDrive",
          },
          visibility: "blindsDrive",
          dependsOn: ["rollerShutter"],
          display: ["tiles"],
          actionTitle: "Details",
        },
        {
          key: "blindsDriveLocation",
          configurationKey: "blindsDriveLocation",
          title: "Antriebsseite",
          visibility: "blindsDriveLocation",
          dependsOn: ["blindsDrive"],
          display: ["tiles"],
          actionTitle: "Details",
        },
        {
          key: "blindsMotor",
          configurationKey: "blindsMotor",
          title: "Motor",
          REPLACED_custom: {
            id: "customBlindsMotor",
          },
          visibility: "blindsMotor",
          dependsOn: ["blindsDrive"],
          display: ["tiles"],
          actionTitle: "Details",
        },
        {
          key: "blindsFixture",
          configurationKey: "blindsFixture",
          title: "Ausstattung",
          component: "BlindsFixtureSelector",
          isFreeText: true,
          actionTitle: "Details",
        },
      ]
    },
    // {
    //   key: "summary",
    //   index: 6,
    //   title: "Zusammenfassung",
    //   categories: [
    //     {
    //       key: "summary",
    //       title: "",
    //       component: "ConfigurationSummary"
    //     },
    //   ]
    // }
  ],
  currentStepKey: "basis"
}

export const configurationProcess = {
  namespaced: true,
  state: () => { return initialState },
  getters: {
    steps(state) {
      return state.steps
    },
    currentStep(state) {
      return state.steps.find(step => step.key == state.currentStepKey)
    },
    currentStepIndex(state) {
      return state.steps.find(step => step.key == state.currentStepKey)?.index || 0
    },
    stepByIndex: (state) => (index) => {
      return state.steps.find(step => step.index == index || 0)
    },
    previousStep(state, getters) {
      return state.steps.find(step => step.index == getters.currentStepIndex - 1)
    },
    nextStep(state, getters) {
      return state.steps.find(step => step.index == getters.currentStepIndex + 1)
    },
    hasNextStep(state, getters) {
      let idx = state.steps.findIndex(step => step.key == state.currentStepKey)
      return idx < getters["steps"].length - 1
    },
    stepValidates: (state, getters, rootState, rootGetters) => (stepKey) => {
      let validates = true
      let step = state.steps.find( step => step.key == stepKey)
      if (!step) {
        console.error("step null", stepKey)
        return false
      }
      let config = rootGetters["configuration/configuration"]
      for (var i=0; i<step.categories.length; i++) {
        let category =  step.categories[i]
        let validatesFunction = category.validatorKey ? validatorMap[category.validatorKey] : validatorMap["defaultValidator"]
        if (!validatesFunction) {
          throw new Error(`Validator ${category.validatorKey} not found`, category)
        }
        if (!validatesFunction(config, category)) {
          console.log("DOES NOT VALIDATE: ", step.key, category.configurationKey)
          validates = false
          break
        }
      }
      return validates
    }
  },
  mutations: {
    currentStepKey(state, currentStepKey) {
      state.currentStepKey = currentStepKey
    },
    selectStepByIndex(state, idx) {
      let step = state.steps.find(step => step.index == idx || 0)
      state.currentStepKey = step.key
    },
    resetCurrentStepKey( state ) {
      state.currentStepKey = "basis"
    },
    nextStep(state) {
      let idx = state.steps.findIndex(step => step.key == state.currentStepKey)
      if (idx == -1) {
        state.currentStepKey = state.steps[0].key
      } else if (idx < state.steps.length - 1) {
        state.currentStepKey = state.steps[idx + 1].key
      }
    },
    previousStep(state) {
      let idx = state.steps.findIndex(step => step.key == state.currentStepKey)
      if (idx == -1) {
        state.currentStepKey = state.steps[0].key
      } else if (idx > 0) {
        state.currentStepKey = state.steps[idx - 1].key
      }
    },
  },
};
