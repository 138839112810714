<template>
    <div class="custom-renderer table-renderer window-ledge-inner-renderer">
      <ul v-if="configuration.windowLedgeInner" data-pdfmake="{'margin': [-12, 0, 0, 0], 'markerColor': '#ffffff'}">
        <li>{{ titleFromMetadata(configuration.windowLedgeInner) }}</li>
        <li v-if="configuration.windowLedgeInnerDepth"><span class="nowrap">Tiefe: {{ configuration.windowLedgeInnerDepth }} mm</span></li>
        <li v-if="configuration.windowLedgeInnerMaterial">
          <CustomPropertiesRenderer label="Material:" :configuration="configuration" configurationKey="windowLedgeInnerMaterial" :title="titleFromMetadata(configuration.windowLedgeInnerMaterial, '')" />
        </li>
        <li v-if="configuration.windowLedgeInnerModel">
          <CustomPropertiesRenderer label="Modell:" :configuration="configuration" configurationKey="windowLedgeInnerModel" :title="titleFromMetadata(configuration.windowLedgeInnerModel, '')" />
        </li>
        <li v-if="configuration.windowLedgeInnerColor ">
          <CustomPropertiesRenderer label="Dekor:" :configuration="configuration" configurationKey="windowLedgeInnerColor" :title="titleFromMetadata(configuration.windowLedgeInnerColor, '')" />
        </li>
        <li v-if="configuration.windowLedgeInnerCustomText"><span class="nowrap">Details: {{ configuration.windowLedgeInnerCustomText }}</span></li>
      </ul>
      <span v-else>Ohne Fensterbank innen</span>
    </div>
</template>

<script>


import MetadataMixin from "@/mixins/MetadataMixin.vue"
import CustomPropertiesRenderer from "@/components/configuration/CustomPropertiesRenderer.vue"

export default {
  name: "WindowLedgeInnerRenderer",
  mixins: [MetadataMixin],
  components: {
    CustomPropertiesRenderer
  },
  props: {
    configuration: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>

