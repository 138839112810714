<template>
  <div></div>
</template>
<script>

import { Util } from "@profineberater/configurator-clientlib"

export default {
  methods: {
    createdAt(item) {
      const parsedDate = Date.parse(item.createdAt)
      return !isNaN(parsedDate) ? Util.formatDate(new Date(parsedDate)) : "&nbsp;"
    }
  },
};
</script>

