<template>
    <RootLayout>
      <template #page>
        <div class="root-page">
          <div class="debug" v-if="debug">
            browserInfo: {{ browserInfo }}<br>
            userAgent: {{ userAgent }}<br>
            browser: {{ currentBrowser }}<br>
            os: {{ currentOS }}<br>
          </div>
          <div v-if="isBrowserPWAEnabled">
            <div class="top-banner">
              <div class="top-banner-content">
                <img width="96" height="96" src="@/assets/img/app_icon_onboarding.png">
                <div class="app-description">
                  <h5>Framework App</h5>
                  <span class="subtitle">profine eSolutions GmbH</span>
                </div>
              </div>
            </div>
            <h1 v-if="isIOS" class="title">App in <span class="primary-color">2</span> Schritten installieren</h1>
            <h1 v-else class="title">App in <span class="primary-color">3</span> Schritten installieren</h1>

            <div v-if="isIOS" class="instructions">
              <h5><b>Hinweis:</b> Auf einem Apple-Gerät benötigen Sie Safari, um die App zu installieren.</h5>
              <b>1.</b> Teilen...
              <b>2.</b> "Zum Homescreen/Startbildschirm hinzufügen".
            </div>
            <div v-else class="instructions">
              <b>1.</b> "Kontextmenü" Symbol tippen
              <b>2.</b> "App installieren" wählen
              <b>3.</b> "Hinzufügen" tippen
            </div>

            <OnboardingVideo id="onboarding" ref="onboarding" />

            <div class="desktop-link">
              <router-link to="/projects">Ich möchte die App im Browser verwenden</router-link>
            </div>
          </div>
          <div v-else-if="!isBrowserPWAEnabled && isIOS" class="non-compatible-browser">
            <div class="app-icon"><img src="@/assets/img/app_icon_onboarding.png"></div>
            <h1 class="title">Bitte benutzen Sie Safari, um die App zu installieren</h1>
            <div class="instructions">
              Dieser Browser unsterstützt das Installieren von Apps nicht.
            </div>
            <div class="desktop-link">
              <a @click="skipOnboarding()">Ich möchte die App im Browser verwenden</a>
            </div>
          </div>
        </div>
      </template>
    </RootLayout>
</template>

<script>
import RootLayout from "@/layouts/RootLayout.vue"
import OnboardingVideo from "../components/onboarding/OnboardingVideo.vue";
import { BrowserCheckMixin } from "@profineberater/configurator-clientlib"

export default {
  name: "RootPage",
  mixins: [BrowserCheckMixin],
  components: {
    RootLayout,
    OnboardingVideo
  },
  computed: {
    videoURL() {
      return `${process.env.VUE_APP_SERVER_ASSETMANAGER_URL}/video/pwa_install.mp4`
    },
  },
  methods: {
    skipOnboarding() {
      this.$router.push("/projects")
    },
    showInstallVideo() {
      this.$refs.onboarding.show()
    }
  },
  mounted() {
    this.ensureBrowserCheck()
    if (this.isStandalone) {
      this.skipOnboarding()
    } else {
      if (this.isBrowserPWAEnabled) {
        this.showInstallVideo()
      }
    }
  }
}
</script>

<style scoped>
  .root-page {
    background-color: #fff;
    margin: 0;
    padding: 0;
  }
  h1, h2 {
    padding: 40px 40px 20px 40px;
  }
  h1.title {
    font-size: 28px;
  }
  #tutorial-video-player {
    max-height: calc(65vh - 120px);
  }
  .primary-color {
    color: var(--primary-color);
  }
  .top-banner {
    width: 100%;
    top: 0;
    left: 0;
    height: 80px;
    background-color: #F7F8FA;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #E9EBF2;
  }
  .top-banner-content {
    max-width: 360px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: left;
  }
  .subtitle {
    color: var(--text-color);
    font-size: 14px;
  }
  .app-description {
    padding-top: 10px;
  }
  .instructions {
    margin: 0 0 20px 0;
    color: var(--text-color);
  }
  .instructions h5 {
    margin-bottom: 20px;
  }
  .instructions b {
    color: #000;
  }
  .desktop-link {
    margin-top: 20px;
    display: none;
    font-size: 14px;
    font-weight: 600;
  }
  .app-icon {
    width: 96px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .app-icon img {
    width: 80%;
    height: 80%;
  }
  :deep(.page-title) {
    display: none;
  }
  .non-compatible-browser {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    padding: 0 10%;
  }
  @media (min-width:801px) {
    .desktop-link {
      display: block;
    }
  }
</style>