<template>
  <div class="tos-page">
    <AppLayout>
      <template #page>
        <PrivacyDocument />
      </template>
    </AppLayout>
  </div>
</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"
import {
  PrivacyDocument
} from "@profineberater/configurator-clientlib"


export default {
  name: 'PrivacyPage',
  components: {
    AppLayout,
    PrivacyDocument
  },
}
</script>
