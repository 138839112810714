<template>
    <div class="custom-renderer table-renderer dimensions-renderer">
      <div v-if="model.useExtendedMeasurement">
        <ul data-pdfmake="{'margin': [-12, 0, 0, 0], 'markerColor': '#ffffff'}">
          <li><span class="key">Elementmaß:</span> <span class="value">{{model.elementDimensions}} mm</span></li>
          <li><span class="key">Rahmenaussenmaß:</span> <span class="value">{{model.frameDimensions}} mm</span></li>
          <li><span class="key">Innenbreite der Öffnung:</span> <span class="value">{{ model.innerWidth }} mm</span></li>
          <li><span class="key">Außenbreite der Öffnung:</span> <span class="value">{{ model.outerWidth }} mm</span></li>
          <li v-if="model.stopLeft"><span class="key">Anschlag links/rechts: je <span class="value"></span>{{model.stopLeft}} mm</span></li>
          <li><span class="key">Innenhöhe der Öffnung:</span> <span class="value">{{ model.innerHeight }} mm</span></li>
          <li><span class="key">Außenhöhe der Öffnung:</span> <span class="value">{{ model.outerHeight }} mm</span></li>
          <li v-if="model.stopTop"><span class="key">Anschlag oben: <span class="value"></span>{{model.stopTop}} mm</span></li>
          <li><span class="key">Griffhöhe:</span> <span class="value">{{ model.handleHeight }} mm</span></li>
          <li v-if="model.visibility.marginTop && model.marginTop"><span class="key">Einbauluft oben:</span> <span class="value">{{ model.marginTop }} mm</span></li>
          <li v-if="model.visibility.marginLeft && model.marginLeft"><span class="key">Einbauluft links:</span> <span class="value">{{ model.marginLeft }} mm</span></li>
          <li v-if="model.visibility.marginRight && model.marginRight"><span class="key">Einbauluft rechts:</span> <span class="value">{{ model.marginRight }} mm</span></li>
          <li v-if="model.visibility.marginBottom && model.marginBottom">
            <span class="key">Einbauluft unten:</span> <span class="value">{{ model.marginBottom }} mm</span>
          </li>
          <li v-if="model.diagonalMeasure"><span class="key">Diagonalmaß: </span>
            <span class="value">{{ model.diagonalMeasure }} mm</span>
          </li>
          <li v-if="model.customMeasure"><span class="key">Eigenes Maß:</span> <span class="value">{{ model.customMeasure }}</span></li>
        </ul>
      </div>
      <div v-else>
        <ul data-pdfmake="{'margin': [-12, 0, 0, 0], 'markerColor': '#ffffff'}">
          <li>Rahmenbreite: {{ model.innerWidth }} mm</li>
          <li>Rahmenhöhe: {{ model.innerHeight }} mm</li>
          <li v-if="model.layoutInputs.handleHeight">Griffhöhe: {{ model.layoutInputs.handleHeight }} mm</li>
          <li v-if="model.customMeasure"><span class="key">Eigenes Maß:</span> <span class="value">{{ model.customMeasure }}</span></li>
        </ul>
      </div>
    </div>
</template>

<script>

export default {
  name: 'DimensionsRenderer',
  components: {
  },
  data: function() {
    return {
      model: this.configuration
    }
  },
  props: {
    configuration: {
      type: Object
    }
  },
}
</script>
