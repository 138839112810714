export default class LayoutModel {
  constructor(id, name, numberOfParts) {
      this.id = id
      this.name = name
      this.numberOfParts = numberOfParts
      this.lightType = "none"
  }
  static allLayoutModels() {
      return allLayoutModelsEnum
  }
  static layoutByQuantity(quantity) {
      return Object.values(this.allLayoutModels())[quantity - 1]
  }
}

const allLayoutModelsEnum = Object.freeze({
  layoutEinteilig: new LayoutModel("layoutEinteilig", "Einteilig", 1),
  layoutZweiteilig: new LayoutModel("layoutZweiteilig", "Zweiteilig", 2),
  layoutDreiteilig: new LayoutModel("layoutDreiteilig", "Dreiteilig", 3),
  layoutVierteilig: new LayoutModel("layoutVierteilig", "Vierteilig", 4),
})

