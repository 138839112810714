<template>
  <div class="more-menu-navigation-item">
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
      <g transform="translate(-225.447 -50.447)">
        <g transform="translate(231.724 65.128) rotate(-90)">
          <circle cx="1.5" cy="1.5" r="1.5" transform="translate(-0.319 -0.276)" fill="#147ae4"/>
          <circle cx="1.5" cy="1.5" r="1.5" transform="translate(-0.319 5.724)" fill="#147ae4"/>
          <circle cx="1.5" cy="1.5" r="1.5" transform="translate(-0.319 11.724)" fill="#147ae4"/>
        </g>
        <g transform="translate(225.447 50.447)" fill="none" stroke="#147ae4" stroke-width="2">
          <circle cx="13" cy="13" r="13" stroke="none"/>
          <circle cx="13" cy="13" r="12" fill="none"/>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>

export default {
  name: "MoreNavigationItem",
}
</script>
