<template>
    <div class="prev-next-step-navigation">
      <button v-if="!isLastStep"
        class="button primary min-width"
        @click.stop="onNextStep">Weiter zu {{ nextStep.title }}</button>

      <button v-else
        class="button primary min-width"
        @click.stop="onShowSummary">Zur Zusammenfassung</button>

      <button v-if="previousStep"
        class="back"
        @click.stop="onPreviousStep">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 7" xml:space="preserve">
            <path fill="currentColor" d="M11 2.994H1.92L4.178.715 3.469 0 0 3.5 3.469 7l.709-.715-2.259-2.279H11z"/>
          </svg>
          Zurück zu {{ previousStep.title }}
      </button>

      <button v-else
        class="back"
        @click.stop="$router.push('/configurations')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 7" xml:space="preserve">
              <path fill="currentColor" d="M11 2.994H1.92L4.178.715 3.469 0 0 3.5 3.469 7l.709-.715-2.259-2.279H11z"/>
          </svg>
          Zurück zur Konfigurationsübersicht</button>
    </div>
</template>

<script>


export default {
  name: 'PrevNextStepNavigation',
  methods: {
    onPreviousStep() {
      this.$store.commit("configurationProcess/previousStep")
    },
    onNextStep() {
      this.$store.commit("configurationProcess/nextStep")
    },
    onShowSummary() {
      this.$emit('summary:show')
    }
  },
  computed: {
    currentStep() {
      return this.$store.getters['configurationProcess/currentStep']
    },
    previousStep() {
      return this.$store.getters['configurationProcess/previousStep']
    },
    nextStep() {
      return this.$store.getters['configurationProcess/nextStep']
    },
    isLastStep() {
      return !this.$store.getters['configurationProcess/hasNextStep']
    },
  },
}
</script>

<style scoped>

.prev-next-step-navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  margin: 80px 0 60px 0;
}
.button {
  align-self: stretch;
}
</style>
display: none;}

