<template>
    <div class="custom-renderer table-renderer extensions-renderer">
      <ul data-pdfmake="{'margin': [-12, 0, 0, 0], 'markerColor': '#ffffff'}">
        <li v-if="model.frameExtensionLeft">Links: {{ model.frameExtensionLeft }} mm</li>
        <li v-if="model.frameExtensionRight">Rechts: {{ model.frameExtensionRight }} mm</li>
      </ul>
    </div>
</template>

<script>

export default {
  name: 'ExtensionsRenderer',
  components: {
  },
  data: function() {
    return {
      model: this.configuration
    }
  },
  methods: {
    showInConfigurationTable(config) {
      return config.frameExtensionLeft || config.frameExtensionRight || config.frameExtensionTop ||  config.frameExtensionBottom
    }
  },
  props: {
    configuration: {
      type: Object
    }
  },
}
</script>
