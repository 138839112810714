<template>
  <div class="dev-page">
    <AppLayout>
      <template #right-navigation-item>
        <MoreNavigationItem @click="onContextMenu" />
      </template>
      <template #page-title>
        <h1>Development and Test Pages</h1>
      </template>
      <template #page>
        <section>
          <ul>
            <li><router-link to="/dev/measurementmodel">Measurement Model</router-link></li>
            <li><router-link to="/dev/elementopeneningtypes">Element Opening Types</router-link></li>
          </ul>
        </section>
      </template>
    </AppLayout>
  </div>
</template>

<script>

import AppLayout from "@/layouts/AppLayout.vue"

export default {
  name: "DevPage",
  components: {
    AppLayout
  },
};
</script>

<style scoped>
ul, div {
  text-align: left;
  list-style-type: none;
}
li {
  margin: 4pt;
}
</style>