<template>
  <div class="products-details-slide">
    <div class="product-details-img cms-content">
      <img v-if="itemDetails && itemDetails.media" :src="attachments[itemDetails.media]" alt="Product Info">
    </div>
    <h5 v-if="itemDetails && itemDetails.kicker" class="product-details-kicker" v-html="itemDetails.kicker"></h5>
    <h1 v-if="itemDetails && itemDetails.title" class="product-details-headline" v-html="itemDetails.title"></h1>
    <h1 v-else>???{{this.contentID}}???</h1>
    <div v-if="itemDetails && itemDetails.description" class="product-details-text" v-html="itemDetails.description"></div>
  </div>
</template>

<script>

import { AttachmentsMixin, DB } from "@profineberater/configurator-clientlib"
import CmsMixin from "@/mixins/CmsMixin.vue"

const database = DB.CONTENTDB

export default {
  name: 'ProductDetailItem',
  mixins: [AttachmentsMixin, CmsMixin],
  props: {
    category: Object,
    contentID: String
  },
  computed: {
    categoryOptions() {
      let opts = this.$store.getters["configurationOptions/" + this.category.key]
      console.log("opts", opts)
      if (!opts) {
        opts = this.$store.state.configurationOptions[this.category.key]
      }
      return opts
    },
    metadata() {
      let metadata = this.$store.getters["configurationOptions/metadataForKey"](this.key)
      if (!metadata) {
        console.error("No metadata found for ", this.contentID)
        return {}
      }
      return metadata
    },
    itemDetails() {
      return this.details[this.contentID]
    },
    title() {
      return this.metadata?.title || "??? " + this.contentID
    },
  },
  methods: {
    selectItem(item) {
      if (item == this.selectedItemId) {
        this.$store.commit("configuration/" + this.category.configurationKey, null)
      } else {
        this.$store.commit("configuration/" + this.category.configurationKey, item)
      }
    },
    loadAttachments(content) {
      let detail = content || this.itemDetails
      if (!detail || !detail.cmsid) return
      if (detail.media) {
        this.$store.dispatch("db/getImageAttachment", {
          db: database,
          documentId: "cms-" + detail.cmsid,
          attachmentId: detail.media
        }, {
          root: true
        }).then(imageUrl => {
            this.attachmentIds.add(detail.media)
            console.log("attachment", imageUrl, detail.media)
        })
      }
    }
  },
  watch: {
    details(newDetails) {
      if (newDetails?.[this.itemDetails.cid]?.media) {
        this.loadAttachments(newDetails?.[this.itemDetails.cid])
      }
    }
  },
  mounted() {
    this.loadAttachments()
  },
}
</script>

<style scoped>
.product-details-img {
  margin-bottom: 20px;
}
.product-details-img img {
  max-height: 30vh;
}
.product-details-kicker {
  font-size: 15px;
  color: var(--primary-color);
  line-height: 1.1em;
  letter-spacing: 0.004em;
  margin-bottom: 15px;
}
.product-details-headline {
  font-size: 36px;
  font-weight: 400;
  line-height: 1.1em;
  margin-bottom: 20px;
  letter-spacing: 0.004em;
}
.product-details-text {
  font-size: 15px;
  color: rgba(103, 109, 124, 1);
  letter-spacing: 0.04em;
}
@media only screen and (min-width : 1024px) {
  .product-details-text {
    padding: 0 60px 20px;
  }
}
</style>

<style>
.products-details-slide td {
  padding: 10px 0;
}
.products-details-slide table {
  margin: 20px 0;
}
.products-details-slide p {
  line-height: 1.5;
  margin: 20px 0;
}
.products-details-slide ul, .products-details-slide ol {
  margin: 20px 20px;
}
.products-details-slide blockquote {
  font-style: italic;
  font-weight: 500;
}
.products-details-slide h1 {
  margin: 20px 0;
}
.products-details-slide h2 {
  margin: 20px 0;
}
.products-details-slide h3 {
  margin: 20px 0;
}
.products-details-slide h5 {
  margin: 20px 0;
}
.products-details-slide li {
  text-align: left;
}

.products-details-slide p {
  line-height: 1.5;
  margin: 20px 0;
}
.products-details-slide {
  padding: 40px 0;
  height: 75vh;
  overflow-y: scroll;
}
.products-details-slide audio {
  margin: 20px 0;
}
.product-details-text img, .product-details-text video, .product-details-text audio {
  max-width: 100%;
}
</style>