import {
  createStore
} from 'vuex'

import { ui } from './ui.module';
import { configuration } from './configuration.module';
import { configurationProcess } from './configurationProcess.module';

import {
  configurationOptionsStoreModule as configurationOptions,
  projectStoreModule as project,
  dbStoreModule as db,
  userStoreModule as user,
  contentModule as content,
  teamModule as team,
} from '@profineberater/configurator-clientlib';

const store = createStore({
  modules: {
    configurationProcess,
    configurationOptions,
    configuration,
    project,
    db,
    ui,
    user,
    content,
    team,
  }
});

export default store