<template>
  <div class="scribbles">
    <section>
      <PageSectionHeader :title="numberOfScribblesTitle" />
      <div class="scribbles-list">
        <div class="scribble-card" v-for="(scribble, index) in scribbles" v-bind:key="index">
          <SimpleCard @click="$emit('scribble:edit', scribble)">
            <CloseIcon class="delete-action" @click.stop.prevent="$emit('scribble:delete', scribble)" />
            <img v-if="attachments[scribble.originalAttachmentId]" :src="attachments[scribble.originalAttachmentId]" />
            <img v-else src="@/assets/img/placeholder-image.png">
          </SimpleCard>
          <p class="title">
            {{ scribble.title }} <span v-if="scribble.positionNumber" class="position-number">({{ scribble.positionNumber }})</span>
            <br><span class="date" v-html="createdAt(scribble)"></span>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import PageSectionHeader from "@/components/page/PageSectionHeader.vue"
import { AttachmentsMixin, CloseIcon, SimpleCard } from "@profineberater/configurator-clientlib"
import DateMixin from "@/mixins/DateMixin.vue"

export default {
  name: 'Scribbles',
  mixins: [AttachmentsMixin, DateMixin],
  components: {
    SimpleCard,
    PageSectionHeader,
    CloseIcon
  },
  data: function() {
    return {
      scribbleToBeDeleted: null
    }
  },
  computed: {
    project() {
      return this.$store.getters["project/currentProject"]
    },
    scribbles() {
      return this.project?.scribbles || []
    },
    numberOfScribblesTitle() {
      let num = this.scribbles.length
      switch (num) {
        case 0: return "Keine Zeichnung"
        case 1: return "1 Zeichnung"
        default: return num + " Zeichnungen"
      }
    },
  },
}
</script>

<style scoped>
.scribbles-list {
  text-align: left;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.scribble-card {
  margin: 10px;
  width: unset;
  text-align: center;
  font-size: 14px;
  width: calc(25% - 20px);
  min-height: 140px;
  position: relative;
}
span.date {
  color: var(--label-color);
  font-size: 12px;
}
/* span.position-number {
  color: var(--darkest-text-color);
} */
:deep(.simple-card > img) {
  width: 100%;
}
p.title {
  margin-top: 20px;
  font-weight: 500;
  color: var(--darkest-text-color);;
}
.delete-action {
  position: absolute;
  right: 10px;
  top: 10px;
}

@media only screen and (max-width : 1023px) {

  .scribble-card {
    width: calc(50% - 20px);
  }
}

</style>