<template>
  <div></div>
</template>

<script>

export default {
  name: "WindowRailsLayoutMixin",
  methods: {
    canSetVerticalCount(path) {
      return path ? this.$store.getters["configurationOptions/treeValueForKeypath"](`${path}.canSetVerticalCount`) || false : false
    },
    canSetHorizontalCount(path) {
      return path ? this.$store.getters["configurationOptions/treeValueForKeypath"](`${path}.canSetHorizontalCount`) || false : false
    },
    canSetTopPadding(path) {
      return path ? this.$store.getters["configurationOptions/treeValueForKeypath"](`${path}.canSetTopPadding`) || false : false
    },
  }
};
</script>
