<template>
  <div class="page-title">
    <slot name="page-title"></slot>
  </div>
</template>

<script>

export default {
  name: "PageTitle",
};
</script>

<style>
.page-title {
  align-self: flex-start;
  margin: 0 10px;
}
.page-title h1 {
  text-align: left;
  font-weight: 500;
  margin: 0 0 4px 0;
  font-size: 30px;
}
.page-title p {
  color: var(--text-color);
  font-size: 16px;
  text-align: left;
}
</style>