<template>
  <div class="custom-renderer double-sided-color-renderer">
    <span class="description double-sided" v-if="configuration.isDoubleSidedColor === true">Beidseitig</span>
    <span class="description single-sided" v-else>Einseitig</span>
  </div>
</template>

<script>

export default {
  name: "DoubleSidedColorRenderer",
  props: {
    configuration: {
      type: Object
    }
  }
}
</script>

<style scoped>
</style>

