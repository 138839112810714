<template>
  <div class="info-text">
    <img width="24" height="24" src="@/assets/svg/info-icon.svg">
    <p>Um eine Fensterbank {{ windowLedgeType == "inner" ? "innen" : "aussen" }} konfigurieren zu können, tragen Sie im Bereich "Maße" einen Wert in das Eingabefeld Fenster&shy;bankanschluss&shy;profil ein.</p>
    <p><a @click.stop="jumpToOddLegFrameExtension()">Jetzt Wert eintragen</a></p>
  </div>
</template>

<script>

export default {
  name: "WindowLedgeInfoText",
  props: {
    configuration: {
      type: Object
    },
    windowLedgeType: {
      type: String
    }
  },
  methods: {
    jumpToOddLegFrameExtension() {
      this.$store.commit("configurationProcess/currentStepKey", "dimensions")
      setTimeout( () => {
        document.getElementById("configurator-category-baseProfile").scrollIntoView()
      }, 200)
    }
  }
}
</script>

