<template>
  <div :class="'app' + (!isTabBarHidden ? ' has-tabbar' : '') ">
    <div v-show="isSyncing" class="sync-status">
      <span class="info" v-show="isSyncing">{{ syncStatusLabel }}</span><AnimatedSyncIcon />
    </div>
    <!-- <div v-show="false" class="confirmations">isUserDbAvailable: {{ isUserDbAvailable }}, lastFullSyncUserDB: {{ $store.getters['user/lastFullSyncUserDB'] }}, lastFullSyncContentDB: {{ $store.getters['user/lastFullSyncContentDB'] }}</div> -->
    <router-view v-slot="{ Component }">
      <!-- <div class="debug" v-if="project">{{ project._id }} - {{ project._rev }}</div> -->
      <transition :name="transitionName">
        <component :is="Component" />
      </transition>
    </router-view>
    <transition name="tabbar">
      <TabBar v-if="!isTabBarHidden" />
    </transition>
    <AlertScreen :isPresented="$store.state.ui.globalError.visible">
      <template #icon>
        <img src="@/assets/img/alert/alert@2x.png" />
      </template>
      <template #message>{{$store.state.ui.globalError.message}}</template>
      <template #content>{{$store.state.ui.globalError.description}}</template>
      <template #actions>
        <button class="button primary full-width" @click="$store.commit('ui/showGlobalError', false)">Verstanden</button>
      </template>
    </AlertScreen>
    <AlertScreen :isPresented="$store.getters['ui/isBlockingSyncInProgress']">
      <template #icon>
        <AnimatedLoader />
        <!-- <img src="@/assets/img/alert/alert@2x.png" /> -->
      </template>
      <template #message>Synchronisiere</template>
      <template #content>Bitte warten Sie einen Moment, während die Daten für die App synchronisiert werden.</template>
      <template #actions>
      </template>
    </AlertScreen>
    <ContextMenu ref="contextMenu" />
    <ErrorMessage v-if="$store.state.ui.error" />

    <CookieConsent />
  </div>
</template>

<script>

import TabBar from "@/components/tabBar/TabBar.vue"
// import { Workbox } from 'workbox-window';
import "@profineberater/configurator-clientlib/dist/clientlib-global.css"
import "@profineberater/configurator-clientlib/dist/library.mjs.css"
import AnimatedLoader from "./components/ui/AnimatedLoader.vue"
import {
  CookieConsent,
  AttachmentsMixin,
  updateGASettings,
  AlertScreen,
  AnimatedSyncIcon,
  ErrorMessage } from "@profineberater/configurator-clientlib"
import ContextMenu from "@/components/ui/ContextMenu.vue"

const reportInterval = 300000 // 300000 milliseconds = 5 minutes
var lastReportTimestamp = 0

export default {
  name: "App",
  mixins: [AttachmentsMixin],
  components: {
    TabBar,
    AlertScreen,
    CookieConsent,
    AnimatedLoader,
    ContextMenu,
    AnimatedSyncIcon,
    ErrorMessage
  },
  data() {
    return {
      transitionName: "",
      shouldHideTabBar: true,
      shouldHideTabBarOnSmartphone: true
    }
  },
  created() {
    window.addEventListener('offline', () => {
      this.$store.commit("ui/isOffline", true)
    })

    window.addEventListener('online', () => {
      this.$store.commit("ui/isOffline", false)
    })
    this.$store.commit("ui/isOffline", !navigator.onLine)

    window.$gtag = this.$gtag
    if (navigator.onLine) {
      updateGASettings()
    }
  },
  mounted() {
    let mql = window.matchMedia("(max-width: 1024px)")
    mql.addEventListener("change", () => {
      this.$store.commit("ui/isSmartphone", mql.matches)
    });
    //console.log("localDB, remoteDB", window.$localDB, window.$remotedDB)
    window.showGlobalError = (show, message, description) => {
      this.$store.commit("ui/globalError",
      {
        visible: show,
        message: message,
        description: description
      })
    }
    if (!this.isContentDbAvailable) {
      this.$store.commit("ui/setBlockingSyncInProgressDeferred")
    }
    setInterval(this.report, reportInterval);

  },
  computed: {
    appUpdateAvailable() {
      return this.$store.getters["ui/appUpdateAvailable"]
    },
    project() {
      return this.$store.getters["project/currentProject"]
    },
    isTabBarHidden() {
      return this.$store.state.ui.isSmartphone ? (this.shouldHideTabBarOnSmartphone || this.shouldHideTabBar) : this.shouldHideTabBar
    },
    isContentDbAvailable() {
      if (this.$store.state.ui.isOffline) {
        return window.$localContentDB != undefined
      }
      return this.$store.getters['db/contentDbAvailable']
    },
    isUserDbAvailable() {
      return this.$store.getters['db/userDbAvailable']
    },
    profile() {
      return this.$store.getters["user/profile"]
    },
    isSyncing() {
      return !this.$store.state.ui.error && (this.$store.getters["ui/isBlockingSyncInProgress"] || this.$store.getters["ui/isUserDBSyncInProgress"])
    },
    syncStatusLabel() {
      let status = this.$store.getters['db/userDbSyncStatus']
      switch (status) {
        case "change": // handle change
          return "Synchronisiere ..."
        case "paused": // replication paused (e.g. replication up to date, user went offline)
          return "Synchronisierung abgeschlossen."
        case "active": // replicate resumed (e.g. new changes replicating, user went back online)
          return "Synchronisiere ..."
        case "denied": // a document failed to replicate (e.g. due to permissions)
          return "Zugriff verweigert"
        case "error":
          return "Fehler"
        case "complete":
          return "Synchronisierung beendet."
        default:
          return "Synchronisiert."
      }
    }
  },
  methods: {
    onDismissNotification() {
      this.$store.commit("ui/appUpdateAvailable", null)
    },
    debug(data) {
      //debugger
      console.log(data);
    },
    report() {
      let now = Date.now()
      if (lastReportTimestamp > 0 && (lastReportTimestamp + reportInterval - 1000 > now)) {
        return
      }
      lastReportTimestamp = now
      this.$store.dispatch("user/updateLastUsage", "configurator-frontend")
    }
  },
  watch: {
    $route(to) {
      this.shouldHideTabBar = to.meta.tabBarHidden  || false
      this.shouldHideTabBarOnSmartphone = to.meta.tabBarHiddenOnSmartphone  || false
      let pendingTransition = this.$store.getters["ui/pendingTransition"]

      if (pendingTransition) {
        this.transitionName = pendingTransition
        this.$store.commit("ui/pendingTransition", null)
      } else if (to.meta.backNavigation) {
        this.transitionName = to.meta.backNavigation.transitionName || "next"
      } else {
        this.transitionName = ""
      }
      if (to.query?.t) {
        this.transitionName = to.query.t
        this.$store.commit("ui/pendingTransition", null)
      }
    },
    isContentDbAvailable(available, wasAvailable) {
      if (!wasAvailable && available) {
        this.$store.dispatch("configurationOptions/fetchAllOptions");
        this.$store.dispatch("content/fetchDetails");
      }
    },
    isUserDbAvailable(available, wasAvailable) {
      if (!wasAvailable && available) {
        this.$store.dispatch("user/fetchProfile").then( () => {
          if (lastReportTimestamp == 0) {
            this.report();
          }
        });
        this.$store.dispatch("user/fetchAssortment");
      }
    },
  },
};
</script>

<style src="./fonts.css"></style>
<style src="./global.css"></style>

<style scoped>
.notification {
  position: fixed;
  bottom: 100px;
  left: 0;
  width: 100%;
  border-radius: 7px;
  height: 80px;
  background-color: var(--primary-color);
  color: white;
  padding: 20px;
}
.notification a {
  color: #fff;
}

/* Transitions */

:root {
  /* --slide-animation-easing: cubic-bezier(0.165, 0.84, 0.44, 1); */
  --slide-animation-easing: ease-in-out;
}
.next-leave-from {
  z-index: 1;
}
.next-leave-to {
  animation: leaveToLeft 250ms both ease-in-out;
}

.next-enter-from {
  transform: translateX(100%);
  z-index: 1;
}

.next-enter-to {
  animation: enterFromRight 250ms both ease-in-out;
  z-index: 0;
}

.prev-leave-from {

}
.prev-leave-to {
  z-index: 1;
  animation: leaveToRight 250ms both ease-in-out;
}

.prev-enter-from {
  transform: translateX(100%);
}

.prev-enter-to {
  animation: enterFromLeft 250ms both ease-in-out;
}

@keyframes leaveToLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-25%);
    /* filter: brightness(0.75); */
    opacity: 0.5;
  }
}

@keyframes enterFromLeft {
  from {
    transform: translateX(-25%);
    /* filter: brightness(0.75); */
    opacity: 0.5;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes leaveToRight {
  from {
    transform: translateX(0);
    /* opacity: 0.5; */
  }
  to {
    transform: translateX(100%);
    opacity: 1;
  }
}

@keyframes enterFromRight {
  from {
    transform: translateX(100%);
    opacity: 1;
  }
  to {
    transform: translateX(0);
    opacity: 0.5;
  }
}


/* Zoom Transition */

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  /* animation-direction: reverse; */
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}

/* Slide in (TabBar) */

.tabbar-enter-from {
  transform: translateY(var(--tab-bar-height));
  transition: all 0.5s ease-in-out;
}
.tabbar-enter-to {
  transform: translateY(0);
  transition: all 0.5s ease-in-out;
}

.tabbar-leave-from {
  transform: translateY(0);
  transition: all 0.5s ease-in-out;
}
.tabbar-leave-to {
  transform: translateY(var(--tab-bar-height));
  transition: all 0.5s ease-in-out;
}


/* Fade */

.fade-enter-from {
  opacity: 0;
  /* transform: translateX(3rem); */
}
.fade-enter-active {
  transition: all 0.25s ease-in;
}
.fade-enter-to{
  opacity: 1;
  /* transform: translateX(0); */
}


/*
  v-enter-from: Starting state for enter. Added before the element is inserted, removed one frame after the element is inserted.
  v-enter-active: Active state for enter. Applied during the entire entering phase. Added before the element is inserted, removed when the transition/animation finishes. This class can be used to define the duration, delay and easing curve for the entering transition.
  v-enter-to: Ending state for enter. Added one frame after the element is inserted (at the same time v-enter-from is removed), removed when the transition/animation finishes.
  v-leave-from: Starting state for leave. Added immediately when a leaving transition is triggered, removed after one frame.
  v-leave-active: Active state for leave. Applied during the entire leaving phase. Added immediately when a leave transition is triggered, removed when the transition/animation finishes. This class can be used to define the duration, delay and easing curve for the leaving transition.
  v-leave-to: Ending state for leave. Added one frame after a leaving transition is triggered (at the same time v-leave-from is removed), removed when the transition/animation finishes.
*/

@media only screen and (min-width : 1024px) {

.tabbar-enter-from {
  transform: translateX(calc(-1 * var(--tab-bar-height)));
}
.tabbar-enter-to {
  transform: translateX(0);
}

.tabbar-leave-from {
  transform: translateX(0);
}
.tabbar-leave-to {
  transform: translateX(calc(-1 * var(--tab-bar-height)));
}

}

.debug {
  position: absolute;
  z-index: 99999999;
  top: 10px;
  left: 220px;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
  color: #fff;
  background: rgba(200, 0, 0, 0.5);
}
.sync-status {
  position: absolute;
  z-index: 99999999;
  top: 0;
  left: 0;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  width: 100vw;
  padding-top: 4px;
  height: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all 1.0s ease-in-out;
}

.sync-status .info {
  color: #cad1d9;
  transition: all 1.0s ease-in-out;
}
.confirmations {
  position: absolute;
  z-index: 99999999;
  top: 30px;
  left: 10px;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
  color: #fff;
  background: rgba(200, 0, 0, 0.5);
}
</style>