<template>
  <svg :class="'dots-icon' + (active ? ' active' :'')" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" @click="onClick">
    <g fill="currentColor">
      <circle cx="9" cy="9" r="2" />
      <circle cx="16" cy="9" r="2" />
      <circle cx="2" cy="9" r="2" />
    </g>
  </svg>
</template>

<script>

export default {
  name: 'DotsIcon',
  data: function() {
    return {
      active: false
    }
  },
  methods: {
    onClick() {
      this.active = !this.active
      this.$emit('click')
    },
  },
  computed: {
    isContextMenuActive() {
      return this.$store.getters["ui/contextMenuState"]?.active
    }
  },
  watch: {
    isContextMenuActive: function(newValue) {
      if (!newValue) {
        this.active = false
      }
    }
  }
}
</script>

<style scoped>
.dots-icon {
  display: block;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.active {
  color: #D3D7E3;
}
</style>