<template>
  <div></div>
</template>
<script>

export default {
  methods: {
    categoryTitle(category, config) {
      if (category.key == "color") {
        switch (config.material) {
          case "holz/alu":
          case "alu/pvc":
            return "Innenfarbe"
          default:
          return "Farbe"
        }
      }
      return category.title
    },
    metadata(item) {
      return this.$store.getters["configurationOptions/metadataForKey"](item)
    },
    isNone(configurationValue) {
      let metadata = this.metadata(configurationValue)
      return metadata?.isNone
    },
    isDisabled(category, configuration) {
      let isDisabled = false
      for (var i=0; i<category.dependsOn?.length || 0; i++) {
        let dependentKey = category.dependsOn[i]
        let dependentValue = configuration[dependentKey]

        if (!dependentValue || this.isNone(dependentValue))
        {
          isDisabled = true
          break
        }
      }
      return isDisabled
    },
  },
};
</script>
