<template>
 <div class="add-button">
    <svg xmlns="http://www.w3.org/2000/svg" width="15.809" height="15.809" viewBox="0 0 15.809 15.809">
      <g transform="translate(0.75 0.75)">
        <g transform="translate(7.154)">
          <path d="M4477.415,812.233v14.309" transform="translate(-4477.415 -812.233)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/>
        </g>
        <g transform="translate(14.309 7.154) rotate(90)">
          <path d="M0,0V14.309" transform="translate(0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/>
        </g>
      </g>
    </svg>
  </div>
 </template>

<script>
export default {
  name: "AddButton",
}
</script>

<style scoped>
  .add-button {
    width: 54px;
    height: 54px;
    background-color: var(--primary-color);
    border-radius: 27px;
    box-shadow: 0 0 20px rgba(102, 119, 142, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 20px;
    bottom: 40px;
    cursor: pointer;
    z-index: 1000;
  }
  .app.has-tabbar .add-button {
    bottom: calc(var(--tab-bar-height) + 20px);
  }

  @media only screen and (min-width : 1024px) {
    .app.has-tabbar .add-button {
      bottom: 40px;
    }
  }
</style>