<template>
  <BottomSheet ref="bottomSheet">
    <template #bottom-sheet-title>
      <slot name="bottom-sheet-title">Projekt löschen</slot>
    </template>
    <template #bottom-sheet-content>
      <slot name="bottom-sheet-content" v-if="project && customer">
        <h3>{{ customer.firstName }} {{ customer.lastName }}</h3>
        <p v-if="isDeleteProjectDisabled" class="info">Das Projekt kann nur vom Ersteller gelöscht werden.</p>
      </slot>
    </template>
    <template #bottom-sheet-actions>
      <button :disabled="isDeleteProjectDisabled" class="button primary login tall" @click="onDelete()">
        <span>Löschen</span>
      </button>
      <button class="button secondary login tall" @click="onCancelDelete()">
        <span>Abbrechen</span>
      </button>
    </template>
  </BottomSheet>
</template>

<script>

import BottomSheet from "@/components/modal/BottomSheet.vue";

export default {
  name: "DeleteProjectSheet",
  components: {
    BottomSheet
  },
  computed: {
    project() {
      return this.$store.getters["project/currentProject"]
    },
    customer() {
      return this.project?.customer
    },
    isDeleteProjectDisabled() {
      return this.$store.getters["team/isProjectCheckedOut"](this.project)
    }
  },
  methods: {
    onDelete() {
      this.dismiss()
      this.$store.dispatch("project/remove", this.project)
      this.$store.commit("project/currentProject", null)
      this.$router.replace({ path: "/projects" })
    },
    onCancelDelete() {
      this.dismiss()
    },
    present() {
      this.$refs.bottomSheet.present()
    },
    dismiss() {
      this.$refs.bottomSheet.dismiss()
    }
  },
};
</script>

<style scoped>
.configurations-page.no-content {
  height: 80%;
}
.thumb {
  max-height: 26vw;
  cursor: pointer;
}
p.info {
  margin-top: 20px;
}
</style>