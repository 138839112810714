<template>
  <div class="measurement-model-page">

<AppLayout>
  <template #right-navigation-item>
    <MoreNavigationItem @click="onContextMenu" />
  </template>
  <template #page-title>
    <h1>All hinges</h1>
  </template>
  <template #page>
<section>
      <h2></h2>
      <ul>
        <li v-for="(hinge, index) in allHinges" v-bind:key="index">
          {{ hinge.name }}
        </li>
      </ul>
    </section>
    <section>
      <h2>All hinge combinations</h2>
      <ul>
        <li v-for="(combination, index) in allHingeCombinations" v-bind:key="index">
          {{ combination.layout.name }} - {{ combination.name }}
          <pre>=VLOOKUP("{{ combination.name }}",Combinations!$E$15:$L$11626,1)</pre>
        </li>
      </ul>
      {{ allHingeCombinations.length }} total
    </section>
  </template>
</AppLayout>


  </div>
</template>

<script>
import HingeModel from "@/model/HingeModel.js";
import LayoutModel from "@/model/LayoutModel.js";
import AppLayout from "@/layouts/AppLayout.vue";

export default {
  name: "MeasurementModelPage",
  components: {
    AppLayout,
  },
  mounted() {},
  computed: {
    allHinges() {
      return HingeModel.allHingeModels();
    },
    allHingeCombinations() {
      let hinges = Object.values(this.allHinges).map((h) => h.name);
      let combinations = hinges.flatMap((v, i) =>
        hinges.slice(i + 1).map((w) => v + " / " + w)
      );
      var quantity;
      combinations = combinations.map((c) => {
        quantity = (c.match(/\//g) || []).length;
        return {
          name: c,
          layout: LayoutModel.layoutByQuantity(quantity),
        };
      });
      return combinations;
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
}
</style>