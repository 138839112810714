<template>
      <div :class="'configuration-step-navigation' + (numbersOnly ? ' numbers-only' : '')">
        <ul>
          <li v-if="numbersOnly" class="current-step-title">{{ currentStepTitle }}</li>
          <li v-for="(item, index) in steps"
              v-bind:key="item.index"
              :class="(currentStepKey == item.key ? 'active' : '')"
              :data-testid="item.key"
              @touchstart="currentStepKey = item.key"
              @mousedown="currentStepKey = item.key">
            <div class="title">{{ numbersOnly ? (index + 1) : item.title}}</div>
          </li>
        </ul>
      </div>
</template>

<script>

export default {
  name: 'ConfigurationStepNavigation',
  props: {
    numbersOnly: {
      type: Boolean,
    }
  },
  methods: {
      stepValidates(stepKey) {
        return this.$store.getters["configurationProcess/stepValidates"](stepKey)
      }
  },
  computed: {
      steps() {
          return this.$store.state.configurationProcess.steps
      },
      currentStepKey: {
          get() {
            return this.$store.state.configurationProcess.currentStepKey
          },
          set(key) {
            this.$store.commit("configurationProcess/currentStepKey", key)
          }
      },
      currentStepTitle() {
        return this.steps.find( s => s.key == this.currentStepKey )?.title
      },
      currentStepValidates() {
        return this.$store.getters["configurationProcess/stepValidates"](this.currentStepKey)
      },
      enabledList() {
        let list = []
        let subsequentValidation
        this.steps.forEach( step => {
          let validates = this.stepValidates( step.key )
          subsequentValidation = true //&= validates
          list.push(validates && subsequentValidation)
        })
        return list
      }
  }
}
</script>

<style scoped>
.configuration-step-navigation {
  background: #edeff3;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.15);
  offset-position: -1;
  border-radius: 7px;
  font-weight: 400;
  letter-spacing: 0.35px;
  font-size: 13px;
  min-height: 44px;
  max-height: 44px;
  margin: 0 0 40px 20px;
  color: var(--darkest-text-color);
  margin-top: 1px;
  display: inline-block;
  margin: auto;
}
ul {
  list-style-type: none;
  display: block;
}
li {
  cursor: pointer;
  float: left;
  height: 44px;
  line-height: 44px;
  border-radius: 7px;
  margin: 0;
  padding: 0 20px;
}
li:last-of-type {
  margin: 0 2px 0 2px;
}

.numbers-only li {
  padding: 0 12px;
  width: 44px;
}
li.active {
  background: #fff;
  height: 40px;
  line-height: 40px;
  margin-top: 2px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(63, 95, 137, 0.15);
}

li a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

/* li a:hover {
  color: #1BA0FB;
} */

li.disabled {
  color: rgb(172, 187, 205);
  cursor: not-allowed;
}

.configuration-step-navigation li.active.disabled {
  color: rgb(117 180 255);
}
li.current-step-title {
  width: 95px;
  color: var(--text-color);
  cursor: default;
}
.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.title svg {
  margin-right: 10px;
}
</style>
