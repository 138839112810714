<template>
  <div class="custom-selector double-sided-color-selector">
    <DropDown :options="options" v-model="configuration.isDoubleSidedColor" data-testid="isDoubleSidedColor" placeholder="Bitte wählen" :allowNoSelection="false" />
    <span class="description double-sided" v-if="configuration.isDoubleSidedColor === true">Beidseitig mit Folie beschichtet.</span>
    <span class="description single-sided" v-if="configuration.isDoubleSidedColor === false">Außenseite mit Folie beschichtet, innen weiss.</span>
  </div>
</template>

<script>

import SelectorMixin from '../SelectorMixin.vue';
import { DropDown } from "@profineberater/configurator-clientlib"

export default {
name: "DoubleSidedColorSelector",
mixins: [SelectorMixin],
components: {
  DropDown
},
computed: {
  options() {
    return [
      {
        label: "Einseitig",
        value: false
      },
      {
        label: "Beidseitig",
        value: true
      }
    ]
    }
  },
  configuration() {
    return this.$store.getters["configuration/configuration"]
  },
}
</script>

<style scoped>
.double-sided-color-selector {
  background: #fff;
  padding: 40px 20px;
  margin: 0 15px;
  border-radius: 7px;
  box-shadow: var(--default-box-shadow);
}
.description {
  color: var(--text-color);
  font-size: 14px;
  text-align: left;
  display: block;
  width: 100%;
  padding-left: 20px;
}
</style>

