import { createWebHistory, createRouter } from "vue-router"

import { LoginPage, LogoutPage } from "@profineberater/configurator-clientlib"

import ConfiguratorPage from "@/views/ConfiguratorPage.vue"
import ConfigurationsPage from "@/views/ConfigurationsPage.vue"
import ProjectDetailsPage from "@/views/ProjectDetailsPage.vue"
import ProjectsPage from "@/views/ProjectsPage.vue"
import ArchivePage from "@/views/ArchivePage.vue"
import ScribblesPage from "@/views/ScribblesPage.vue"
import EditScribblePage from "@/views/EditScribblePage.vue"
import EditProjectPage from "@/views/EditProjectPage.vue"
import NotesPage from "@/views/NotesPage.vue"
import EditNotePage from "@/views/EditNotePage.vue"
import PhotosPage from "@/views/PhotosPage.vue"
import ProfilePage from "@/views/ProfilePage.vue"
import RootPage from "@/views/RootPage.vue"
import PdfPage from "@/views/PdfPage.vue"
import TosPage from "@/views/TosPage.vue"
import ImprintPage from "@/views/ImprintPage.vue"
import DpaPage from "@/views/DpaPage.vue"
import PrivacyPage from "@/views/PrivacyPage.vue"
import ReleaseNotesPage from "@/views/ReleaseNotesPage.vue"
import EndUserPrivacyTemplatePage from "@/views/EndUserPrivacyTemplatePage.vue"
import AuthErrorPage from "@/views/AuthErrorPage.vue"

import MeasurementModelPage from "@/views/dev/MeasurementModelPage.vue"

import DevPage from "@/views/dev/DevPage.vue"

import store from "@/store"

/*
  Query parameters used in this app:
    - p: project id
    - t: transition (Name of transition, e.g. "slide" or "none")
*/

const routes = [
  {
    path: '/',
    component: RootPage,
    meta: {
      tabBarHidden: true,
    },
    // redirect: () => {
    //   return { path: 'projects'}
    // }
  },
  {
    path: '/projects',
    meta: {
      tabBarHidden: true,
    },
    component: ProjectsPage
  },
  {
    path: '/archive',
    meta: {
      tabBarHidden: true,
      backNavigation: {
        title: "Zurück",
        path: "/projects",
        transitionName: "prev"
      }
    },
    component: ArchivePage
  },
  {
    path: '/project',
    meta: {
      needsProject: true,
      transparentNavigationBar: true,
      customAppLayoutClass: 'project-details',
      backNavigation: {
        title: "Kunden",
        path: "/projects",
        transitionName: "fade"
      }
    },
    component: ProjectDetailsPage,
  },
  {
    path: '/project/edit',
    meta: {
      needsProject: true,
      transparentNavigationBar: true,
      backNavigation: {
        title: "Zurück",
        path: "/project",
      }
    },
    component: EditProjectPage,
  },
  {
    path: '/configurations',
    meta: {
      needsProject: true,
      backNavigation: {
        title: "Projekt",
        path: "/project",
        transitionName: "fade"
      }
    },
    component: ConfigurationsPage,
  },
  {
    path: '/configurator',
    meta: {
      needsProject: true,
      needsConfiguration: true,
      backNavigation: {
        title: "Zurück",
        path: "/configurations",
        transitionName: "fade"
      },
      tabBarHiddenOnSmartphone: true,
    },
    component: ConfiguratorPage,
  },
  {
    path: '/scribbles',
    meta: {
      needsProject: true,
      backNavigation: {
        title: "Projekt",
        path: "/project",
        transitionName: "fade"
      }
    },
    component: ScribblesPage,
  },
  {
    path: '/scribbles/edit',
    meta: {
      needsProject: true,
      backNavigation: {
        title: "Skizzen",
        path: "/scribbles"
      }
    },
    component: EditScribblePage,
  },
  {
    path: '/notes',
    meta: {
      needsProject: true,
      backNavigation: {
        title: "Projekt",
        path: "/project",
        transitionName: "fade"
      }
    },
    component: NotesPage,
  },
  {
    path: '/notes/edit',
    meta: {
      needsProject: true,
      backNavigation: {
        title: "Notizen",
        path: "/notes"
      }
    },
    component: EditNotePage,
  },
  {
    path: '/photos',
    meta: {
      needsProject: true,
      backNavigation: {
        title: "Projekt",
        path: "/project",
        transitionName: "fade"
      }
    },
    component: PhotosPage,
  },
  {
    path: '/profile',
    component: ProfilePage,
    meta: {
      tabBarHidden: true,
      backNavigation: {
        title: "Kunden",
        path: "/projects",
        transitionName: "fade"
      }
    }
  },
  {
    path: '/pdf',
    component: PdfPage,
    meta: {
      backNavigation: {
        title: "Projekt",
        path: "/project",
      }
    }
  },
  {
    path: "/login",
    meta: {
      public: true,
      tabBarHidden: true,
    },
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/logout",
    meta: {
      tabBarHidden: true,
    },
    name: "Logout",
    component: LogoutPage,
  },

  {
    path: "/tos",
    meta: {
      tabBarHidden: true,
      backNavigation: {
        title: "Zurück",
        path: "/profile",
        transitionName: "fade"
      }
    },
    name: "Nutzungsbedingungen",
    component: TosPage,
  },

  {
    path: "/dpa",
    meta: {
      tabBarHidden: true,
      backNavigation: {
        title: "Zurück",
        path: "/profile",
        transitionName: "fade"
      }
    },
    name: "Auftragsverarbeitungsvertrag",
    component: DpaPage,
  },
  {
    path: "/enduserprivacytemplate",
    meta: {
      tabBarHidden: true,
      backNavigation: {
        title: "Zurück",
        path: "/profile",
        transitionName: "fade"
      }
    },
    name: "Vorlage für Datenschutzbestimmungen (Endkunden)",
    component: EndUserPrivacyTemplatePage,
  },

  {
    path: "/imprint",
    meta: {
      tabBarHidden: true,
      backNavigation: {
        title: "Zurück",
        path: "/profile",
        transitionName: "fade"
      }
    },
    name: "Impressum",
    component: ImprintPage,
  },

  {
    path: "/privacy",
    meta: {
      tabBarHidden: true,
      backNavigation: {
        title: "Zurück",
        path: "/profile",
        transitionName: "fade"
      }
    },
    name: "Datenschutz",
    component: PrivacyPage,
  },
  {
    path: "/releasenotes",
    meta: {
      tabBarHidden: true,
      backNavigation: {
        title: "Zurück",
        path: "/profile",
        transitionName: "fade"
      }
    },
    name: "Release Notes",
    component: ReleaseNotesPage,
  },
  // DEV
  {
    path: "/dev/measurementmodel",
    name: "Measurement Model",
    component: MeasurementModelPage,
    meta: {
      backNavigation: {
        title: "Dev",
        path: "/dev",
      }
    }
  },
  {
    path: "/dev",
    name: "Dev",
    component: DevPage,
    meta: {
      backNavigation: {
        title: "Kunden",
        path: "/projects",
      }
    }
  },
  {
    path: "/autherror",
    name: "AuthError",
    component: AuthErrorPage,
    meta: {
      tabBarHidden: true,
      backNavigation: {
        title: "Login",
        path: "/login",
      }
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function checkPreconditions(to, from, next) {
  let isProjectNeeded = to.meta?.needsProject
  let isConfigurationNeeded = to.meta?.needsConfiguration
  let isProjectSet = store.getters["project/currentProject"] != null
  let isConfigurationOptionsAvailable = store.getters["configurationOptions/options"]?.tree != undefined
  let isConfigurationSet = store.getters["configuration/configuration"] != null
  let isProjectIdInQuery = to.query?.p != null
  let isConfigurationIdInQuery = to.query?.c != null
  let backNavigation = to.meta?.backNavigation
  let isNavigationBarTransparent = to.meta?.transparentNavigationBar
  let customAppLayoutClass = to.meta?.customAppLayoutClass || ''

  // keep isEmbeed true until it is set to false or browser window is closed
  if (to.query.embedded == "true") {
    store.commit("ui/isEmbedded", true)
  } else if (to.query.embedded == "false") {
    store.commit("ui/isEmbedded", false)
  }

  // redirect is no project is set or given
  if (isProjectNeeded && (!isProjectSet && !isProjectIdInQuery)) {
    next({
      path: '/projects'
    })
    return
  }

  if (isConfigurationNeeded && (!isConfigurationSet || !isConfigurationOptionsAvailable)) {
    console.log(`No configuration set but needed for '${to.path}', redirecting to /configurations`)
    next({
      path: '/configurations'
    })
    return
  }

  // set current project if given in query
  if (isProjectIdInQuery) {
    console.log("Fetching project from query")

    store.dispatch("project/fetchProject", to.query.p).then( (doc) => {
      if (doc) {
        console.log("Setting current project from query")
        store.commit("project/currentProject", doc)
        if (isConfigurationIdInQuery) {
          let config = doc.configurations.find( c => c._id == to.query.c)
          store.commit("configuration/configuration", config)
        }
      } else {
        console.error("Project not found in query")
      }
    }).catch( () => {
      store.commit("project/currentProject", null)
      console.error("Failed to fetch project from query")
    })
  }

  // set back navigation if available in to.meta
  store.commit("ui/backNavigation", backNavigation)
  store.commit("ui/isNavigationBarTransparent", isNavigationBarTransparent)
  store.commit("ui/customAppLayoutClass", customAppLayoutClass)

  // proceed
  next()
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

router.beforeEach(
  async (to, from, next) => {
    let isPublic = to.meta?.public
    if (!isPublic) {
      // We wait for Keycloak init, then we can call all methods safely
      while (!window.$keycloak || window.$keycloak.createLoginUrl === null) {
        console.log("waiting for keycloak")
        await sleep(100)
      }

      if (window?.$keycloak?.authenticated) {
        // let tries = 0
        // while (store.getters["ui/isUserDBSyncInProgress"]) {
        //   console.log("waiting, user db sync in progress")
        //   if (tries++ > 10) {
        //      break
        //   }
        //   await sleep(100)
        // }
        checkPreconditions(to, from, next)
      } else {
        next({ path: "/login" })
      }
    } else {
      checkPreconditions(to, from, next)
    }
  }
)

export default router;
