<template>
    <AppLayout class="navbar-no-title-padding">
      <template #navigation-title>
        <div class="toggle-button-wrapper">
          <ToggleButton class="toggle-button" elId="toggleDrawMove" v-model="isScrollable">
            <template #toggle-option-1-name>Zeichnen</template>
            <template #toggle-option-2-name>Bewegen</template>
          </ToggleButton>
        </div>
      </template>
      <template #right-navigation-item>
        <MoreNavigationItem @click="onContextMenu" />
      </template>
      <template #page>
        <DrawingPad ref="drawingPad" :scribble="scribble" :project="project" :isScrollable="isScrollable" />
      </template>
      <template #modal>
        <BottomSheet ref="contextMenuBottomSheet"
          @sheet:presented="onBottomSheetDidPresent"
          @sheet:dismissed="onBottomSheetDidDismiss">
          <template #bottom-sheet-title>
            <slot name="bottom-sheet-title">Zeichnung</slot>
          </template>
          <template #bottom-sheet-content>
            <slot name="bottom-sheet-content">
              <TitleAndPositionInput v-model="properties" />
            </slot>
          </template>
          <template #bottom-sheet-actions>
            <button class="button primary" @click.stop="onAcceptEditProperties">Übernehmen</button>
            <button
              class="plain"
              @click.stop="onCancelEditProperties">
              Abbrechen
            </button>
          </template>
        </BottomSheet>
      </template>
    </AppLayout>
</template>

<script>

import { ref } from 'vue'
import AppLayout from "@/layouts/AppLayout.vue"
import ToggleButton from "@/components/buttons/ToggleButton.vue"
import MoreNavigationItem from "@/components/navigationBar/MoreNavigationItem.vue"
import BottomSheet from "@/components/modal/BottomSheet.vue"
import DrawingPad from "@/components/drawingPad/DrawingPad.vue"
import TitleAndPositionInput from '@/components/modal/TitleAndPositionInput.vue'
import { nanoid } from "nanoid"

export default {
  name: "EditScribblePage",
  components: {
    AppLayout,
    ToggleButton,
    MoreNavigationItem,
    BottomSheet,
    DrawingPad,
    TitleAndPositionInput
  },
  data() {
    return {
      saveOnLeave: true,
      saveOnBottomSheetDismiss: false,
      properties: {
        title: null,
        positionNumber: null
      },
    };
  },
  computed: {
    scribble: {
      get() {
        return this.$store.getters["project/currentScribble"]
      },
      set(value) {
        console.log("set currentScribble", value)
        this.$store.commit("project/currentScribble", value)
        this.saveOnBottomSheetDismiss = true
      }
    },
    project() {
      return this.$store.getters["project/currentProject"]
    }
  },
  methods: {
    onContextMenu() {
      this.properties = {
        title: this.scribble.title,
        positionNumber: this.scribble.positionNumber
      },
      this.$refs.contextMenuBottomSheet.present()
    },
    onBottomSheetDidPresent() {
      this.saveOnBottomSheetDismiss = false
    },
    onBottomSheetDidDismiss() {
      if (this.saveOnBottomSheetDismiss) {
        this.saveProject()
      }
    },
    onAcceptEditProperties() {
      this.scribble.title = this.properties.title
      this.scribble.positionNumber = this.properties.positionNumber
      this.saveOnBottomSheetDismiss = true
      this.$refs.contextMenuBottomSheet.dismiss()
    },
    onCancelEditProperties() {
      this.$refs.contextMenuBottomSheet.dismiss()
    },
    saveBlob() {
      return new Promise( (resolve, reject) => {
        let pad = this.$refs.drawingPad
        if (pad.canvasHasContent()) {

          if (!this.scribble.originalAttachmentId) {
            this.scribble.originalAttachmentId = "scribble-" + nanoid(10)
          }
          if (!this.scribble.drawDataAttachmentId) {
            this.scribble.drawDataAttachmentId = "drawdata-" + nanoid(10)
          }
          this.saveProject()

          pad.getBlob().then ( (blob) => {
            // save bitmap
            this.$store.dispatch("db/saveBlobToDB", {
              document: this.project,
              blob: blob,
              attachmentId: this.scribble.originalAttachmentId,
              mimetype: "image/png"
            }).then( () => {
              let history = pad.history
              // save strokes aka history
              this.$store.dispatch("db/saveBlobToDB", {
                document: this.project,
                blob: new Blob([JSON.stringify(history)], {type: "application/json"}),
                attachmentId: this.scribble.drawDataAttachmentId,
                mimetype: "application/json"
              }).then( () => {
                resolve()
              })
            }).catch( err => reject(err))
          })
        } else {
          resolve()
        }
      })
    },
    saveProject() {
      this.$store.dispatch("project/update", this.project)
    }
  },
  beforeRouteLeave (to, from, next) {
    let pad = this.$refs.drawingPad
    if ((pad.history || []).length == 0) {
      this.saveOnLeave = false
      this.$store.dispatch("project/deleteScribble", { project: this.project, scribble: this.scribble } )
    }

    if (this.saveOnLeave) {
      this.saveBlob().then( () => {
        next()
      })
    } else {
      next()
    }
  },
  setup() {
    const isScrollable = ref(false)
    return {
      isScrollable,
    }
  },
};
</script>

<style scoped>
:deep(.page) {
  padding: 0;
  overflow: none;
}
@media only screen and (max-width : 1023px) {
  .page {
    left: 0;
    width: 100%;
  }
  .app.has-tabbar .page {
    height: calc(100vh - var(--navigation-bar-height) - var(--tab-bar-height));
  }
  .toggle-button {
    transform: scale(0.75);
  }
}
</style>